import { FC, useEffect, useState } from 'react';
import * as Slider from '@radix-ui/react-slider';
import './ProgressComponent.css';

interface SliderComponentProps {
    initialProgress: number;
    fromLabel?: string;
    toLabel?: string;
    onValueChange?: (value: number) => void;
}

const SliderComponent: FC<SliderComponentProps> = ({ initialProgress, fromLabel, toLabel, onValueChange }) => {
    const [progress, setProgress] = useState(initialProgress);

    useEffect(() => {
        setProgress(initialProgress);
    }, [initialProgress]);

    const handleValueChange = (value: number[]) => {
        setProgress(value[0]);
        if (onValueChange) {
            onValueChange(value[0]);
        }
    };

    return (
        <div className="w-full">
            <Slider.Root
                className="relative flex items-center h-2 rounded-full bg-[#d2d4d6]"
                value={[progress]}
                onValueChange={handleValueChange}
                max={100}
                step={1}
            >
                <Slider.Track className="relative flex-1 h-2 rounded-full bg-[#d2d4d6]">
                    <Slider.Range className="absolute h-full bg-[#333] rounded-full" />
                </Slider.Track>
                <Slider.Thumb className="block w-5 h-5 bg-[#08a0e2] rounded-full" />
            </Slider.Root>
            {
                (fromLabel || toLabel) &&
                <div className="flex justify-between mt-2">
                    <span className="range-label text-sm">{fromLabel}</span>
                    <span className="range-label text-sm">{toLabel}</span>
                </div>
            }
            {/* <div className="mt-2 text-center text-sm">
                Current Value: {progress / 20}
            </div> */}
        </div>
    );
};

export default SliderComponent;