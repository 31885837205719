// Directly ported from
// https://github.com/transitive-bullshit/react-starfield-animation

import React, { useState, useEffect, useRef } from 'react';
import raf from 'raf';

type Bounds = { x: { min: number; max: number }; y: { min: number; max: number }; z: { min: number; max: number }; depth: number };

class Particle {

    x: number = 0;
    y: number = 0;
    z: number = 0;
    ox: number = 0;
    oy: number = 0;
    oz: number = 0;
    vx: number = 0;
    vy: number = 0;
    vz: number = 0;
    ax: number = 0;
    ay: number = 0;
    az: number = 0;
    s: number = 0;
    sx: number = 0;
    sy: number = 0;
    os: number = 0;
    osx: number = 0;
    osy: number = 0;
    hue: number = 0;
    lightness: number = 0;
    alpha: number = 0;
    sr: number = 1;

    _bounds: Bounds;

    constructor(bounds: Bounds) {
        this._bounds = bounds
        this.reset(true)
    }

    reset(init = false) {
        this.z = init ? random(0, this._bounds.z.max) : this._bounds.z.max
        const depth = (this._bounds.depth / (this._bounds.depth + this.z))

        this.x = random(this._bounds.x.min, this._bounds.x.max) / depth
        this.y = random(this._bounds.y.min, this._bounds.y.max) / depth
        this.ox = this.x
        this.oy = this.y
        this.oz = this.z
        this.vx = 0
        this.vy = 0
        this.vz = random(-2, -5)
        this.ax = 0
        this.ay = 0.001
        this.az = 0.001
        this.s = 0
        this.sx = 0
        this.sy = 0
        this.os = this.s
        this.osx = this.sx
        this.osy = this.sy
        this.hue = random(0, 359)
        this.lightness = random(40, 100)
        this.alpha = 0
    }

    update() {
        this.vx += this.ax
        this.vy += this.ay
        this.vz += this.az

        this.x += this.vx
        this.y += this.vy
        this.z += this.vz

        if (this.sx - this.sr > this._bounds.x.max ||
            this.sy - this.sr > this._bounds.y.max ||
            this.z > this._bounds.z.max ||
            this.sx + this.sr < this._bounds.x.min ||
            this.sy + this.sr < this._bounds.y.min ||
            this.z < this._bounds.z.min
        ) {
            this.reset()
        }

        this.ox = this.x
        this.oy = this.y
        this.oz = this.z
        this.os = this.s
        this.osx = this.sx
        this.osy = this.sy
    }
}

function random(min: number, max: number) {
    return Math.random() * (max - min) + min
}

interface StarfieldAnimationProps {
    numParticles?: number;
    alphaFactor?: number;
    lineWidth?: number;
    depth?: number;
    style?: React.CSSProperties;
    rest?: any;
}

const StarfieldAnimation: React.FC<StarfieldAnimationProps> = ({
    numParticles = 500,
    alphaFactor = 1,
    lineWidth = 2,
    depth = 300,
    style = {},
    ...rest
}) => {
    // const { numParticles, lineWidth, alphaFactor, depth, style, ...rest } = props;

    const [particles, setParticles] = useState<Particle[]>([]);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const divRef = useRef<HTMLDivElement | null>(null);
    const tickRafRef = useRef<number | null>(null);

    const size = { width: 0, height: 0 };

    useEffect(() => {
        const resize = () => {
            if (divRef.current) {
                const rect = divRef.current.getBoundingClientRect();
                size.width = rect.width;
                size.height = rect.height;
                vp.current.x = size.width / 2;
                vp.current.y = size.height / 2;
                bounds.current.width = size.width;
                bounds.current.height = size.height;
                bounds.current.x = { min: -vp.current.x, max: size.width - vp.current.x };
                bounds.current.y = { min: -vp.current.y, max: size.height - vp.current.y };
                bounds.current.z = { min: -depth, max: 1000 };
                canvasRef.current!.width = size.width;
                canvasRef.current!.height = size.height;

                reset();
            }
        }
        if (divRef.current && canvasRef.current) {
            window.addEventListener('resize', resize, { passive: true });
            resize();
        }
        return () => window.removeEventListener('resize', resize);
    }, [divRef.current, canvasRef.current])

    const vp = useRef({ x: size.width / 2, y: size.height / 2 });
    const bounds = useRef({
        depth,
        width: size.width,
        height: size.height,
        x: { min: -vp.current.x, max: size.width - vp.current.x },
        y: { min: -vp.current.y, max: size.height - vp.current.y },
        z: { min: -depth, max: 1000 },
    });


    useEffect(() => {
        if (!canvasRef.current) return;

        reset();
        tick();

        return () => {
            raf.cancel(tickRafRef.current!);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef.current]);

    useEffect(() => reset(), [numParticles, depth]);

    const reset = () => {
        const newParticles = [];
        for (let i = 0; i < numParticles; ++i) {
            newParticles.push(new Particle(bounds.current));
        }
        setParticles(newParticles);
    };

    const update = () => {
        particles.forEach(particle => particle.update());
    };

    const draw = () => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current.getContext('2d');
        if (!ctx) return;
        ctx.save();
        ctx.translate(vp.current.x, vp.current.y);
        ctx.clearRect(-vp.current.x, -vp.current.y, bounds.current.width, bounds.current.height);
        ctx.lineWidth = lineWidth;

        particles.forEach(p => {
            p.s = bounds.current.depth / (bounds.current.depth + p.z);
            p.sx = p.x * p.s;
            p.sy = p.y * p.s;
            p.alpha = alphaFactor * (bounds.current.z.max - p.z) / (bounds.current.z.max / 2);

            ctx.beginPath();
            ctx.moveTo(p.sx, p.sy);
            ctx.lineTo(p.osx, p.osy);
            ctx.strokeStyle = `hsla(${p.hue}, 17%, ${p.lightness}%, ${p.alpha})`;
            ctx.stroke();
        });

        ctx.restore();
    };

    const tick = () => {
        update();
        draw();
        tickRafRef.current = raf(tick);
    };

    return (
        <div
            style={{
                overflow: 'hidden',
                ...style
            }}
            {...rest}
            ref={divRef}
        >
            <canvas
                ref={canvasRef}
                width={size.width}
                height={size.height}
                className="bg-black"
            />
        </div>
    );
}

// StarfieldAnimation.defaultProps = {
//     numParticles: 500,
//     alphaFactor: 1,
//     lineWidth: 2,
//     depth: 300,
//     style: {},
// };

export default StarfieldAnimation;