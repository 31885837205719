import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { PropertyProps } from "./Property.interface";
import { Loader, SearchIcon, X } from "lucide-react";
import LocationIcon from "@/components/icons/LocationIcon";
import LocationGoogleMap from "./LocationGoogleMap";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import { convertDateToYYYYMMDD, formatCurrency } from "@/lib/utils"; //getTimeInDefault
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeader } from "@/components/ui/table";
import React, {  useState } from "react";
import { PropertyDetailsProps } from "./PropertyDetails.interface";
import { PROPERTY_COMPONENTS } from "@/constants/PropertyComponents";
import * as Switch from '@radix-ui/react-switch';

// import Properties from "../properties/Properties";

type PropertyDetailsModalProps = {
    isOpen: boolean;
    toggleModal: () => void;
    property: PropertyProps | undefined;
    details?: PropertyDetailsProps;
};

interface FilteredItem {
    key: string;
    value: string;
}

interface RenderFieldProps {
    key: string;
    title: string;
}

const PropertyDetailsModal: React.FC<PropertyDetailsModalProps> = ({ isOpen, toggleModal, property, details }) => {
    const [search, setSearch] = useState('');
    const [hideFields, setHideFields] = useState(false);

    if (!property) return null;

    const propertyDetails = details || property.details;
    // const taxAssessmentValue = property?.assessedValue;
    // const assignmentReleases = assignment_releases?.map((assignment) => assignment.assignment_uuid);
    // const taxAssessmentValue = (propertyDetails?.taxAssessment || []).length < 0
    //     ? (propertyDetails?.taxAssessment[0].assessedValue || 0)
    //     : 0;

    // const numberOfLiens = (propertyDetails?.mortgages?.filter(m => m.isOpen) ?? []).length;
    // const numberOfLiens = propertyDetails?.numberOfLiens; 
    // const countyName = (propertyDetails?.mortgages || []).map((mortgage) => mortgage.county.split(', ')[0])[0] || '';
    // const foreclosure = (propertyDetails?.mortgages || []).map((mortgage) => mortgage.transactionType)[0] || '';
    // console.log("foreclosure", propertyDetails?.mortgages.map((mortgage) => mortgage.transactionType));
    // console.log("is_open: " + propertyDetails?.mortgages.map((mortgage) => String(mortgage.isOpen) === 'true' ? 'tr' : 'F'));
    const estimatedMortgageBalance = propertyDetails?.estimated_mortgage_balance;
    // console.log('propertyId ' + propertyDetails?.propertyId);
    // console.log('is_open ' + JSON.stringify(propertyDetails?.foreclosureDocument));
    // console.log(''+ assignment_releases)

    const liens =
        [
            {
                amount: property.totalAmountinDefault,
                recordingDate: property.recordingDate,
                position: 'First',
                isTaxLien: property.taxLien === '1'
            }
        ];

    const filteredItems = PROPERTY_COMPONENTS.filter(item =>
        item.value.toLowerCase().includes(search.toLowerCase())
    );
    const clearSearch = () => {
        setSearch('');
    };

    const keyExistsInFilteredItems = (filteredItems: FilteredItem[], key: string): boolean => {
        return filteredItems.some(item => item.key === key);
    };

    const renderField = ({ key, title }: RenderFieldProps, filteredItems: FilteredItem[], hideFields: boolean, property: any) => {
        const hasKey = keyExistsInFilteredItems(filteredItems, key);
        const showField = hasKey && (!hideFields || property[key]);
        return (
            !!showField && (
                <div className="property-box border-2 m-1 p-2" key={key}>
                    <p className="property-box-title text-gray-500 text-sm">{title}</p>
                    <p className="property-box-description text-black text-sm font-bold">{typeof property[key] === "boolean" ? property[key] ? "Yes" : "No" : property[key]}</p>
                </div>
            )
        );
    };

    return (
        <Dialog open={isOpen} onOpenChange={toggleModal}>
            <DialogContent className="w-11/12 max-w-screen h-full outline-none overflow-x-hidden p-0 border-0">
                <DialogHeader>
                    <div className="flex w-full bg-[#3B4F72] text-white min-h-28 text-xl items-center">
                        <div className="w-1/3 ps-7">
                            <DialogTitle className="font-bold text-xl space-y-14 ">Property Details</DialogTitle>
                        </div>
                        <div className="flex w-2/3 pe-7">
                            <div className="mr-3 mt-1">
                                <LocationIcon />
                            </div>
                            <p className="font-semibold text-xl">{propertyDetails?.address}</p>
                        </div>
                    </div>
                </DialogHeader>
                <>
                    {
                        !propertyDetails
                            ? <div className="flex px-7"><Loader /> Loading...</div>
                            : (!propertyDetails.propertyId ? <div className="flex px-7">No data available</div> : null)
                    }
                    <div className="flex w-full gap-2">
                        <div className="w-1/3 pt-5 px-7">
                            <div className="h-[450px] ">
                                {
                                    propertyDetails?.longitude
                                    && propertyDetails?.latitude
                                    && <LocationGoogleMap
                                        longitude={propertyDetails.longitude}
                                        latitude={propertyDetails.latitude} />
                                }
                            </div>
                        </div>
                        {/* {console.log('>>>>>>>>property' , property)} */}
                        <div className="w-2/3 pr-3">
                            <div className="flex items-center mt-5">
                                <div className="flex-1">
                                    <div className="custom-search relative">
                                        <SearchIcon size={16} className="cursor-pointer custom-search-icon absolute left-2 top-1/2 transform -translate-y-1/2" />
                                        <input className="custom-search-input" type="text" placeholder="Search" value={search}
                                            onChange={e => setSearch(e.target.value)} />
                                        {!!search?.length && <X size={16} className="cursor-pointer custom-search-icon absolute right-2 top-1/2 transform -translate-y-1/2" onClick={() => clearSearch()} />}
                                    </div>
                                </div>

                                <div className="pr-5">
                                    <div className="flex items-center">
                                        <label className="  text-[15px] leading-none pr-[15px]" htmlFor="hide-fields">
                                            {hideFields ? "Show" : "Hide"} Empty Fields
                                        </label>
                                        <Switch.Root
                                            checked={hideFields}
                                            onCheckedChange={() => setHideFields(!hideFields)}
                                            className="w-[42px] h-[25px] bg-blackA6 rounded-full relative shadow-[0_2px_5px] shadow-blackA4 focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-black outline-none cursor-default"
                                            id="hide-fields"
                                        >
                                            <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-[0_2px_2px] shadow-blackA4 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                                        </Switch.Root>
                                    </div>
                                </div>

                            </div>
                            <div className="grid grid-cols-3 pt-4" style={{ maxHeight: '500px', overflow: 'auto' }}>
                                {filteredItems?.map((data: any) => {
                                    return renderField({ key: data.key, title: data.value }, filteredItems, hideFields, property)
                                })}
                            </div>
                            {/* <div className="grid grid-cols-3 pt-4" style={{ maxHeight: '500px', overflow: 'auto' }}>
                                {renderField({ key: "address", title: "Address" }, filteredItems, hideFields, property)}
                                {renderField({ key: "apn", title: "Apn" }, filteredItems, hideFields, property)}
                                {renderField({ key: "appliances", title: "Appliances" }, filteredItems, hideFields, property)}
                                {renderField({ key: "architecture_type", title: "Architecture Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "assessed_improvement_value", title: "Tax Assessed Improvement Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "assessed_land_value", title: "Tax Assessed Land Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "assessed_value", title: "Tax Assessed Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "assessment_year", title: "Tax Assessment Year" }, filteredItems, hideFields, property)}
                                {renderField({ key: "assetClassGroup", title: "Asset Class" }, filteredItems, hideFields, property)}
                                {renderField({ key: "auction_date", title: "Foreclosure Auction Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "auction_opening_bid", title: "Foreclosure Auction Opening Bid" }, filteredItems, hideFields, property)}
                                {renderField({ key: "auction_street_address", title: "Foreclosure Auction Street Address" }, filteredItems, hideFields, property)}
                                {renderField({ key: "auction_time", title: "Foreclosure Auction Time" }, filteredItems, hideFields, property)}
                                {renderField({ key: "basement_finished_percent", title: "Basement Finished Percent" }, filteredItems, hideFields, property)}
                                {renderField({ key: "basement_type", title: "Basement Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "borrowerCalc", title: "Borrower" }, filteredItems, hideFields, property)}
                                {renderField({ key: "carrier_route", title: "Carrier Route" }, filteredItems, hideFields, property)}
                                {renderField({ key: "census_block", title: "Census Block" }, filteredItems, hideFields, property)}
                                {renderField({ key: "census_block_group", title: "Census Block Group" }, filteredItems, hideFields, property)}
                                {renderField({ key: "census_tract", title: "Census Tract" }, filteredItems, hideFields, property)}
                                {renderField({ key: "city", title: "City" }, filteredItems, hideFields, property)}
                                {renderField({ key: "congressional_district", title: "Congressional District" }, filteredItems, hideFields, property)}
                                {renderField({ key: "construction", title: "Construction" }, filteredItems, hideFields, property)}
                                {renderField({ key: "construction_materials", title: "Construction Materials" }, filteredItems, hideFields, property)}
                                {renderField({ key: "county", title: "County" }, filteredItems, hideFields, property)}
                                {renderField({ key: "county_use_code", title: "County Use Code" }, filteredItems, hideFields, property)}
                                {renderField({ key: "defaultRecordingDate", title: "Default Recording Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "description", title: "Legal Description" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_bank_value", title: "Estimated Bank Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_equity", title: "Estimated Equity" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_equity_pct", title: "Estimated Equity Pct" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_lendable_equity", title: "Estimated Lendable Equity" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_mortgage_balance", title: "Estimated Mortgage Balance" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_mortgage_payment", title: "Estimated Mortgage Payment" }, filteredItems, hideFields, property)}
                                {renderField({ key: "estimated_value", title: "Estimated Market Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "exterior_wall_type", title: "Exterior Wall Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fips", title: "Fips" }, filteredItems, hideFields, property)}
                                {renderField({ key: "firstLienPositionMortgageLTV", title: "LTV" }, filteredItems, hideFields, property)}
                                {renderField({ key: "firstLienPositionMortgageLoanTerm", title: "Loan Term" }, filteredItems, hideFields, property)}
                                {renderField({ key: "firstLienPostionMortgageLoanType", title: "Loan Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "firstLienPostionMortgageOrginationAmount", title: "Loan Amount at Origination" }, filteredItems, hideFields, property)}
                                {renderField({ key: "firstLienPostionMortgageRecordingDate", title: "Loan Recording Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "flooring_types", title: "Flooring Types" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fmr_1_bed", title: "Fmr 1 Bed" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fmr_2_bed", title: "Fmr 2 Bed" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fmr_3_bed", title: "Fmr 3 Bed" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fmr_4_bed", title: "Fmr 4 Bed" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fmr_efficient", title: "Fmr Efficient" }, filteredItems, hideFields, property)}
                                {renderField({ key: "fmr_year", title: "Fmr Year" }, filteredItems, hideFields, property)}
                                {renderField({ key: "foundation_type", title: "Foundation Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_ac", title: "Has Ac" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_basement", title: "Has Basement" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_carport", title: "Has Carport" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_central_ac", title: "Has Central Ac" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_deck_area", title: "Has Deck Area" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_family_room", title: "Has Family Room" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_fire_sprinklers", title: "Has Fire Sprinklers" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_fireplace", title: "Has Fireplace" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_flood_zone", title: "Has Flood Zone" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_game_room", title: "Has Game Room" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_garage", title: "Has Garage" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_golf_course", title: "Has Golf Course" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_judgement", title: "Has Judgement" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_judgment", title: "Has Judgment" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_mobilehome_hookup", title: "Has Mobilehome Hookup" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_mortgage", title: "Has Mortgage" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_negative_equity", title: "Has Negative Equity" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_patio", title: "Has Patio" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_pool", title: "Has Pool" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_porch", title: "Has Porch" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_rv_parking", title: "Has Rv Parking" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_safety_fire_sprinklers", title: "Has Safety Fire Sprinklers" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_tennis_court", title: "Has Tennis Court" }, filteredItems, hideFields, property)}
                                {renderField({ key: "has_window_ac", title: "Has Window Ac" }, filteredItems, hideFields, property)}
                                {renderField({ key: "heating_fuel_type", title: "Heating Fuel Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "heating_type", title: "Heating Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "hud_area_code", title: "Hud Area Code" }, filteredItems, hideFields, property)}
                                {renderField({ key: "hud_area_name", title: "Hud Area Name" }, filteredItems, hideFields, property)}
                                {renderField({ key: "id", title: "Id" }, filteredItems, hideFields, property)}
                                {renderField({ key: "improvement_value", title: "Improvement Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "interior_wall_type", title: "Interior Wall Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_absentee_owner", title: "Is Absentee Owner" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_adjustable_rate", title: "Is Adjustable Rate" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_agriculture", title: "Is Agriculture" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_air_conditioning_available", title: "Is Air Conditioning Available" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_arms_length", title: "Is Arms Length" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_assumable", title: "Is Assumable" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_auctioned", title: "Is Auctioned" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_bank_owned", title: "Is Bank Owned" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_cash_buyer", title: "Is Cash Buyer" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_commercial", title: "Is Commercial" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_corporate_owned", title: "Is Corporate Owned" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_death", title: "Is Death" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_for_rent", title: "Is For Rent" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_for_sale", title: "Is For Sale" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_foreclosure", title: "Is Foreclosure" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_free_clear", title: "Is Free Clear" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_high_equity", title: "Is High Equity" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_in_state_absentee_owner", title: "Is In State Absentee Owner" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_individually_owned", title: "Is Individually Owned" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_industrial", title: "Is Industrial" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_inherited", title: "Is Inherited" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_investor_buyer", title: "Is Investor Buyer" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_judicial_state", title: "Is Judicial State" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_mail_opted_out", title: "Is Mail Opted Out" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_mobile_home", title: "Is Mobile Home" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_opted_out", title: "Is Opted Out" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_out_of_state_absentee_owner", title: "Is Out Of State Absentee Owner" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_owner_estate", title: "Is Owner Estate" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_owner_occupied", title: "Is Owner Occupied" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_owner_trust", title: "Is Owner Trust" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_preforeclosure", title: "Is Preforeclosure" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_private_lender", title: "Is Private Lender" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_rented", title: "Is Rented" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_residential", title: "Is Residential" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_residential_lease", title: "Is Residential Lease" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_delinquent", title: "Is Tax Delinquent" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_exempt", title: "Is Tax Exempt" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_exempt_disabled", title: "Is Tax Exempt Disabled" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_exempt_homeowner", title: "Is Tax Exempt Homeowner" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_exempt_senior", title: "Is Tax Exempt Senior" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_exempt_veteran", title: "Is Tax Exempt Veteran" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_tax_exempt_widow", title: "Is Tax Exempt Widow" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_vacant", title: "Is Vacant" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_valid_record", title: "Is Valid Record" }, filteredItems, hideFields, property)}
                                {renderField({ key: "is_valid_record_history", title: "Is Valid Record History" }, filteredItems, hideFields, property)}
                                {renderField({ key: "land_use", title: "Land Use" }, filteredItems, hideFields, property)}
                                {renderField({ key: "land_use_code", title: "Land Use Code" }, filteredItems, hideFields, property)}
                                {renderField({ key: "land_value", title: "Land Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "last_sale_date", title: "Last Sale Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "last_sale_price", title: "Last Sale Price" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lastname", title: "Lastname" }, filteredItems, hideFields, property)}
                                {renderField({ key: "latitude", title: "Latitude" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lender", title: "Lender" }, filteredItems, hideFields, property)}
                                {renderField({ key: "longitude", title: "Longitude" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lot_acres", title: "Lot Acres" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lot_depth", title: "Lot Depth" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lot_size", title: "Lot Size" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lot_width", title: "Lot Width" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_address", title: "Mail Address" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_city", title: "Mail City" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_direction", title: "Mail Direction" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_fips", title: "Mail Fips" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_house", title: "Mail House" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_post_direction", title: "Mail Post Direction" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_state", title: "Mail State" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_street", title: "Mail Street" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_street_suffix", title: "Mail Street Suffix" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_unit", title: "Mail Unit" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_unit_type", title: "Mail Unit Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_zip4", title: "Mail Zip4" }, filteredItems, hideFields, property)}
                                {renderField({ key: "mail_zip5", title: "Mail Zip5" }, filteredItems, hideFields, property)}
                                {renderField({ key: "market_improvement_value", title: "Estimated Market Improvement Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "market_land_value", title: "Estimated Market Land Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "median_income", title: "Median Income" }, filteredItems, hideFields, property)}
                                {renderField({ key: "num_bathrooms", title: "Num Bathrooms" }, filteredItems, hideFields, property)}
                                {renderField({ key: "num_bedrooms", title: "Num Bedrooms" }, filteredItems, hideFields, property)}
                                {renderField({ key: "num_rooms", title: "Num Rooms" }, filteredItems, hideFields, property)}
                                {renderField({ key: "num_stories", title: "Num Stories" }, filteredItems, hideFields, property)}
                                {renderField({ key: "num_units", title: "Num Units" }, filteredItems, hideFields, property)}
                                {renderField({ key: "parking_spaces", title: "Parking Spaces" }, filteredItems, hideFields, property)}
                                {renderField({ key: "parking_type", title: "Parking Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "plumbing_fixtures_count", title: "Plumbing Fixtures Count" }, filteredItems, hideFields, property)}
                                {renderField({ key: "post_direction", title: "Post Direction" }, filteredItems, hideFields, property)}
                                {renderField({ key: "price_per_square_foot", title: "Price Per Square Foot" }, filteredItems, hideFields, property)}
                                {renderField({ key: "prior_sale_amount", title: "Prior Sale Amount" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_class", title: "Property Class" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_condition", title: "Property Condition" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_identifier", title: "Property Identifier" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_tax", title: "Property Tax" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_type", title: "Property Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_use", title: "Property Use" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_use_code", title: "Property Use Code" }, filteredItems, hideFields, property)}
                                {renderField({ key: "property_uuid", title: "Property Uuid" }, filteredItems, hideFields, property)}
                                {renderField({ key: "recording_date", title: "Recording Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "relatives", title: "Relatives" }, filteredItems, hideFields, property)}
                                {renderField({ key: "roof_material_type", title: "Roof Material Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sewage", title: "Sewage" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft", title: "Sqft" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_attic", title: "Sqft Attic" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_balcony", title: "Sqft Balcony" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_basement", title: "Sqft Basement" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_basement_finished", title: "Sqft Basement Finished" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_canopy", title: "Sqft Canopy" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_carport", title: "Sqft Carport" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_deck", title: "Sqft Deck" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_driveway", title: "Sqft Driveway" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_finished", title: "Sqft Finished" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_first_floor", title: "Sqft First Floor" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_garage", title: "Sqft Garage" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_gazebo", title: "Sqft Gazebo" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_grainery", title: "Sqft Grainery" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_living", title: "Sqft Living" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_loading_platform", title: "Sqft Loading Platform" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_lot", title: "Sqft Lot" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_milk_house", title: "Sqft Milk House" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_patio", title: "Sqft Patio" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_pole_structure", title: "Sqft Pole Structure" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_pool", title: "Sqft Pool" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_porch", title: "Sqft Porch" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_poultry_house", title: "Sqft Poultry House" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_second_floor", title: "Sqft Second Floor" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_shed", title: "Sqft Shed" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_stable", title: "Sqft Stable" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_storage_building", title: "Sqft Storage Building" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_unfinished_basement", title: "Sqft Unfinished Basement" }, filteredItems, hideFields, property)}
                                {renderField({ key: "sqft_utility_building", title: "Sqft Utility Building" }, filteredItems, hideFields, property)}
                                {renderField({ key: "square_feet", title: "Square Feet" }, filteredItems, hideFields, property)}
                                {renderField({ key: "state", title: "State" }, filteredItems, hideFields, property)}
                                {renderField({ key: "street", title: "Street" }, filteredItems, hideFields, property)}
                                {renderField({ key: "street_suffix", title: "Street Suffix" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_amount", title: "Tax Amount" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_billed_amount", title: "Tax Billed Amount" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_change_yoy", title: "Tax Change Yoy" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_delinquent_year", title: "Tax Delinquent Year" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_rate", title: "Tax Rate" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_rate_area", title: "Tax Rate Area" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_value", title: "Tax Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "tax_year", title: "Tax Year" }, filteredItems, hideFields, property)}
                                {renderField({ key: "total_portfolio_equity", title: "Total Portfolio Equity" }, filteredItems, hideFields, property)}
                                {renderField({ key: "assetClass", title: "Total Portfolio Mortgage Balance" }, filteredItems, hideFields, property)}
                                {renderField({ key: "total_portfolio_value", title: "Total Portfolio Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "total_properties_owned", title: "Total Properties Owned" }, filteredItems, hideFields, property)}
                                {renderField({ key: "unit", title: "Unit" }, filteredItems, hideFields, property)}
                                {renderField({ key: "unit_type", title: "Unit Type" }, filteredItems, hideFields, property)}
                                {renderField({ key: "utilities_sewage_usage", title: "Utilities Sewage Usage" }, filteredItems, hideFields, property)}
                                {renderField({ key: "utilities_water_source", title: "Utilities Water Source" }, filteredItems, hideFields, property)}
                                {renderField({ key: "water", title: "Water" }, filteredItems, hideFields, property)}
                                {renderField({ key: "year_built", title: "Year Built" }, filteredItems, hideFields, property)}
                                {renderField({ key: "years_owned", title: "Years Owned" }, filteredItems, hideFields, property)}
                                {renderField({ key: "zip4", title: "Zip4" }, filteredItems, hideFields, property)}
                                {renderField({ key: "zip5", title: "Zip5" }, filteredItems, hideFields, property)}
                                {renderField({ key: "zoning", title: "Zoning" }, filteredItems, hideFields, property)}
                                {renderField({ key: "unitsCount", title: "Units Count" }, filteredItems, hideFields, property)}
                                {renderField({ key: "marketValue", title: "Market Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "taxAssessmentValue", title: "Tax Assessed Value" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lastSaleDate", title: "Last Sale Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "lastSalePrice", title: "Last Sale Price" }, filteredItems, hideFields, property)}
                                {renderField({ key: "foreclosure", title: "Foreclosure Status" }, filteredItems, hideFields, property)}
                                {renderField({ key: "foreclosureDate ", title: "Foreclosure Date" }, filteredItems, hideFields, property)}
                                {renderField({ key: "numberOfLiens", title: "Number of Liens" }, filteredItems, hideFields, property)}
                                {renderField({ key: "foreclosureDocument", title: "Foreclosure Doc" }, filteredItems, hideFields, property)}
                                {renderField({ key: "recordingDate", title: "Time in Default" }, filteredItems, hideFields, property)}
                                {renderField({ key: "totalAmountinDefault", title: "Amount in Default" }, filteredItems, hideFields, property)}
                                {renderField({ key: "countyName", title: "County" }, filteredItems, hideFields, property)}
                                {renderField({ key: "isJudicialState", title: "Judicial State" }, filteredItems, hideFields, property)}
                            </div> */}
                        </div>
                    </div>
                    <div className="px-7">
                        <div className="w-full">
                            <Accordion type="single" collapsible>
                                <AccordionItem value="sale-history" className="border-b-2">
                                    <div className="rounded-lg flex flex-col lg:bg-card px-2 mr-0">
                                        <AccordionTrigger>
                                            <h4 className="font-bold text-base px-0 pb-3">Sales History</h4>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent data-state="open" className="bg-[#F8F6F6] pt-1 pb-3">
                                        <Table className="accordian-table">
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>Arms Length</TableHead>
                                                    <TableHead>Buyer Name</TableHead>
                                                    <TableHead>Document Type</TableHead>
                                                    <TableHead>Document Type Code</TableHead>
                                                    <TableHead>Down Payment</TableHead>
                                                    <TableHead>LTV</TableHead>
                                                    <TableHead>Owner Individual</TableHead>
                                                    <TableHead>Purchase Method</TableHead>
                                                    <TableHead>Recording Date</TableHead>
                                                    <TableHead>Sale Amount</TableHead>
                                                    <TableHead>Sale Date</TableHead>
                                                    <TableHead>Seller</TableHead>
                                                    <TableHead>Last Update Date</TableHead>
                                                    <TableHead>Seq No</TableHead>
                                                    <TableHead>Transaction</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    (propertyDetails?.salesHistory || []).map((history, index) => (
                                                        <TableRow key={`history${index}`}>
                                                            <TableCell>{history.armsLength ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell>{history.buyerNames}</TableCell>
                                                            <TableCell>{history.documentType}</TableCell>
                                                            <TableCell>{history.documentTypeCode}</TableCell>
                                                            <TableCell>{(formatCurrency(history.downPayment)) || ''}</TableCell>
                                                            <TableCell>{history.ltv || ''}</TableCell>
                                                            <TableCell>{history.ownerIndividual ? "Yes" : "No"}</TableCell>
                                                            <TableCell>{history.purchaseMethod}</TableCell>
                                                            <TableCell>{convertDateToYYYYMMDD(history.recordingDate)}</TableCell>
                                                            <TableCell>{formatCurrency(history.saleAmount)}</TableCell>
                                                            <TableCell>{convertDateToYYYYMMDD(history.saleDate)}</TableCell>
                                                            <TableCell>{history.sellerNames}</TableCell>
                                                            <TableCell>{convertDateToYYYYMMDD(history.lastUpdateDate) || '--'}</TableCell>
                                                            <TableCell>{history.seqNo || '0'}</TableCell>
                                                            <TableCell>{history.transactionType}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                {
                                                    (propertyDetails?.salesHistory || []).length === 0
                                                    && <TableRow>
                                                        <TableCell colSpan={15} className="text-center">No sales history available</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                            <h4 className="font-bold text-base px-2 pb-3 mt-5">Liens</h4>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                            <Accordion type="multiple">
                                <AccordionItem value="item-1" className="border-b-2">
                                    <div className="rounded-lg flex flex-col lg:bg-card px-2 mr-0">
                                        <AccordionTrigger>
                                            <h3 className="ml-0 text-md text-base leading-none tracking-tight !font-normal lg:!font-medium py-1 lg:bg-card">Assignment Releases</h3>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent data-state="open" className="bg-[#F8F6F6] pt-1 pb-3">
                                        <Table className="accordian-table">
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>New Lender</TableHead>
                                                    <TableHead>County</TableHead>
                                                    <TableHead>Document Type</TableHead>
                                                    <TableHead>Loan Position</TableHead>
                                                    <TableHead>Page</TableHead>
                                                    <TableHead>Book</TableHead>
                                                    <TableHead>Loan Amount</TableHead>
                                                    <TableHead>Borrower</TableHead>
                                                    <TableHead>Document Number</TableHead>
                                                    <TableHead>Recording Date</TableHead>
                                                    <TableHead>Orig Page</TableHead>
                                                    <TableHead>State</TableHead>
                                                    <TableHead>Updated</TableHead>
                                                    <TableHead>Original Book</TableHead>
                                                    <TableHead>Contract Date</TableHead>
                                                    <TableHead>Original Mortgage Date</TableHead>
                                                    <TableHead>Original Lender</TableHead>
                                                    <TableHead>Property ID</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    (propertyDetails?.assignment_releases || [])
                                                        // .filter((mortgage) => String(mortgage.isOpen) === 'true')
                                                        .map((assignment) => (
                                                            <TableRow key={assignment.assignment_uuid}>
                                                                <TableCell>{assignment.new_lender}</TableCell>
                                                                <TableCell>{assignment.county}</TableCell>
                                                                <TableCell>{assignment.document_type}</TableCell>
                                                                <TableCell>{assignment.mortgage_loan_position === '1' ? 'Yes' : 'No'}</TableCell>
                                                                <TableCell>{assignment.recording_page}</TableCell>
                                                                <TableCell>{assignment.recording_book}</TableCell>
                                                                <TableCell>{assignment.original_loan_amount}</TableCell>
                                                                <TableCell>{assignment.borrower}</TableCell>
                                                                <TableCell>{assignment.original_document_number}</TableCell>
                                                                <TableCell>{assignment.recording_date}</TableCell>
                                                                <TableCell>{assignment.state}</TableCell>
                                                                <TableCell>{assignment.original_page}</TableCell>
                                                                <TableCell>{assignment.updated_at}</TableCell>
                                                                <TableCell>{assignment.original_book}</TableCell>
                                                                <TableCell>{assignment.contract_date}</TableCell>
                                                                <TableCell>{assignment.original_mortgage_date}</TableCell>
                                                                <TableCell>{assignment.original_lender}</TableCell>
                                                                <TableCell>{assignment.property_identifier}</TableCell>
                                                            </TableRow>
                                                        ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                                <AccordionItem value="item-2" className="border-b-2">
                                    <div className="rounded-lg flex flex-col lg:bg-card px-2 mr-0">
                                        <AccordionTrigger>
                                            <h3 className="ml-0 text-md text-base leading-none tracking-tight !font-normal lg:!font-medium py-1 lg:bg-card">Active Mortgages</h3>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent data-state="open" className="bg-[#F8F6F6] pt-1 pb-3">
                                        <Table className="accordian-table">
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>Recording Date</TableHead>
                                                    <TableHead>Term</TableHead>
                                                    <TableHead>Term Type</TableHead>
                                                    <TableHead>Origination Amount</TableHead>
                                                    <TableHead>Estimated Mortgage Balance</TableHead>
                                                    <TableHead>Lien Position</TableHead>

                                                    <TableHead>Assumable</TableHead>
                                                    <TableHead>County</TableHead>
                                                    <TableHead>State</TableHead>
                                                    <TableHead>Deed Type</TableHead>
                                                    <TableHead>Document Date</TableHead>
                                                    <TableHead>Grantee Name</TableHead>
                                                    {/* <TableHead>interestRate</TableHead> */}
                                                    {/* <TableHead>interestRateType</TableHead> */}
                                                    <TableHead>Lender Code</TableHead>
                                                    <TableHead>Original Lender</TableHead>
                                                    <TableHead>Lender Type</TableHead>
                                                    <TableHead>Loan Type Code</TableHead>
                                                    <TableHead>Transaction Type</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    (propertyDetails?.mortgages || [])
                                                        .filter((mortgage) => String(mortgage.isOpen) === 'true')
                                                        .map((mortgage) => (
                                                            <TableRow key={mortgage.mortgageId}>
                                                                <TableCell>{convertDateToYYYYMMDD(mortgage.recordingDate)}</TableCell>
                                                                <TableCell>{mortgage.term || ''}</TableCell>
                                                                <TableCell>{mortgage.termType || ''}</TableCell>
                                                                <TableCell>{formatCurrency(mortgage.amount)}</TableCell>
                                                                <TableCell>{formatCurrency(Number(estimatedMortgageBalance))}</TableCell>
                                                                <TableCell>{mortgage.position}</TableCell>

                                                                <TableCell>{mortgage.assumable ? "True" : "False"}</TableCell>
                                                                <TableCell>{mortgage.county}</TableCell>
                                                                <TableCell>{mortgage.state}</TableCell>
                                                                <TableCell>{mortgage.deedType}</TableCell>
                                                                <TableCell>{convertDateToYYYYMMDD(mortgage.documentDate)}</TableCell>
                                                                <TableCell>{mortgage.granteeName}</TableCell>
                                                                {/* <TableCell>{mortgage.interestRate}</TableCell> */}
                                                                {/* <TableCell>{mortgage.interestRateType}</TableCell> */}
                                                                <TableCell>{mortgage.lenderCode}</TableCell>
                                                                <TableCell>{mortgage.lenderName}</TableCell>
                                                                <TableCell>{mortgage.lenderType}</TableCell>
                                                                <TableCell>{mortgage.loanTypeCode}</TableCell>
                                                                <TableCell>{mortgage.transactionType}</TableCell>
                                                            </TableRow>
                                                        ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>

                                {/* fore closures */}
                                <AccordionItem value="item-3" className="border-b-2">
                                    <div className="rounded-lg flex flex-col lg:bg-card px-2 mr-0">
                                        <AccordionTrigger>
                                            <h3 className="ml-0 text-md text-base leading-none tracking-tight !font-normal lg:!font-medium py-1 lg:bg-card">Foreclosure </h3>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent data-state="open" className="bg-[#F8F6F6] pt-1 pb-3">
                                        <Table className="accordian-table">
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>Auction Address</TableHead>
                                                    <TableHead>Auction Date</TableHead>
                                                    <TableHead>Auction Time</TableHead>
                                                    <TableHead>Borrower</TableHead>
                                                    <TableHead>Case Number</TableHead>
                                                    <TableHead>County</TableHead>
                                                    {/* <TableHead>created at</TableHead> */}
                                                    <TableHead>Document Type</TableHead>
                                                    <TableHead>Foreclosure UUID</TableHead>
                                                    <TableHead>Instrument Number</TableHead>
                                                    <TableHead>Is Auctioned</TableHead>
                                                    <TableHead>Is Foreclosure</TableHead>
                                                    <TableHead>Is Preforeclosure</TableHead>
                                                    <TableHead>Lender</TableHead>
                                                    <TableHead>Lender Address</TableHead>
                                                    <TableHead>Loan Balance</TableHead>
                                                    <TableHead>Loan Default Amount</TableHead>
                                                    <TableHead>Original Loan Amount</TableHead>
                                                    <TableHead>Original Loan Book Page</TableHead>
                                                    <TableHead>Original Loan Instrument Number</TableHead>
                                                    <TableHead>Original Loan Recording Date</TableHead>
                                                    {/* <TableHead>property_identifier</TableHead> */}
                                                    <TableHead>Recording Date</TableHead>
                                                    <TableHead>State</TableHead>
                                                    <TableHead>Transaction Type</TableHead>
                                                    <TableHead>Trustee</TableHead>
                                                    <TableHead>Trustee Address</TableHead>
                                                    <TableHead>Trustee Phone</TableHead>
                                                    {/* <TableHead>updated_at</TableHead> */}
                                                    {/* <TableHead>version</TableHead> */}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    (propertyDetails?.foreclosures || [])
                                                        .map((fc, id) => (
                                                            <TableRow key={id}>
                                                                <TableCell>{fc.auction_address || ""}</TableCell>
                                                                <TableCell>{convertDateToYYYYMMDD(fc.auction_date)}</TableCell>
                                                                <TableCell>{fc.auction_time || ""}</TableCell>
                                                                <TableCell>{fc.borrower || ""}</TableCell>
                                                                <TableCell>{fc.case_number || ""}</TableCell>
                                                                <TableCell>{fc.county || ""}</TableCell>
                                                                {/* <TableCell>{fc.created_at}</TableCell> */}
                                                                <TableCell>{fc.document_type}</TableCell>
                                                                <TableCell>{fc.foreclosure_uuid}</TableCell>
                                                                <TableCell>{fc.instrument_number}</TableCell>
                                                                <TableCell>{fc.is_auctioned}</TableCell>
                                                                <TableCell>{fc.is_foreclosure}</TableCell>
                                                                <TableCell>{fc.is_preforeclosure}</TableCell>
                                                                <TableCell>{fc.lender}</TableCell>
                                                                <TableCell>{fc.lender_address}</TableCell>
                                                                <TableCell>{fc.loan_balance}</TableCell>
                                                                <TableCell>{formatCurrency(fc.loan_default_amount)}</TableCell>
                                                                <TableCell>{formatCurrency(fc.original_loan_amount)}</TableCell>
                                                                <TableCell>{fc.original_loan_book_page}</TableCell>
                                                                <TableCell>{fc.original_loan_instrument_number}</TableCell>
                                                                <TableCell>{fc.original_loan_recording_date}</TableCell>
                                                                {/* <TableCell>{fc.property_identifier}</TableCell> */}
                                                                <TableCell>{convertDateToYYYYMMDD(fc.recording_date)}</TableCell>
                                                                <TableCell>{fc.state}</TableCell>
                                                                <TableCell>{fc.transaction_type}</TableCell>
                                                                <TableCell>{fc.trustee}</TableCell>
                                                                <TableCell>{fc.trustee_address}</TableCell>
                                                                <TableCell>{fc.trustee_phone}</TableCell>
                                                                {/* <TableCell>{fc.updated_at}</TableCell> */}
                                                                {/* <TableCell>{fc.version}</TableCell> */}
                                                            </TableRow>
                                                        ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                                {/* fore closures */}

                                <AccordionItem value="item-4" className="border-b-2" disabled>
                                    <div className="rounded-lg flex flex-col lg:bg-card px-2 mr-0">
                                        <AccordionTrigger>
                                            <h3 className="ml-0 text-md text-base leading-none tracking-tight !font-normal lg:!font-medium py-1 lg:bg-card">Active Tax Liens</h3>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent data-state="open" className="bg-[#F8F6F6] pt-1 pb-3">
                                        <Table className="accordian-table">
                                            <TableHeader>
                                                <TableRow>
                                                    {/* <TableHead>Recording Date</TableHead>
                                                    <TableHead>Amount</TableHead>
                                                    <TableHead>Position</TableHead> */}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    liens.filter((lien) => lien.isTaxLien).map((lien, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{convertDateToYYYYMMDD(lien.recordingDate)}</TableCell>
                                                            <TableCell>{formatCurrency(lien.amount || 0)}</TableCell>
                                                            <TableCell>{lien.position}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                                <AccordionItem value="item-5" className="border-b-2" disabled>
                                    <div className="rounded-lg flex flex-col lg:bg-card px-2 mr-0">
                                        <AccordionTrigger>
                                            <h3 className="ml-0 text-md text-base leading-none tracking-tight !font-normal lg:!font-medium py-1 lg:bg-card">Active HOA Liens</h3>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent data-state="open" className="bg-[#F8F6F6] pt-1 pb-3">
                                        <Table className="accordian-table">
                                            <TableHeader>
                                                <TableRow>
                                                    {/* <TableHead>Recording Date</TableHead>
                                                    <TableHead>Amount</TableHead>
                                                    <TableHead>Position</TableHead> */}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    liens.filter((lien) => !lien.isTaxLien).map((lien, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{!propertyDetails && convertDateToYYYYMMDD(lien.recordingDate)}</TableCell>
                                                            {/*  */}
                                                            <TableCell>-</TableCell>
                                                            {/* {formatCurrency(lien.amount)} */}
                                                            <TableCell>-</TableCell>
                                                            {/* {lien.position} */}
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>

                        </div>
                        <div className="mt-0">

                        </div>
                        {/* <div>
                            <h4 className="font-bold text-base px-2 pb-3 pt-8">Tax Assessments</h4>
                            <div className="bg-[#F8F6F6] pt-1 pb-3">
                                <div className="mt-3 flex justify-between">
                                    <p className="text-gray-500 text-base px-2">Principal Balance</p>
                                    <p className="text-black text-base pr-2">YYYY/MM/DDDD</p>
                                </div>
                                <div className="mt-3 flex justify-between">
                                    <p className="text-gray-500 text-base px-2">Original Loan Amount</p>
                                    <p className="text-black text-base pr-2">YYYY/MM/DDDD</p>
                                </div>
                            </div>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                        </div> */}
                        {/* <div>
                            <h4 className="font-bold text-base px-2 pb-3 pt-8">Property Owners</h4>
                            <div className="bg-[#F8F6F6] pt-1 pb-3">
                                <div className="mt-3 flex justify-between">
                                    <p className="text-gray-500 text-base px-2">YYYY/MM/DDDD</p>
                                    <p className="text-black text-base pr-2">$1,234,567</p>
                                </div>
                                <div className="mt-3 flex justify-between">
                                    <p className="text-gray-500 text-base px-2">YYYY/MM/DDDD</p>
                                    <p className="text-black text-base pr-2">$1,234,567</p>
                                </div>
                            </div>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                        </div> */}
                    </div>
                </>
            </DialogContent>
        </Dialog>
    )
}

export default PropertyDetailsModal;