// @ts-ignore
import { point } from '@turf/turf';

export const DEALS_COORDINATES: [number, number][] = [
  [-74.00143546646768, 40.61390697188941],
  [-74.00143546646768, 40.61390697188941],
  [-73.98392716406673, 40.619501557040266],
  [-74.0112863178679, 40.60725956691354],
  [-73.9884078649338, 40.59752560096576],
  [-73.9866045123755, 40.728959121846124],
  [-73.98491844673599, 40.72521623466046],
  [-73.97958775436064, 40.72517751403773],
  [-73.99573311018122, 40.72360285629476],
  [-73.97596015859081, 40.7221701435211],
  [-73.96418323165813, 40.75651093359137],
  [-73.97118295870692, 40.75053759509984],
  [-73.97794425223094, 40.75644642956266],
  [-73.95551275699454, 40.76956894742079],
  [-80.22328199943546, 25.77125452708236],
  [-80.3537446499445, 25.807731548812324],
  [-80.29401462608776, 25.72030512298481],
  [-80.94826381211662, 27.980115522334444],
  [-109.53142150756574, 37.560465645812506],
  [-122.4250935036416, 48.44355183358909],
  [-66.99505246321486, 44.912336835178095],
  [-94.1822066186475, 34.31857433926711],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-97.78212276369011, 26.17317070949327],
  [-96.45527432236847, 32.54864161032886],
  [-97.74725146270288, 32.416538233174116],
  [-96.20079397654503, 32.30078886849192],
  [-97.067733463874, 33.00599228089111],
  [-68.70646717209884, 45.18160639147614],
  [-80.33006348535848, 27.11348764207607],
  [-80.2331701412436, 26.526728882372346],
  [-121.032882583813, 36.319061935528055],
  [-120.96505802234194, 36.45556306143339],
  [-121.4090006065163, 36.37120882787202],
  [-120.50570076510594, 35.735639354268166],
  [-119.76887939276098, 36.82168326358451],
  [-122.40214549162826, 40.64575053598695],
];

export interface Deal {
  coordinates: point;
  address: string;
  estimatedReturn: number;
  status: 'hot' | 'active' | 'completed';
  type: 'Commercial' | 'Residential';
}

export const DEALS: Deal[] = DEALS_COORDINATES.map((coordinates, index) => ({
  coordinates,
  address: `Address ${index}`,
  estimatedReturn: Math.random() * 100000,
  status: index % 2 === 0 ? 'active' : index % 3 === 0 ? 'completed' : 'hot',
  type: index % 2 === 0 ? 'Commercial' : 'Residential',
}));

// export const FINANCIAL_TERM_ABBREVIATIONS = [
//   'PB - Principal Balance',
//   'AV - Asset Value',
//   'LTV - Loan-To-Value',
//   'EPP - Estimated Purchase Price',
//   'ESP - Estimated Sale Price',
//   'EPP - Estimated Profit Percent',
//   'DT - Default Type',
//   'TID - Time In Default',
// ];