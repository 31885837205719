const WhiteHashtagIcon = () => {
    return (
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="-0.5" width="31" height="31" rx="7.5" transform="matrix(1 0 0 -1 0.830078 31.5)" stroke="white"/>
        <path d="M11.8301 14.25H23.8301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.8301 18.75H23.8301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.3301 9.75L14.8301 23.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.8301 9.75L19.3301 23.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      
    )
}

export default WhiteHashtagIcon;