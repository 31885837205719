import { useEffect, useState } from 'react';
import LenderProfileCard from '../lender-profile/LenderProfileCard';
import Searchbar from '../common/Searchbar';
import { LENDER_PROFILE_QUERY_API_URL } from '@/config/config';
import { LenderProfileResponseProps } from './LenderProfileResponseProps';
import { LenderProfileProps } from '../LenderProfileProps';
import FilterButtonSort from '../common/FilterButtonSort';
import FilterButton from '../common/FilterButton';
import FilterProfiles from '../common/FilterProfiles';

const LenderProfile = () => {
  const [lenderProfiles, setLenderProfiles] = useState<LenderProfileProps[]>([]);
  const [resultCount, setResultCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(LENDER_PROFILE_QUERY_API_URL)
      .then((response) => response.json())
      .then((data) => {
        console.log('Lender profiles:', data);
        const profiles = data.rows.map((row: LenderProfileResponseProps) => ({
          lenderName: row['lendername'],
          defaultRate: parseFloat(row['Default Rate']), // Parse string to float
          totalPortfolioValue: parseFloat(row['Total Portfolio Value']),
          loanVol: parseFloat(row['Loan Volume']),
          defaultVol: parseFloat(row['Default Volume']),
          defaultTotal: parseFloat(row['Amount In Default']),
          averageLoanAmount: parseFloat(row['Average Loan Amount']),
        }));

        setLenderProfiles(profiles);
        setResultCount(profiles.length);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      <Searchbar backgroundColor="bg-card" buttonOne={<FilterButtonSort />} buttonTwo={<FilterButton onFilterButtonClick={() => setIsFilterOpen(!isFilterOpen)} />} />
      <FilterProfiles isOpen={isFilterOpen} onClose={() => setIsFilterOpen(false)} side="right" />
      {!loading && <span>{resultCount} Profiles Found</span>}
      <div className="flex flex-wrap gap-4 4xl:gap-0">
        {loading && <p>Loading...</p>}
        {lenderProfiles.map((profile, index) => (
          <LenderProfileCard className="mb-5 cursor-pointer hover:shadow-hover lg:mr-5" key={index} {...profile} />
        ))}
      </div>
    </div>
  );
};

export default LenderProfile;
