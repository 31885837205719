export const PROPERTY_COMPONENTS = [
    {"key":"address","value":"Address"},
    {"key":"age","value":"Age"},
    {"key":"aliases","value":"Aliases"},
    {"key":"totalAmountinDefault","value":"Amount in Default"},
    {"key":"apn","value":"Apn"},
    {"key":"apn_unformatted","value":"Apn_unformatted"},
    {"key":"appliances","value":"Appliances"},
    {"key":"apu","value":"Apu"},
    {"key":"architecture_type","value":"Architecture Type"},
    {"key":"asking_price","value":"Asking Price"},
    {"key":"assessedValue","value":"Assessed Value"},
    {"key":"assessedYear","value":"Assessed Year"},
    {"key":"assetClassGroup","value":"Asset Class"},
    {"key":"basement_finished_percent","value":"Basement Finished Percent"},
    {"key":"basement_type","value":"Basement Type"},
    {"key":"borrowerCalc","value":"Borrower"},
    {"key":"borrower_calc","value":"Borrower Calc"},
    {"key":"brokerage_email","value":"Brokerage Email"},
    {"key":"brokerage_name","value":"Brokerage Name"},
    {"key":"brokerage_phone","value":"Brokerage Phone"},
    {"key":"carrier_route","value":"Carrier Route"},
    {"key":"census_block","value":"Census Block"},
    {"key":"census_block_group","value":"Census Block Group"},
    {"key":"census_tract","value":"Census Tract"},
    {"key":"city","value":"City"},
    {"key":"company","value":"Company"},
    {"key":"congressional_district","value":"Congressional District"},
    {"key":"construction","value":"Construction"},
    {"key":"construction_materials","value":"Construction Materials"},
    {"key":"county","value":"County"},
    {"key":"countyName","value":"County"},
    {"key":"county_use_code","value":"County Use Code"},
    {"key":"created_at","value":"Created At"},
    {"key":"defaultRecordingDate","value":"Default Recording Date"},
    {"key":"direction","value":"Direction"},
    {"key":"equity_pct","value":"Equity Pct"},
    {"key":"estimated_bank_value","value":"Estimated Bank Value"},
    {"key":"estimated_equity","value":"Estimated Equity"},
    {"key":"estimated_equity_pct","value":"Estimated Equity Pct"},
    {"key":"estimated_lendable_equity","value":"Estimated Lendable Equity"},
    {"key":"market_improvement_value","value":"Estimated Market Improvement Value"},
    {"key":"market_land_value","value":"Estimated Market Land Value"},
    {"key":"estimated_value","value":"Estimated Market Value"},
    {"key":"estimated_mortgage_balance","value":"Estimated Mortgage Balance"},
    {"key":"estimated_mortgage_payment","value":"Estimated Mortgage Payment"},
    {"key":"estimatedPurchasePrice","value":"Estimated Purchase Price"},
    {"key":"estimatedValue","value":"Estimated Value"},
    {"key":"exterior_wall_type","value":"Exterior Wall Type"},
    {"key":"fips","value":"Fips"},
    {"key":"firstname","value":"First Name"},
    {"key":"firstLienPositionMortgageARLTV","value":"Firstlien Position Mortgage Arltv"},
    {"key":"firstLienPositionMortgageLTC","value":"Firstlien Position Mortgage Ltc"},
    {"key":"flooring_types","value":"Flooring Types"},
    {"key":"fmr_1_bed","value":"Fmr 1 Bed"},
    {"key":"fmr_2_bed","value":"Fmr 2 Bed"},
    {"key":"fmr_3_bed","value":"Fmr 3 Bed"},
    {"key":"fmr_4_bed","value":"Fmr 4 Bed"},
    {"key":"fmr_efficient","value":"Fmr Efficient"},
    {"key":"fmr_year","value":"Fmr Year"},
    {"key":"auction_date","value":"Foreclosure Auction Date"},
    {"key":"auction_opening_bid","value":"Foreclosure Auction Opening Bid"},
    {"key":"auction_street_address","value":"Foreclosure Auction Street Address"},
    {"key":"auction_time","value":"Foreclosure Auction Time"},
    {"key":"foreclosureDate ","value":"Foreclosure Date"},
    {"key":"foreclosureDocument","value":"Foreclosure Doc"},
    {"key":"foreclosure","value":"Foreclosure Status"},
    {"key":"foundation_type","value":"Foundation Type"},
    {"key":"has_ac","value":"Has Ac"},
    {"key":"has_basement","value":"Has Basement"},
    {"key":"has_carport","value":"Has Carport"},
    {"key":"has_central_ac","value":"Has Central Ac"},
    {"key":"has_deck_area","value":"Has Deck Area"},
    {"key":"has_family_room","value":"Has Family Room"},
    {"key":"has_fire_sprinklers","value":"Has Fire Sprinklers"},
    {"key":"has_fireplace","value":"Has Fireplace"},
    {"key":"has_flood_zone","value":"Has Flood Zone"},
    {"key":"has_game_room","value":"Has Game Room"},
    {"key":"has_garage","value":"Has Garage"},
    {"key":"has_golf_course","value":"Has Golf Course"},
    {"key":"has_judgement","value":"Has Judgement"},
    {"key":"has_judgment","value":"Has Judgment"},
    {"key":"has_mobilehome_hookup","value":"Has Mobilehome Hookup"},
    {"key":"has_mortgage","value":"Has Mortgage"},
    {"key":"has_negative_equity","value":"Has Negative Equity"},
    {"key":"has_patio","value":"Has Patio"},
    {"key":"has_pool","value":"Has Pool"},
    {"key":"has_porch","value":"Has Porch"},
    {"key":"has_rv_parking","value":"Has Rv Parking"},
    {"key":"has_safety_fire_sprinklers","value":"Has Safety Fire Sprinklers"},
    {"key":"has_tennis_court","value":"Has Tennis Court"},
    {"key":"has_window_ac","value":"Has Window Ac"},
    {"key":"heating_fuel_type","value":"Heating Fuel Type"},
    {"key":"heating_type","value":"Heating Type"},
    {"key":"home_value","value":"Home Value"},
    {"key":"home_value_change_yoy","value":"Home Value Change Yoy"},
    {"key":"home_value_max","value":"Home Value Max"},
    {"key":"home_value_min","value":"Home Value Min"},
    {"key":"home_value_updated","value":"Home Value Updated"},
    {"key":"house","value":"House"},
    {"key":"hud_area_code","value":"Hud Area Code"},
    {"key":"hud_area_name","value":"Hud Area Name"},
    {"key":"id","value":"Id"},
    {"key":"improvement_value","value":"Improvement Value"},
    {"key":"interior_wall_type","value":"Interior Wall Type"},
    {"key":"is_absentee_owner","value":"Is Absentee Owner"},
    {"key":"is_adjustable_rate","value":"Is Adjustable Rate"},
    {"key":"is_agriculture","value":"Is Agriculture"},
    {"key":"is_air_conditioning_available","value":"Is Air Conditioning Available"},
    {"key":"is_arms_length","value":"Is Arms Length"},
    {"key":"is_assumable","value":"Is Assumable"},
    {"key":"is_auctioned","value":"Is Auctioned"},
    {"key":"is_bank_owned","value":"Is Bank Owned"},
    {"key":"is_cash_buyer","value":"Is Cash Buyer"},
    {"key":"is_commercial","value":"Is Commercial"},
    {"key":"is_corporate_owned","value":"Is Corporate Owned"},
    {"key":"is_death","value":"Is Death"},
    {"key":"is_for_rent","value":"Is For Rent"},
    {"key":"is_for_sale","value":"Is For Sale"},
    {"key":"is_foreclosure","value":"Is Foreclosure"},
    {"key":"is_free_clear","value":"Is Free Clear"},
    {"key":"is_high_equity","value":"Is High Equity"},
    {"key":"is_in_state_absentee_owner","value":"Is In State Absentee Owner"},
    {"key":"is_individually_owned","value":"Is Individually Owned"},
    {"key":"is_industrial","value":"Is Industrial"},
    {"key":"is_inherited","value":"Is Inherited"},
    {"key":"is_investor_buyer","value":"Is Investor Buyer"},
    {"key":"is_judicial_state","value":"Is Judicial State"},
    {"key":"is_mail_opted_out","value":"Is Mail Opted Out"},
    {"key":"is_mobile_home","value":"Is Mobile Home"},
    {"key":"is_opted_out","value":"Is Opted Out"},
    {"key":"is_out_of_state_absentee_owner","value":"Is Out Of State Absentee Owner"},
    {"key":"is_owner_estate","value":"Is Owner Estate"},
    {"key":"is_owner_occupied","value":"Is Owner Occupied"},
    {"key":"is_owner_trust","value":"Is Owner Trust"},
    {"key":"is_preforeclosure","value":"Is Preforeclosure"},
    {"key":"is_private_lender","value":"Is Private Lender"},
    {"key":"is_rented","value":"Is Rented"},
    {"key":"is_residential","value":"Is Residential"},
    {"key":"is_residential_lease","value":"Is Residential Lease"},
    {"key":"is_tax_delinquent","value":"Is Tax Delinquent"},
    {"key":"is_tax_exempt","value":"Is Tax Exempt"},
    {"key":"is_tax_exempt_disabled","value":"Is Tax Exempt Disabled"},
    {"key":"is_tax_exempt_homeowner","value":"Is Tax Exempt Homeowner"},
    {"key":"is_tax_exempt_senior","value":"Is Tax Exempt Senior"},
    {"key":"is_tax_exempt_veteran","value":"Is Tax Exempt Veteran"},
    {"key":"is_tax_exempt_widow","value":"Is Tax Exempt Widow"},
    {"key":"is_vacant","value":"Is Vacant"},
    {"key":"is_valid_record","value":"Is Valid Record"},
    {"key":"is_valid_record_history","value":"Is Valid Record History"},
    {"key":"isJudicialState","value":"Judicial State"},
    {"key":"jurisdiction","value":"Jurisdiction"},
    {"key":"land_use","value":"Land Use"},
    {"key":"land_use_code","value":"Land Use Code"},
    {"key":"land_value","value":"Land Value"},
    {"key":"lastSaleDate","value":"Last Sale Date"},
    {"key":"last_sale_date","value":"Last Sale Date"},
    {"key":"lastSalePrice","value":"Last Sale Price"},
    {"key":"last_sale_price","value":"Last Sale Price"},
    {"key":"lastname","value":"Lastname"},
    {"key":"latitude","value":"Latitude"},
    {"key":"description","value":"Legal Description"},
    {"key":"lender","value":"Lender"},
    {"key":"lenderName","value":"Lender Name"},
    {"key":"lenderOriginal","value":"Lender Original"},
    {"key":"lenderUUID","value":"Lender Uuid"},
    {"key":"firstLienPostionMortgageOrginationAmount","value":"Loan Amount at Origination"},
    {"key":"loan_documents","value":"Loan Documents"},
    {"key":"loanDocumentsCount","value":"Loan Documents Count"},
    {"key":"firstLienPostionMortgageRecordingDate","value":"Loan Recording Date"},
    {"key":"firstLienPositionMortgageLoanTerm","value":"Loan Term"},
    {"key":"firstLienPostionMortgageLoanType","value":"Loan Type"},
    {"key":"longitude","value":"Longitude"},
    {"key":"lot_acres","value":"Lot Acres"},
    {"key":"lot_depth","value":"Lot Depth"},
    {"key":"lot_size","value":"Lot Size"},
    {"key":"lot_width","value":"Lot Width"},
    {"key":"firstLienPositionMortgageLTV","value":"LTV"},
    {"key":"mail_address","value":"Mail Address"},
    {"key":"mail_city","value":"Mail City"},
    {"key":"mail_direction","value":"Mail Direction"},
    {"key":"mail_fips","value":"Mail Fips"},
    {"key":"mail_house","value":"Mail House"},
    {"key":"mail_post_direction","value":"Mail Post Direction"},
    {"key":"mail_state","value":"Mail State"},
    {"key":"mail_street","value":"Mail Street"},
    {"key":"mail_street_suffix","value":"Mail Street Suffix"},
    {"key":"mail_unit","value":"Mail Unit"},
    {"key":"mail_unit_type","value":"Mail Unit Type"},
    {"key":"mail_zip4","value":"Mail Zip4"},
    {"key":"mail_zip5","value":"Mail Zip5"},
    {"key":"marketValue","value":"Market Value"},
    {"key":"market_value","value":"Market Value"},
    {"key":"median_income","value":"Median Income"},
    {"key":"middlename","value":"Middle Name"},
    {"key":"mls_agent_email","value":"Mls Agent Email"},
    {"key":"mls_agent_name","value":"Mls Agent Name"},
    {"key":"mls_agent_office","value":"Mls Agent Office"},
    {"key":"mls_agent_phone","value":"Mls Agent Phone"},
    {"key":"mls_has_photos","value":"Mls Has Photos"},
    {"key":"mls_status","value":"Mls Status"},
    {"key":"monthly_rent","value":"Monthly Rent"},
    {"key":"num_bathrooms","value":"Num Bathrooms"},
    {"key":"num_bedrooms","value":"Num Bedrooms"},
    {"key":"num_rooms","value":"Num Rooms"},
    {"key":"num_stories","value":"Num Stories"},
    {"key":"num_units","value":"Num Units"},
    {"key":"numberOfLiens","value":"Number of Liens"},
    {"key":"offerPrice","value":"Offer Price"},
    {"key":"open_mortgage_balance","value":"Open Mortgage Balance"},
    {"key":"owner_1_firstname","value":"Owner 1 Firstname"},
    {"key":"owner_1_lastname","value":"Owner 1 Lastname"},
    {"key":"owner_2_firstname","value":"Owner 2 Firstname"},
    {"key":"owner_2_lastname","value":"Owner 2 Lastname"},
    {"key":"parking_spaces","value":"Parking Spaces"},
    {"key":"parking_type","value":"Parking Type"},
    {"key":"phone","value":"Phone"},
    {"key":"plumbing_fixtures_count","value":"Plumbing Fixtures Count"},
    {"key":"portfolio","value":"Portfolio"},
    {"key":"post_direction","value":"Post Direction"},
    {"key":"price_per_square_foot","value":"Price Per Square Foot"},
    {"key":"principalBalance","value":"Principal Balance"},
    {"key":"prior_sale_amount","value":"Prior Sale Amount"},
    {"key":"property_class","value":"Property Class"},
    {"key":"property_condition","value":"Property Condition"},
    {"key":"property_identifier","value":"Property Identifier"},
    {"key":"property_tax","value":"Property Tax"},
    {"key":"property_type","value":"Property Type"},
    {"key":"property_use","value":"Property Use"},
    {"key":"property_use_code","value":"Property Use Code"},
    {"key":"property_uuid","value":"Property Uuid"},
    {"key":"recording_date","value":"Recording Date"},
    {"key":"relatives","value":"Relatives"},
    {"key":"roof_material_type","value":"Roof Material Type"},
    {"key":"sewage","value":"Sewage"},
    {"key":"sqft","value":"Sqft"},
    {"key":"sqft_attic","value":"Sqft Attic"},
    {"key":"sqft_balcony","value":"Sqft Balcony"},
    {"key":"sqft_basement","value":"Sqft Basement"},
    {"key":"sqft_basement_finished","value":"Sqft Basement Finished"},
    {"key":"sqft_canopy","value":"Sqft Canopy"},
    {"key":"sqft_carport","value":"Sqft Carport"},
    {"key":"sqft_deck","value":"Sqft Deck"},
    {"key":"sqft_driveway","value":"Sqft Driveway"},
    {"key":"sqft_finished","value":"Sqft Finished"},
    {"key":"sqft_first_floor","value":"Sqft First Floor"},
    {"key":"sqft_garage","value":"Sqft Garage"},
    {"key":"sqft_gazebo","value":"Sqft Gazebo"},
    {"key":"sqft_grainery","value":"Sqft Grainery"},
    {"key":"sqft_living","value":"Sqft Living"},
    {"key":"sqft_loading_platform","value":"Sqft Loading Platform"},
    {"key":"sqft_lot","value":"Sqft Lot"},
    {"key":"sqft_milk_house","value":"Sqft Milk House"},
    {"key":"sqft_patio","value":"Sqft Patio"},
    {"key":"sqft_pole_structure","value":"Sqft Pole Structure"},
    {"key":"sqft_pool","value":"Sqft Pool"},
    {"key":"sqft_porch","value":"Sqft Porch"},
    {"key":"sqft_poultry_house","value":"Sqft Poultry House"},
    {"key":"sqft_second_floor","value":"Sqft Second Floor"},
    {"key":"sqft_shed","value":"Sqft Shed"},
    {"key":"sqft_stable","value":"Sqft Stable"},
    {"key":"sqft_storage_building","value":"Sqft Storage Building"},
    {"key":"sqft_unfinished_basement","value":"Sqft Unfinished Basement"},
    {"key":"sqft_utility_building","value":"Sqft Utility Building"},
    {"key":"square_feet","value":"Square Feet"},
    {"key":"src_path","value":"Src Path"},
    {"key":"state","value":"State"},
    {"key":"street","value":"Street"},
    {"key":"street_suffix","value":"Street Suffix"},
    {"key":"tax_amount","value":"Tax Amount"},
    {"key":"assessed_improvement_value","value":"Tax Assessed Improvement Value"},
    {"key":"assessed_land_value","value":"Tax Assessed Land Value"},
    {"key":"assessed_value","value":"Tax Assessed Value"},
    {"key":"taxAssessmentValue","value":"Tax Assessed Value"},
    {"key":"assessment_year","value":"Tax Assessment Year"},
    {"key":"tax_billed_amount","value":"Tax Billed Amount"},
    {"key":"tax_change_yoy","value":"Tax Change Yoy"},
    {"key":"tax_delinquent_year","value":"Tax Delinquent Year"},
    {"key":"tax_rate","value":"Tax Rate"},
    {"key":"tax_rate_area","value":"Tax Rate Area"},
    {"key":"tax_value","value":"Tax Value"},
    {"key":"tax_year","value":"Tax Year"},
    {"key":"taxLien","value":"Taxlien"},
    {"key":"recordingDate","value":"Time in Default"},
    {"key":"totalMarketValue","value":"Total Market Value"},
    {"key":"total_portfolio_equity","value":"Total Portfolio Equity"},
    {"key":"assetClass","value":"Total Portfolio Mortgage Balance"},
    {"key":"total_portfolio_mortgage_balance","value":"Total Portfolio Mortgage Balance"},
    {"key":"total_portfolio_value","value":"Total Portfolio Value"},
    {"key":"total_properties_owned","value":"Total Properties Owned"},
    {"key":"unit","value":"Unit"},
    {"key":"unit_type","value":"Unit Type"},
    {"key":"unitsCount","value":"Units Count"},
    {"key":"updated_at","value":"Updated At"},
    {"key":"utilities_sewage_usage","value":"Utilities Sewage Usage"},
    {"key":"utilities_water_source","value":"Utilities Water Source"},
    {"key":"version","value":"Version"},
    {"key":"water","value":"Water"},
    {"key":"year_built","value":"Year Built"},
    {"key":"years_owned","value":"Years Owned"},
    {"key":"zip","value":"Zip"},
    {"key":"zip4","value":"Zip4"},
    {"key":"zip5","value":"Zip5"},
    {"key":"zoning","value":"Zoning"}
]