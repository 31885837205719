import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { toast } from 'react-toastify';

interface QueryNameModalProps {
    isOpen: boolean;
    toggleModal: () => void;
    onSave: (queryName: string) => void;
    initialQueryName?: string;
}

const QueryNameModal: React.FC<QueryNameModalProps> = ({ isOpen, onSave, initialQueryName, toggleModal }) => {
    const [queryName, setQueryName] = useState('');

    useEffect(() => {
        setQueryName(initialQueryName || '');
    }, [initialQueryName]);

    const handleSave = () => {
        if (!queryName) {
            toast.error("Please enter a query name.");
            return;
        }

        onSave(queryName);
        setQueryName('');
        toggleModal();
    };

    return (
        <Dialog
            open={isOpen}
            onOpenChange={toggleModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Save Query</DialogTitle>
                </DialogHeader>
                <input
                    type="text"
                    required
                    value={queryName}
                    onChange={(e) => setQueryName(e.target.value)}
                    placeholder="Enter query name"
                    className="border border-gray-300 rounded-md p-2 mb-4"
                />
                <DialogFooter>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleSave}>
                        {initialQueryName !== queryName ? 'Create New Version' : 'Update'}
                    </button>
                    <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={toggleModal}>Cancel</button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default QueryNameModal;