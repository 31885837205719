import "./CustomTabs.css";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export interface CustomTabProps {
    value: string;
    title: string;
};

interface CustomTabsProps {
    contents: CustomTabProps[];
    renderTabContent?: (value: string) => JSX.Element;
};


const CustomTabs: React.FC<CustomTabsProps> = ({ contents, renderTabContent }) => {
    return (
        <Tabs defaultValue={contents[0].value} className="tabs">
            <TabsList className="tabs-list">
                {
                    contents.map((content: CustomTabProps) => (
                        <TabsTrigger className="tabs-trigger" value={content.value}>{content.title}</TabsTrigger>
                    ))
                }
            </TabsList>
            <div className="mt-5">
                {
                    contents.map((content: CustomTabProps) => (
                        <TabsContent className="tabs-content w-full" value={content.value}>{renderTabContent && renderTabContent(content.value)}</TabsContent>
                    ))
                }
            </div>
        </Tabs>
    );
};

export default CustomTabs;