
import { Button } from "../../ui/button";
import ComposableCard from "../../composable/ComposableCard";
import { Link } from "react-router-dom";

const Settings = () => {
    return (
        <>
            <ComposableCard title="Settings" className="w-full">
                <Link to="/logout">
                    <Button>
                        Logout
                    </Button>
                </Link>
            </ComposableCard>
        </>
    );
};

export default Settings;
