import React, { useState } from 'react';
import { PlusIcon, Trash2Icon } from 'lucide-react';
import { Field, RuleGroupType, RuleType } from 'react-querybuilder';
import PricingUpdateRuleModal from './PricingUpdateRuleModal';
// import FloatInput from './FloatInput';
// import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import MultipleChoiceDropdown, { MultipleChoiceDropdownOption } from './MultipleChoiceDropdown';

export interface PricingAction {
  id: number;
  aivPercentage?: string;
  upb?: string;
  aao?: string;
  pricing_strategy?: string;
  pricingType?: string;
  pricingTypeOperator?: string;
}

export interface PricingCondition {
  id: number;
  query?: RuleGroupType;
  isSelected?: boolean;
  actions: PricingAction[];
}

export interface PricingScope {
  id: number;
  assetClass: string[];
  query: RuleGroupType;
  conditions: PricingCondition[];
}

interface PricingRuleFormProps {
  filterFields: Field[];
  scopeRules: RuleGroupType;
  conditionRules: RuleGroupType;
}

const PricingRuleForm: React.FC<PricingRuleFormProps> = ({ filterFields, scopeRules, conditionRules }) => {
  const assetClassOptions: MultipleChoiceDropdownOption[] = [
    { label: 'Residential', value: 'Residential' },
    { label: 'Multi Family', value: 'Multi Family' },
    { label: 'Commercial', value: 'Commercial' },
    { label: 'Industrial', value: 'Industrial' },
  ];

  const pricingTypeOptions = [
    { label: 'LTC', value: 'LTC' },
    { label: 'LTV', value: 'LTV' },
    { label: 'LTARV', value: 'LTARV' },
  ];
  const pricingTypeOperators = [
    { label: 'Lesser Than', value: '<' },
    { label: 'Greater Than', value: '>' },
  ];

  const defaultEmptyScope = {
    id: 1,
    query: { ...scopeRules },
    assetClass: [],
    conditions: [{ id: 1, actions: [{ id: 1 }] }],
  };

  // const pricingStrategies = [{ label: 'Min', value: 'MIN' }, { label: 'Max', value: 'MAX' }, { label: 'Avg', value: 'AVG' }, { label: 'Sum', value: 'SUM' }];
  const [scopes, setScopes] = useState<PricingScope[]>([{ ...defaultEmptyScope }]);
  const [scopeCount, setScopeCount] = useState(1);
  const [conditionCount, setConditionCount] = useState(1);
  const [selectedScope, setSelectedScope] = useState<PricingScope | null>(null);
  const [selectedCondition, setSelectedCondition] = useState<PricingCondition | null>(null);

  // Control for the modal
  const [isScopeRuleModalOpen, setIsScopeRuleModalOpen] = useState(false);
  const [isConditionRuleModalOpen, setIsConditionRuleModalOpen] = useState(false);

  const addScope = () => {
    setScopes((prevScopes) => [
      ...prevScopes,
      {
        id: scopeCount + 1,
        query: { ...scopeRules },
        assetClass: [],
        conditions: [{ id: conditionCount + 1, actions: [{ id: conditionCount + 1 }] }],
      },
    ]);

    setScopeCount((prevCount) => prevCount + 1);
    setConditionCount((prevCount) => prevCount + 1);
  };

  const addCondition = (scopeId: number) => {
    setScopes((prevScopes) =>
      prevScopes.map((scope) =>
        scope.id === scopeId
          ? {
              ...scope,
              conditions: [...scope.conditions, { id: conditionCount + 1, query: { ...conditionRules }, actions: [{ id: conditionCount + 1 }] }],
            }
          : scope,
      ),
    );
    setConditionCount((prevCount) => prevCount + 1);
  };

  const removeScope = (scopeId: number) => {
    setScopes((prevScopes) => prevScopes.filter((scope) => scope.id !== scopeId));
  };

  const removeCondition = (scopeId: number, conditionId: number) => {
    setScopes((prevScopes) =>
      prevScopes.map((scope) =>
        scope.id === scopeId
          ? {
              ...scope,
              conditions: scope.conditions.filter((condition) => condition.id !== conditionId),
            }
          : scope,
      ),
    );
  };

  const onScopeQueryChange = (scope: PricingScope, newQuery: RuleGroupType) => {
    setSelectedScope({ ...scope, query: newQuery });
  };

  // const onOpenUpdateScopeRulesModal = (scope: PricingScope): void => {
  //   setSelectedScope({ ...scope });
  //   setIsScopeRuleModalOpen(true);
  // }

  const onSaveScopeQueryHandler = () => {
    setIsScopeRuleModalOpen(false);

    if (selectedScope) {
      setScopes((prevScopes) => prevScopes.map((s) => (s.id === selectedScope.id ? { ...selectedScope } : s)));

      setSelectedScope(null);
    }
  };

  const renderSelectedRule = (rule: RuleType<string, string, any, string> | RuleGroupType<RuleType<string, string, any, string>, string>): React.ReactNode => {
    if (rule.hasOwnProperty('rules')) {
      const groupRule = rule as RuleGroupType<RuleType<string, string, any, string>, string>;
      return (
        <div>
          {groupRule.rules.map((r, index) => (
            <div key={index}>{renderSelectedRule(r)}</div>
          ))}
        </div>
      );
    } else {
      const singleRule = rule as RuleType<string, string, any, string>;
      const field = filterFields.find((f) => f.name === singleRule.field);

      return (
        <p className="mb-1">
          {field?.label} {singleRule.operator} {singleRule.value}
        </p>
      );
    }
  };
  const onConditionQueryChange = (selectedCondition: PricingCondition, q: RuleGroupType<RuleType<string, string, any, string>, string>): void => {
    setSelectedCondition({ ...selectedCondition, query: q });
  };

  const onSaveCondtionQueryHandler = (): void => {
    setIsConditionRuleModalOpen(false);

    if (selectedCondition) {
      setScopes((prevScopes) =>
        prevScopes.map((s) => ({
          ...s,
          conditions: s.conditions.map((c) => (c.id === selectedCondition.id ? { ...selectedCondition } : c)),
        })),
      );

      setSelectedCondition(null);
    }
  };

  // const onOpenUpdateConditionRulesModal = (_scope: PricingScope, condition: PricingCondition): void => {
  //   setSelectedCondition({ ...condition });
  //   setIsConditionRuleModalOpen(true);
  // };

  const onActionValueChange = (value: string, action: PricingAction, field: string): void => {
    setScopes((prevScopes) => {
      console.log('prevScopes... ', prevScopes);
      return prevScopes.map((s) => ({
        ...s,
        conditions: s.conditions.map((c) => ({
          ...c,
          actions: c.actions.map((a) => (a.id === action.id ? { ...action, [field]: value } : a)),
        })),
      }));
    });
  };

  const toggleScopeAssetClass = (scope: PricingScope, option: string) => {
    const newAssetClass = scope.assetClass.includes(option) ? scope.assetClass.filter((item) => item !== option) : [...scope.assetClass, option];
    setScopes((prevScopes) => prevScopes.map((s) => (s.id === scope.id ? { ...s, assetClass: newAssetClass } : s)));
  };

  return (
    <div className="m-5 flex flex-col space-y-8">
      <div className="flex flex-row flex-wrap">
        {scopes.map((scope, scopeInex) => (
          <div className="flex w-full flex-col lg:w-1/2">
            <div key={scope.id} className="my-5 mr-5 flex flex-col rounded-3xl border-2 border-gray-500 px-5 py-3">
              <div className="mb-5 flex items-center justify-between">
                {/* <h3>Scope {scope.id}</h3> */}
                <div className="flex items-center">
                  {/* <button
                    onClick={() => addCondition(scope.id)}
                    title='Add Condition'
                    className=""
                  >
                    <PlusIcon />
                  </button> */}
                  {scopes.length > 1 && ( // Only show trash button if there is more than one scope
                    <button onClick={() => removeScope(scope.id)} className="ml-5 text-red-500" title="Remove Scope">
                      <Trash2Icon />
                    </button>
                  )}
                </div>
              </div>
              <div className="relative">
                <MultipleChoiceDropdown selectedOptions={scope.assetClass} toggleOption={(o) => toggleScopeAssetClass(scope, o)} options={assetClassOptions} />
              </div>
              <div>
                <div>
                  {/* {
                    scope.query.rules.map((rule) => (
                      <p>{renderSelectedRule(rule)}</p>
                    ))
                  } */}
                </div>
                {/* <button
                  onClick={() => onOpenUpdateScopeRulesModal(scope)}
                  className="apply-new-query-button text-white px-4 py-2 rounded-lg mt-5">Update Scope Rules</button> */}
              </div>
              {scope.conditions.map((condition, cIndex) => (
                <div key={`condition-${cIndex}`} className="my-5 flex flex-col rounded-3xl border-2 border-gray-400 p-4">
                  <div className="flex items-center justify-between">
                    {/* <h4>Condition {cIndex + 1}</h4> */}
                    {scope.conditions.length > 1 && ( // Only show trash button if there is more than one condition
                      <button onClick={() => removeCondition(scope.id, condition.id)} className="text-red-500">
                        <Trash2Icon />
                      </button>
                    )}
                  </div>
                  {/* <div>
                    <div>
                      {
                        scope.conditions.map((c) =>
                          c.query?.rules.map((rule) => (
                            <p>{renderSelectedRule(rule)}</p>
                          ))
                        )
                      }
                    </div>
                    <button
                      onClick={() => onOpenUpdateConditionRulesModal(scope, condition)}
                      className="apply-new-query-button text-white px-4 py-2 rounded-lg my-5">Update Condition Rules</button>
                  </div> */}
                  <div className="flex flex-col ">
                    {condition.actions.map((action) => (
                      <div className="my-5 flex flex-col rounded-3xl border-2 border-gray-500 bg-gray-200 p-4">
                        <div className="mb-2 flex justify-between">
                          {pricingTypeOptions.map((option) => (
                            <label className="flex items-center justify-around space-x-2">
                              <input
                                type="radio"
                                name={`pricingType-${cIndex}`}
                                value={option.value}
                                checked={action.pricingType === option.value}
                                onChange={(e) => onActionValueChange(e.target.value, action, 'pricingType')}
                                className="form-radio"
                              />
                              <span>{option.label}</span>
                            </label>
                          ))}
                        </div>
                        <div className="mb-2 flex justify-around">
                          {pricingTypeOperators.map((option) => (
                            <label className="flex items-center justify-around space-x-2">
                              <input
                                type="radio"
                                name={`pricingTypeOperator-${cIndex}`}
                                value={option.value}
                                checked={action.pricingTypeOperator === option.value}
                                onChange={(e) => onActionValueChange(e.target.value, action, 'pricingTypeOperator')}
                                className="form-radio"
                              />
                              <span>{option.label}</span>
                            </label>
                          ))}
                        </div>
                        {/* <div className='flex w-full justify-around 2xl:space-x-4 lg:space-x-1 mb-5 mt-5' key={action.id}>
                            <FloatInput
                              title='AIV'
                              initialValue={action.aivPercentage}
                              onValueChange={(v) => onActionValueChange(v, action, 'aivPercentage')} />
                            <FloatInput
                              title='UPB'
                              initialValue={action.upb}
                              onValueChange={(v) => onActionValueChange(v, action, 'upb')} />
                            <FloatInput
                              title='AAO'
                              initialValue={action.aao}
                              onValueChange={(v) => onActionValueChange(v, action, 'aao')} />
                          </div>
                          <div className="flex w-full items-center">
                            <ToggleGroup
                              type="single"
                              value={action.pricing_strategy}
                              onValueChange={(value) => onActionValueChange(value, action, 'pricing_strategy')}
                              className="flex w-full items-center !border-none"
                            >
                              {
                                pricingStrategies.map((item) => (
                                  <ToggleGroupItem
                                    key={item.value}
                                    value={item.value}
                                    className="flex px-2 py-2 !bg-white border-[1px] !border-gray-300 rounded-lg mr-2 data-[state=on]:!text-white data-[state=on]:!bg-[#38BDF8]"
                                  >
                                    {item.label}
                                  </ToggleGroupItem>
                                ))
                              }
                            </ToggleGroup>
                          </div> */}
                      </div>
                    ))}
                  </div>

                  {cIndex + 1 === scope.conditions.length && (
                    <div className="relative">
                      <button
                        onClick={() => addCondition(scope.id)}
                        title="Add Condition"
                        className="absolute -bottom-7 left-1/2 z-10 h-6 w-6 rounded-full border-2 border-gray-500 bg-white"
                      >
                        <PlusIcon width={20} height={20} />
                      </button>
                    </div>
                  )}
                </div>
              ))}

              {scopeInex + 1 === scopes.length && (
                <div className="relative">
                  <button onClick={addScope} title="Add Scope" className="absolute -bottom-6 left-1/2 z-10 h-6 w-6 rounded-full border-2 border-gray-500 bg-white">
                    <PlusIcon width={20} height={20} />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {selectedScope && (
        <PricingUpdateRuleModal
          isOpen={isScopeRuleModalOpen}
          filterFields={filterFields}
          query={selectedScope.query}
          onQueryChange={(q) => onScopeQueryChange(selectedScope, q)}
          toggleModal={() => setIsScopeRuleModalOpen(!isScopeRuleModalOpen)}
          onSaveQuery={onSaveScopeQueryHandler}
        />
      )}

      {selectedCondition && (
        <PricingUpdateRuleModal
          isOpen={isConditionRuleModalOpen}
          filterFields={filterFields}
          query={selectedCondition.query}
          onQueryChange={(q) => onConditionQueryChange(selectedCondition, q)}
          toggleModal={() => setIsConditionRuleModalOpen(!isConditionRuleModalOpen)}
          onSaveQuery={onSaveCondtionQueryHandler}
        />
      )}
    </div>
  );
};

export default PricingRuleForm;
