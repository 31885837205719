import React, { useEffect, useRef, useState } from 'react';
import { Check, X } from 'lucide-react';
import { formatCurrency } from '@/lib/utils';
export interface EditableFieldProps {
    initialValue: UpdatedOpportunityField;
    onSave: (value: UpdatedOpportunityField) => void;
    onDiscard: () => void;
}
export class UpdatedOpportunityField {
    id: string;
    field: string;
    value: number;
    displayValue: string;
    constructor(id: string, field: string, value: number, displayValue?: string) {
        this.id = id;
        this.field = field;
        this.value = value;
        this.displayValue = displayValue || formatCurrency(value);
    }
}
const EditableField: React.FC<EditableFieldProps> = ({ initialValue, onSave, onDiscard }) => {
    const [editedItem, setEditedItem] = useState<UpdatedOpportunityField | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setEditedItem(initialValue);
    }, [initialValue]);
    useEffect(() => {
        if (editedItem && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editedItem]);
    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!editedItem) return;
        const value = event.target.value;
        const numericValue = Number(value.replace(/[$,]/g, ''));
        const cursorPosition = event.target.selectionStart || 0;
        if (!isNaN(numericValue)) {
            setEditedItem(new UpdatedOpportunityField(editedItem.id, editedItem.field, numericValue));
        }
        // Maintain cursor position
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    };
    const handleSaveClick = () => {
        if (editedItem) {
            onSave(editedItem);
        }
    };
    return (
        <>
            {editedItem && (
                <div className="flex justify-center items-center w-full">
                    <input
                        ref={inputRef}
                        type="text"
                        value={editedItem.displayValue}
                        onChange={onValueChange}
                        className="bg-transparent border-none min-w-[120px] w-[60%] h-[32px] focus:outline-none focus:ring-0"
                    />
                    <Check onClick={handleSaveClick} className="ml-2 cursor-pointer" width={20} />
                    <X onClick={onDiscard} className="ml-2 cursor-pointer" width={20} />
                </div>
            )}
        </>
    );
};
export default EditableField;