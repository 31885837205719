import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { tabTableData } from '@/constants/__mock__/LenderProfileDetails';


const FinancialInformation = () => {
    return (
        <div className="w-full">
            <h4 className="font-semibold text-lg pl-4">Financial Information</h4>
            <Table className="no-row-border">
                <TableBody>
                    {
                        tabTableData.map((data, index) => (
                            <TableRow key={index}>
                                <TableCell><span className="text-[#A1A3A7] text-base">{data.col1}</span></TableCell>
                                <TableCell><span className="text-[#FFFFFF] text-base">{data.col2}</span></TableCell>
                                <TableCell><span className="text-[#A1A3A7] text-base">{data.col3}</span></TableCell>
                                <TableCell><span className="text-[#FFFFFF] text-base">{data.col4}</span></TableCell>
                                <TableCell><span className="text-[#A1A3A7] text-base">{data.col5}</span></TableCell>
                                <TableCell><span className="text-[#FFFFFF] text-base">{data.col6}</span></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </div>
    );
}

export default FinancialInformation;