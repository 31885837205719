import { formatCurrencyShort, fillNullValues } from "@/lib/utils";
import { ApexOptions } from "apexcharts";
import ApexChart from "react-apexcharts";
import { useEffect, useRef } from "react";
import { useBreakpoint } from "@/hooks/useBreakpoint.ts";

export interface ChartProps {
    series: ApexAxisChartSeries;
    smoothCurves?: boolean;
}

const LineChart = ({ series, smoothCurves }: ChartProps) => {

    const isLg = useBreakpoint('lg');
    const chartRef = useRef<any>(null);
    const maxSize = 10;

    useEffect(() => {
        chartRef.current && chartRef.current.chart.zoomX(1, isLg ? maxSize : 7);
    }, [isLg])

    const options: ApexOptions = {
        theme: {
            mode: "dark",
        },
        chart: {
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp: number) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    }
                },
                autoSelected: 'zoom'
            },
            fontFamily: "inherit",
            dropShadow: {
                enabled: false,
            },
            width: '100%',
            height: 'auto',
            background: 'transparent',
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.55,
                opacityTo: 0,
            },
        },
        dataLabels: {
            enabled: false,

        },
        stroke: {
            width: 2,
            curve: smoothCurves ? 'monotoneCubic' : 'smooth',
        },
        grid: {
            show: true,
            strokeDashArray: 0,
            borderColor: "hsl(var(--border))",
            padding: {
                left: 21,
                right: 0,
                top: 0,
                bottom: 21,
            },
        },
        xaxis: {
            categories: [
                '1 June',
                '3 June',
                '5 June',
                '7 June',
                '9 June',
                '11 June',
                '13 June',
                '15 June',
                '17 June',
                '19 June',
                '21 June',
                '23 June',
                '30 June',
                '',
            ],
            labels: {
                show: true,
                offsetY: 10,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            max: maxSize
        },
        yaxis: {
            show: true,
            labels: {
                formatter: (amount: number) => formatCurrencyShort({ amount, decimals: 0 }),
                offsetX: -18,
            },

        },
        legend: {
            show: false,
        },
    };

    const processedSeries = series.map(serie => ({
        ...serie,
        data: fillNullValues(serie.data as number[], smoothCurves ? 'spline' : 'linear'),
    }));

    return (
        <ApexChart
            ref={chartRef}
            options={options}
            width="100%"
            height="100%"
            className="font-headings flex flex-col !min-h-0 [&>div]:grow"
            series={processedSeries}
            type="area"
        />
    );
};


export default LineChart;