import DealsCard from '../deals/DealsCard';
import InvestmentPerformanceCard from '../investment-performance/InvestmentPerformanceCard';
import NewOpportunityCard from '../new-opportunities/NewOpportunityCard';
import PortfolioBreakdownCard from '../portfolio-breakdown/PortfolioBreakdownCard';

const Dashboard = () => {

  return (
    <>

      <div className="flex-col lg:flex-row flex grow gap-5">
        <div className="flex flex-col h-full gap-5 grow">
          <InvestmentPerformanceCard className="shrink-0 grow" />
          <div className="grid 2xl:flex gap-5 grow">
            <PortfolioBreakdownCard />
            <DealsCard />
          </div>
        </div>
        <div className="lg:h-full lg:w-[375px] shrink-0">
          <NewOpportunityCard className="w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
