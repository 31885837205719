import React from 'react';
import { FieldSelectorProps } from 'react-querybuilder';
import ComboBox, { ComboBoxOption } from './ComboBox';

const CustomFieldSelector: React.FC<FieldSelectorProps> = ({ options, value, handleOnChange }) => {
    const filteredOptions: ComboBoxOption[] = options
        .filter(option => 'value' in option && typeof option.label === 'string')
        .map(option => {
            if ('value' in option && typeof option.value === 'string') {
                return {
                    label: option.label,
                    value: option.value,
                };
            }

            return {
                label: option.label,
                value: option.label,
            };
        });

    return (
        <ComboBox
            options={filteredOptions}
            defaultValue={value}
            onSelect={(op) => handleOnChange(op.value)} />
    );
};

export default CustomFieldSelector;