import * as React from "react"

import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const inputVariants = cva(
  "relative flex overflow-hidden w-full rounded-lg font-light bg-textbox px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background text-foreground hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2 text-base",
        sm: "h-9 px-3 text-sm",
        lg: "h-14 px-7 text-lg",
      },
    },
    defaultVariants: {
      // variant: "default",
      size: "default",
    },
  }
)

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
  VariantProps<typeof inputVariants> {
  asChild?: boolean
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  customInputClasses?: string;
}


const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size, iconStart, iconEnd, type, customInputClasses, ...props }, ref) => {
    const inputClasses = 'relative w-full border-none bg-transparent px-6 py-4 outline-none ring-0 focus:outline-none disabled:text-gray-500 flex';

    return (
      <div className={cn(inputVariants({ size, className }))}>
        {iconStart ? (
          <div className="absolute left-0 top-0 flex h-full items-center pl-4">
            {iconStart}
          </div>
        ) : null}
        {iconEnd ? (
          <div className="absolute right-0 top-0 flex h-full items-center pr-4">
            {iconEnd}
          </div>
        ) : null}
        <input
          type={type}
          ref={ref}
          {...props}
          size={undefined}
          className={cn(inputClasses, customInputClasses || { '!pl-12': iconStart })}
        />
      </div>

    )
  }
)
Input.displayName = "Input"

export { Input }
