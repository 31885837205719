import { UserIcon } from "lucide-react";
import { match } from "ts-pattern";

import { cn } from "@/lib/utils"
import { cva, type VariantProps } from "class-variance-authority";

const profileHeadVariants = cva(
    "profile-icon",
    {
        variants: {
            size: {
                large: "large",
            },
        },
    }
);

export interface ProfileHeadProps
    extends VariantProps<typeof profileHeadVariants> {
    asChild?: boolean;
    displayName?: string | null;
    userImage?: string | null;
    className?: string;
}

const ProfileHead = ({ displayName, userImage, size, className }: ProfileHeadProps) => {
    const displayMode = userImage ? "image" : displayName ? "initials" : "default";

    return <div className={cn(profileHeadVariants({ size, className }))}>
        {
            match(displayMode)
                .with("initials", () => {
                    const initials = displayName!.split(" ").map((name) => name[0]).join("").toUpperCase();
                    return <span className="select-none">{initials}</span>
                })
                .with("image", () => (
                    <img src={userImage!} className="w-full h-full object-cover" alt={displayName + ' Profile Picture'} />
                )).otherwise(() => (
                    <UserIcon />
                ))
        }
    </div>;
};

export default ProfileHead;