import React from "react";
import useStore from "@/store";
import { decodeIdToken } from "@/services/authService";

const AuthContext = React.createContext<UserInfo | null | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const authToken = useStore((state) => state.auth);

    const [user, setUser] = React.useState<UserInfo | null | undefined>(undefined);

    React.useEffect(() => {
        const userInfo = decodeIdToken(authToken);

        if (userInfo?.isTokenExpired) {
            setUser(null);
            return;
        }

        setUser(userInfo);
    }, [authToken]);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuth = () => {
    const context = React.useContext(AuthContext);
    return context;
}