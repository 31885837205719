// CarouselComponent.tsx
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselComponent.css";
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon';

interface CarouselComponentProps {
  settings?: {
    className: string;
    centerMode: boolean;
    infinite: boolean;
    centerPadding: string;
    slidesToShow: number;
    speed: number;
    arrows?: boolean;
    dots?: boolean;
  };

  onSlideChange?: (currentIndex: number) => void; // Add callback function prop
  children?: React.ReactNode;
}

const SamplePrevArrow = (props: any) => {
  const { className, onClick } = props;
  return(
    <div onClick={onClick} className={`arrow ${className}`} >
      <ChevronLeftIcon />
    </div>
  )
}

const SampleNextArrow = (props: any) => {
  const { className, onClick } = props;
  return(
    <div onClick={onClick} className={`arrow ${className}`} >
      <ChevronRightIcon />
    </div>
  )
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({ settings, onSlideChange, children }) => {
  const defaultSettings = {
    className: "center carousel-component",
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    dots: false,
    arrows: true,
    afterChange: (current: number) => {
      if (onSlideChange) {
        onSlideChange(current); // Call the callback function with the current index
      }
    },
    nextArrow: <SampleNextArrow to="next" style={{ color: '#428cff' }} />,
    prevArrow: <SamplePrevArrow to="prev" style={{ color: '#428cff' }} />,
  };

  return (
    <Slider {...(settings || defaultSettings)}>
      {children}
    </Slider>
  );
};

export default CarouselComponent;