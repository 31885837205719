import { ApexOptions } from 'apexcharts';
import clsx from 'clsx';
import React from 'react';
import ApexChart from 'react-apexcharts';

export interface SemiCircleChartProps {
  series?: number[];
  className?: string;
  label?: React.ReactNode;
  width?: string;
  height?: string;
}

const SemiCircleChart = React.memo(({ className, series = [80.08], width = "100%", height = "300px", label }: SemiCircleChartProps) => {
  const options: ApexOptions = {
    chart: {
      offsetY: -20,
      sparkline: { enabled: true },
    },
    colors: ['#08a0e2'],
    plotOptions: {
      radialBar: {
        startAngle: -115,
        endAngle: 115,
        track: {
          background: '#d2d4d6',
          startAngle: -115,
          endAngle: 115,
          strokeWidth: '90%',
        },
        dataLabels: {
          name: {
            show: false, // Hide the default label
          },
          value: {
            color: 'white',
            fontFamily: 'Work Sans, sans-serif',
            fontWeight: 'bold',
            offsetY: 4,
            fontSize: '0px'
          }
        },
      },
    },
    fill: {
      type: 'solid', // Ensure fill is solid
    },
    stroke: {
      lineCap: 'butt',
    },
    grid: {
      padding: {

      },
    },
    labels: [''],
  };

  return (
    <div className={clsx('relative', className)}>
      <ApexChart width={width} height={height} options={options} series={series} type="radialBar" />
      {label && <div className="absolute inset-0 flex items-center justify-center">
        {label}
      </div>}
    </div>
  );
});

export default SemiCircleChart;