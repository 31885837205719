const WhitePercentageIcon = () => {
    return (
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="-0.5" width="31" height="31" rx="7.5" transform="matrix(1 0 0 -1 0.689453 31.5)" stroke="white"/>
        <path d="M21.9395 11.25L11.4395 21.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5645 14.25C13.6 14.25 14.4395 13.4105 14.4395 12.375C14.4395 11.3395 13.6 10.5 12.5645 10.5C11.5289 10.5 10.6895 11.3395 10.6895 12.375C10.6895 13.4105 11.5289 14.25 12.5645 14.25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.8145 22.5C21.85 22.5 22.6895 21.6605 22.6895 20.625C22.6895 19.5895 21.85 18.75 20.8145 18.75C19.7789 18.75 18.9395 19.5895 18.9395 20.625C18.9395 21.6605 19.7789 22.5 20.8145 22.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    )
}

export default WhitePercentageIcon;