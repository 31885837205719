import { QueryBuilder, RuleGroupType, formatQuery } from 'react-querybuilder';
import { Dialog, DialogContent } from '../../ui/dialog';
import { ArrowDown, ArrowUp, Trash2Icon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { GetFilterPreviewResponse, SavedQueryResponse, UpdateFilterInput } from '../pages/deals/types';
import DealsBanner from '../pages/deals/DealsBanner';
import PropertyDealsFilterTable from "../pages/deals/PropertyDealsFilterTable";
import { useQueryClient } from "react-query";
import { PropertyDealDetailsQueryKeys, useFetchSavedQueries, useFilterDetails, useFilterPreviewFetch, useFilterUpdate } from "../pages/deals/hooks/index";
import PropertyDetailsModal from "../pages/deals/PropertyDetailsModal";
import { useDealDetails } from "../pages/deals/hooks";
import 'react-querybuilder/dist/query-builder.css';
import './QueryBuilderModalComponent.css';
import { toast } from 'react-toastify';
import PaginationComponent from '@/components/pagination-component/PaginationComponent';
import { LIMIT_FILTER_PREVIEW } from '../pages/deals/DealsV2';
import { useAuth } from '@/components/AuthProvider';
import QueryNameModal from '../pages/deals/QueryNameModal';
import CustomFieldSelector from './CustomFieldSelector';

export const defaultInitialQuery: RuleGroupType = { combinator: 'and', rules: [] };
export interface QueryBuilderComponentProps {
    isOpen: boolean;
    toggleModal: () => void;
    showProgressBar?: () => void;
    hideProgressBar?: () => void;
}
export const openSearchQueryOption = { format: 'elasticsearch', parseNumbers: true };
const jsonQueryOption = { format: 'json', parseNumbers: true };

const QueryBuilderModalComponent = ({
    isOpen,
    toggleModal,
    showProgressBar,
    hideProgressBar }: QueryBuilderComponentProps) => {

    const initialIsOpenDetailsModal = false;
    const initialShowReview = false;
    const [showReview, setShowReview] = useState(initialShowReview);
    const [totalOpportunities, setTotalOpportunities] = useState(-1);
    const queryClient = useQueryClient();
    const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(initialIsOpenDetailsModal);
    const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedQuery, setSelectedQuery] = useState<RuleGroupType>({ combinator: 'and', rules: [] });
    const [propertyPreviewList, setPropertyPreviewList] = useState<GetFilterPreviewResponse[] | undefined>();
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [isOpenQueryNameModal, setIsOpenQueryNameModal] = useState(false);
    const [selectedQueryTemplate, setSelectedQueryTemplate] = useState<SavedQueryResponse>();


    const auth = useAuth();

    // Fetching properties preview data table
    const {
        mutate: fetchFilterPreview,
        data: filterPreviewData,
        error: filterPreviewError,
        isLoading: isLoadingPreviewProperties } = useFilterPreviewFetch();

    // Preview or Save Query
    const {
        mutate: updateFilterQuery,
        data: updatedFilterData,
        error: updateFilterError,
        isLoading: isLoadingPreviewSummary } = useFilterUpdate();

    // Fetching property details for second modal (when user clicks on a property row)
    const { data: propertyDetails, error: propertyDetailsError } = useDealDetails(selectedPropertyId);

    // Filter fields
    const { data: filterDetails, error: filterDetailsError, isLoading: isLoadingFields } = useFilterDetails(isOpen);

    // Fetching saved queries for dropdown
    const { data: savedQueryData, error: savedQueryError, isLoading: isLoadingSavedQuery } = useFetchSavedQueries(auth?.sub);

    useEffect(() => {
        if (!selectedQuery) {
            return;
        }

        setShowReview(false);
        setTotalOpportunities(-1);
    }, [selectedQuery]);

    useEffect(() => {
        if (propertyDetailsError) {
            console.error('Failed to fetch property details:', propertyDetailsError);
            toast.error('Failed to fetch property details');
            hideProgressBar && hideProgressBar();
        } else if (propertyDetails) {
            setIsOpenDetailsModal(true);
            hideProgressBar && hideProgressBar();
        }
    }, [propertyDetails, propertyDetailsError]);

    useEffect(() => {
        const formatQuery = formatAndCheckQuery(selectedQuery);
        if (formatQuery && page > 0) {
            showProgressBar && showProgressBar();
            fetchFilterPreview({ filter: formatQuery, fromIndex: page - 1, size: LIMIT_FILTER_PREVIEW })
        }
    }, [page]);

    useEffect(() => {
        if (filterPreviewError) {
            console.error('Failed to fetch filter preview:', filterPreviewError);
            toast.error('Failed to fetch filter preview');
            // hideProgressBar && hideProgressBar();
            return;
        }

        if (filterPreviewData) {
            setPropertyPreviewList(filterPreviewData);
        }
    }, [filterPreviewData, filterPreviewError]);

    useEffect(() => {
        if (savedQueryError) {
            console.error('Failed to fetch saved queries:', savedQueryError);
            toast.error('Failed to fetch saved queries');
            return;
        }

        if (savedQueryData && savedQueryData.length > 0) {
            const enabledQuery = savedQueryData.find(q => q.currentlyEnabled);
            if (enabledQuery) {
                setSelectedQuery({ ...enabledQuery.jsonQuery });
                setSelectedQueryTemplate(enabledQuery);
                console.log('enabledQuery:', enabledQuery);
            }
        }
    }, [savedQueryData, savedQueryError]);

    console.log('savedQueryData:', savedQueryData);

    useEffect(() => {
        if (isLoadingFields || isLoadingSavedQuery) {
            showProgressBar && showProgressBar();
        } else {
            hideProgressBar && hideProgressBar();
        }
    }, [isLoadingFields, isLoadingSavedQuery]);

    useEffect(() => {
        console.log('isLoadingPreviewSummary:', isLoadingPreviewSummary);
        console.log('isLoadingPreviewProperties:', isLoadingPreviewProperties);
        if (isLoadingPreviewSummary || isLoadingPreviewProperties) {
            showProgressBar && showProgressBar();
        } else {
            hideProgressBar && hideProgressBar();
        }
    }, [isLoadingPreviewSummary, isLoadingPreviewProperties]);

    useEffect(() => {
        if (updatedFilterData) {
            if (!isPreviewMode) {
                toggleModal();
                toast.success("Filter updated successfully.");
            } else {
                setTotalOpportunities(updatedFilterData.numberOfOpportunities || 0);
                setTotalPages(Math.ceil(updatedFilterData.numberOfProperties / LIMIT_FILTER_PREVIEW));
                setPage(1);
            }
        }
    }, [updatedFilterData, updateFilterError]);

    useEffect(() => {
    }, [filterDetails, filterDetailsError]);

    const onViewPropertyDetailsHandler = (propertyId: string) => {
        setSelectedPropertyId(propertyId);

        const cachedPropertyDetails = queryClient.getQueryData([PropertyDealDetailsQueryKeys, propertyId]);
        if (cachedPropertyDetails === undefined) {
            showProgressBar && showProgressBar();
        } else {
            setIsOpenDetailsModal(prevState => !prevState);
        }
    };

    const onClearHandler = () => {
        setSelectedQuery(defaultInitialQuery);
        setPropertyPreviewList(undefined);
        setTotalOpportunities(-1);
        setTotalPages(1);
        setSelectedQueryTemplate(undefined);
    };

    const handleTemplateQueryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const queryId = event.target.value;
        const savedQuery = savedQueryData?.find(q => q.opportunityCreationCriteriaID === queryId);

        if (!savedQuery) {
            setSelectedQuery(defaultInitialQuery);
            setSelectedQueryTemplate(undefined);
            return;
        } else {
            setSelectedQuery({ ...savedQuery.jsonQuery });
            setSelectedQueryTemplate(savedQuery);
        }
    }

    const formatAndCheckQuery = (query: RuleGroupType, formatQueryOption = openSearchQueryOption) => {
        const formatFilter = formatQuery(query, formatQueryOption);
        const filterObj = JSON.parse(JSON.stringify(formatFilter));
        return Object.keys(filterObj).length > 0 ? filterObj : null;
    };

    const handlePreviewOrSaveQuery = (isSaved: boolean, queryName?: string) => {
        setIsPreviewMode(!isSaved);
        let opportunityCriteriaID = undefined;

        if (isSaved) {
            if (totalOpportunities < 0) {
                toast.error("Please preview the query first.");
                return;
            }

            // If a query is selected and no new name is provided, use the selected query name
            if (!queryName) {
                setIsOpenQueryNameModal(true);
                return;
            }

            // Confirm the user wants to override the current query
            if (!window.confirm("Are you sure you want to override the current query?")) {
                return;
            }

            // const isUpdated = opportunityCriteriaID === selectedQueryTemplate?.opportunityCreationCriteriaName;
            // const confirmMessage = isUpdated ? "Message for updating " : "Message for creating ";
            // if (window.confirm(confirmMessage)) {
            //     return;
            // }
            // opportunityCriteriaID.isUpdated ? selectedQueryTemplate ? opportunityCriteriaID : undefined
            // const openSearchFormatQuery = formatAndCheckQuery(selectedQuery);

            opportunityCriteriaID =
                queryName === selectedQueryTemplate?.opportunityCreationCriteriaName // Is updated?
                    ? selectedQueryTemplate?.opportunityCreationCriteriaID
                    : undefined;
        }

        const openSearchFormatQuery = formatAndCheckQuery(selectedQuery);

        if (openSearchFormatQuery === null) {
            toast.error("Please create a filter before previewing or saving.");
            return;
        }

        const jsonFormatQuery = formatAndCheckQuery(selectedQuery, jsonQueryOption);

        const updatedFilterInput: UpdateFilterInput = {
            openSearchQuery: openSearchFormatQuery,
            jsonQuery: jsonFormatQuery,
            userId: auth?.sub,
            getCount: !isSaved,
            opportunityCriteriaName: queryName,
            opportunityCriteriaID: opportunityCriteriaID,
        };

        console.log('Updated Filter Input: ', JSON.stringify(updatedFilterInput));

        updateFilterQuery(updatedFilterInput);

        if (!isSaved) {
            fetchFilterPreview({ filter: openSearchFormatQuery, fromIndex: 0, size: LIMIT_FILTER_PREVIEW });
        }
    };

    const onQueryChange = (newQuery: RuleGroupType) => {
        setSelectedQuery(newQuery);
        // console.log('Updated Query: ', JSON.stringify(formatQuery(newQuery, formatQueryOption)));
    };

    const parsedQueryData = typeof savedQueryData === 'string' ? JSON.parse(savedQueryData) : savedQueryData;
    const queryArray = parsedQueryData || [];
    // console.log("currentlyEnabled " + queryArray.map(((q: any) => (q.opportunityCreationCriteriaName +" --- "+ q.opportunityCreationCriteriaID))));
    // console.log("propertyPreviewList "+propertyPreviewList.map((p: any) => p.address));
    // console.log("queryArray "+JSON.stringify(queryArray));
    // console.log("ID "+selectedQueryTemplate?.opportunityCreationCriteriaID);
    // console.log("selectedQuery " + JSON.stringify(selectedQuery))

    return (
        <Dialog open={isOpen} onOpenChange={toggleModal}>
            <DialogContent className="w-11/12 max-w-screen h-full outline-none overflow-x-hidden p-0 border-0 flex flex-col">
                <div className="flex-grow max-h-[60%] overflow-auto">
                    {
                        queryArray.length > 0 && (
                            <select
                                className='absolute right-[70] bg-filterSelect'
                                onChange={handleTemplateQueryChange}
                                value={selectedQueryTemplate?.opportunityCreationCriteriaID}>
                                <option value={undefined} selected={!selectedQueryTemplate?.opportunityCreationCriteriaID}>Select a query</option>
                                {
                                    queryArray.sort((a: any, b: any) => a.opportunityCreationCriteriaName.localeCompare(b.opportunityCreationCriteriaName))
                                        .map((query: any, index: number) => (
                                            <option key={index} value={query.opportunityCreationCriteriaID}>{query.opportunityCreationCriteriaName}</option>
                                        ))
                                    
                                }
                            </select>
                        )                   
                    }
                    <QueryBuilder
                        fields={filterDetails}
                        query={selectedQuery}
                        onQueryChange={onQueryChange}
                        showShiftActions={true}
                        addRuleToNewGroups={true}
                        showCombinatorsBetweenRules={false}
                        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                        controlElements={{
                            fieldSelector: CustomFieldSelector,
                        }}
                        translations={{
                            addGroup: {
                                label: 'Add Group',
                                title: 'Add Group',
                            },
                            addRule: {
                                label: 'Add Rule',
                                title: 'Add Rule',
                            },
                            removeGroup: {
                                label: <Trash2Icon color='#F47C79' size={20} />,
                                title: 'Remove Group',
                            },
                            removeRule: {
                                label: <Trash2Icon color='#F47C79' size={20} />,
                                title: 'Remove Rule',
                            },
                            shiftActionDown: {
                                label: <ArrowDown color='black' size={15} />,
                                title: 'Shift Down',
                            },
                            shiftActionUp: {
                                label: <ArrowUp color='black' size={15} />,
                                title: "Shift Up",
                            }
                        }}
                    />
                    {/* {showReview && <div className="p-5">{JSON.stringify(formatQuery(initialQuery, formatQueryOption))}</div>}
                    {totalOpportunities >= 2 ? <div className="p-5 font-bold text-lg">{totalOpportunities} properties found!</div> : totalOpportunities === 1 ? <div className="p-5 font-bold text-lg">1 property found!</div> : null} */}
                </div>
                <div className='p-3 sticky'>
                    {
                        updatedFilterData 
                        && propertyPreviewList
                        && <DealsBanner
                            details={{
                                totalOpportunities: updatedFilterData.numberOfOpportunities,
                                totalProperties: updatedFilterData.numberOfProperties,
                                totalAIV: updatedFilterData.totalMarketValue,
                                totalUPB: updatedFilterData.totalUPB,
                                averageAIV: updatedFilterData.averageMarketValue,
                                profitPercentage: 0,
                                createdAt: "",
                                totalPropertiesAsIsValue: 0,
                                metrics_uuid: '0',
                                averagePropertyAsIsValue: 0
                            }} />
                    }
                </div>
                <div className="flex flex-col h-full items-left justify-end pb-5 max-h-[40%] px-3 ">
                    <div className="flex-grow overflow-auto h-auto w-full pb-10">
                        {/* {JSON.stringify(propertyPreviewList)} */}
                        {propertyPreviewList && (
                            <>
    {console.log('>>>>>>totalPages' , totalPages, page)}
                                {
                                    totalPages > 1 && (
                                        <PaginationComponent
                                            totalPages={totalPages}
                                            setPage={setPage}
                                            page={page}
                                            isDisabledNext={page == totalPages} />
                                    )
                                }
                                {
                                    totalPages > 0 && propertyPreviewList && (
                                        <PropertyDealsFilterTable
                                            properties={propertyPreviewList}
                                            onDetailsClick={onViewPropertyDetailsHandler}
                                        />
                                )}

                            </>
                        )}
                        { 
                            !!propertyPreviewList?.length && (
                                <PropertyDetailsModal
                                    isOpen={isOpenDetailsModal}
                                    toggleModal={() => setIsOpenDetailsModal(!isOpenDetailsModal)}
                                    property={propertyPreviewList.find(p => p.id === selectedPropertyId)}
                                    details={propertyDetails}
                                />
                            )
                        }

                        <QueryNameModal
                            isOpen={isOpenQueryNameModal}
                            onSave={(newQueryName: string) => handlePreviewOrSaveQuery(true, newQueryName)}
                            toggleModal={() => setIsOpenQueryNameModal(!isOpenQueryNameModal)}
                            initialQueryName={selectedQueryTemplate?.opportunityCreationCriteriaName}
                            />
                    </div>
                    <div className='h-auto'>
                        {
                            showReview && <div className="p-5">{JSON.stringify(formatQuery(selectedQuery, openSearchQueryOption))}</div>
                        }
                        {
                            showReview && <div className="p-5">{formatQuery(selectedQuery, 'json_without_ids')}</div>
                        }
                    </div>
                    <div className="flex-shrink-0">
                        <button className="apply-new-query-button" onClick={() => handlePreviewOrSaveQuery(false)}>Preview</button>
                        <button
                            title={totalOpportunities < 0 ? 'Please preview the query first' : ''}
                            className={`ml-5 apply-new-query-button ${totalOpportunities < 0 ? 'disabled' : ''}`}
                            onClick={() => handlePreviewOrSaveQuery(true)}>Save Query</button>
                        <button className="ml-5 apply-new-query-button" onClick={() => setShowReview(!showReview)}>Toggle Query</button>
                        <button className="ml-5 apply-new-query-button" onClick={onClearHandler}>Clear</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default QueryBuilderModalComponent;