// ProgressBar.tsx
import "./ProgressBar.css";
import DisableScrollY from '@/components/DisableScrollY';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface ProgressBarProps {
  progress: number;
  timeoutInMiliseconds?: number;
}

// Function to get the translateY percentage
const getTranslateYPercentage = (num: number): string => {
  const translateMap = [-45, 45, 35, 25, 15, 5, -5, -15, -25, -35];
  if (num >= 0 && num <= 9 && Number.isInteger(num)) {
    return `${translateMap[num]}%`;
  }
  throw new Error('Input must be an integer between 0 and 9.');
};


const ProgressBar: React.FC<ProgressBarProps> = ({ progress, timeoutInMiliseconds = 30000 }) => {
  const preloaderRef = useRef<HTMLDivElement>(null);
  const topPreloaderRef = useRef<HTMLDivElement>(null);
  const bottomPreloaderRef = useRef<HTMLDivElement>(null);
  const baseBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Function to animate the progress
    const animateProgress = (to: number) => {
      const hundredths = Math.floor(to / 100);
      const tenths = Math.floor((to % 100) / 10);
      const units = to % 10;

      // Apply the calculated transform
      if (preloaderRef.current) {
        const percent1st = preloaderRef.current.querySelector('.preloader-percent-1st') as HTMLDivElement;
        const percent2nd = preloaderRef.current.querySelector('.preloader-percent-2nd') as HTMLDivElement;
        const percent3rd = preloaderRef.current.querySelector('.preloader-percent-3rd') as HTMLDivElement;

        if (percent1st) percent1st.style.transform = `translate3d(0, ${getTranslateYPercentage(hundredths)}, 0)`;
        if (percent2nd) percent2nd.style.transform = `translate3d(0, ${getTranslateYPercentage(tenths)}, 0)`;
        if (percent3rd) percent3rd.style.transform = `translate3d(0, ${getTranslateYPercentage(units)}, 0)`;
      }

      // Update the base bar width
      if (baseBarRef.current) {
        baseBarRef.current.style.width = `${to}%`;
      }
    };

    animateProgress(progress);

    if (progress >= 95) {
      const timeout =  progress >= 100 ? 500 : timeoutInMiliseconds;
      setTimeout(() => {
        if (bottomPreloaderRef.current) bottomPreloaderRef.current.classList.add('animation-hidden');
        if (preloaderRef.current) preloaderRef.current.classList.add('animation-hidden');
        if (topPreloaderRef.current) topPreloaderRef.current.classList.add('animation-hidden');
      }, timeout);
    } else if (progress === 0) {
      if (bottomPreloaderRef.current) bottomPreloaderRef.current.classList.remove('animation-hidden');
      if (preloaderRef.current) preloaderRef.current.classList.remove('animation-hidden');
      if (topPreloaderRef.current) topPreloaderRef.current.classList.remove('animation-hidden');
    }
  }, [progress]);

  return (
    <DisableScrollY>
      <motion.div>
        <div
          className="preloader-wrapper flex flex-col justify-center items-center fixed inset-0 z-[9999999] transition-opacity duration-500"
          ref={preloaderRef}
        >
          <div
            className="top-preloader w-full h-1/2 bg-gray-800 transition-transform duration-500 ease-in-out"
            ref={topPreloaderRef}
          ></div>
          <div
            className="bottom-preloader w-full h-1/2 bg-gray-800 transition-transform duration-500 ease-in-out"
            ref={bottomPreloaderRef}
          ></div>
          <div className="preloader-percent-wrapper flex justify-center items-center absolute top-1/2 w-full h-5 overflow-hidden">
            <div
              className="preloader-base-bar bg-gray-300 rounded-full min-w-[20%] max-w-[20%] mr-2 overflow-hidden"
            >
              <div className="preloader-bar-horizontal bg-gradient-to-r from-gray-200 to-black rounded-full w-full h-3" ref={baseBarRef}></div>
            </div>
            <div className="flex flex-col justify-center items-center overflow-hidden preloader-percent-1st">
              {[...Array(10)].map((_, index) => (
                <p key={index} className="text-white text-xl">
                  {index === 9 ? 0 : index + 1}
                </p>
              ))}
            </div>
            <div className="flex flex-col justify-center items-center overflow-hidden preloader-percent-2nd">
              {[...Array(10)].map((_, index) => (
                <p key={index} className="text-white text-xl">
                  {index === 9 ? 0 : index + 1}
                </p>
              ))}
            </div>
            <div className="flex flex-col justify-center items-center overflow-hidden preloader-percent-3rd">
              {[...Array(10)].map((_, index) => (
                <p key={index} className="text-white text-xl">
                  {index === 9 ? 0 : index + 1}
                </p>
              ))}
            </div>
            <div className="flex flex-col justify-center items-center overflow-hidden">
              <p className="text-white text-xl">%</p>
            </div>
          </div>
        </div>
      </motion.div>
    </DisableScrollY>
  );
};

export default ProgressBar;