import { LenderProfileProps } from "@/components/dashboard/LenderProfileProps";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export type Store = {
  auth: any;
  setAuthToken: (auth: any) => void;
  renderMiddleHeaderContent: () => JSX.Element | null;
  setRenderMiddleHeaderContent: (content: () => JSX.Element | null) => void;
  profile: LenderProfileProps | null;
  setProfile: (profile: LenderProfileProps | null) => void;
};

const useStore = create<Store>()(
  persist(
    (set) => ({
      auth: null,
      setAuthToken: (state: any) => set({ auth: state }),
      renderMiddleHeaderContent: () => null,
      setRenderMiddleHeaderContent: (content: () => JSX.Element | null) =>
        set({ renderMiddleHeaderContent: content }),
      profile: null,
      setProfile: (profile: LenderProfileProps | null) => set({ profile }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useStore;