export const INVESTMENT_PERFORMANCE_SERIES = [
  {
    data: [1, null, 150000000, null, 413200000, null, null, 400000000, null, null, 300000000, null, 400000000, 500000000],
    color: '#089EF3',
  },
  {
    data: [200000000, null, null, 150000000, null, null, 400000000, null, null, 500000000, null, null, null, 400000000],
    color: '#751DA3',
  },
];
