// useProgressBar.ts
import ProgressBar from '@/components/dashboard/common/ProgressBar';
import { useState, useEffect } from 'react';

const useProgressBar = (): [() => React.ReactNode, () => void, () => void] => {
  const [progress, setProgress] = useState<number>(0);
  const startProgressBar = () => setProgress(0);
  const stopProgressBar = () => setProgress(100);
  const renderProgressBarUI = () => <ProgressBar progress={progress} />;

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (progress >= 0 && progress < 95) {
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 5, 95));
      }, 200);
    }
    return () => {
      if (interval)
        clearInterval(interval);
      interval = undefined;
    };
  }, [progress]);

  return [renderProgressBarUI, startProgressBar, stopProgressBar];

};
export default useProgressBar;