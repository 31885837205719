import DiamondIcon from '@/components/icons/DiamondIcon';
import DoubleArrowIcon from '@/components/icons/DoubleArrowIcon';
import ChartIcon from '@/components/icons/ChartIcon';

export const infoCardList = [
  {
    title: 'Estimated Purchase Price',
    value: <span className="text-base font-bold text-[#1ACE7C] whitespace-nowrap">-</span>,
    icon: <DiamondIcon />
  },
  {
    title: 'Offer Range',
    value: <span className="text-base font-bold text-[#13ADF0] whitespace-nowrap">-</span>,
    icon: <DoubleArrowIcon />
  },
  {
    title: 'Default Amount',
    value: <span className="text-base font-bold text-[#878787] whitespace-nowrap">-</span>,
    icon: <ChartIcon />
  },
  {
    title: 'Profit A',
    value: <span className="text-base font-bold text-[#1ACE7C] whitespace-nowrap">-</span>,
    icon: <ChartIcon />
  },
  {
    title: 'Profit B',
    value: <span className="text-base font-bold text-[#1ACE7C] whitespace-nowrap">-</span>,
    icon: <ChartIcon />
  }
];

export const financialInfoList = [
  {
    label: "Incorporation Date",
    value: "01/23/1987",
  },
  {
    label: "Fund Type",
    value: "Example",
  },
  {
    label: "Incorporation Date",
    value: "01/23/1987",
  },
  {
    label: "Fund Type",
    value: "Example",
  },
  {
    label: "Incorporation Date",
    value: "01/23/1987",
  },
  {
    label: "Fund Type",
    value: "Example",
  },
  {
    label: "Incorporation Date",
    value: "01/23/1987",
  },
  {
    label: "Fund Type",
    value: "Example",
  },
  {
    label: "Solitare",
    value: "3230",
  },
  {
    label: "Fund Type",
    value: "Example",
  },
  {
    label: "Incorporation Date",
    value: "01/23/1987",
  },
  {
    label: "Fund Type",
    value: "Example",
  }
];

export const tabTableData = [
  {
    col1: "Incorporation Date",
    col2: "01/23/1987",
    col3: "Incorporation Date",
    col4: "01/23/1987",
    col5: "Solitare",
    col6: "3230"
  },
  {
    col1: "Fund Type",
    col2: "Example",
    col3: "Fund Type",
    col4: "Example",
    col5: "Fund Type",
    col6: "Example"
  },
  {
    col1: "Incorporation Date",
    col2: "01/23/1987",
    col3: "Incorporation Date",
    col4: "01/23/1987",
    col5: "Solitare",
    col6: "3230"
  },
  {
    col1: "Fund Type",
    col2: "Example",
    col3: "Fund Type",
    col4: "Example",
    col5: "Fund Type",
    col6: "Example"
  }
];

export const accordionTableData = [
  {
    col1: "Single Family",
    col2: "FL-33067",
    col3: "$5,034,231",
    col4: "$5,034,231",
    col5: "$5.2M - $6.1M",
    col6: "+ $2,342,214",
    col7: "~ $3,342,214"
  },
  {
    col1: "Multi Family",
    col2: "FL-33068",
    col3: "$6,034,231",
    col4: "$6,034,231",
    col5: "$6.2M - $7.1M",
    col6: "+ $3,342,214",
    col7: "~ $4,342,214"
  },
  {
    col1: "Commercial",
    col2: "FL-33069",
    col3: "$7,034,231",
    col4: "$7,034,231",
    col5: "$7.2M - $8.1M",
    col6: "+ $4,342,214",
    col7: "~ $5,342,214"
  }

];