// import reactLogo from "./assets/react.svg";
import 'apexcharts';
import 'flowbite';
import { initFlowbite } from 'flowbite';
import './App.css';

// import { auth } from "./Firebase";
// import * as Popover from "@radix-ui/react-popover";
import AuthProvider from '@/components/AuthProvider';
import Layout from '@/components/Layout';
import Account from '@/components/dashboard/pages/Account';
import Dashboard from '@/components/dashboard/pages/Dashboard';
import Settings from '@/components/dashboard/pages/Settings';
import Login from '@/components/login/Login';
import Splash from '@/components/splash/Splash';
import useStore from '@/store';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import QueryBuilder from 'react-querybuilder';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logout from './components/Logout';
import AWSCognito from './components/aws-cognito/aws-cognito';
import AWSCognitoMain from './components/aws-cognito/aws-cognito-main';
import DemoS3FileTree from './components/dashboard/pages/DemoS3FileTree';
import LenderProfile from './components/dashboard/pages/LenderProfile';
import DealsSeller from './components/dashboard/pages/deals/DealsSeller';
import InReviewOpportunities from './components/dashboard/pages/deals/InReviewOpportunities';
import OpenOpportunities from './components/dashboard/pages/deals/OpenOpportunities';
import RejectOpportunities from './components/dashboard/pages/deals/RejectOpportunities';
import BuyersPage from './components/dashboard/pages/deals/buyer';
import LenderProfileDetails from './components/dashboard/pages/lender-profile-details/LenderProfileDetails';
import Properties from './components/dashboard/pages/properties/Properties';

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    initFlowbite();
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
    }
  }, []);

  const authToken = useStore((state) => state.auth);
  const setRenderMiddleHeaderContent = useStore((state) => state.setRenderMiddleHeaderContent);
  const location = useLocation();

  const PrivateRoutes = () => {
    useEffect(() => {
      if (!location.pathname.includes('/lender-profiles/details') && !location.pathname.includes('/deals')) {
        setRenderMiddleHeaderContent(() => null);
      }
    }, [location.pathname, setRenderMiddleHeaderContent]);
    console.log('>>>>>> : ' , location.hash, location);
    
    return authToken ? (
      <Layout>
        <Outlet />
      </Layout>
    ) : (
      <Navigate to="/login" replace={true} />
    );
  };
  
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer limit={1} />
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Navigate to="/v2/deals" replace={true} />} /> 
            <Route path="/investments" element={<Dashboard />} />
            <Route path="/lender-profiles" element={<LenderProfile />} />
            <Route path="/lender-profiles/details/:name" element={<LenderProfileDetails />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/products" element={<Dashboard />} />
            <Route path="/me" element={<Account />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/demo-s3-file-tree" element={<DemoS3FileTree />} />
            {/* <Route path="/deals" element={<Deals />} /> */}
            <Route path="/offers" element={<DealsSeller status={'OfferMade'} />} />
            <Route path="/buyers" element={<BuyersPage status={'BuyerOfferMade'} />} />
            <Route path="v2/deals" element={<OpenOpportunities />} />
            <Route path="inreview/deals" element={<InReviewOpportunities />} />
            <Route path="reject/deals" element={<RejectOpportunities />} />
            <Route path="/query-builder" element={<QueryBuilder />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/aws-login" element={<AWSCognito />} />
          <Route path="/aws-main" element={<AWSCognitoMain />} />
          <Route path="/splash" element={authToken ? <Navigate to="/v2/deals" replace={true} /> : <Splash />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
