import { BookmarkIcon } from 'lucide-react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Progress } from '@/components/ui/progress';
import ComposableCard from '@/components/composable/ComposableCard';
import useStore from '@/store';
import { LenderProfileProps } from '../LenderProfileProps';

const LenderProfileCard = (lenderProfile: LenderProfileProps) => {
  const {
    lenderName,
    defaultRate,
    totalPortfolioValue,
    loanVol,
    defaultVol,
    defaultTotal,
    className,
    averageLoanAmount
  } = lenderProfile;
    const navigate = useNavigate();
    const { setProfile } = useStore();

    const TitleElement = (
      <div className="flex justify-between">
        <h3 className="pb-1 dark:text-white">{lenderName}</h3>
        <BookmarkIcon className="ml-2 h-8 w-6" />
      </div>  
  );

  const data = [
    {
      name: 'Total Portfolio Value',
      value: totalPortfolioValue,
    },
    {
      name: 'Loan Volume',
      value: loanVol,
    },
    {
      name: 'Default Volume',
      value: defaultVol,
    },
    {
      name: 'Default Total',
      value: defaultTotal,
    },
    {
      name: 'Average Loan Amount',
      value: averageLoanAmount,
    },
  ];

  function convertToInternationalCurrencySystem (labelValue: number = 0) {
    return Math.abs(labelValue) >= 1.0e+9  // Nine Zeroes for Billions
    ? (Math.abs(labelValue) / 1.0e+9).toFixed(2) + "B"
    : Math.abs(labelValue) >= 1.0e+6  // Six Zeroes for Millions 
    ? (Math.abs(labelValue) / 1.0e+6).toFixed(2) + "M"
    : Math.abs(labelValue) >= 1.0e+3 // Three Zeroes for Thousands
    ? (Math.abs(labelValue) / 1.0e+3).toFixed(2) + "K"
    : Math.abs(labelValue); 
  }

  return (
    <ComposableCard
      onClick={() => {
        setProfile(lenderProfile);
        navigate(`/lender-profiles/details/${lenderName}`);
      }}
      title={TitleElement}
      className={clsx(className, 'flex w-96 lg:w-80 md:w-72 flex-col gap-0 !font-headings lg:bg-card-lender')}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-3">
          <span className="text-[#788190]">Default Rate</span> <span className="font-semibold">{defaultRate}%</span>
        </div>
        <Progress variant="gradient" value={defaultRate} />
      </div>
      <h4 className="mt-2">Profile Overview:</h4>
      <div className="flex flex-row flex-wrap items-center gap-0">
        {data.map((item, index) => (
          <h5 className={clsx("flex flex-col items-start justify-center gap-1 font-normal", index + 1 !== data.length ? "w-1/2" : "")}>
            <span className="text-[#788190]">{item.name}</span> <span className="font-semibold">{  convertToInternationalCurrencySystem(item.value || 0)}</span>
          </h5>
        ))}
      </div>
    </ComposableCard>
  );
};

export default LenderProfileCard;
