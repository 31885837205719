import { FC } from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import './ProgressComponent.css';

interface ProgressComponentProps {
    progress: number;
    fromLabel?: string;
    toLabel?: string;
}

const ProgressComponent: FC<ProgressComponentProps> = ({ progress, fromLabel, toLabel }) => {
    return (
        <div className="w-full">
            <ProgressPrimitive.Root className="relative h-2 rounded-full bg-[#d2d4d6]" value={progress}>
                <ProgressPrimitive.Indicator className="progress-indicator bg-[#333]" style={{ width: `${progress}%` }} />
            </ProgressPrimitive.Root>
                        {
                (fromLabel || toLabel) &&
                <div className="flex justify-between mt-2">
                    <span className="range-label text-sm">{fromLabel}</span>
                    <span className="range-label text-sm">{toLabel}</span>
                </div>
            }
        </div>
    );
};

export default ProgressComponent;