import { SIDE_NAV_LINKS } from '@/constants/SideNavLinks';
import clsx from 'clsx';
import { ArrowLeft } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../branding/Logo';
import { Button } from '../ui/button';
import MobileProfileLink from './MobileProfileLink';

interface SideNavProps {
  className?: string;
  onNavigate?: () => void;
}

const SideNav = ({ className, onNavigate }: SideNavProps) => {
  const location = useLocation();

  const isActive = useCallback(
    (to: string) => {
      return location.pathname === to || location.pathname.includes(`${to}/`); // For nested routes
    },
    [location],
  );

  const { top, bottom } = SIDE_NAV_LINKS;
  const [topMenu, setTopMenu] = useState(top);
  const userPermissionString = localStorage.getItem('userPermission') || '[]'; // or '{}'
  const userPermission = JSON.parse(userPermissionString);

  useEffect(() => {
    if (userPermission.length) {
      if (!userPermission.includes('admin') && userPermission.includes('selling') && !userPermission.includes('buying')) {
        setTopMenu(top.filter((item) => item.key === 'offers'));
      } else if (!userPermission.includes('admin') && userPermission.includes('buying') && !userPermission.includes('selling')) {
        setTopMenu(top.filter((item) => item.key === 'buyers'));
      } else if (!userPermission.includes('admin') && (userPermission.includes('selling') || userPermission.includes('buying'))) {
        setTopMenu([...top.filter((item) => item.key === 'offers'), ...top.filter((item) => item.key === 'buyers')]);
      }
    }
  }, []);

  return (
    <div className={clsx('side-nav-wrapper', className)}>
      <div className="flex items-center justify-between pr-2 lg:p-0">
        <Logo className="mb-12 ml-7 mr-auto mt-7 h-auto w-[92px] lg:ml-auto lg:w-[109px] lg:-translate-x-6" />
        <Button variant="ghost" size="icon" className="mb-5 lg:hidden" onClick={() => onNavigate?.()} aria-label="Exit Mobile Navigation">
          <ArrowLeft />
        </Button>
      </div>

      <h2 className="side-nav-heading">Menu</h2>
      <MobileProfileLink onNavigate={onNavigate} />
      <div className="flex grow flex-col justify-between pb-10">
        <ul className="side-nav-links">
          {topMenu.map((links) => (
            <Link to={links.to} key={links.key} className={clsx('side-nav-link', { active: isActive(links.to) })} onClick={() => onNavigate?.()}>
              {links.icon}
              {links.label}
            </Link>
          ))}
        </ul>

        <ul className="side-nav-links">
          {bottom.map((links) => (
            <Link to={links.to} key={links.key} className={clsx('side-nav-link', { active: isActive(links.to) })} onClick={() => onNavigate?.()}>
              {links.icon}
              {links.label}
            </Link>
          ))}
        </ul>

        {/* Conditionally render the "Offers" link only if on the seller page */}
        {/* {(isOfferPage || isBuyersPage) && (
          <>
            <ul className="side-nav-links">
              <a className="side-nav-link" href={isOfferPage ? '/offers' : '/buyers'}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-codesandbox "
                >
                  <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                  <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
                  <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
                  <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
                  <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                  <line x1="12" x2="12" y1="22.08" y2="12"></line>
                </svg>
                Offers
              </a>
            </ul>
            <ul className="side-nav-links h-full"></ul>
          </>
        )} */}
      </div>
    </div>
  );
};

export default SideNav;
