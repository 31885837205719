import { motion, AnimatePresence, useAnimate } from 'framer-motion';
import StarfieldAnimation from "../animated/StarfieldCustom";
import Logo from '../branding/Logo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import subtleStarfield from "@/assets/subtle-starfield.webp";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from '../ui/button';
import { useAuth } from '../AuthProvider';
import useStore from '@/store';
import DisableScrollY from '../DisableScrollY';

declare global {
  interface Navigator {
    userAgentData?: {
      mobile: boolean;
    };
    standalone?: boolean;
  }
}

export interface SplashProps {
  quickSplash?: boolean;
  logoutSplash?: boolean;
  suppressInstallPrompt?: boolean;
}

const Splash = ({ quickSplash, logoutSplash, suppressInstallPrompt }: SplashProps) => {

  const auth = useAuth();
  const authToken = useStore(state => state.auth);

  const [logoScope, logoAnimate] = useAnimate();
  const [mainScope, mainAnimate] = useAnimate();
  const [starFieldScope, starFieldAnimate] = useAnimate();
  const [subtleStarFieldScope, subtleStarFieldAnimate] = useAnimate();

  const navigate = useNavigate();

  async function defaultSplashFlow() {
    console.log('>>>>>> 1 ');
    
    await logoAnimate(logoScope.current, { opacity: 1 }, { delay: 1 });
    if (auth && authToken) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await navigate('/');
    }
    await starFieldAnimate(starFieldScope.current, { opacity: 1 }, { delay: 1 });
    await logoAnimate(logoScope.current, { translateY: "calc(-50vh + 87px)" }, { delay: 2 });
    await starFieldAnimate(starFieldScope.current, { opacity: 0 });
    await subtleStarFieldAnimate(subtleStarFieldScope.current, { opacity: 1 });
    await navigate('/login', { replace: true });
  }

  async function quickSplashFlow() {
    await logoAnimate(logoScope.current, { opacity: 1 });
    await starFieldAnimate(starFieldScope.current, { opacity: 0.5 });
    await mainAnimate(mainScope.current, { opacity: 0 }, { delay: 1 });
    setShowSplash(false);
  }

  async function logoutSplashFlow() {
    console.log('>>>>>> 2 ');
    await starFieldAnimate(starFieldScope.current, { opacity: 0.5 });
    await logoAnimate(logoScope.current, { translateY: "calc(-50vh + 87px)" });
    await navigate('/login', { replace: true });
    setShowSplash(false);
  }

  const isStandalone = useCallback(() => {
    if (navigator.standalone) {
      return true;
    }
    return (window.matchMedia('(display-mode: standalone)').matches);
  }, []);


  const isMobile = useCallback(() => {
    if (navigator?.userAgentData?.mobile) {
      return true;
    }
    if (
      (window.innerWidth <= 800) &&
      ('ontouchstart' in document.documentElement) &&
      (navigator.maxTouchPoints > 0)
    ) {
      return true;
    }
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
      return true;
    }

    return false;
  }, []);

  const [showSplash, setShowSplash] = useState(true);
  const [showMobileModal, setShowMobileModal] = useState(false);

  const splashFunc =
    quickSplash ?
      quickSplashFlow :
      logoutSplash ?
        logoutSplashFlow :
        defaultSplashFlow;


  const kickoff = () => {
    setShowMobileModal(false);
    splashFunc();
  };

  useEffect(() => {
    if (auth === undefined) {
      return;
    }

    if (!isStandalone() && isMobile() && !suppressInstallPrompt) {
      setShowMobileModal(true);
    } else {
      kickoff();
    }
  }, [isStandalone, isMobile, auth]);

  const open = (!isStandalone() && showMobileModal);

  const intervalRef = useRef<number>();
  const [countdown, setCountdown] = useState(20);

  useEffect(() => {
    if (!open) {
      clearInterval(intervalRef.current);
      return;
    }
    intervalRef.current = window.setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(intervalRef.current);
          kickoff();
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [open])

  return (
    <AnimatePresence>
      {showSplash ? (
        <>
          <DisableScrollY>
            <motion.div initial={{ opacity: 1 }} exit={{ opacity: 0, speed: 1 }} className="fixed w-screen h-screen bg-background z-50" key="main" ref={mainScope}>
              <Dialog open={open} onOpenChange={(open) => !open && kickoff()}>
                <DialogContent className="max-w-[90vw] lg:max-w-xl">
                  <DialogHeader>
                    <DialogTitle>Add to Home Screen</DialogTitle>
                  </DialogHeader>
                  <p>For the best experience, add this app to your home screen.</p>
                  <DialogFooter>
                    <Button onClick={() => kickoff()}>Dismiss ({countdown})</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              <motion.div className="absolute" initial={{ opacity: 0 }} ref={starFieldScope} key="starfield">
                <StarfieldAnimation
                  // className="w-screen h-screen"
                  depth={600}
                  numParticles={200}
                  alphaFactor={0.5}
                />
              </motion.div>
              <motion.div className="absolute" initial={{ opacity: 0 }} ref={subtleStarFieldScope} key="subtleStarField">
                <div className="fixed h-screen w-screen -z-10">
                  <img src={subtleStarfield} alt="" className="w-full h-full" />
                </div>
              </motion.div>
              <motion.div
                className="flex justify-center items-center h-screen"
                key="logo"
                initial={{ opacity: (quickSplash || logoutSplash) ? 1 : 0 }}
                ref={logoScope}
              >
                <Logo className="z-10" />
              </motion.div>
            </motion.div>
          </DisableScrollY>
        </>
      ) : null}

    </AnimatePresence>
  );
};

export default Splash;