import { type NewOpportunitySummary } from '@/components/dashboard/new-opportunities/NewOpportunitySummary';

export const NEW_OPPORTUNITY_SUMMARIES: NewOpportunitySummary[] = [
  {
    lenderName: '🔥 Apple Equity LP',
    earlyDefaultAmount: 35333332,
    lateDefaultAmount: 17666666,
    propertiesInDefault: 3,
    loanVolume: 176666660,
    totalLoans: 10,

    totalInvested: 25000000,
    maxInvestment: 100000000,
  },
  {
    lenderName: 'Google Equity LP',
    earlyDefaultAmount: 7666,
    lateDefaultAmount: 30000,
    propertiesInDefault: 3,
    loanVolume: 100000,
    totalLoans: 10,

    totalInvested: 2000000,
    maxInvestment: 10000000,
  },
  {
    lenderName: 'Microsoft Equity LP',
    earlyDefaultAmount: 35333332,
    lateDefaultAmount: 17666666,
    propertiesInDefault: 3,
    loanVolume: 296666660,
    totalLoans: 10,

    totalInvested: 3000000,
    maxInvestment: 10000000,
  },
  {
    lenderName: 'Facebook Equity LP',
    earlyDefaultAmount: 35333332,
    lateDefaultAmount: 7666666,
    propertiesInDefault: 3,
    loanVolume: 176666660,
    totalLoans: 10,

    totalInvested: 4000000,
    maxInvestment: 10000000,
  },
];
