import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import outputs from '../amplify_outputs.json';
import App from './App.tsx';
import ThemeProvider from './components/ThemeProvider.tsx';

import iconLogo from '@/assets/icon-logo.svg';
import building from '@/assets/map-hi.webp';
import { Passwordless } from 'amazon-cognito-passwordless-auth';
import 'amazon-cognito-passwordless-auth/passwordless.css';
import { Passwordless as PasswordlessComponent, PasswordlessContextProvider } from 'amazon-cognito-passwordless-auth/react';

Amplify.configure(outputs);

Passwordless.configure({
  cognitoIdpEndpoint: import.meta.env.VITE_COGNITO_IDP_ENDPOINT,
  clientId: import.meta.env.VITE_CLIENT_ID,
  fido2: {
    baseUrl: import.meta.env.VITE_FIDO2_BASE_URL,
    authenticatorSelection: {
      userVerification: 'required',
      requireResidentKey: true,
      residentKey: 'preferred',
      authenticatorAttachment: 'platform',
    },
  },
  debug: console.debug,
});

// https://github.com/aws-samples/amazon-cognito-passwordless-auth/tree/21f72ff6c71a09928fbd43d9af3c68d952881844

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    {location.hash || location.pathname === '/aws-login' ? (
      <PasswordlessContextProvider enableLocalUserCache={true}>
        <div className="aws-wraper">
          <div className="aws-wraper-btn">
            <button type="submit" className="aws-login-btn">
              <a className="text-sm text-[#000]" href="/login">
                Login with Email & Password
              </a>
            </button>
          </div>
          <PasswordlessComponent
            brand={{
              backgroundImageUrl: building,
              customerName: " ",
              customerLogoUrl: iconLogo,
            }}
          >
            <React.StrictMode>
              <BrowserRouter>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </BrowserRouter>
            </React.StrictMode>
          </PasswordlessComponent>
        </div>
      </PasswordlessContextProvider>
    ) : (
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    )}
  </>,
);
