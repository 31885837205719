import { PaginationDealProps } from "../components/dashboard/pages/deals/Deal.interface";
import { create } from "zustand";
// import { persist } from "zustand/middleware";

export type ExpiryStore = {
    paginationDeal: PaginationDealProps[],
    setPaginationDeal: (deals: PaginationDealProps[]) => void;
};

// function setWithExpiry(key: string, value: any, ttl: number) {
//     const now = new Date();
//     const item = {
//         value: value,
//         expiry: now.getTime() + ttl,
//     };
//     localStorage.setItem(key, JSON.stringify(item));
// }

// function getWithExpiry(key: string) {
//     const itemStr = localStorage.getItem(key);
//     if (!itemStr) {
//         return null;
//     }
//     const item = JSON.parse(itemStr);
//     const now = new Date();
//     if (now.getTime() > item.expiry) {
//         localStorage.removeItem(key);
//         return null;
//     }

//     return item.value;
// }

const useExpiryStore = create<ExpiryStore>()(
    // persist(
        (set) => ({
            paginationDeal: [],
            setPaginationDeal: (paginationDeal: PaginationDealProps[]) => set({ paginationDeal }),
        }),
    //     {
    //         name: "expiry-storage",
    //         storage: {
    //             getItem: (name) => Promise.resolve(getWithExpiry(name)),
    //             setItem: (name, value) => Promise.resolve(setWithExpiry(name, value, 3600000)), // 1 hour TTL
    //             removeItem: (name) => Promise.resolve(localStorage.removeItem(name)),
    //         },
    //     }
    // )
);

export default useExpiryStore;