import ComposableCard from "@/components/composable/ComposableCard"
import { formatCurrencyShort } from "@/lib/utils";
import clsx from "clsx";
import LineChart from '../charts/LineChart';
import { INVESTMENT_PERFORMANCE_SERIES } from '@/constants/__mock__/InvestmentPerformance';

export interface InvestmentPerformanceCardProps {
    className?: string;
}

const InvestmentPerformanceData = {
    totalValue: 455721039,
    monthlyChange: 56712129,
    avgReturn: 22.08,
    volume: 528,
}

const InvestmentPerformanceCard = ({ className }: InvestmentPerformanceCardProps) => {
    const { totalValue, monthlyChange, avgReturn, volume } = InvestmentPerformanceData;

    const positiveReturn = avgReturn > 0;
    const negativeReturn = avgReturn < 0;


    return (
        <ComposableCard
            title="Investment Performance"
            className={clsx("flex flex-col grow", className)}
            Content={{ className: "grow" }}
        >
            <div className="flex gap-10">
                <div className="w-fit font-headings">
                    <span className="block text-gray-400">Total Value</span>
                    <span className="text-xl font-bold">{formatCurrencyShort({ amount: totalValue, decimals: 1 })}</span>
                </div>
                <div className="w-fit font-headings">
                    <span className="block text-gray-400">Monthly Change</span>
                    <span className="text-xl font-bold">{formatCurrencyShort({ amount: monthlyChange, decimals: 1 })}</span>
                </div>
                <div className="w-fit font-headings">
                    <span className="block text-gray-400">Average Return</span>
                    <span className={clsx("text-xl font-bold", { "text-green-400": positiveReturn, "text-red-500": negativeReturn })}>
                        {positiveReturn && "+"}
                        {negativeReturn && "-"}
                        {avgReturn}
                        %
                    </span>
                </div>
                <div className="w-fit font-headings hidden sm:block">
                    <span className="block text-gray-400">Volume</span>
                    <span className="block text-xl font-bold text-center">{volume}</span>
                </div>
            </div>
            <LineChart series={INVESTMENT_PERFORMANCE_SERIES} smoothCurves />

        </ComposableCard>)
}

export default InvestmentPerformanceCard;