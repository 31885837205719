import React, { useState, useEffect, useRef } from 'react';

export interface ComboBoxOption {
  label: string;
  value: string;
}

interface ComboBoxProps {
  options: ComboBoxOption[];
  onSelect: (selected: ComboBoxOption) => void;
  defaultValue?: string;
}

const ComboBox: React.FC<ComboBoxProps> = ({ options, onSelect, defaultValue = null }) => {
  const [query, setQuery] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const comboboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // console.log('defaultValue', defaultValue);
    if (defaultValue) {
      const option = options.find(option => option.value === defaultValue);

      if (option?.label) {
        setQuery(option.label);
      }
    }
  }, [defaultValue, options]);

  const filteredOptions = options.filter(option =>
    !query || option.label.toLowerCase().includes(query.toLowerCase())
  );

  const handleSelectOption = (option: ComboBoxOption) => {
    setQuery(option.label);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (comboboxRef.current && !comboboxRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleClear = () => {
    console.log('clear');
    setQuery('');
    setIsOpen(true);
    onSelect({ label: '', value: '' });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlerSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setIsOpen(true);
  }
  
  return (
    <div className="relative w-64" ref={comboboxRef}>
      <div className="relative flex items-center border border-gray-300 rounded-md focus-within:ring-2 focus-within:ring-blue-500">
        <input
          type="text"
          className="w-full px-4 py-2 focus:outline-none"
          placeholder="Select an option"
          value={query}
          onChange={handlerSearchChange}
          onClick={() => setIsOpen(!isOpen)}
        />
        {query && (
          <button
            type="button"
            title="Clear"
            className="absolute right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={handleClear}
          >
            &times;
          </button>
        )}
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <li
                key={option.value}
                className="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                title={option.value}
                onClick={() => handleSelectOption(option)}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-gray-500">No options found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ComboBox;