import ComposableCard from "@/components/composable/ComposableCard";
import { CardTitle } from "@/components/ui/card";
import Combobox from "@/components/ui/combobox";
import { PORTFOLIO_BREAKDOWN_DATA } from "@/constants/__mock__/PortfolioBreakdown";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { cn, formatCurrencyShort } from "@/lib/utils";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import React from "react";

const PortfolioBreakdownCard = () => {

    const [sortBy, setSortBy] = React.useState<"amount" | "loanNumber" | "returnPercent">("amount");

    return <ComposableCard header={
        <div className="flex justify-between items-center">
            <CardTitle>Portfolio Breakdown</CardTitle>
            <div>
                <span className="text-sm text-muted-foreground pr-3 hidden 3xl:inline whitespace-nowrap">Sort by:</span>
                <Combobox options={[
                    {
                        value: "amount",
                        label: "Amount",
                    },
                    {
                        value: "loanNumber",
                        label: "Loan Number",
                    },
                    {
                        value: "returnPercent",
                        label: "Return",
                    },
                ]}
                    onSelect={(value) => setSortBy(value as "amount" | "loanNumber" | "returnPercent")}
                />
            </div>
        </div>
    } Header={{ className: "!pb-2" }} className="w-full 2xl:w-2/3">
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead className="w-[100px]">#</TableHead>
                    <TableHead>Loan Number</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead className="text-right">Return</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {PORTFOLIO_BREAKDOWN_DATA.sort((a, b) => {
                    if (typeof a[sortBy] === "string" && typeof b[sortBy] === "string") {
                        return (a[sortBy] as string).localeCompare(b[sortBy] as string)
                    }

                    return (a[sortBy] as number - (b[sortBy] as number))
                }).map(({ id, loanNumber, amount, returnPercent }, index) => (
                    <TableRow key={index} compact>
                        <TableCell className="font-medium">#{id}</TableCell>
                        <TableCell>{loanNumber}</TableCell>
                        <TableCell className="flex  items-center">

                            {formatCurrencyShort({ amount, decimals: 1 })}
                            {returnPercent < 0 ?
                                <TriangleDownIcon className="text-red-500" width="20" height="20" /> :
                                <TriangleUpIcon className="text-green-400" width="20" height="20" />
                            }
                        </TableCell>
                        <TableCell className={cn("text-right text-green-400", { "text-red-500": returnPercent < 0 })}>
                            {returnPercent >= 0 && "+"}
                            {returnPercent}%
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </ComposableCard>
}

export default PortfolioBreakdownCard;