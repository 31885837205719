export interface InfoCardProps {
  title: string;
  value: any;
  icon: any;
}

const InfoCard = ({ title, value, icon }: InfoCardProps) => {
  return (
      <div className="flex items-center h-[42px] p-0">
          <div className="mr-3">
              {icon}
          </div>
          <div>
              <div className="text-[#788190] whitespace-nowrap">{title}</div>
              <div className="font-semibold">{value}</div>
          </div>
      </div>
  );
}

export default InfoCard;