import clsx from "clsx";
import useDisplayName from "@/hooks/useDisplayName";

export interface WelcomeHeadingProps {
    className?: string;
}

const WelcomeHeading = ({ className }: WelcomeHeadingProps) => {
    const displayName = useDisplayName();

    const welcomeStringDefault = 'Welcome back!';
    const welcomeString = displayName ? `Welcome, ${displayName}!` : welcomeStringDefault;

    return <h1 className={clsx("text-2xl 2xl:text-3xl ", className)}>{welcomeString}</h1>
}

export default WelcomeHeading;