import { Trash2Icon } from 'lucide-react';
import React from 'react';
import QueryBuilder from 'react-querybuilder';
import CustomFieldSelector from './CustomFieldSelector';

interface FilterProfilesProps {
  isOpen: boolean;
  onClose: () => void;
  side: 'left' | 'right' | 'top' | 'bottom';
}

const FilterContent = () => {
  return (
    <div className="flex h-screen flex-col">
      {/* <div className="flex grow flex-col dark:bg-filterContainer px-6 sm:px-10 justify-between py-6 sm:py-10 sm:pb-9 bg-customGrayEAEAEA">
        <div>
          <h3 className="text-4xl mb-1">Filter Profiles</h3>
          <div className="flex flex-col gap-3">
            <h5 className="subtitle">Saved Search Profile:</h5>
            <Select>
              <SelectTrigger className="w-100 border-none bg-filterSelect">
                <SelectValue placeholder="Select Search Profile" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="light">Light</SelectItem>
                <SelectItem value="dark">Dark</SelectItem>
                <SelectItem value="system">System</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h4 className="text-2xl">Asset Class</h4>
          <h5 className="subtitle">Select Asset Classes</h5>
          <Scrollbars autoHeightMin={250} autoHeight renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: '#16ACEE', width: '2px' }} />}>
            <ChipFilterBox filterOptions={ASSET_CLASS} />
          </Scrollbars>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="subtitle">Configure Modifiers</h5>
          <Button variant="secondary" className="font-headings rounded-sm !font-normal bg-filterSelect h-12">
            Configure Now
            <ArrowRightIcon className="w-3.5 h-3.5 ml-2" />
          </Button>
        </div>
        <div className="flex flex-col gap-3.5">
          <h4 className="text-2xl ">Additional Filters</h4>
          <div className="flex flex-col sm:flex-row justify-between gap-3">
            <div className="flex w-full flex-col">
              <h5 className="subtitle mb-2">Location</h5>
              <Input className="my-0 h-12 border border-[hsl(218,19%,37%)] pl-0 w-full " placeholder="Enter city, state or zip code" iconStart={<MapPin className="mr-8 stroke-[hsl(217,10%,52%)]" />} />
            </div>
            <div className="flex w-full sm:w-4/12 flex-col">
              <h5 className="subtitle mb-2 whitespace-nowrap">Min Default %</h5>
              <Input className="w-1/2 sm:w-auto h-12 border border-[hsl(218,19%,37%)]" iconEnd={<span className="pr-2 text-[hsl(217,10%,52%)]">%</span>} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-customGrayEAEAEA dark:bg-filterContainerFooter w-full py-3 sm:py-5 shrink-0 px-6 sm:px-10">
        <div className="flex w-full justify-evenly !font-headings gap-4">
          <Button variant="outline" className="sm:w-1/4 shrink-0 rounded-md border-selectedChip bg-transparent text-selectedChip !font-normal !text-lg">
            <Heart className="w-5 h-5 mr-2 shrink-0" />
            Save Filter
          </Button>
          <Button variant="secondary" className="w-full rounded-md bg-selectedChip font-semibold !text-lg">
            Apply Filters
          </Button>
        </div>
      </div> */}
      <div className="flex-grow max-h-[90%] overflow-auto">
        <QueryBuilder
          // fields={``}
          // query={query}
          // onQueryChange={onQueryChange}
          showShiftActions={false}
          addRuleToNewGroups={true}
          showCombinatorsBetweenRules={false}
          controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
          controlElements={{
            addGroupAction: null,
            removeGroupAction: null,
            fieldSelector: CustomFieldSelector,
          }}
          translations={{
            addGroup: {
              label: 'Add Group',
              title: 'Add Group',
            },
            addRule: {
              label: 'Add Rule',
              title: 'Add Rule',
            },
            removeGroup: {
              label: <Trash2Icon color='#F47C79' size={20} />,
              title: 'Remove Group',
            },
            removeRule: {
              label: <Trash2Icon color='#F47C79' size={20} />,
              title: 'Remove Rule',
            },
          }}
        />
      </div>
      <div className="flex flex-col h-full items-left justify-end pb-5 max-h-[10%] px-3 ">
        <div className="flex-shrink-0">
          <button className="apply-new-query-button" onClick={() => { }}>Save</button>
        </div>
      </div>
    </div>
  );
};

const FilterProfiles: React.FC<FilterProfilesProps> = ({ isOpen, onClose, side }) => {
  const getPositionClasses = (side: string) => {
    switch (side) {
      case 'left':
        return 'left-0 transform -translate-x-full';
      case 'right':
        return 'right-0 transform translate-x-full';
      case 'top':
        return 'top-0 transform -translate-y-full';
      case 'bottom':
        return 'bottom-0 transform translate-y-full';
      default:
        return '';
    }
  };

  const filterClasses = isOpen ? 'translate-x-0' : getPositionClasses(side);

  return (
    <>
      {isOpen && <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={onClose}></div>}
      <div
        className={`fixed top-0 ${side === 'left' || side === 'top' ? 'left-0' : side === 'right' || side === 'bottom' ? 'right-0' : ''} z-50 h-full bg-customGrayEAEAEA dark:bg-filterContainer shadow-xl transition-all duration-300 ease-in-out ${filterClasses} w-full sm:w-[940px]`}
      >
        <FilterContent />
      </div>
    </>
  );
};

export default FilterProfiles;
