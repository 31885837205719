import ComposableCard from '@/components/composable/ComposableCard';
import NewOpportunitySummary from './NewOpportunitySummary';
import { NEW_OPPORTUNITY_SUMMARIES } from '@/constants/__mock__/NewOpportunitySummaries';
import clsx from 'clsx';

export interface NewOpportunityCardProps {
  className?: string;
}

const NewOpportunityCard = ({ className }: NewOpportunityCardProps) => {
  return (
    <ComposableCard
      title="New Opportunities"
      className={clsx('!bg-transparent !p-0 !pl-0 flex flex-col', className)}
      Content={{ className: 'flex flex-col !p-0 lg:!px-1 grow' }}
      Header={{ className: 'pt-2 lg:pt-4 !px-4 lg:!px-6' }}
      Title={{ className: 'lg:!px-0' }}
    >
      <div className="flex flex-col gap-3 ">
        {NEW_OPPORTUNITY_SUMMARIES.sort((a, b) => b.lateDefaultAmount - a.lateDefaultAmount).map((summary, index) => (
          <NewOpportunitySummary key={index} summary={summary} />
        ))}
      </div>
    </ComposableCard>
  );
};

export default NewOpportunityCard;
