// s3Service.ts
import { GetObjectCommand, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import s3 from '../config/awsConfig';

interface S3Object {
  Key: string;
  LastModified?: Date;
  ETag?: string;
  Size?: number;
  StorageClass?: string;
}

export const listS3Objects = async (bucketName: string): Promise<S3Object[]> => {
  try {
    // Create the command with the necessary parameters
    const command = new ListObjectsV2Command({
      Bucket: bucketName,
      Prefix: "",
    });

    // Use the command with the client
    return s3.send(command)
      .then((data) => {
        console.log('S3 objects:', data.Contents);
        return data.Contents as S3Object[];
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.error('Error fetching S3 objects:', error);
    throw error;
  }
};

export const getS3SignedUrl = async (bucketName: string, key: string): Promise<string> => {
  const params = {
    Bucket: bucketName,
    Key: key,
  };

  try {
    // Create the command with the necessary parameters
    const command = new GetObjectCommand(params);

    // Generate the signed URL
    return getSignedUrl(s3, command, { expiresIn: 3600 })
      .then((url) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.error('Error fetching file URL:', error);
    throw error;
  }
};