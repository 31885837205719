const ChevronLeftIcon = () => {
    return (
      <svg width="62" height="126" viewBox="0 0 62 126" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_578_64307)">
        <rect opacity="0.8" x="9" y="5" width="44" height="108" rx="3" fill="white"/>
        <path d="M37 47L27 57L37 67" stroke="#08A1E2" stroke-width="2"/>
        </g>
        <defs>
        <filter id="filter0_d_578_64307" x="0" y="0" width="62" height="126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="4.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_578_64307"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_578_64307" result="shape"/>
        </filter>
        </defs>
      </svg>
    )
}

export default ChevronLeftIcon;