import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { Description } from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { ComponentProps } from 'react';

export interface ComposableCardProps {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  Content?: ComponentProps<typeof CardContent>;
  Description?: ComponentProps<typeof CardDescription>;
  Footer?: ComponentProps<typeof CardFooter>;
  Header?: ComponentProps<typeof CardHeader>;
  Title?: ComponentProps<typeof CardTitle>;
  onClick?: () => void;
}

const ComposableCard = ({ title, header, description, footer, children, className, Content, Title, Header, Footer, onClick }: ComposableCardProps) => {
  const hasHeader = title || description || header;
  return (
    <Card className={clsx(className)} onClick={onClick}>
      {hasHeader && (
        <CardHeader {...Header} className={clsx(Header?.className)}>
          {header ? header : (
            <>
              {title && (
                <CardTitle {...Title} className={clsx(Title?.className)}>
                  {title}
                </CardTitle>
              )}
              {description && <CardDescription {...Description}>{description}</CardDescription>}
            </>
          )}
        </CardHeader>
      )}
      {children && (
        <CardContent {...Content} className={clsx(Content?.className)}>
          {children}
        </CardContent>
      )}
      {footer && (
        <CardFooter {...Footer} className={clsx(className)}>
          {footer}
        </CardFooter>
      )}
    </Card>
  );
};

export default ComposableCard;
