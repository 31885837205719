import iconLogo from '@/assets/icon-logo.svg';
import building from '@/assets/map-hi.webp';
import { Passwordless } from 'amazon-cognito-passwordless-auth';
import 'amazon-cognito-passwordless-auth/passwordless.css';
import { Passwordless as PasswordlessComponent, PasswordlessContextProvider } from 'amazon-cognito-passwordless-auth/react';
import React from 'react';
import AWSCognito from './aws-cognito';

function AWSCognitoMain() {
  Passwordless.configure({
    cognitoIdpEndpoint: import.meta.env.VITE_COGNITO_IDP_ENDPOINT,
    clientId: import.meta.env.VITE_CLIENT_ID,
    fido2: {
      baseUrl: import.meta.env.VITE_FIDO2_BASE_URL,
      authenticatorSelection: {
        userVerification: 'required',
        requireResidentKey: true,
        residentKey: 'preferred',
        authenticatorAttachment: 'platform',
      },
    },
    debug: console.debug,
  });

  return (
    <React.Fragment>
      <PasswordlessContextProvider enableLocalUserCache={true}>
        <PasswordlessComponent
          brand={{
            backgroundImageUrl: building,
            customerName: 'Icon',
            customerLogoUrl: iconLogo,
          }}
        >
          <AWSCognito />
        </PasswordlessComponent>
      </PasswordlessContextProvider>
    </React.Fragment>
  );
}
export default AWSCognitoMain;
