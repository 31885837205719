
export interface DiamondProps {
    className?: string;
}

const Diamond = () => (
    <>
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" className="block dark:hidden">
            <rect width="32" height="32" rx="8" transform="matrix(1 0 0 -1 0 32.5)" fill="#EAEAEA" />
            <path d="M16 25.3511L6.596 14.2637L9.308 8.9375H22.692L25.404 14.2637L16 25.3511ZM12.817 13.8482H19.183L17.183 9.91964H14.817L12.817 13.8482ZM15.5 23.2375V14.8304H8.392L15.5 23.2375ZM16.5 23.2375L23.608 14.8304H16.5V23.2375ZM20.292 13.8482H24.058L22.058 9.91964H18.292L20.292 13.8482ZM7.942 13.8482H11.708L13.708 9.91964H9.942L7.942 13.8482Z" fill="#1ACE7C" />
        </svg>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden dark:block">
            <rect width="32" height="32" rx="8" transform="matrix(1 0 0 -1 0 32)" fill="#1D2634" />
            <path d="M16.0002 24.8511L6.59619 13.7637L9.30819 8.4375H22.6922L25.4042 13.7637L16.0002 24.8511ZM12.8172 13.3482H19.1832L17.1832 9.41964H14.8172L12.8172 13.3482ZM15.5002 22.7375V14.3304H8.39219L15.5002 22.7375ZM16.5002 22.7375L23.6082 14.3304H16.5002V22.7375ZM20.2922 13.3482H24.0582L22.0582 9.41964H18.2922L20.2922 13.3482ZM7.94219 13.3482H11.7082L13.7082 9.41964H9.94219L7.94219 13.3482Z" fill="#1ACE7C" />
        </svg>
    </>
)

export default Diamond;