import 'react-querybuilder/dist/query-builder.css';
import './QueryBuilderModalComponent.css';

import { Dialog, DialogContent } from '../../ui/dialog';
import 'react-querybuilder/dist/query-builder.css';
import './QueryBuilderModalComponent.css';
import QueryBuilder, { Field, RuleGroupType } from 'react-querybuilder';
import { Trash2Icon } from 'lucide-react';
import CustomFieldSelector from './CustomFieldSelector';

export interface PricingUpdateRuleModalProps {
    isOpen: boolean;
    filterFields: Field[];
    query?: RuleGroupType;
    toggleModal: () => void;
    onQueryChange: (query: RuleGroupType) => void;
    onSaveQuery: () => void;
}

const PricingUpdateRuleModal = ({
    isOpen,
    filterFields,
    query,
    toggleModal,
    onQueryChange,
    onSaveQuery }: PricingUpdateRuleModalProps) => {
    return (
        <Dialog open={isOpen} onOpenChange={toggleModal}>
            <DialogContent
                className="w-11/12 max-w-screen h-full outline-none overflow-x-hidden p-0 border-0 flex flex-col">
                <div className="flex-grow max-h-[90%] overflow-auto">
                    <QueryBuilder
                        fields={filterFields}
                        query={query}
                        onQueryChange={onQueryChange}
                        showShiftActions={false}
                        addRuleToNewGroups={true}
                        showCombinatorsBetweenRules={false}
                        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                        controlElements={{
                            addGroupAction: null,
                            removeGroupAction: null,
                            fieldSelector: CustomFieldSelector,
                        }}
                        translations={{
                            addGroup: {
                                label: 'Add Group',
                                title: 'Add Group',
                            },
                            addRule: {
                                label: 'Add Rule',
                                title: 'Add Rule',
                            },
                            removeGroup: {
                                label: <Trash2Icon color='#F47C79' size={20} />,
                                title: 'Remove Group',
                            },
                            removeRule: {
                                label: <Trash2Icon color='#F47C79' size={20} />,
                                title: 'Remove Rule',
                            },
                        }}
                    />
                </div>
                <div className="flex flex-col h-full items-left justify-end pb-5 max-h-[10%] px-3 ">
                    <div className="flex-shrink-0">
                        <button className="apply-new-query-button" onClick={onSaveQuery}>Save</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default PricingUpdateRuleModal;