import { ChevronLeftIcon, ChevronRightIcon, ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import "./DealsMobile.css";
import ExternalLinkIcon from "@/components/icons/ExternalLinkIcon";
import ProgressComponent from "../../common/ProgressComponent";
// import BlueCircleCheckIcon from "@/components/icons/BlueCircleCheckIcon";
import BlueCircleXIcon from "@/components/icons/BlueCircleXIcon";
import SemiCircleChart from "../../charts/SemiCircleChart";
import CircleChart from "../../charts/CircleChart";
// import NumberDisplay from "../../common/NumberDisplay";
// import { DealProps } from "./Deal.interface";
// import { formatCurrencyShort } from "@/lib/utils";
import LocationDotsMap from "../../deals/LocationDotsMap";
import NumberDisplay from "../../common/NumberDisplay";



const DealsMobile = () => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="title text-xl">Opportunities</h1>
        <p className="uppercase new-update"><span>New: 6</span><span className="ml-3">Updated: 8</span></p>
      </div>
      <div className="flex justify-between items-center mt-8 mb-5">
        <button><ChevronLeftIcon color="#08A1E2" /></button>
        <p className="pagination-wrapper">
          <span className=" text-[#08a0e2]">6</span>
          <span>/25</span>
        </p>
        <button><button><ChevronRightIcon color="#08A1E2" /></button></button>
      </div>
      <div className="opportunity-card">
        <div className="px-3 py-5 flex items-center justify-center opportunity-title">
          <span>Civic Financial Services LLC</span>
          <ExternalLinkIcon color="#08A1E2" />
        </div>
        <div className="bg-[#eee]">
          <div className="px-3 py-5">
            <div className="flex justify-between">
              <button className="btn"><ThumbsDownIcon color="#08A1E2" /></button>
              <div className="text-white pill-wrapper">
                <span className="font-bold">$10M</span>
                <span className="font-light mx-1">|</span>
                <span className="font-bold">44%</span>
              </div>
              <button className="btn"><ThumbsUpIcon color="#08A1E2" /></button>
            </div>
          </div>
          <div className="mt-1 flex px-3 py-3">
            <div className="w-[60%]">
              <div className="w-[90%] 2xl:w-[80%]">
                <div className="uppercase thin-title text-xs mb-3">OFFER RANGE</div>
                <ProgressComponent progress={22} fromLabel={'$8M'} toLabel={'$10M'} />
              </div>
            </div>
            <div className="w-[40%]">
              <div className="w-full flex flex-col">
                <div className="uppercase thin-title text-xs mb-3">Total Origination Amount</div>
                <div className="text-base font-medium">$50M - $125M</div>{/*estimatedAssetValue is totalassetvalue*/}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex px-3 py-3">
          <div className="w-[1/3]">
            <div className="uppercase thin-title text-xs mb-3 mt-2 mr-4">DEFAULT RATE</div>
            <div className="flex items-start justify-around">
              <div className="w-[90px] h-[80px] ">
                <CircleChart
                  series={[100 - 20, 50]}
                  title={`${50}%`}
                  donutSize="70%"
                  className="font-base"
                />
              </div>
            </div>
          </div>
          <div className="w-[1/3] mr-5">
            <div className="uppercase thin-title text-xs mb-3 mt-2 2xl:mr-4 xl:mr-2">DEFAULT AMOUNT</div>
            <div className="flex items-center justify-center">
              <div className="2xl:w-[50%] md:w-[30%] h-[60px]">
                <NumberDisplay topNumber={'125'} bottomNumber={'125'} className="font-light xl:text-sm lg:text-xs" />
              </div>
            </div>
          </div>
          <div className="w-[1/3]">
            <div className="uppercase thin-title text-xs mt-2 mb-3">TOTAL PROPERTIES</div>
            <div className="flex justify-center items-center font-light xl:text-sm lg:text-xs">5</div>
          </div>
        </div>
        <div className="flex px-3 py-3 ">
          <div className="flex w-full py-5 justify-around border-t-2">
            <div className="flex flex-col items-left w-[1/2]">
              <div className="uppercase thin-title text-xs mb-3">TAX LIEN</div>
              <div><BlueCircleXIcon /></div>
            </div>
            <div className="flex flex-col items-left w-[1/2]">
              <div className="uppercase thin-title text-xs mb-3">HOA LIEN</div>
              <div><BlueCircleXIcon /></div>
            </div>
          </div>
        </div>
        <div className="flex px-3 py-3 ">
          <div className="flex w-full py-5 justify-around border-t-2">
            <div className="flex flex-col w-[1/2] items-left">
              <div className="uppercase thin-title text-xs mb-3">AS IS VALUE</div>
              <div>44</div>
            </div>
            <div className="flex flex-col items-left w-[1/2]">
              <div className="uppercase thin-title text-xs mb-3">UNPAID PRINCIPLE BALANCE</div>
              <div>55</div>
            </div>
          </div>
        </div>
        <div className="flex px-3 mt-3 ">
          <div className="flex py-5 justify-around border-t-2">
            <div className="flex flex-col  w-[50%]">
              <div className="uppercase thin-title text-xs mb-3 h-10">AS IS VALUE</div>
              <div>
              <SemiCircleChart
                  label={<NumberDisplay topNumber={'100M'} bottomNumber={'100M'} />}
                  series={[100 - 20, 50]} />
              </div>
            </div>
            <div className="flex flex-col w-[50%]">
              <div className="uppercase thin-title text-xs mb-3 h-10">UNPAID PRINCIPLE BALANCE</div>
              <div>
              <SemiCircleChart
                  label={<NumberDisplay topNumber={'100M'} bottomNumber={'100M'} />}
                  series={[100 - 20, 50]} />
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 py-3 ">
          <div className="w-full mb-0 border-t-2 pt-5">
            <div className="uppercase thin-title text-xs ">COUNT OF ASSET CLASS MIX</div>
          </div>
          <div className="flex w-full py-2 justify-around">
            <div className="flex flex-col w-[1/2] items-left">
              <div>
                <CircleChart className="w-[127px] h-[127px] font-bold"
                  series={[100 - 20, 50]}
                  // fillColors={colorMapAssetClassMix.map(item => item.color)}
                  title={'TEXT'}
                  donutSize="60%"
                  // labels={assetClassMixKeys}
                />
              </div>
            </div>
            <div className="flex flex-col items-left w-[1/2]">
              <div>
                text here<br />
                text here<br />
                text here<br />
                text here<br />
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 py-3 ">
          <div className="w-full mb-0 border-t-2 pt-5">
            <div className="uppercase thin-title text-xs ">COUNT OF ASSET CLASS MIX</div>
          </div>
          <div className="flex w-full py-2 justify-around">
            <div className="flex flex-col w-[1/2] items-left">
              <div>
                <CircleChart className="w-[127px] h-[127px] font-bold"
                  series={[100 - 20, 50]}
                  // fillColors={colorMapAssetClassMix.map(item => item.color)}
                  title={'TEXT'}
                  donutSize="60%"
                  // labels={assetClassMixKeys}
                />
              </div>
            </div>
            <div className="flex flex-col items-left w-[1/2]">
              <div>
                text here<br />
                text here<br />
                text here<br />
                text here<br />
              </div>
            </div>
          </div>
        </div>
        <div className="flex px-3 mt-3 ">
          <div className="flex py-5 justify-around border-t-2">
            <div className="flex flex-col w-full">
              <div className="uppercase thin-title text-xs mb-3 h-10">LOCATIONS</div>
              <div>
                <LocationDotsMap locations={[{"coordinates":[-82.797973,27.941443],"address":"1527 SADLON AVE A, CLEARWATER, FL 33756-7109","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-82.719037,27.509158],"address":"5623 GULF DR, HOLMES BEACH, FL 34217-1616","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-80.205122,26.05687],"address":"4362 SW 52ND ST 1-2, FORT LAUDERDALE, FL 33314-5736","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-81.285532,29.86425],"address":"132 SOUTHWIND CIR, SAINT AUGUSTINE, FL 32080-5352","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-82.097154,27.960225],"address":"1083 COWART RD, PLANT CITY, FL 33567-3613","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-80.204742,26.056879],"address":"4332 SW 52ND ST, FORT LAUDERDALE, FL 33314-5736","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-80.174447,25.942629],"address":"1300 NE 182ND ST, NORTH MIAMI BEACH, FL 33162-1334","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-82.437688,27.294482],"address":"4145 PALAU DR, SARASOTA, FL 34241-5736","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-80.251337,26.281799],"address":"9550 NW 42ND CT, CORAL SPRINGS, FL 33065-1576","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-80.247928,26.647945],"address":"12715 KINGSWAY RD, WELLINGTON, FL 33414-6243","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-82.810842,27.896862],"address":"2312 22ND AVE SW, LARGO, FL 33774-1741","estimatedReturn":0,"status":"active","type":"Residential"},{"coordinates":[-81.681743,28.319426],"address":"638 KENSINGTON DR, DAVENPORT, FL 33897-7721","estimatedReturn":0,"status":"active","type":"Residential"}]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DealsMobile;