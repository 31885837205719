import React, { useState, useEffect } from 'react';
import { Treebeard } from 'react-treebeard';
import { listS3Objects } from '../../services/s3Service';
import PdfViewer from './PdfViewer';
import { AWS_CONFIG } from '@/config/config';

interface FileTreeProps {
  bucketName: string;
}

const FileTree: React.FC<FileTreeProps> = ({ bucketName }) => {
  const [treeData, setTreeData] = useState<any>({});
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await listS3Objects(bucketName);
      const structuredData = structureTreeData(data);
      setTreeData(structuredData);
    };

    fetchData();
  }, [bucketName]);

  // Function to structure S3 data into a tree format
  const structureTreeData = (data: { Key: string }[]): any => {
    // Initialize the root of the tree with a name and an empty array for children
    const tree: any = { name: AWS_CONFIG.rootBucketName, toggled: true, children: [] };

    // Iterate over each item in the S3 data
    data.forEach(item => {
      // Remove trailing slash from the key if it exists
      const cleanedKey = item.Key.replace(/\/$/, '');

      // Split the cleaned key into parts based on '/'
      const parts = cleanedKey.split('/');

      // Start at the root of the tree
      let currentLevel = tree;

      // Iterate over each part of the key
      parts.forEach((part, index) => {
        if (!part) return; // Skip empty parts

        // If the current level doesn't have children, initialize it as an empty array
        if (!currentLevel.children) {
          currentLevel.children = [];
        }

        // Check if the part already exists in the current level's children
        let existingPath = currentLevel.children.find((child: any) => child.name === part);

        const isFile = part.toLowerCase().endsWith('.pdf');

        // If the part doesn't exist, create a new node
        if (!existingPath) {
          existingPath = {
            name: part, // Name of the part
            toggled: false, // Whether the node is toggled open (default is closed)
            children: isFile ? null : [], // Initialize children as an empty array
            isFile: isFile, // Check if the part is a file
            fullPath: parts.slice(0, index + 1).join('/'), // Full path up to this part
          };

          // Add the new node to the current level's children
          currentLevel.children.push(existingPath);
        }

        // Move to the next level in the tree
        currentLevel = existingPath;
      });
    });

    return tree; // Return the structured tree
  };

  const onToggle = (node: any, toggled: boolean) => {
    if (node.isFile) {
      setSelectedFile(node.fullPath);
    } else {
      // setSelectedFile(null);
    }

    if (node.children) {
      node.toggled = toggled;
    }

    setTreeData({ ...treeData });
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: '40%', height: '100%', overflowY: 'auto' }}>
        <Treebeard
          data={treeData}
          onToggle={onToggle}
        />
      </div>
      <div style={{ width: '60%', padding: '20px' }}>
        {selectedFile && <PdfViewer filePath={selectedFile} bucketName={bucketName} />}
      </div>
    </div>
  );
};

export default FileTree;