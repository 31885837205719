// PdfViewer.tsx
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { getS3SignedUrl } from '@/services/s3Service';
import './pdfWorker'; // Import the worker configuration

interface PdfViewerProps {
  filePath: string;
  bucketName: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ filePath, bucketName }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages || 1);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    const fetchFileUrl = async () => {

      try {
        // Generate signed URL
        const url = await getS3SignedUrl(bucketName, filePath);
        setFileUrl(url);
      } catch (error) {
        console.error('Error fetching file URL:', error);
      }
    };

    fetchFileUrl();
  }, [filePath, bucketName]);

  return (
    <div>
      {fileUrl ? (
        <>
          <div>
              
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
            <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                className='--button-gradient-purple'
              >
                <i className="fas fa-chevron-circle-left" style={{ fontSize: '20px', paddingRight: '10px' }}></i>Previous
              </button>
              <p>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next <i className="fas fa-chevron-circle-right" style={{ fontSize: '20px', paddingLeft: '10px' }}></i>
              </button>
            </div>
          </div>
          
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PdfViewer;