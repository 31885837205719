const BlueFillThumbUpIcon = () => (
  <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_726_55375)">
          <path d="M18.6247 6.12504L17.583 10.4167H23.6559C23.9794 10.4167 24.2983 10.492 24.5876 10.6367C24.8769 10.7813 25.1285 10.9913 25.3226 11.25C25.5166 11.5088 25.6478 11.8092 25.7057 12.1274C25.7635 12.4456 25.7465 12.7729 25.6559 13.0834L23.2288 21.4167C23.1026 21.8494 22.8395 22.2296 22.4788 22.5C22.1182 22.7705 21.6796 22.9167 21.2288 22.9167H7.16634C6.61381 22.9167 6.0839 22.6972 5.6932 22.3065C5.3025 21.9158 5.08301 21.3859 5.08301 20.8334V12.5C5.08301 11.9475 5.3025 11.4176 5.6932 11.0269C6.0839 10.6362 6.61381 10.4167 7.16634 10.4167H10.0413C10.4289 10.4165 10.8088 10.3082 11.1382 10.1039C11.4676 9.89965 11.7334 9.60755 11.9059 9.26046L15.4997 2.08337C15.9909 2.08946 16.4744 2.20647 16.9141 2.42566C17.3537 2.64486 17.7381 2.96057 18.0386 3.34921C18.3391 3.73785 18.5479 4.18937 18.6494 4.67004C18.7509 5.15071 18.7424 5.6481 18.6247 6.12504Z" fill="#07bc0c" stroke="#07bc0c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.292 10.4166V22.9166" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
          <filter id="filter0_d_726_55375" x="-1" y="0" width="33" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_726_55375" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_726_55375" result="shape" />
          </filter>
      </defs>
  </svg>

)

export default BlueFillThumbUpIcon;