
export interface ChartIconProps {
    className?: string;
}

const ChartIcon = () => (
    <>
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" className="block dark:hidden">
            <rect width="32" height="32" rx="8" transform="matrix(1 0 0 -1 0 32.5)" fill="#EAEAEA" />
            <path d="M7.17152 12.2122L8.18356 11.2252C8.26258 11.1478 8.39259 11.1478 8.47162 11.2252L14.4546 17.0697L16.9426 14.6385C17.0959 14.4896 17.3029 14.4061 17.5187 14.4061C17.7345 14.4061 17.9416 14.4896 18.0948 14.6385L24.8298 21.215C24.9089 21.2925 24.9089 21.4199 24.8298 21.4974L23.8178 22.4869C23.7795 22.5241 23.7277 22.5449 23.6738 22.5449C23.6198 22.5449 23.5681 22.5241 23.5297 22.4869L17.5213 16.6149L15.0307 19.0462C14.8774 19.195 14.6704 19.2785 14.4546 19.2785C14.2388 19.2785 14.0318 19.195 13.8785 19.0462L7.17152 12.4946C7.15246 12.4761 7.13732 12.4541 7.12698 12.4299C7.11665 12.4057 7.11133 12.3797 7.11133 12.3534C7.11133 12.3271 7.11665 12.3011 7.12698 12.2769C7.13732 12.2527 7.15246 12.2307 7.17152 12.2122Z" fill="#929DAD" />
        </svg>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden dark:block">
            <rect width="32" height="32" rx="8" transform="matrix(1 0 0 -1 0 32)" fill="#1D2634" />
            <path d="M7.17152 11.7122L8.18356 10.7252C8.26258 10.6478 8.39259 10.6478 8.47162 10.7252L14.4546 16.5697L16.9426 14.1385C17.0959 13.9896 17.3029 13.9061 17.5187 13.9061C17.7345 13.9061 17.9416 13.9896 18.0948 14.1385L24.8298 20.715C24.9089 20.7925 24.9089 20.9199 24.8298 20.9974L23.8178 21.9869C23.7795 22.0241 23.7277 22.0449 23.6738 22.0449C23.6198 22.0449 23.5681 22.0241 23.5297 21.9869L17.5213 16.1149L15.0307 18.5462C14.8774 18.695 14.6704 18.7785 14.4546 18.7785C14.2388 18.7785 14.0318 18.695 13.8785 18.5462L7.17152 11.9946C7.15246 11.9761 7.13732 11.9541 7.12698 11.9299C7.11665 11.9057 7.11133 11.8797 7.11133 11.8534C7.11133 11.8271 7.11665 11.8011 7.12698 11.7769C7.13732 11.7527 7.15246 11.7307 7.17152 11.7122Z" fill="#878787" />
        </svg>
    </>
)

export default ChartIcon;