import { IconProps } from "./IconProps.interface";

export interface GlobeIconProps {
  className?: string;
}

const GlobeIcon = ({ className }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clip-path="url(#clip0_2749_10482)">
      <path d="M23.1431 11.9993C23.1431 14.9546 21.9692 17.7888 19.8795 19.8785C17.7898 21.9682 14.9556 23.1422 12.0003 23.1422C9.04501 23.1422 6.21078 21.9682 4.12109 19.8785C2.0314 17.7888 0.857422 14.9546 0.857422 11.9993M23.1431 11.9993C23.1431 9.04403 21.9692 6.2098 19.8795 4.12011C17.7898 2.03042 14.9556 0.856445 12.0003 0.856445C9.04501 0.856445 6.21078 2.03042 4.12109 4.12011C2.0314 6.2098 0.857422 9.04403 0.857422 11.9993M23.1431 11.9993H0.857422" stroke="#858F9D" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.2858 11.9993C16.0753 16.0741 14.5744 19.9765 12.0001 23.1422C9.42576 19.9765 7.92487 16.0741 7.71436 11.9993C7.92487 7.92446 9.42576 4.02213 12.0001 0.856445C14.5744 4.02213 16.0753 7.92446 16.2858 11.9993Z" stroke="#858F9D" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2749_10482">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GlobeIcon;