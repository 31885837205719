import { ChevronDown } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useTheme } from '@/components/ThemeProvider';

const FilterButtonSort = () => {
    const themeInfo = useTheme();

    if (!themeInfo) {
        // handle the case where themeInfo is null or undefined
        return null;
    }

    const { switchTheme, theme } = themeInfo;

    return (
        <Select onValueChange={switchTheme} value={theme}>
            <SelectTrigger className="w-100 border-none bg-customGrayEAEAEA dark:bg-customDark131923">
                <SelectValue>
                    <ChevronDown />
                </SelectValue>
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="light">Light</SelectItem>
                <SelectItem value="dark">Dark</SelectItem>
                <SelectItem value="system">System</SelectItem>
            </SelectContent>
        </Select>
    );
};

export default FilterButtonSort;