import { createContext, useContext, useState } from "react";

interface ThemeInfo {
    switchTheme: (theme: "light" | "dark" | "system") => void;
    theme: string;
}
const ThemeContext = createContext<ThemeInfo | undefined | null>(undefined);

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const [theme, setTheme] = useState('light');

    const switchTheme = (newTheme: string) => {
        setTheme(newTheme);
    };

    return <ThemeContext.Provider
        value={{
            theme,
            switchTheme
        }}>
        <div className={`app ${theme}`}>
            {children}
        </div>
    </ThemeContext.Provider>;
}

export default ThemeProvider;

export const useTheme = () => {
    const context = useContext(ThemeContext);
    return context;
}