import { Link } from "react-router-dom"
import { Button } from "../ui/button"
import ProfileHead from "./ProfileHead"
import { ChevronRight } from "lucide-react"
import useDisplayName from "@/hooks/useDisplayName"

const MobileProfileLink = ({ onNavigate }: { onNavigate?: () => void }) => {
    const displayName = useDisplayName();

    return <div className="px-2 lg:hidden">
        <Link to="/me" onClick={() => onNavigate?.()}>
            <Button className="w-full pl-5 flex gap-4 !font-headings items-center mb-5 hover:bg-white/10 rounded-md h-fit" variant="ghost">
                <ProfileHead userImage={null} displayName={displayName} size="large" className="pointer-events-none" />
                <div className="text-left font-normal">
                    <span className="block text-foreground font-medium text-base">{displayName}</span>
                    <span className="block text-slate-500 text-[15px]">Team Leader</span>
                </div>
                <ChevronRight className="ml-auto" />
            </Button>
        </Link>
    </div>
}

export default MobileProfileLink;