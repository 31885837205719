import React from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '@/config/config';

// interface Location {
//   lat: number;
//   lng: number;
// }

interface LocationGoogleMapProps {
  longitude: number;
  latitude: number;
}

const LocationGoogleMap: React.FC<LocationGoogleMapProps> = ({ longitude, latitude }) => {
    const location = { lng: longitude, lat: latitude };

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: GOOGLE_MAPS_API_KEY
    });
  
    // console.log("location", location)

  return (
    isLoaded ?
      <GoogleMap
        id="google-map-script"
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
        zoom={17}
        center={location}
      >
        <MarkerF
          position={location}
          icon={{
            url: 'https://coffective.com/wp-content/uploads/2018/06/icon-house-blue.png', // URL to custom icon image
            scaledSize: new window.google.maps.Size(40, 40), // Size of icon
            origin: new window.google.maps.Point(0, 0), // Origin of icon, useful if icon is part of a sprite
            anchor: new window.google.maps.Point(25, 25), // Anchor point of the icon, corresponds to the icon's position relative to its location on the map
          }}
          animation={window.google.maps.Animation.DROP} // Example of adding an animation
        />
      </GoogleMap>
    : <p>Loading Map...</p>
  );
};

export default LocationGoogleMap;