import "./NewOpportunitySummary.css";
import ComposableCard from "@/components/composable/ComposableCard";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import clsx from "clsx";
import { ArrowRight } from "lucide-react";
import { formatCurrencyShort } from "@/lib/utils";
import RadialChart from "../charts/RadialChart";

export interface NewOpportunitySummary {
    lenderName: string;
    earlyDefaultAmount: number;
    lateDefaultAmount: number;
    loanVolume: number;
    totalLoans: number;
    propertiesInDefault: number;

    totalInvested: number;
    maxInvestment: number;
}

export interface NewOpportunitySummaryProps {
    className?: string;
    summary: NewOpportunitySummary;
}

const NewOpportunitySummary = ({
    className,
    summary: {
        lenderName,
        loanVolume,
        totalLoans,
        earlyDefaultAmount,
        lateDefaultAmount,
        totalInvested,
        maxInvestment,
    }
}: NewOpportunitySummaryProps) => {
    const defaultAmount = earlyDefaultAmount + lateDefaultAmount;
    const defaultAmountPresentation = formatCurrencyShort({ amount: defaultAmount });

    const lateDefaultRate = Math.round((earlyDefaultAmount / loanVolume) * 100);
    const earlyDefaultRate = Math.round((lateDefaultAmount / loanVolume) * 100);

    const defaultRate = Math.round((defaultAmount / loanVolume) * 100);

    const loanVolumePresentation = formatCurrencyShort({ amount: loanVolume });

    const totalInvestedPresentation = formatCurrencyShort({ amount: totalInvested });
    const maxInvestmentPresentation = formatCurrencyShort({ amount: maxInvestment });

    const investmentProgress = Math.round((totalInvested / maxInvestment) * 100);

    return (
        <ComposableCard className={clsx("new-opportunity-summary", className)} Content={{ className: 'flex flex-col !pt-3 grow justify-between' }}>
            <h2 className="text-lg font-bold pb-1">{lenderName}</h2>
            <div className="flex gap-6 text-sm lg:text-base">
                <div className="w-fit">
                    <span className="text-sm block text-gray-400 whitespace-nowrap">Default Volume</span>
                    <div className='w-fit flex gap-2 '>
                        <span className="block font-semibold">3</span>
                        <span className="block font-semibold">/</span>
                        <span className="block font-semibold">{defaultAmountPresentation}</span>

                    </div>
                </div>
                <div className="w-fit">
                    <span className="text-sm block text-gray-400 whitespace-nowrap">Loan Volume</span>
                    <div className='w-full flex justify-between pr-3'>
                        <span className="block font-semibold">{totalLoans}</span>
                        <span className="block font-semibold">/</span>
                        <span className="block font-semibold">{loanVolumePresentation}</span>
                    </div>
                </div>
                <RadialChart className="w-20 h-20 shrink-0 -mt-8 ml-auto" series={[100 - defaultRate, earlyDefaultRate, lateDefaultRate]} />
            </div>

            <div className="flex gap-4 pt-2 text-sm lg:text-base w-full">
                <div className="flex flex-col justify-between">
                    <span className="block text-gray-400 whitespace-nowrap text-sm">{totalInvestedPresentation} / {maxInvestmentPresentation} invested</span>
                    <Progress value={investmentProgress} variant={"custom_variant"} className="progress-background" />
                </div>
                { /* TO-DO - Remove JIT color */}
                <Button variant="ghost" className="text-accent-foreground bg-transparent rounded-md font-medium text-sm h-9 shrink-0 min-w-0 !px-2">
                    Invest Now
                    <ArrowRight className="w-5 h-5 ml-2" />
                </Button>
            </div>
        </ComposableCard>
    )
}

export default NewOpportunitySummary;