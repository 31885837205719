import {
    DropdownMenu, DropdownMenuArrow,
    DropdownMenuContent,
    DropdownMenuItem, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu.tsx";
import ProfileHead from "@/components/dashboard/ProfileHead.tsx";
import {CircleUserRound, LogOut, Settings} from "lucide-react";
import {Fragment, JSX} from "react";
import {useNavigate} from "react-router-dom";

export interface ProfileDropdownMenuProps {
    displayName: string | null | undefined;
    role: string;
}

const ProfileItem = ({displayName, role}: ProfileDropdownMenuProps) => {
    return <span className={'overflow-ellipsis whitespace-nowrap overflow-hidden'}>
        {displayName}
        <br/>
        <span className={'block text-gray-400 text-xs'}>{role}</span>
    </span>
}

export const ProfileDropdownMenu = (props: ProfileDropdownMenuProps) => {

    const navigate = useNavigate();

    const menuItems: {id: number, icon: JSX.Element, content: string | JSX.Element, navigateTo?: string}[] = [
        {
            id: 1,
            icon: <CircleUserRound />,
            content: <ProfileItem {...props}></ProfileItem>,
            navigateTo: '/me'
        },
        {   id: 2,  
            icon: <Settings/>,
            content: 'Account Settings',
            navigateTo: '/settings'
        },
        {   
            id: 3, 
            icon: <LogOut/>,
            content: 'Log Out',
            navigateTo: '/logout'
        }
    ];

    return <DropdownMenu>
        <DropdownMenuTrigger>
            <ProfileHead displayName={props.displayName} />
        </DropdownMenuTrigger>
        <DropdownMenuContent className={'bg-card rounded-lg border opacity-100 w-64'}>
            {menuItems.map((item, index) => {
                return (
                    <Fragment key={`menuItem${index}`}>
                        {index != 0 && <DropdownMenuSeparator className={'bg-accent'}/>}
                        <DropdownMenuItem
                            className={'text-base px-5 py-3 h-14'}
                            onClick={() => item.navigateTo && navigate(item.navigateTo)}>
                            <span className="mr-3 h-7 w-7" key={item.id}>{item.icon}</span>
                            {item.content}
                        </DropdownMenuItem>
                    </Fragment>
                )
            })}
            <DropdownMenuArrow className='fill-card' height={10} width={20}/>
        </DropdownMenuContent>
    </DropdownMenu>

}
