export default function USAMap({ className }: { className?: string }) {
    return <svg width="318" height="195" viewBox="0 0 318 195" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M111.047 127.598C111.062 127.067 110.615 126.595 110.083 126.578C109.548 126.561 109.079 127.001 109.06 127.536C109.041 128.067 109.485 128.537 110.022 128.554C110.561 128.571 111.032 128.131 111.047 127.598Z" fill="#747070" />
        <path d="M115.84 164.018C115.307 164.002 114.836 164.444 114.817 164.978C114.799 165.508 115.244 165.979 115.78 165.995C116.315 166.011 116.786 165.572 116.804 165.038C116.821 164.508 116.373 164.035 115.84 164.018Z" fill="#747070" />
        <path d="M158.979 131.045C158.446 131.058 157.997 131.529 158.01 132.06C158.022 132.59 158.494 133.036 159.028 133.023C159.56 133.009 160.01 132.539 159.997 132.01C159.984 131.478 159.511 131.032 158.979 131.045Z" fill="#747070" />
        <path d="M153.276 131.045C152.742 131.03 152.268 131.473 152.253 132.004C152.238 132.533 152.686 133.006 153.218 133.022C153.752 133.038 154.223 132.597 154.24 132.063C154.257 131.533 153.81 131.06 153.276 131.045Z" fill="#747070" />
        <path d="M147.525 131.046C146.992 131.026 146.515 131.469 146.497 132C146.479 132.529 146.924 133.004 147.456 133.022C147.991 133.04 148.464 132.601 148.484 132.069C148.503 131.538 148.059 131.065 147.525 131.046Z" fill="#747070" />
        <path d="M141.694 131.046C141.164 131.067 140.718 131.547 140.74 132.074C140.763 132.606 141.239 133.044 141.772 133.022C142.309 133.001 142.748 132.527 142.725 131.996C142.703 131.462 142.227 131.024 141.694 131.046Z" fill="#747070" />
        <path d="M121.6 164.018C121.064 164 120.592 164.44 120.574 164.975C120.556 165.503 120.999 165.973 121.536 165.995C122.079 166.016 122.543 165.583 122.561 165.04C122.578 164.511 122.132 164.036 121.6 164.018Z" fill="#747070" />
        <path d="M110.018 164.018C109.484 164.037 109.04 164.513 109.061 165.042C109.082 165.576 109.555 166.014 110.09 165.995C110.622 165.975 111.066 165.501 111.047 164.971C111.029 164.439 110.552 163.998 110.018 164.018Z" fill="#747070" />
        <path d="M104.815 164.018C104.281 164.037 103.836 164.513 103.858 165.042C103.879 165.576 104.352 166.014 104.887 165.995C105.419 165.975 105.863 165.501 105.844 164.971C105.826 164.439 105.35 163.998 104.815 164.018Z" fill="#747070" />
        <path d="M153.216 95.5828C153.754 95.5999 154.223 95.1616 154.241 94.6254C154.258 94.0901 153.815 93.6195 153.28 93.6054C152.743 93.5902 152.274 94.0285 152.254 94.5638C152.234 95.095 152.679 95.5666 153.216 95.5828Z" fill="#747070" />
        <path d="M104.267 128.555C104.803 128.571 105.275 128.127 105.289 127.595C105.303 127.065 104.855 126.594 104.322 126.577C103.781 126.559 103.316 126.998 103.301 127.537C103.288 128.069 103.733 128.54 104.267 128.555Z" fill="#747070" />
        <path d="M92.8078 126.577C92.269 126.561 91.8051 127 91.789 127.537C91.7738 128.064 92.2165 128.524 92.7633 128.547C93.2949 128.57 93.7649 128.125 93.7771 127.587C93.7882 127.056 93.3435 126.592 92.8078 126.577Z" fill="#747070" />
        <path d="M86.9939 126.579C86.4572 126.596 86.0155 127.061 86.0317 127.594C86.0479 128.116 86.5219 128.555 87.0626 128.548C87.5943 128.541 88.04 128.067 88.0188 127.532C87.9976 126.996 87.5316 126.561 86.9939 126.579Z" fill="#747070" />
        <path d="M81.2418 128.548C81.7755 128.576 82.2505 128.135 82.2637 127.599C82.2768 127.07 81.8321 126.595 81.3035 126.577C80.7668 126.559 80.2948 126.995 80.2756 127.529C80.2554 128.052 80.6981 128.52 81.2418 128.548Z" fill="#747070" />
        <path d="M110.084 42.3295C109.551 42.3002 109.078 42.7385 109.06 43.2788C109.041 43.8141 109.479 44.2796 110.018 44.2988C110.562 44.318 111.026 43.8908 111.048 43.3505C111.069 42.8203 110.635 42.3608 110.084 42.3295Z" fill="#747070" />
        <path d="M133.113 126.579C132.576 126.561 132.11 126.995 132.087 127.532C132.065 128.067 132.511 128.542 133.041 128.549C133.581 128.556 134.057 128.117 134.073 127.596C134.092 127.063 133.65 126.596 133.113 126.579Z" fill="#747070" />
        <path d="M121.602 44.2986C122.138 44.2824 122.578 43.8138 122.56 43.2796C122.543 42.7585 122.067 42.3192 121.526 42.3273C120.997 42.3353 120.549 42.813 120.572 43.3463C120.596 43.8815 121.064 44.3148 121.602 44.2986Z" fill="#747070" />
        <path d="M127.283 42.3272C126.754 42.3343 126.307 42.812 126.328 43.3462C126.349 43.8805 126.82 44.3167 127.356 44.2976C127.892 44.2784 128.334 43.8108 128.317 43.2796C128.301 42.7595 127.823 42.3202 127.283 42.3272Z" fill="#747070" />
        <path d="M134.075 165.039C134.092 164.515 133.646 164.05 133.103 164.025C132.572 164.001 132.098 164.445 132.087 164.98C132.076 165.512 132.516 165.976 133.053 165.994C133.596 166.012 134.058 165.581 134.075 165.039Z" fill="#747070" />
        <path d="M127.283 164.024C126.755 164.035 126.307 164.516 126.329 165.047C126.352 165.577 126.827 166.012 127.363 165.994C127.898 165.977 128.338 165.507 128.318 164.972C128.298 164.449 127.822 164.013 127.283 164.024Z" fill="#747070" />
        <path d="M115.782 44.2994C116.325 44.3146 116.786 43.8834 116.804 43.3431C116.821 42.8149 116.382 42.3564 115.833 42.3301C115.299 42.3049 114.828 42.7472 114.817 43.2855C114.805 43.8207 115.246 44.2843 115.782 44.2994Z" fill="#747070" />
        <path d="M127.289 126.577C126.757 126.597 126.313 127.069 126.33 127.598C126.347 128.135 126.823 128.576 127.354 128.548C127.899 128.519 128.339 128.052 128.318 127.527C128.297 126.991 127.827 126.557 127.289 126.577Z" fill="#747070" />
        <path d="M181.038 94.5515C181.014 95.0807 181.465 95.5685 181.986 95.5776C182.524 95.5877 183.006 95.1484 183.026 94.6313C183.046 94.0991 182.61 93.6295 182.072 93.6073C181.536 93.585 181.061 94.0183 181.038 94.5515Z" fill="#747070" />
        <path d="M121.591 128.548C122.136 128.523 122.578 128.06 122.561 127.534C122.544 126.996 122.078 126.56 121.539 126.578C121.004 126.595 120.559 127.061 120.573 127.592C120.587 128.129 121.059 128.573 121.591 128.548Z" fill="#747070" />
        <path d="M115.778 126.577C115.249 126.595 114.803 127.068 114.817 127.596C114.831 128.132 115.306 128.574 115.838 128.547C116.386 128.519 116.824 128.058 116.804 127.529C116.783 126.993 116.316 126.558 115.778 126.577Z" fill="#747070" />
        <path d="M97.5463 127.538C97.5311 128.067 97.9718 128.524 98.5206 128.548C99.0523 128.571 99.5213 128.126 99.5334 127.587C99.5455 127.054 99.1008 126.592 98.5641 126.578C98.0244 126.561 97.5614 126.999 97.5463 127.538Z" fill="#747070" />
        <path d="M135.948 177.863C135.417 177.878 134.969 178.35 134.983 178.879C134.998 179.404 135.475 179.851 136.007 179.837C136.539 179.824 136.986 179.352 136.971 178.82C136.956 178.291 136.482 177.848 135.948 177.863Z" fill="#747070" />
        <path d="M92.8267 42.3273C92.289 42.3172 91.8079 42.7575 91.7887 43.2756C91.7695 43.8089 92.2071 44.2764 92.7448 44.2976C93.2805 44.3178 93.7536 43.8846 93.7778 43.3514C93.8021 42.8191 93.3543 42.3374 92.8267 42.3273Z" fill="#747070" />
        <path d="M98.5714 44.2986C99.1051 44.2784 99.5488 43.8098 99.5346 43.2807C99.5205 42.7424 99.0464 42.3021 98.5138 42.3293C97.965 42.3586 97.5273 42.8181 97.5465 43.3463C97.5657 43.8856 98.0317 44.3188 98.5714 44.2986Z" fill="#747070" />
        <path d="M170.563 133.022C171.099 132.998 171.534 132.526 171.511 131.992C171.487 131.471 171.004 131.035 170.469 131.05C169.941 131.065 169.498 131.548 169.526 132.08C169.552 132.61 170.031 133.045 170.563 133.022Z" fill="#747070" />
        <path d="M164.789 131.045C164.258 131.027 163.785 131.471 163.768 132.005C163.749 132.535 164.194 133.005 164.731 133.022C165.267 133.038 165.74 132.597 165.755 132.065C165.77 131.537 165.322 131.064 164.789 131.045Z" fill="#747070" />
        <path d="M135.957 131.045C135.424 131.058 134.973 131.525 134.983 132.056C134.993 132.588 135.461 133.034 135.997 133.022C136.531 133.01 136.982 132.543 136.97 132.014C136.959 131.481 136.489 131.032 135.957 131.045Z" fill="#747070" />
        <path d="M130.202 131.053C129.658 131.075 129.213 131.536 129.226 132.062C129.239 132.604 129.699 133.04 130.241 133.023C130.777 133.007 131.222 132.547 131.213 132.016C131.202 131.479 130.733 131.031 130.202 131.053Z" fill="#747070" />
        <path d="M104.324 44.2995C104.863 44.2843 105.304 43.8228 105.291 43.2876C105.278 42.7473 104.808 42.3039 104.276 42.3292C103.726 42.3544 103.286 42.8129 103.303 43.3421C103.319 43.8834 103.78 44.3146 104.324 44.2995Z" fill="#747070" />
        <path d="M75.5479 128.549C76.0705 128.544 76.5253 128.062 76.5061 127.534C76.4869 127.004 76.0149 126.562 75.4822 126.579C74.9455 126.595 74.5018 127.062 74.518 127.591C74.5352 128.112 75.0112 128.554 75.5479 128.549Z" fill="#747070" />
        <path d="M69.7984 128.55C70.3179 128.541 70.7708 128.055 70.7495 127.527C70.7273 126.994 70.2573 126.562 69.7196 126.581C69.1809 126.6 68.7422 127.067 68.7634 127.599C68.7836 128.116 69.2658 128.559 69.7984 128.55Z" fill="#747070" />
        <path d="M170.541 95.5759C171.088 95.5476 171.535 95.0791 171.507 94.564C171.477 94.0166 171.016 93.5864 170.482 93.6056C169.952 93.6248 169.508 94.0974 169.523 94.6246C169.538 95.1669 170.006 95.6042 170.541 95.5759Z" fill="#747070" />
        <path d="M164.781 95.5763C165.328 95.55 165.777 95.0835 165.75 94.5674C165.72 94.019 165.263 93.5888 164.729 93.606C164.196 93.6231 163.752 94.0917 163.767 94.6209C163.782 95.1592 164.251 95.6005 164.781 95.5763Z" fill="#747070" />
        <path d="M159.025 95.5765C159.573 95.5502 160.02 95.0836 159.993 94.5656C159.964 94.0152 159.507 93.586 158.972 93.6062C158.439 93.6264 157.996 94.0939 158.01 94.6221C158.023 95.1584 158.495 95.6017 159.025 95.5765Z" fill="#747070" />
        <path d="M144.569 126.58C144.033 126.595 143.589 127.059 143.602 127.59C143.615 128.128 144.086 128.574 144.616 128.548C145.154 128.523 145.603 128.055 145.589 127.536C145.574 127.002 145.108 126.565 144.569 126.58Z" fill="#747070" />
        <path d="M193.516 93.6053C192.988 93.6194 192.536 94.0961 192.552 94.6223C192.567 95.1495 193.042 95.5958 193.574 95.5827C194.103 95.5696 194.552 95.0959 194.538 94.5667C194.522 94.0335 194.052 93.5912 193.516 93.6053Z" fill="#747070" />
        <path d="M187.812 95.5747C188.349 95.5494 188.799 95.0798 188.783 94.5607C188.767 94.0285 188.298 93.5902 187.76 93.6054C187.231 93.6195 186.783 94.0921 186.796 94.6193C186.809 95.1505 187.286 95.5999 187.812 95.5747Z" fill="#747070" />
        <path d="M148.483 94.5464C148.454 94.0172 147.978 93.586 147.444 93.6062C146.92 93.6263 146.486 94.102 146.5 94.6423C146.513 95.1523 147.006 95.5936 147.543 95.5775C148.071 95.5613 148.513 95.0756 148.483 94.5464Z" fill="#747070" />
        <path d="M140.744 94.6404C140.758 95.1514 141.249 95.5927 141.787 95.5776C142.314 95.5624 142.756 95.0787 142.728 94.5474C142.699 94.0152 142.225 93.585 141.692 93.6062C141.163 93.6274 140.73 94.1001 140.744 94.6404Z" fill="#747070" />
        <path d="M193.5 131.05C192.977 131.063 192.528 131.548 192.553 132.077C192.577 132.608 193.049 133.04 193.585 133.022C194.122 133.003 194.559 132.534 194.536 132.001C194.514 131.479 194.033 131.039 193.5 131.05Z" fill="#747070" />
        <path d="M187.82 133.023C188.359 133.01 188.799 132.546 188.779 132.008C188.761 131.485 188.284 131.043 187.75 131.052C187.214 131.061 186.77 131.534 186.793 132.069C186.817 132.602 187.284 133.035 187.82 133.023Z" fill="#747070" />
        <path d="M181.994 131.052C181.459 131.06 181.015 131.532 181.037 132.068C181.059 132.602 181.524 133.035 182.063 133.023C182.601 133.01 183.041 132.546 183.024 132.009C183.008 131.489 182.529 131.044 181.994 131.052Z" fill="#747070" />
        <path d="M176.241 131.052C175.71 131.057 175.264 131.529 175.282 132.066C175.3 132.597 175.767 133.035 176.304 133.023C176.84 133.012 177.282 132.547 177.268 132.01C177.254 131.488 176.78 131.046 176.241 131.052Z" fill="#747070" />
        <path d="M135.948 93.6059C135.418 93.622 134.981 94.0876 134.987 94.6279C134.993 95.1409 135.477 95.5843 136.018 95.5762C136.547 95.5671 136.993 95.0914 136.972 94.5582C136.951 94.026 136.482 93.5907 135.948 93.6059Z" fill="#747070" />
        <path d="M130.179 93.6073C129.651 93.6295 129.218 94.1021 129.23 94.6394C129.242 95.1484 129.733 95.5907 130.271 95.5766C130.8 95.5624 131.24 95.0827 131.211 94.5485C131.184 94.0143 130.712 93.5851 130.179 93.6073Z" fill="#747070" />
        <path d="M118.672 95.5749C119.2 95.6042 119.681 95.1609 119.699 94.6287C119.717 94.1045 119.27 93.6238 118.743 93.6046C118.209 93.5854 117.733 94.0217 117.711 94.5529C117.69 95.076 118.131 95.5446 118.672 95.5749Z" fill="#747070" />
        <path d="M112.988 93.6042C112.452 93.584 111.977 94.0213 111.957 94.5515C111.937 95.0706 112.383 95.5452 112.917 95.5745C113.444 95.6038 113.924 95.1605 113.943 94.6282C113.961 94.1041 113.514 93.6244 112.988 93.6042Z" fill="#747070" />
        <path d="M150.375 128.548C150.907 128.524 151.358 128.052 151.343 127.533C151.328 127 150.861 126.563 150.322 126.579C149.79 126.595 149.344 127.063 149.357 127.59C149.371 128.12 149.851 128.571 150.375 128.548Z" fill="#747070" />
        <path d="M176.287 95.5756C176.832 95.5534 177.286 95.0888 177.264 94.5748C177.24 94.0294 176.782 93.5921 176.25 93.6053C175.724 93.6184 175.273 94.087 175.28 94.6142C175.287 95.1514 175.753 95.5968 176.287 95.5756Z" fill="#747070" />
        <path d="M70.7488 43.3386C70.7649 42.8114 70.3172 42.3398 69.7855 42.3237C69.2519 42.3075 68.7748 42.7559 68.7667 43.2811C68.7586 43.8173 69.2013 44.2839 69.732 44.298C70.2656 44.3132 70.7336 43.8719 70.7488 43.3386Z" fill="#747070" />
        <path d="M81.2892 44.3001C81.8218 44.289 82.2706 43.8264 82.2615 43.2962C82.2524 42.7559 81.7885 42.3096 81.2589 42.3298C80.7131 42.351 80.2592 42.8135 80.2795 43.3286C80.3017 43.8729 80.7555 44.3102 81.2892 44.3001Z" fill="#747070" />
        <path d="M87.0124 42.3295C86.4666 42.3527 86.0137 42.8183 86.037 43.3323C86.0623 43.8787 86.5191 44.314 87.0528 44.2998C87.5804 44.2857 88.0281 43.8181 88.0201 43.2889C88.012 42.7496 87.546 42.3063 87.0124 42.3295Z" fill="#747070" />
        <path d="M138.863 128.548C139.405 128.521 139.849 128.054 139.831 127.531C139.813 126.997 139.344 126.559 138.805 126.577C138.273 126.594 137.831 127.061 137.843 127.596C137.855 128.13 138.331 128.574 138.863 128.548Z" fill="#747070" />
        <path d="M110.083 91.1119C110.611 91.0958 111.062 90.6191 111.047 90.0939C111.031 89.5627 110.56 89.1234 110.024 89.1396C109.489 89.1558 109.043 89.6243 109.062 90.1515C109.08 90.6807 109.556 91.1281 110.083 91.1119Z" fill="#747070" />
        <path d="M159.038 177.863C158.503 177.845 158.027 178.286 158.01 178.815C157.993 179.341 158.442 179.82 158.971 179.838C159.499 179.856 159.98 179.408 159.997 178.883C160.014 178.355 159.568 177.881 159.038 177.863Z" fill="#747070" />
        <path d="M153.221 177.863C152.689 177.877 152.241 178.345 152.254 178.874C152.267 179.402 152.741 179.849 153.272 179.836C153.804 179.823 154.253 179.351 154.239 178.823C154.225 178.292 153.756 177.849 153.221 177.863Z" fill="#747070" />
        <path d="M112.925 131.053C112.391 131.078 111.942 131.552 111.957 132.072C111.974 132.611 112.44 133.044 112.98 133.023C113.517 133.003 113.954 132.54 113.942 132.005C113.93 131.47 113.457 131.028 112.925 131.053Z" fill="#747070" />
        <path d="M147.454 179.836C147.984 179.856 148.46 179.415 148.481 178.884C148.502 178.356 148.061 177.883 147.526 177.863C146.993 177.844 146.517 178.282 146.497 178.812C146.478 179.339 146.924 179.816 147.454 179.836Z" fill="#747070" />
        <path d="M141.709 177.863C141.179 177.876 140.728 178.345 140.741 178.874C140.754 179.405 141.224 179.85 141.759 179.837C142.29 179.825 142.74 179.354 142.728 178.824C142.715 178.296 142.243 177.849 141.709 177.863Z" fill="#747070" />
        <path d="M133.111 44.2976C133.64 44.2804 134.077 43.8128 134.07 43.2755C134.063 42.7645 133.58 42.3202 133.039 42.3272C132.516 42.3343 132.067 42.815 132.088 43.3462C132.107 43.8805 132.575 44.3157 133.111 44.2976Z" fill="#747070" />
        <path d="M138.878 44.2968C139.405 44.2746 139.84 43.802 139.827 43.2657C139.815 42.7577 139.32 42.3124 138.787 42.3275C138.264 42.3427 137.818 42.8305 137.844 43.3566C137.87 43.8888 138.344 44.3201 138.878 44.2968Z" fill="#747070" />
        <path d="M144.63 44.2975C145.16 44.2773 145.599 43.8047 145.584 43.2694C145.57 42.7443 145.085 42.2999 144.553 42.3231C144.024 42.3474 143.579 42.8261 143.6 43.3502C143.623 43.8814 144.096 44.3177 144.63 44.2975Z" fill="#747070" />
        <path d="M150.308 42.3231C149.779 42.3464 149.335 42.8281 149.357 43.3512C149.38 43.8824 149.854 44.3187 150.388 44.2975C150.917 44.2763 151.355 43.8027 151.339 43.2684C151.324 42.7423 150.841 42.2999 150.308 42.3231Z" fill="#747070" />
        <path d="M156.081 44.2998C156.614 44.3129 157.073 43.8736 157.093 43.3303C157.113 42.8041 156.662 42.3335 156.127 42.3234C155.594 42.3133 155.127 42.7587 155.115 43.2879C155.104 43.8171 155.553 44.2867 156.081 44.2998Z" fill="#747070" />
        <path d="M153.283 46.7931C152.756 46.7729 152.278 47.2173 152.257 47.7465C152.235 48.2747 152.677 48.7463 153.213 48.7655C153.746 48.7847 154.222 48.3454 154.242 47.8172C154.26 47.293 153.811 46.8133 153.283 46.7931Z" fill="#747070" />
        <path d="M104.327 81.7378C104.86 81.7207 105.306 81.2501 105.287 80.7229C105.269 80.1937 104.795 79.7514 104.264 79.7675C103.734 79.7837 103.285 80.2583 103.302 80.7845C103.321 81.3137 103.793 81.755 104.327 81.7378Z" fill="#747070" />
        <path d="M124.44 95.5759C124.973 95.6032 125.444 95.1639 125.457 94.6256C125.471 94.0984 125.024 93.6238 124.498 93.6056C123.967 93.5864 123.504 94.0197 123.474 94.565C123.446 95.079 123.894 95.5487 124.44 95.5759Z" fill="#747070" />
        <path d="M150.311 164.022C149.788 164.03 149.338 164.511 149.359 165.042C149.38 165.577 149.846 166.008 150.384 165.991C150.925 165.974 151.363 165.51 151.343 164.976C151.322 164.456 150.845 164.014 150.311 164.022Z" fill="#747070" />
        <path d="M144.556 164.023C144.022 164.031 143.577 164.504 143.6 165.038C143.623 165.574 144.087 166.006 144.625 165.992C145.166 165.977 145.604 165.515 145.586 164.98C145.568 164.458 145.091 164.016 144.556 164.023Z" fill="#747070" />
        <path d="M147.453 58.139C147.981 58.1592 148.459 57.7169 148.481 57.1867C148.504 56.6575 148.063 56.1869 147.526 56.1667C146.993 56.1465 146.516 56.5848 146.496 57.115C146.477 57.6381 146.926 58.1188 147.453 58.139Z" fill="#747070" />
        <path d="M152.253 57.1316C152.244 57.6578 152.7 58.1294 153.227 58.1405C153.757 58.1516 154.228 57.7002 154.239 57.172C154.25 56.6398 153.804 56.1793 153.266 56.1682C152.731 56.1571 152.262 56.6034 152.253 57.1316Z" fill="#747070" />
        <path d="M138.873 165.995C139.409 165.98 139.85 165.512 139.831 164.976C139.813 164.456 139.334 164.014 138.797 164.024C138.263 164.034 137.819 164.508 137.844 165.044C137.869 165.578 138.336 166.011 138.873 165.995Z" fill="#747070" />
        <path d="M107.223 133.022C107.757 133.003 108.201 132.535 108.186 132.006C108.171 131.466 107.701 131.025 107.169 131.055C106.632 131.084 106.185 131.552 106.202 132.071C106.217 132.608 106.684 133.042 107.223 133.022Z" fill="#747070" />
        <path d="M63.0134 43.3334C63.0357 43.8757 63.4955 44.314 64.0282 44.2999C64.5568 44.2857 65.0056 43.8131 64.9914 43.2859C64.9773 42.7567 64.5083 42.3114 63.9766 42.3235C63.443 42.3356 62.9922 42.8082 63.0134 43.3334Z" fill="#747070" />
        <path d="M118.737 133.022C119.275 133.001 119.714 132.536 119.701 132.004C119.687 131.47 119.211 131.027 118.68 131.053C118.142 131.08 117.695 131.552 117.712 132.073C117.731 132.612 118.198 133.044 118.737 133.022Z" fill="#747070" />
        <path d="M124.503 131.052C123.964 131.042 123.487 131.481 123.47 132.004C123.452 132.542 123.89 133.008 124.428 133.022C124.967 133.037 125.434 132.605 125.458 132.07C125.481 131.537 125.036 131.062 124.503 131.052Z" fill="#747070" />
        <path d="M110.081 81.7373C110.614 81.7221 111.061 81.2525 111.046 80.7253C111.03 80.1992 110.554 79.7528 110.025 79.7669C109.492 79.7821 109.044 80.2537 109.06 80.7809C109.077 81.3141 109.546 81.7534 110.081 81.7373Z" fill="#747070" />
        <path d="M58.2457 16.2011C58.7965 16.2001 59.2392 15.7648 59.2352 15.2245C59.2322 14.6761 58.7985 14.251 58.2416 14.251C57.6888 14.252 57.2501 14.6822 57.2471 15.2265C57.2461 15.7588 57.6989 16.2011 58.2457 16.2011Z" fill="#747070" />
        <path d="M156.082 135.946C155.541 135.958 155.107 136.408 155.117 136.948C155.127 137.482 155.587 137.916 156.131 137.905C156.66 137.894 157.116 137.423 157.101 136.9C157.086 136.366 156.625 135.933 156.082 135.946Z" fill="#747070" />
        <path d="M150.287 137.905C150.82 137.938 151.306 137.514 151.339 136.985C151.374 136.454 150.948 135.979 150.41 135.944C149.869 135.91 149.39 136.325 149.354 136.86C149.319 137.382 149.755 137.871 150.287 137.905Z" fill="#747070" />
        <path d="M144.592 135.945C144.045 135.945 143.601 136.384 143.6 136.926C143.599 137.456 144.061 137.916 144.593 137.915C145.119 137.915 145.586 137.449 145.586 136.922C145.587 136.382 145.142 135.945 144.592 135.945Z" fill="#747070" />
        <path d="M75.4902 135.946C74.9403 135.96 74.5067 136.408 74.5219 136.949C74.536 137.476 75.0141 137.93 75.5387 137.916C76.0713 137.901 76.5231 137.428 76.508 136.902C76.4918 136.36 76.036 135.931 75.4902 135.946Z" fill="#747070" />
        <path d="M69.7452 135.945C69.1933 135.952 68.7567 136.395 68.7648 136.937C68.7728 137.463 69.2459 137.924 69.7714 137.916C70.3031 137.908 70.76 137.441 70.7519 136.913C70.7428 136.372 70.291 135.937 69.7452 135.945Z" fill="#747070" />
        <path d="M63.9402 137.904C64.4809 137.934 64.957 137.517 64.9883 136.984C65.0186 136.45 64.5951 135.976 64.0605 135.945C63.5207 135.913 63.0386 136.331 63.0032 136.863C62.9699 137.38 63.4146 137.873 63.9402 137.904Z" fill="#747070" />
        <path d="M58.2774 137.904C58.8171 137.886 59.2588 137.421 59.2376 136.893C59.2153 136.351 58.7554 135.925 58.2127 135.946C57.6689 135.965 57.2343 136.422 57.2515 136.956C57.2697 137.488 57.7397 137.922 58.2774 137.904Z" fill="#747070" />
        <path d="M52.5283 135.943C51.9876 135.924 51.5156 136.352 51.4893 136.884C51.464 137.4 51.9168 137.883 52.4475 137.903C52.9953 137.924 53.4562 137.504 53.4744 136.965C53.4926 136.422 53.0671 135.963 52.5283 135.943Z" fill="#747070" />
        <path d="M46.7783 135.943C46.2376 135.924 45.7656 136.352 45.7393 136.884C45.714 137.4 46.1668 137.883 46.6975 137.903C47.2453 137.924 47.7062 137.504 47.7244 136.965C47.7436 136.422 47.3171 135.963 46.7783 135.943Z" fill="#747070" />
        <path d="M274.094 133.031C274.622 133.05 275.103 132.601 275.123 132.071C275.143 131.549 274.689 131.061 274.165 131.042C273.629 131.023 273.166 131.458 273.147 132.002C273.126 132.555 273.545 133.01 274.094 133.031Z" fill="#747070" />
        <path d="M268.369 133.029C268.902 133.032 269.369 132.572 269.369 132.044C269.369 131.518 268.9 131.042 268.383 131.04C267.841 131.038 267.4 131.482 267.397 132.03C267.392 132.592 267.816 133.026 268.369 133.029Z" fill="#747070" />
        <path d="M270.994 137.843C271.526 137.846 271.994 137.386 271.994 136.858C271.994 136.332 271.525 135.856 271.008 135.854C270.466 135.852 270.025 136.297 270.022 136.845C270.018 137.407 270.441 137.841 270.994 137.843Z" fill="#747070" />
        <path d="M269.034 141.415C269.034 140.888 268.566 140.412 268.048 140.411C267.506 140.409 267.065 140.853 267.062 141.401C267.059 141.963 267.481 142.397 268.034 142.4C268.567 142.402 269.034 141.942 269.034 141.415Z" fill="#747070" />
        <path d="M262.598 131.05C262.051 131.058 261.626 131.507 261.639 132.058C261.652 132.614 262.092 133.038 262.64 133.027C263.17 133.016 263.626 132.544 263.614 132.019C263.602 131.491 263.132 131.042 262.598 131.05Z" fill="#747070" />
        <path d="M256.84 131.05C256.297 131.061 255.867 131.511 255.88 132.059C255.893 132.611 256.336 133.038 256.883 133.027C257.414 133.016 257.864 132.549 257.855 132.017C257.845 131.488 257.377 131.04 256.84 131.05Z" fill="#747070" />
        <path d="M250.123 132.097C250.151 132.639 250.618 133.056 251.162 133.025C251.697 132.995 252.129 132.514 252.099 131.983C252.07 131.462 251.574 131.021 251.049 131.05C250.513 131.078 250.094 131.552 250.123 132.097Z" fill="#747070" />
        <path d="M245.301 133.03C245.826 133.056 246.319 132.612 246.347 132.089C246.374 131.563 245.934 131.085 245.397 131.056C244.854 131.026 244.395 131.443 244.367 131.992C244.339 132.541 244.754 133.002 245.301 133.03Z" fill="#747070" />
        <path d="M240.589 132.086C240.612 131.572 240.164 131.091 239.633 131.058C239.096 131.025 238.633 131.451 238.61 131.998C238.587 132.545 239.006 133.004 239.551 133.029C240.077 133.055 240.565 132.611 240.589 132.086Z" fill="#747070" />
        <path d="M150.286 14.2514C149.74 14.2847 149.327 14.7483 149.361 15.2906C149.394 15.8339 149.862 16.2399 150.412 16.2046C150.939 16.1702 151.384 15.6754 151.351 15.1603C151.318 14.6281 150.836 14.2181 150.286 14.2514Z" fill="#747070" />
        <path d="M233.888 133.026C234.421 132.999 234.858 132.518 234.832 131.99C234.807 131.488 234.298 131.034 233.781 131.053C233.252 131.072 232.821 131.554 232.849 132.093C232.878 132.627 233.353 133.054 233.888 133.026Z" fill="#747070" />
        <path d="M161.854 16.2001C162.4 16.2042 162.857 15.7659 162.858 15.2337C162.859 14.6883 162.425 14.2561 161.871 14.251C161.313 14.246 160.877 14.6661 160.87 15.2165C160.863 15.7558 161.303 16.1961 161.854 16.2001Z" fill="#747070" />
        <path d="M167.585 16.2012C168.129 16.2194 168.599 15.7902 168.614 15.26C168.63 14.7176 168.204 14.2713 167.653 14.2531C167.095 14.2339 166.647 14.6439 166.627 15.1923C166.606 15.7276 167.039 16.183 167.585 16.2012Z" fill="#747070" />
        <path d="M170.48 11.4512C171.024 11.4694 171.494 11.0402 171.509 10.51C171.525 9.96765 171.099 9.52127 170.548 9.50309C169.99 9.4839 169.542 9.89392 169.522 10.4423C169.501 10.9786 169.934 11.433 170.48 11.4512Z" fill="#747070" />
        <path d="M173.341 16.3448C173.885 16.3629 174.355 15.9337 174.37 15.4035C174.386 14.8612 173.961 14.4148 173.409 14.3966C172.851 14.3775 172.403 14.7875 172.383 15.3359C172.363 15.8721 172.795 16.3266 173.341 16.3448Z" fill="#747070" />
        <path d="M179.687 14.5524C179.129 14.5332 178.682 14.9432 178.661 15.4916C178.641 16.0269 179.074 16.4823 179.62 16.5005C180.163 16.5187 180.633 16.0895 180.648 15.5593C180.665 15.018 180.239 14.5716 179.687 14.5524Z" fill="#747070" />
        <path d="M222.281 131.053C221.745 131.065 221.313 131.533 221.337 132.077C221.362 132.614 221.827 133.044 222.363 133.026C222.895 133.008 223.337 132.535 223.318 132.004C223.3 131.498 222.803 131.042 222.281 131.053Z" fill="#747070" />
        <path d="M216.526 131.052C215.996 131.064 215.558 131.537 215.581 132.076C215.604 132.61 216.073 133.043 216.607 133.027C217.137 133.01 217.579 132.536 217.559 132.005C217.54 131.492 217.052 131.042 216.526 131.052Z" fill="#747070" />
        <path d="M210.849 133.021C211.387 133.005 211.821 132.541 211.802 132.003C211.784 131.491 211.297 131.042 210.769 131.052C210.241 131.063 209.8 131.539 209.822 132.074C209.844 132.608 210.311 133.038 210.849 133.021Z" fill="#747070" />
        <path d="M205.092 133.022C205.628 133.005 206.064 132.541 206.045 132.004C206.026 131.489 205.545 131.045 205.013 131.053C204.485 131.061 204.044 131.537 204.066 132.074C204.087 132.606 204.555 133.037 205.092 133.022Z" fill="#747070" />
        <path d="M199.259 131.053C198.739 131.059 198.288 131.543 198.309 132.072C198.331 132.601 198.802 133.037 199.335 133.022C199.872 133.005 200.308 132.541 200.29 132.005C200.273 131.483 199.801 131.046 199.259 131.053Z" fill="#747070" />
        <path d="M94.6846 19.6897C94.6826 20.2108 95.1505 20.6925 95.668 20.7016C96.2017 20.7107 96.6616 20.2593 96.6687 19.719C96.6757 19.1635 96.2532 18.7323 95.6933 18.7242C95.1445 18.7162 94.6866 19.1545 94.6846 19.6897Z" fill="#747070" />
        <path d="M101.451 18.7218C100.903 18.7147 100.446 19.154 100.443 19.6893C100.44 20.2174 100.902 20.6911 101.425 20.6971C101.963 20.7042 102.422 20.2548 102.428 19.7165C102.434 19.1651 102.004 18.7278 101.451 18.7218Z" fill="#747070" />
        <path d="M107.215 20.6963C107.748 20.6842 108.198 20.2136 108.186 19.6824C108.172 19.1279 107.732 18.7098 107.174 18.7219C106.625 18.7341 106.188 19.1845 106.2 19.7238C106.213 20.2479 106.693 20.7084 107.215 20.6963Z" fill="#747070" />
        <path d="M112.958 20.6961C113.493 20.692 113.948 20.2315 113.943 19.6983C113.938 19.1469 113.499 18.7167 112.947 18.7227C112.396 18.7288 111.951 19.1731 111.958 19.7094C111.964 20.2335 112.437 20.7001 112.958 20.6961Z" fill="#747070" />
        <path d="M118.731 20.6973C119.261 20.6832 119.714 20.2095 119.701 19.6824C119.688 19.134 119.239 18.7098 118.687 18.724C118.136 18.7381 117.703 19.1855 117.718 19.7278C117.732 20.255 118.209 20.7115 118.731 20.6973Z" fill="#747070" />
        <path d="M124.436 20.6974C124.968 20.7125 125.44 20.2662 125.455 19.7339C125.47 19.1876 125.045 18.7392 124.495 18.724C123.946 18.7089 123.487 19.1361 123.472 19.6754C123.458 20.1995 123.913 20.6822 124.436 20.6974Z" fill="#747070" />
        <path d="M130.228 20.696C130.76 20.6909 131.217 20.2274 131.212 19.6972C131.207 19.1468 130.769 18.7196 130.214 18.7247C129.662 18.7297 129.224 19.169 129.229 19.7113C129.235 20.2375 129.705 20.701 130.228 20.696Z" fill="#747070" />
        <path d="M101.492 131.053C100.956 131.038 100.474 131.473 100.449 131.993C100.424 132.529 100.856 133.001 101.391 133.022C101.928 133.043 102.401 132.616 102.429 132.086C102.458 131.55 102.019 131.067 101.492 131.053Z" fill="#747070" />
        <path d="M95.7376 131.052C95.208 131.036 94.7188 131.478 94.6935 131.991C94.6672 132.526 95.0988 132.999 95.6335 133.021C96.1692 133.043 96.6442 132.616 96.6725 132.086C96.7018 131.555 96.2602 131.067 95.7376 131.052Z" fill="#747070" />
        <path d="M169.523 19.6662C169.502 20.1903 169.951 20.6741 170.478 20.6973C171 20.7195 171.485 20.2701 171.507 19.743C171.53 19.2037 171.102 18.7462 170.554 18.725C170.001 18.7028 169.545 19.1188 169.523 19.6662Z" fill="#747070" />
        <path d="M176.324 20.6957C176.842 20.6684 177.294 20.1695 177.268 19.6525C177.24 19.1142 176.77 18.6971 176.22 18.7233C175.676 18.7496 175.256 19.2111 175.283 19.7545C175.312 20.2887 175.792 20.723 176.324 20.6957Z" fill="#747070" />
        <path d="M182.013 20.6972C182.535 20.7073 183.009 20.2478 183.02 19.7206C183.031 19.1783 182.596 18.7339 182.045 18.7238C181.491 18.7137 181.047 19.1379 181.037 19.6883C181.028 20.2175 181.481 20.6861 182.013 20.6972Z" fill="#747070" />
        <path d="M187.802 20.6966C188.327 20.6885 188.786 20.2139 188.778 19.6867C188.77 19.1464 188.317 18.7152 187.767 18.7243C187.216 18.7334 186.786 19.1737 186.796 19.7231C186.805 20.2573 187.268 20.7047 187.802 20.6966Z" fill="#747070" />
        <path d="M190.382 25.4842C190.907 25.4761 191.365 25.0015 191.358 24.4743C191.35 23.934 190.897 23.5028 190.347 23.5119C189.795 23.5209 189.367 23.9613 189.376 24.5107C189.385 25.0449 189.848 25.4923 190.382 25.4842Z" fill="#747070" />
        <path d="M193.572 20.6955C194.09 20.6803 194.554 20.1885 194.539 19.6714C194.524 19.1352 194.06 18.706 193.511 18.7221C192.959 18.7383 192.535 19.1847 192.553 19.733C192.57 20.2683 193.04 20.7116 193.572 20.6955Z" fill="#747070" />
        <path d="M89.8887 131.058C89.353 131.091 88.9103 131.568 88.9345 132.084C88.9598 132.619 89.4349 133.05 89.9685 133.024C90.5062 132.997 90.9358 132.53 90.9146 131.996C90.8944 131.462 90.4163 131.025 89.8887 131.058Z" fill="#747070" />
        <path d="M84.2014 131.053C83.6728 131.048 83.1877 131.503 83.1755 132.013C83.1634 132.546 83.6091 133.012 84.1418 133.025C84.6795 133.037 85.1404 132.602 85.1576 132.066C85.1758 131.527 84.7331 131.058 84.2014 131.053Z" fill="#747070" />
        <path d="M78.3616 133.024C78.8983 133.05 79.3724 132.625 79.4017 132.091C79.432 131.553 78.9994 131.071 78.4698 131.052C77.9492 131.034 77.4439 131.484 77.4186 131.988C77.3923 132.518 77.831 132.999 78.3616 133.024Z" fill="#747070" />
        <path d="M72.6213 131.058C72.0877 131.089 71.6359 131.571 71.6601 132.081C71.6864 132.609 72.1695 133.051 72.6961 133.028C73.2379 133.005 73.6624 132.543 73.6422 132C73.623 131.454 73.158 131.026 72.6213 131.058Z" fill="#747070" />
        <path d="M66.9335 133.029C67.4743 133.01 67.9018 132.549 67.8846 132.006C67.8674 131.458 67.4075 131.027 66.8698 131.057C66.3372 131.085 65.8813 131.565 65.9026 132.074C65.9268 132.603 66.4069 133.048 66.9335 133.029Z" fill="#747070" />
        <path d="M61.1838 133.03C61.7316 133.006 62.1501 132.551 62.1278 131.999C62.1066 131.451 61.6508 131.03 61.105 131.054C60.5723 131.077 60.1246 131.556 60.1468 132.079C60.169 132.605 60.6562 133.051 61.1838 133.03Z" fill="#747070" />
        <path d="M55.3662 131.054C54.8356 131.068 54.3787 131.54 54.3909 132.063C54.403 132.589 54.8801 133.041 55.4097 133.029C55.9595 133.017 56.385 132.569 56.3709 132.018C56.3557 131.463 55.914 131.038 55.3662 131.054Z" fill="#747070" />
        <path d="M49.6905 131.048C49.1649 131.019 48.6697 131.458 48.6383 131.979C48.607 132.511 49.0376 132.992 49.5722 133.025C50.116 133.057 50.582 132.642 50.6123 132.098C50.6436 131.549 50.2302 131.079 49.6905 131.048Z" fill="#747070" />
        <path d="M43.8267 131.054C43.297 131.084 42.8513 131.569 42.8776 132.088C42.9038 132.611 43.4001 133.057 43.9237 133.031C44.4705 133.003 44.8798 132.545 44.8556 131.989C44.8303 131.44 44.3735 131.024 43.8267 131.054Z" fill="#747070" />
        <path d="M213.633 128.558C214.153 128.576 214.643 128.122 214.665 127.6C214.686 127.072 214.239 126.596 213.705 126.574C213.158 126.553 212.706 126.976 212.688 127.526C212.669 128.078 213.092 128.539 213.633 128.558Z" fill="#747070" />
        <path d="M207.945 128.548C208.479 128.528 208.925 128.056 208.905 127.53C208.885 127.001 208.406 126.554 207.878 126.574C207.325 126.596 206.909 127.044 206.929 127.595C206.95 128.149 207.398 128.568 207.945 128.548Z" fill="#747070" />
        <path d="M202.183 128.548C202.716 128.531 203.171 128.058 203.155 127.537C203.139 127.011 202.66 126.559 202.132 126.575C201.587 126.591 201.158 127.044 201.172 127.59C201.187 128.139 201.636 128.566 202.183 128.548Z" fill="#747070" />
        <path d="M196.414 128.549C196.947 128.539 197.406 128.074 197.398 127.551C197.39 127.022 196.92 126.567 196.389 126.576C195.843 126.585 195.408 127.031 195.415 127.577C195.422 128.125 195.868 128.559 196.414 128.549Z" fill="#747070" />
        <path d="M190.697 126.578C190.158 126.551 189.684 126.976 189.657 127.512C189.629 128.052 190.056 128.527 190.591 128.552C191.121 128.578 191.611 128.139 191.64 127.616C191.668 127.089 191.227 126.604 190.697 126.578Z" fill="#747070" />
        <path d="M184.934 126.577C184.396 126.552 183.926 126.981 183.9 127.518C183.875 128.058 184.303 128.529 184.84 128.554C185.368 128.578 185.856 128.136 185.882 127.612C185.907 127.083 185.466 126.601 184.934 126.577Z" fill="#747070" />
        <path d="M179.145 128.552C179.675 128.544 180.133 128.076 180.125 127.55C180.117 127.014 179.656 126.57 179.117 126.579C178.57 126.588 178.135 127.034 178.143 127.579C178.151 128.125 178.6 128.561 179.145 128.552Z" fill="#747070" />
        <path d="M173.402 126.581C172.865 126.568 172.4 127.005 172.384 127.539C172.369 128.073 172.81 128.539 173.346 128.553C173.879 128.567 174.352 128.123 174.366 127.594C174.381 127.058 173.941 126.594 173.402 126.581Z" fill="#747070" />
        <path d="M167.651 128.553C168.18 128.536 168.628 128.06 168.61 127.533C168.591 126.995 168.123 126.559 167.585 126.578C167.046 126.597 166.61 127.062 166.628 127.598C166.645 128.13 167.117 128.57 167.651 128.553Z" fill="#747070" />
        <path d="M161.897 126.581C161.362 126.564 160.892 126.998 160.871 127.53C160.85 128.065 161.292 128.539 161.823 128.55C162.357 128.562 162.837 128.119 162.854 127.599C162.871 127.065 162.432 126.598 161.897 126.581Z" fill="#747070" />
        <path d="M156.081 126.579C155.551 126.593 155.104 127.06 155.115 127.589C155.126 128.118 155.602 128.569 156.126 128.548C156.657 128.527 157.111 128.054 157.096 127.538C157.082 127.003 156.618 126.565 156.081 126.579Z" fill="#747070" />
        <path d="M228.04 133.027C228.567 133.052 229.05 132.611 229.076 132.081C229.101 131.57 228.651 131.089 228.117 131.057C227.581 131.025 227.114 131.454 227.093 131.998C227.072 132.539 227.497 133.001 228.04 133.027Z" fill="#747070" />
        <path d="M64.033 128.549C64.5566 128.543 65.0094 128.065 64.9912 127.536C64.973 127.003 64.508 126.566 63.9734 126.58C63.4356 126.594 62.995 127.056 63.0091 127.59C63.0233 128.109 63.5003 128.556 64.033 128.549Z" fill="#747070" />
        <path d="M210.83 84.2395C210.289 84.2284 209.833 84.6697 209.825 85.212C209.817 85.7664 210.238 86.2007 210.795 86.2108C211.342 86.2209 211.803 85.7836 211.809 85.2504C211.816 84.7232 211.357 84.2496 210.83 84.2395Z" fill="#747070" />
        <path d="M58.2644 126.579C57.7277 126.567 57.2648 127.008 57.2527 127.543C57.2405 128.06 57.6984 128.53 58.2301 128.548C58.7607 128.565 59.2266 128.118 59.2347 127.583C59.2448 127.048 58.8021 126.591 58.2644 126.579Z" fill="#747070" />
        <path d="M52.4504 126.581C51.9127 126.6 51.4761 127.068 51.4963 127.605C51.5165 128.135 51.9956 128.576 52.5262 128.554C53.0619 128.532 53.5006 128.06 53.4793 127.529C53.4581 126.994 52.9881 126.562 52.4504 126.581Z" fill="#747070" />
        <path d="M46.7543 126.579C46.2166 126.566 45.7527 127.007 45.7395 127.542C45.7264 128.07 46.1782 128.539 46.7109 128.551C47.2435 128.563 47.7115 128.118 47.7236 127.588C47.7347 127.056 47.291 126.593 46.7543 126.579Z" fill="#747070" />
        <path d="M40.9515 126.581C40.4128 126.593 39.9701 127.054 39.9832 127.591C39.9963 128.117 40.4714 128.566 41.002 128.553C41.5387 128.541 41.9804 128.077 41.9653 127.541C41.9501 127.004 41.4912 126.569 40.9515 126.581Z" fill="#747070" />
        <path d="M35.2406 126.575C34.71 126.562 34.238 127.011 34.2258 127.543C34.2137 128.073 34.6645 128.54 35.1992 128.552C35.744 128.565 36.1957 128.131 36.2079 127.587C36.219 127.044 35.7844 126.589 35.2406 126.575Z" fill="#747070" />
        <path d="M29.5038 126.575C28.9772 126.55 28.492 126.995 28.4677 127.524C28.4435 128.05 28.8872 128.529 29.4209 128.552C29.9606 128.575 30.4255 128.148 30.4488 127.607C30.472 127.063 30.0465 126.599 29.5038 126.575Z" fill="#747070" />
        <path d="M222.367 121.704C221.81 121.683 221.36 122.089 221.338 122.633C221.316 123.171 221.738 123.629 222.28 123.654C222.824 123.679 223.296 123.257 223.319 122.723C223.344 122.199 222.901 121.724 222.367 121.704Z" fill="#747070" />
        <path d="M170.523 86.2088C171.075 86.2038 171.515 85.7614 171.508 85.2201C171.5 84.697 171.027 84.2294 170.506 84.2324C169.982 84.2355 169.52 84.7071 169.524 85.2363C169.528 85.7857 169.969 86.2149 170.523 86.2088Z" fill="#747070" />
        <path d="M216.624 123.653C217.167 123.623 217.591 123.156 217.56 122.624C217.528 122.087 217.057 121.674 216.513 121.704C215.968 121.733 215.544 122.199 215.577 122.733C215.609 123.269 216.08 123.682 216.624 123.653Z" fill="#747070" />
        <path d="M211.806 122.621C211.777 122.093 211.298 121.675 210.755 121.703C210.212 121.731 209.785 122.201 209.82 122.733C209.856 123.274 210.323 123.683 210.871 123.653C211.409 123.624 211.836 123.153 211.806 122.621Z" fill="#747070" />
        <path d="M205.066 121.704C204.511 121.698 204.068 122.125 204.065 122.668C204.062 123.218 204.49 123.648 205.046 123.654C205.599 123.66 206.043 123.234 206.054 122.69C206.062 122.16 205.611 121.71 205.066 121.704Z" fill="#747070" />
        <path d="M199.359 123.654C199.905 123.623 200.324 123.16 200.291 122.621C200.259 122.088 199.783 121.672 199.24 121.703C198.7 121.735 198.273 122.209 198.307 122.738C198.342 123.28 198.808 123.686 199.359 123.654Z" fill="#747070" />
        <path d="M101.449 123.654C102.003 123.648 102.432 123.217 102.429 122.669C102.426 122.127 101.983 121.699 101.428 121.704C100.886 121.709 100.436 122.158 100.443 122.69C100.449 123.233 100.895 123.66 101.449 123.654Z" fill="#747070" />
        <path d="M95.75 123.654C96.2917 123.617 96.7011 123.148 96.6647 122.606C96.6293 122.066 96.1613 121.666 95.6085 121.704C95.0748 121.741 94.6402 122.231 94.6837 122.749C94.7281 123.287 95.2062 123.693 95.75 123.654Z" fill="#747070" />
        <path d="M89.9952 123.654C90.5339 123.616 90.9452 123.146 90.9099 122.605C90.8745 122.065 90.4075 121.667 89.8537 121.705C89.322 121.741 88.8833 122.234 88.9268 122.748C88.9703 123.279 89.4564 123.69 89.9952 123.654Z" fill="#747070" />
        <path d="M84.1965 123.653C84.7403 123.636 85.1689 123.187 85.1557 122.647C85.1426 122.102 84.6979 121.689 84.1399 121.702C83.6083 121.716 83.1585 122.182 83.1706 122.708C83.1828 123.238 83.6518 123.669 84.1965 123.653Z" fill="#747070" />
        <path d="M78.411 123.653C78.9578 123.651 79.3974 123.218 79.4005 122.678C79.4035 122.133 78.9699 121.706 78.414 121.705C77.8702 121.703 77.4215 122.143 77.4194 122.68C77.4174 123.214 77.8672 123.655 78.411 123.653Z" fill="#747070" />
        <path d="M167.592 117.204C167.061 117.216 166.615 117.69 166.63 118.227C166.646 118.779 167.092 119.2 167.646 119.184C168.195 119.169 168.633 118.713 168.614 118.177C168.595 117.649 168.114 117.192 167.592 117.204Z" fill="#747070" />
        <path d="M161.905 119.181C162.456 119.154 162.884 118.693 162.855 118.158C162.827 117.641 162.329 117.182 161.817 117.203C161.293 117.224 160.846 117.716 160.87 118.244C160.895 118.794 161.352 119.209 161.905 119.181Z" fill="#747070" />
        <path d="M156.093 117.203C155.566 117.207 155.11 117.676 155.115 118.21C155.121 118.763 155.559 119.19 156.113 119.182C156.665 119.174 157.109 118.728 157.098 118.191C157.088 117.668 156.61 117.199 156.093 117.203Z" fill="#747070" />
        <path d="M150.356 119.182C150.908 119.175 151.352 118.73 151.343 118.192C151.334 117.67 150.855 117.199 150.338 117.204C149.807 117.208 149.351 117.674 149.357 118.209C149.364 118.761 149.802 119.189 150.356 119.182Z" fill="#747070" />
        <path d="M144.542 117.208C144.012 117.238 143.572 117.725 143.6 118.252C143.629 118.798 144.092 119.212 144.641 119.183C145.189 119.153 145.61 118.692 145.581 118.153C145.552 117.626 145.06 117.18 144.542 117.208Z" fill="#747070" />
        <path d="M138.827 117.208C138.296 117.214 137.839 117.679 137.844 118.209C137.849 118.759 138.291 119.189 138.843 119.182C139.395 119.175 139.833 118.734 139.827 118.192C139.82 117.666 139.349 117.202 138.827 117.208Z" fill="#747070" />
        <path d="M133.127 119.182C133.674 119.153 134.098 118.688 134.068 118.151C134.039 117.625 133.547 117.179 133.028 117.207C132.5 117.235 132.056 117.726 132.085 118.25C132.114 118.796 132.578 119.211 133.127 119.182Z" fill="#747070" />
        <path d="M127.342 117.208C126.81 117.199 126.341 117.651 126.334 118.181C126.326 118.733 126.754 119.174 127.304 119.182C127.853 119.19 128.307 118.758 128.316 118.219C128.325 117.693 127.866 117.217 127.342 117.208Z" fill="#747070" />
        <path d="M93.7743 118.174C93.7622 117.647 93.2861 117.196 92.7585 117.209C92.2339 117.222 91.7791 117.701 91.7913 118.227C91.8044 118.766 92.2633 119.195 92.8111 119.183C93.3589 119.17 93.7875 118.722 93.7743 118.174Z" fill="#747070" />
        <path d="M86.973 117.208C86.4555 117.237 86.0047 117.74 86.033 118.256C86.0623 118.793 86.5374 119.211 87.0842 119.181C87.6279 119.153 88.0474 118.688 88.0171 118.145C87.9868 117.613 87.5026 117.178 86.973 117.208Z" fill="#747070" />
        <path d="M81.2434 117.208C80.7198 117.222 80.264 117.705 80.2782 118.229C80.2923 118.768 80.7522 119.195 81.301 119.182C81.8488 119.169 82.2764 118.719 82.2612 118.171C82.2461 117.639 81.775 117.194 81.2434 117.208Z" fill="#747070" />
        <path d="M74.5248 118.162C74.5016 118.701 74.9281 119.159 75.478 119.182C76.0278 119.205 76.4857 118.787 76.5079 118.239C76.5291 117.711 76.0834 117.229 75.5548 117.208C75.0322 117.187 74.5471 117.636 74.5248 118.162Z" fill="#747070" />
        <path d="M70.7467 118.151C70.7214 117.616 70.2413 117.177 69.7117 117.205C69.1932 117.232 68.7403 117.731 68.7646 118.248C68.7899 118.783 69.2619 119.207 69.8087 119.183C70.3515 119.16 70.7729 118.697 70.7467 118.151Z" fill="#747070" />
        <path d="M64.0515 119.183C64.5952 119.16 65.0147 118.697 64.9874 118.15C64.9611 117.614 64.48 117.174 63.9524 117.205C63.4379 117.234 62.9791 117.739 63.0023 118.25C63.0276 118.78 63.5057 119.206 64.0515 119.183Z" fill="#747070" />
        <path d="M58.2954 117.201C57.786 117.175 57.2847 117.632 57.2523 118.151C57.219 118.686 57.6435 119.151 58.1943 119.181C58.7492 119.212 59.2071 118.802 59.2364 118.25C59.2637 117.724 58.818 117.226 58.2954 117.201Z" fill="#747070" />
        <path d="M52.4428 119.182C52.9947 119.211 53.4505 118.8 53.4778 118.246C53.5041 117.717 53.0604 117.225 52.5348 117.203C52.0254 117.181 51.5241 117.64 51.4948 118.156C51.4634 118.689 51.893 119.153 52.4428 119.182Z" fill="#747070" />
        <path d="M216.584 102.973C216.038 102.963 215.592 103.392 215.581 103.94C215.569 104.48 216.008 104.929 216.555 104.936C217.089 104.943 217.559 104.492 217.567 103.966C217.576 103.437 217.127 102.983 216.584 102.973Z" fill="#747070" />
        <path d="M210.749 102.972C210.21 103.006 209.792 103.48 209.828 104.02C209.863 104.552 210.354 104.984 210.879 104.945C211.395 104.905 211.85 104.392 211.815 103.887C211.778 103.361 211.286 102.94 210.749 102.972Z" fill="#747070" />
        <path d="M205.046 102.972C204.505 102.976 204.061 103.425 204.065 103.964C204.07 104.49 204.539 104.954 205.065 104.948C205.589 104.942 206.056 104.466 206.051 103.945C206.045 103.412 205.589 102.968 205.046 102.972Z" fill="#747070" />
        <path d="M199.241 102.972C198.706 103.003 198.28 103.479 198.313 104.013C198.344 104.543 198.832 104.978 199.359 104.946C199.871 104.914 200.329 104.402 200.297 103.895C200.263 103.365 199.78 102.942 199.241 102.972Z" fill="#747070" />
        <path d="M193.519 104.944C194.042 104.96 194.522 104.503 194.536 103.978C194.55 103.443 194.113 102.988 193.568 102.973C193.025 102.958 192.568 103.387 192.552 103.929C192.536 104.454 192.988 104.929 193.519 104.944Z" fill="#747070" />
        <path d="M187.721 102.973C187.182 103.007 186.763 103.481 186.798 104.018C186.833 104.548 187.323 104.98 187.851 104.944C188.364 104.909 188.82 104.394 188.785 103.889C188.747 103.357 188.261 102.938 187.721 102.973Z" fill="#747070" />
        <path d="M118.648 104.947C119.173 104.98 119.663 104.545 119.695 104.015C119.728 103.477 119.306 103.006 118.767 102.974C118.227 102.943 117.744 103.364 117.709 103.895C117.677 104.402 118.133 104.913 118.648 104.947Z" fill="#747070" />
        <path d="M112.949 102.973C112.406 102.975 111.955 103.423 111.958 103.957C111.96 104.478 112.434 104.948 112.955 104.947C113.482 104.945 113.947 104.479 113.944 103.952C113.941 103.409 113.496 102.972 112.949 102.973Z" fill="#747070" />
        <path d="M107.193 104.945C107.723 104.945 108.186 104.483 108.185 103.956C108.183 103.416 107.739 102.973 107.196 102.972C106.653 102.972 106.201 103.418 106.2 103.952C106.2 104.476 106.668 104.945 107.193 104.945Z" fill="#747070" />
        <path d="M101.455 102.972C100.913 102.964 100.452 103.404 100.443 103.938C100.435 104.459 100.898 104.938 101.421 104.948C101.946 104.958 102.42 104.498 102.427 103.971C102.436 103.433 101.995 102.98 101.455 102.972Z" fill="#747070" />
        <path d="M95.6877 102.973C95.1459 102.969 94.6891 103.413 94.686 103.948C94.683 104.468 95.152 104.942 95.6746 104.949C96.2022 104.955 96.6671 104.493 96.6691 103.963C96.6711 103.419 96.2325 102.976 95.6877 102.973Z" fill="#747070" />
        <path d="M89.9183 104.949C90.451 104.956 90.9108 104.499 90.9129 103.963C90.9149 103.418 90.4752 102.975 89.9304 102.973C89.3887 102.97 88.9288 103.417 88.9268 103.948C88.9237 104.468 89.3937 104.941 89.9183 104.949Z" fill="#747070" />
        <path d="M84.1538 102.973C83.609 102.982 83.1683 103.431 83.1764 103.968C83.1855 104.499 83.6494 104.945 84.1851 104.936C84.7329 104.926 85.1696 104.479 85.1574 103.939C85.1453 103.392 84.7006 102.964 84.1538 102.973Z" fill="#747070" />
        <path d="M245.367 93.6056C244.82 93.5985 244.378 94.0277 244.365 94.5771C244.353 95.1245 244.785 95.5718 245.331 95.5799C245.863 95.587 246.329 95.1396 246.342 94.6084C246.356 94.0812 245.9 93.6126 245.367 93.6056Z" fill="#747070" />
        <path d="M239.627 93.6055C239.082 93.5883 238.63 94.0125 238.609 94.5619C238.587 95.1072 239.015 95.5647 239.561 95.5829C240.088 95.6 240.574 95.1496 240.592 94.6265C240.609 94.0983 240.16 93.6227 239.627 93.6055Z" fill="#747070" />
        <path d="M233.825 93.5992C233.283 93.6073 232.845 94.0617 232.853 94.6051C232.861 95.1514 233.31 95.5887 233.852 95.5796C234.386 95.5705 234.843 95.104 234.834 94.5778C234.826 94.0537 234.349 93.5921 233.825 93.5992Z" fill="#747070" />
        <path d="M46.7244 32.9561C46.2019 32.9622 45.7309 33.4388 45.7329 33.9579C45.7349 34.4881 46.1948 34.9335 46.7376 34.9325C47.2884 34.9315 47.722 34.4942 47.72 33.9448C47.716 33.4126 47.251 32.9501 46.7244 32.9561Z" fill="#747070" />
        <path d="M52.4609 32.9565C51.9403 32.9737 51.4764 33.4615 51.4905 33.9775C51.5057 34.5108 51.9727 34.946 52.5144 34.9319C53.0572 34.9178 53.4878 34.4643 53.4746 33.922C53.4615 33.3878 52.9915 32.9394 52.4609 32.9565Z" fill="#747070" />
        <path d="M58.2216 34.9317C58.7664 34.9448 59.2222 34.5136 59.2363 33.9713C59.2495 33.4492 58.7876 32.9634 58.2681 32.9533C57.7516 32.9432 57.2644 33.4098 57.2513 33.9278C57.2381 34.4641 57.6778 34.9186 58.2216 34.9317Z" fill="#747070" />
        <path d="M63.9396 32.956C63.4251 32.9943 62.9713 33.5084 63.0047 34.0133C63.04 34.5455 63.5252 34.9657 64.0649 34.9313C64.6036 34.897 65.0231 34.4213 64.9877 33.8861C64.9534 33.3569 64.4581 32.9176 63.9396 32.956Z" fill="#747070" />
        <path d="M69.817 34.9316C70.3568 34.9003 70.7782 34.4297 70.7469 33.8914C70.7156 33.3612 70.2274 32.9249 69.7008 32.9573C69.1853 32.9896 68.7295 33.4996 68.7608 34.0086C68.7942 34.5408 69.2763 34.9629 69.817 34.9316Z" fill="#747070" />
        <path d="M75.5777 34.9324C76.1165 34.8981 76.5369 34.4234 76.5026 33.8872C76.4682 33.358 75.975 32.9227 75.4514 32.9581C74.938 32.9934 74.4821 33.5085 74.5165 34.0144C74.5519 34.5436 75.04 34.9658 75.5777 34.9324Z" fill="#747070" />
        <path d="M228.038 93.6044C227.495 93.6286 227.071 94.0932 227.095 94.6365C227.119 95.1788 227.584 95.604 228.124 95.5798C228.658 95.5555 229.101 95.0768 229.076 94.5507C229.052 94.0255 228.565 93.5812 228.038 93.6044Z" fill="#747070" />
        <path d="M222.349 93.6058C221.807 93.5917 221.35 94.0259 221.337 94.5682C221.323 95.1126 221.757 95.5681 222.299 95.5812C222.832 95.5943 223.305 95.1469 223.318 94.6187C223.331 94.0865 222.885 93.6199 222.349 93.6058Z" fill="#747070" />
        <path d="M133.094 53.6714C133.618 53.6603 134.08 53.1836 134.072 52.6595C134.063 52.1181 133.611 51.6849 133.063 51.694C132.511 51.7041 132.08 52.1454 132.088 52.6928C132.098 53.222 132.57 53.6815 133.094 53.6714Z" fill="#747070" />
        <path d="M216.52 95.5815C217.048 95.6077 217.536 95.1684 217.561 94.6423C217.587 94.111 217.152 93.6344 216.616 93.6071C216.079 93.5808 215.605 94.009 215.579 94.5443C215.552 95.0775 215.986 95.5552 216.52 95.5815Z" fill="#747070" />
        <path d="M209.821 94.5468C209.794 95.076 210.231 95.5557 210.765 95.5829C211.293 95.6092 211.779 95.1689 211.804 94.6417C211.83 94.1085 211.396 93.6338 210.859 93.6086C210.322 93.5823 209.848 94.0115 209.821 94.5468Z" fill="#747070" />
        <path d="M205.083 95.5818C205.614 95.5686 206.062 95.098 206.047 94.5668C206.032 94.0346 205.561 93.5902 205.028 93.6054C204.494 93.6205 204.051 94.0881 204.065 94.6213C204.079 95.1525 204.548 95.5949 205.083 95.5818Z" fill="#747070" />
        <path d="M199.335 95.582C199.866 95.5638 200.31 95.0881 200.29 94.5589C200.271 94.0277 199.796 93.5864 199.263 93.6056C198.737 93.6248 198.29 94.1045 198.309 94.6306C198.328 95.1608 198.801 95.6002 199.335 95.582Z" fill="#747070" />
        <path d="M138.896 32.9582C138.368 32.9259 137.88 33.3602 137.847 33.8904C137.815 34.4276 138.237 34.9003 138.777 34.9316C139.319 34.9629 139.799 34.5428 139.834 34.0075C139.866 33.4955 139.414 32.9895 138.896 32.9582Z" fill="#747070" />
        <path d="M144.568 32.9578C144.039 32.973 143.585 33.4507 143.599 33.9748C143.615 34.5161 144.07 34.9463 144.616 34.9322C145.159 34.9181 145.599 34.4616 145.585 33.9263C145.57 33.4052 145.086 32.9437 144.568 32.9578Z" fill="#747070" />
        <path d="M150.364 32.9538C149.841 32.9417 149.367 33.4033 149.359 33.9324C149.35 34.4727 149.788 34.9222 150.332 34.9312C150.874 34.9403 151.333 34.5 151.343 33.9658C151.352 33.4447 150.889 32.966 150.364 32.9538Z" fill="#747070" />
        <path d="M156.17 32.9575C155.652 32.9182 155.155 33.3565 155.12 33.8846C155.084 34.4189 155.506 34.8976 156.042 34.9319C156.58 34.9662 157.067 34.5461 157.104 34.0159C157.138 33.511 156.683 32.9969 156.17 32.9575Z" fill="#747070" />
        <path d="M161.878 34.9316C162.422 34.9225 162.866 34.4711 162.857 33.9348C162.848 33.4178 162.364 32.9471 161.848 32.9522C161.326 32.9582 160.863 33.4379 160.872 33.9631C160.88 34.5074 161.331 34.9407 161.878 34.9316Z" fill="#747070" />
        <path d="M167.579 32.9543C167.055 32.9745 166.605 33.4663 166.628 33.9894C166.652 34.5327 167.116 34.9559 167.66 34.9317C168.203 34.9074 168.639 34.4408 168.613 33.9117C168.588 33.3905 168.094 32.9341 167.579 32.9543Z" fill="#747070" />
        <path d="M173.431 32.9583C172.903 32.925 172.42 33.3603 172.391 33.8965C172.36 34.4409 172.779 34.9064 173.323 34.9327C173.864 34.959 174.346 34.5348 174.378 34.0056C174.407 33.4966 173.95 32.9917 173.431 32.9583Z" fill="#747070" />
        <path d="M107.221 95.5817C107.754 95.5665 108.2 95.0959 108.184 94.5667C108.168 94.0345 107.698 93.5922 107.166 93.6073C106.63 93.6225 106.187 94.088 106.202 94.6213C106.218 95.1545 106.687 95.5968 107.221 95.5817Z" fill="#747070" />
        <path d="M101.467 93.6054C100.933 93.5893 100.462 94.0326 100.446 94.5658C100.43 95.094 100.88 95.5677 101.41 95.5818C101.944 95.5959 102.415 95.1526 102.43 94.6234C102.444 94.0881 102.003 93.6216 101.467 93.6054Z" fill="#747070" />
        <path d="M95.637 93.6085C95.1003 93.6337 94.6657 94.1074 94.691 94.6406C94.7163 95.1728 95.1953 95.6081 95.728 95.5818C96.2627 95.5556 96.6983 95.0799 96.673 94.5487C96.6468 94.0145 96.1727 93.5832 95.637 93.6085Z" fill="#747070" />
        <path d="M89.9027 93.6072C89.366 93.6193 88.9202 94.0839 88.9324 94.6171C88.9445 95.1463 89.4165 95.5947 89.9482 95.5816C90.4838 95.5685 90.9296 95.1039 90.9164 94.5707C90.9033 94.0344 90.4404 93.5941 89.9027 93.6072Z" fill="#747070" />
        <path d="M84.137 93.6075C83.6013 93.6257 83.1596 94.0953 83.1768 94.6275C83.1949 95.1557 83.671 95.6 84.2027 95.5819C84.7394 95.5647 85.177 95.0971 85.1578 94.5608C85.1386 94.0256 84.6716 93.5893 84.137 93.6075Z" fill="#747070" />
        <path d="M78.4556 93.604C77.928 93.5797 77.4428 94.0231 77.4175 94.5513C77.3923 95.0795 77.834 95.5561 78.3697 95.5794C78.9114 95.6026 79.3774 95.1764 79.4006 94.6371C79.4238 94.0958 78.9953 93.6282 78.4556 93.604Z" fill="#747070" />
        <path d="M72.6054 93.6023C72.0778 93.6296 71.6341 94.1184 71.6614 94.6425C71.6886 95.1667 72.1809 95.609 72.7064 95.5827C73.2421 95.5555 73.6697 95.0808 73.6424 94.5415C73.6161 94.0063 73.139 93.575 72.6054 93.6023Z" fill="#747070" />
        <path d="M66.8692 95.5795C67.416 95.5987 67.8718 95.1705 67.886 94.6262C67.9001 94.0778 67.4746 93.6193 66.9319 93.6001C66.4103 93.582 65.9221 94.0384 65.9029 94.5605C65.8837 95.0857 66.3355 95.5614 66.8692 95.5795Z" fill="#747070" />
        <path d="M61.1769 93.6032C60.6513 93.583 60.1662 94.0334 60.148 94.5586C60.1298 95.0878 60.5755 95.5614 61.1102 95.5806C61.656 95.5998 62.1129 95.1736 62.1301 94.6293C62.1452 94.0809 61.7217 93.6244 61.1769 93.6032Z" fill="#747070" />
        <path d="M55.3781 95.5775C55.9259 95.5856 56.3666 95.1533 56.3706 94.6039C56.3747 94.0535 55.9451 93.6122 55.3973 93.6041C54.8676 93.596 54.4007 94.0505 54.3936 94.5817C54.3875 95.1079 54.8444 95.5704 55.3781 95.5775Z" fill="#747070" />
        <path d="M259.715 89.1328C259.165 89.1338 258.743 89.562 258.74 90.1195C258.737 90.6659 259.174 91.1112 259.714 91.1133C260.247 91.1153 260.69 90.6628 260.689 90.1195C260.689 89.564 260.262 89.1318 259.715 89.1328Z" fill="#747070" />
        <path d="M247.223 90.1009C247.215 90.6452 247.65 91.1067 248.181 91.1199C248.707 91.133 249.171 90.6785 249.181 90.1413C249.191 89.5878 248.768 89.1435 248.222 89.1354C247.67 89.1263 247.232 89.5505 247.223 90.1009Z" fill="#747070" />
        <path d="M242.46 91.117C242.988 91.1089 243.437 90.6403 243.429 90.106C243.421 89.5577 242.976 89.1224 242.432 89.1325C241.879 89.1426 241.455 89.5819 241.467 90.1333C241.477 90.6787 241.928 91.125 242.46 91.117Z" fill="#747070" />
        <path d="M236.748 89.1362C236.198 89.107 235.737 89.518 235.709 90.0643C235.681 90.6067 236.104 91.0894 236.635 91.1197C237.141 91.149 237.65 90.6905 237.684 90.1744C237.718 89.6392 237.292 89.1655 236.748 89.1362Z" fill="#747070" />
        <path d="M230.907 89.1323C230.352 89.1505 229.941 89.5918 229.96 90.1523C229.977 90.6997 230.433 91.136 230.967 91.1158C231.484 91.0956 231.948 90.6017 231.935 90.0877C231.92 89.5605 231.441 89.1151 230.907 89.1323Z" fill="#747070" />
        <path d="M224.203 90.1706C224.228 90.715 224.697 91.1482 225.227 91.1169C225.737 91.0866 226.201 90.5787 226.179 90.0727C226.156 89.5485 225.664 89.1062 225.134 89.1325C224.583 89.1587 224.178 89.6112 224.203 90.1706Z" fill="#747070" />
        <path d="M161.91 91.1046C162.442 91.0663 162.881 90.5825 162.856 90.0624C162.831 89.5373 162.342 89.102 161.81 89.1313C161.278 89.1606 160.849 89.6382 160.874 90.1715C160.898 90.7077 161.383 91.143 161.91 91.1046Z" fill="#747070" />
        <path d="M156.085 91.1098C156.608 91.1098 157.084 90.6483 157.096 90.1322C157.108 89.599 156.662 89.1415 156.122 89.1334C155.581 89.1253 155.128 89.5636 155.116 90.1049C155.103 90.6382 155.558 91.1098 156.085 91.1098Z" fill="#747070" />
        <path d="M150.329 91.1057C150.858 91.1067 151.329 90.6523 151.34 90.1311C151.351 89.5959 150.906 89.1414 150.364 89.1344C149.823 89.1273 149.371 89.5626 149.359 90.1039C149.346 90.6411 149.795 91.1047 150.329 91.1057Z" fill="#747070" />
        <path d="M144.541 89.1341C144.011 89.1614 143.579 89.6391 143.602 90.1723C143.626 90.7025 144.107 91.1347 144.642 91.1065C145.167 91.0782 145.61 90.5914 145.586 90.0673C145.562 89.5421 145.074 89.1058 144.541 89.1341Z" fill="#747070" />
        <path d="M138.787 89.1362C138.254 89.1655 137.824 89.6411 137.848 90.1713C137.872 90.7035 138.349 91.1338 138.885 91.1065C139.411 91.0792 139.853 90.5925 139.828 90.0703C139.802 89.5401 139.32 89.1069 138.787 89.1362Z" fill="#747070" />
        <path d="M133.047 89.1417C132.511 89.1598 132.074 89.6234 132.091 90.1566C132.108 90.6888 132.577 91.1251 133.113 91.1069C133.647 91.0888 134.092 90.6181 134.073 90.09C134.055 89.5567 133.586 89.1235 133.047 89.1417Z" fill="#747070" />
        <path d="M127.286 91.1122C127.811 91.1334 128.293 90.687 128.314 90.1598C128.336 89.6327 127.893 89.159 127.361 89.1398C126.827 89.1196 126.352 89.5569 126.331 90.0871C126.31 90.6143 126.754 91.091 127.286 91.1122Z" fill="#747070" />
        <path d="M121.537 89.1396C121.003 89.1568 120.557 89.6274 120.574 90.1536C120.591 90.6808 121.069 91.1271 121.598 91.112C122.126 91.0969 122.577 90.6192 122.561 90.094C122.543 89.5598 122.074 89.1225 121.537 89.1396Z" fill="#747070" />
        <path d="M115.79 91.1117C116.317 91.1229 116.791 90.6684 116.802 90.1432C116.813 89.614 116.365 89.1495 115.83 89.1384C115.294 89.1273 114.826 89.5716 114.816 90.1018C114.807 90.629 115.262 91.1006 115.79 91.1117Z" fill="#747070" />
        <path d="M183.019 141.431C183.037 140.891 182.603 140.432 182.058 140.416C181.513 140.401 181.055 140.829 181.037 141.373C181.019 141.902 181.467 142.38 181.998 142.397C182.521 142.414 183 141.961 183.019 141.431Z" fill="#747070" />
        <path d="M104.258 89.1399C103.724 89.1611 103.282 89.6348 103.305 90.1619C103.327 90.6881 103.81 91.1335 104.334 91.1122C104.861 91.091 105.31 90.6073 105.291 90.0842C105.27 89.557 104.791 89.1187 104.258 89.1399Z" fill="#747070" />
        <path d="M98.5037 91.1112C99.0313 91.1304 99.5124 90.686 99.5326 90.1598C99.5538 89.6337 99.1081 89.159 98.5764 89.1398C98.0418 89.1196 97.5667 89.5579 97.5465 90.0881C97.5273 90.6133 97.974 91.092 98.5037 91.1112Z" fill="#747070" />
        <path d="M6.42598 81.7427C6.97784 81.7498 7.41145 81.3256 7.41953 80.7671C7.42762 80.2187 6.98694 79.7582 6.45428 79.7612C5.93577 79.7633 5.4486 80.2359 5.44051 80.7439C5.43344 81.2731 5.88928 81.7356 6.42598 81.7427Z" fill="#747070" />
        <path d="M12.2173 81.745C12.7691 81.7319 13.1916 81.2875 13.1775 80.7361C13.1633 80.1827 12.7095 79.7423 12.1778 79.7666C11.6512 79.7898 11.1873 80.2695 11.1984 80.7815C11.2096 81.3107 11.6816 81.7571 12.2173 81.745Z" fill="#747070" />
        <path d="M17.9073 79.7692C17.3787 79.8096 16.9279 80.2994 16.9542 80.8084C16.9805 81.3326 17.4737 81.7749 18.0013 81.7466C18.5512 81.7184 18.9626 81.2599 18.9332 80.7095C18.9029 80.1571 18.437 79.7288 17.9073 79.7692Z" fill="#747070" />
        <path d="M274.072 177.853C273.53 177.884 273.116 178.353 273.146 178.903C273.176 179.447 273.648 179.871 274.185 179.837C274.705 179.805 275.154 179.303 275.127 178.788C275.099 178.268 274.596 177.822 274.072 177.853Z" fill="#747070" />
        <path d="M268.331 177.853C267.781 177.879 267.373 178.331 267.395 178.889C267.417 179.437 267.875 179.86 268.416 179.834C268.938 179.808 269.391 179.317 269.369 178.802C269.348 178.279 268.855 177.83 268.331 177.853Z" fill="#747070" />
        <path d="M176.235 140.418C175.695 140.439 175.266 140.901 175.285 141.443C175.304 141.974 175.783 142.42 176.311 142.399C176.831 142.379 177.285 141.887 177.265 141.365C177.247 140.828 176.778 140.397 176.235 140.418Z" fill="#747070" />
        <path d="M23.6807 6.84837C24.2194 6.87463 24.6752 6.44441 24.6914 5.89603C24.7076 5.35169 24.28 4.89521 23.7383 4.87602C23.2087 4.85785 22.7296 5.30423 22.7124 5.8314C22.6952 6.34544 23.148 6.82211 23.6807 6.84837Z" fill="#747070" />
        <path d="M29.5228 6.85317C30.0534 6.83197 30.483 6.34822 30.4507 5.80893C30.4183 5.27469 29.9423 4.84952 29.4076 4.8778C28.879 4.90608 28.4393 5.39285 28.4676 5.91901C28.4939 6.41992 29.0063 6.87337 29.5228 6.85317Z" fill="#747070" />
        <path d="M35.2098 6.84894C35.7495 6.86611 36.2013 6.42681 36.2063 5.87843C36.2124 5.3351 35.7768 4.88771 35.233 4.87761C34.7003 4.86751 34.2354 5.31591 34.2243 5.85015C34.2142 6.36116 34.6781 6.83178 35.2098 6.84894Z" fill="#747070" />
        <path d="M38.0862 2.27766C38.6249 2.29483 39.0777 1.85552 39.0828 1.30714C39.0889 0.763808 38.6532 0.316419 38.1095 0.30632C37.5768 0.296221 37.1119 0.74462 37.1007 1.27886C37.0906 1.79088 37.5546 2.26049 38.0862 2.27766Z" fill="#747070" />
        <path d="M41.016 6.8512C41.5456 6.84211 41.9873 6.36645 41.9651 5.83019C41.9429 5.29898 41.4729 4.86269 40.9392 4.87885C40.4086 4.89501 39.9628 5.36967 39.982 5.89886C40.0012 6.40482 40.4955 6.86029 41.016 6.8512Z" fill="#747070" />
        <path d="M167.664 173.39C167.114 173.363 166.656 173.777 166.629 174.327C166.603 174.858 167.043 175.346 167.572 175.369C168.084 175.392 168.583 174.935 168.612 174.417C168.642 173.885 168.211 173.417 167.664 173.39Z" fill="#747070" />
        <path d="M161.873 173.391C161.319 173.381 160.88 173.807 160.871 174.361C160.863 174.894 161.317 175.364 161.847 175.369C162.367 175.374 162.842 174.91 162.856 174.384C162.871 173.852 162.424 173.401 161.873 173.391Z" fill="#747070" />
        <path d="M156.12 173.389C155.569 173.379 155.125 173.807 155.114 174.359C155.104 174.891 155.561 175.363 156.089 175.368C156.61 175.373 157.086 174.911 157.1 174.385C157.114 173.852 156.668 173.399 156.12 173.389Z" fill="#747070" />
        <path d="M149.357 174.335C149.334 174.862 149.782 175.348 150.308 175.369C150.825 175.389 151.315 174.934 151.337 174.411C151.361 173.868 150.937 173.415 150.385 173.391C149.834 173.367 149.382 173.783 149.357 174.335Z" fill="#747070" />
        <path d="M144.541 173.39C143.996 173.416 143.577 173.879 143.603 174.424C143.63 174.958 144.112 175.398 144.64 175.369C145.16 175.341 145.611 174.842 145.585 174.324C145.559 173.786 145.087 173.364 144.541 173.39Z" fill="#747070" />
        <path d="M139.827 174.354C139.819 173.815 139.363 173.38 138.816 173.389C138.266 173.399 137.835 173.843 137.845 174.392C137.855 174.927 138.321 175.38 138.852 175.368C139.376 175.357 139.837 174.878 139.827 174.354Z" fill="#747070" />
        <path d="M170.561 168.487C170.032 168.463 169.55 168.906 169.529 169.437C169.506 169.983 169.928 170.441 170.475 170.464C171.023 170.487 171.486 170.067 171.511 169.525C171.534 169.002 171.084 168.511 170.561 168.487Z" fill="#747070" />
        <path d="M164.812 168.487C164.286 168.457 163.796 168.9 163.768 169.429C163.74 169.971 164.162 170.436 164.707 170.464C165.255 170.492 165.725 170.075 165.754 169.534C165.782 169.013 165.334 168.515 164.812 168.487Z" fill="#747070" />
        <path d="M24.6922 80.7791C24.7033 80.2328 24.2616 79.7632 23.735 79.7632C23.2175 79.7632 22.7263 80.2308 22.7142 80.7367C22.701 81.273 23.1447 81.7325 23.6865 81.7436C24.2353 81.7537 24.681 81.3275 24.6922 80.7791Z" fill="#747070" />
        <path d="M29.4386 79.768C28.906 79.7973 28.4501 80.277 28.4683 80.788C28.4875 81.3202 28.9636 81.7636 29.4952 81.7444C30.0451 81.7252 30.4675 81.2728 30.4504 80.7244C30.4322 80.176 29.9703 79.7387 29.4386 79.768Z" fill="#747070" />
        <path d="M23.7537 42.3218C23.2281 42.2966 22.7318 42.745 22.7116 43.2641C22.6914 43.7902 23.1381 44.28 23.6607 44.3033C24.1954 44.3275 24.6623 43.8983 24.6906 43.357C24.7199 42.8126 24.2995 42.3481 23.7537 42.3218Z" fill="#747070" />
        <path d="M29.5047 42.3231C28.972 42.3008 28.4869 42.7432 28.4707 43.2673C28.4535 43.8006 28.8952 44.2772 29.4228 44.2974C29.9646 44.3186 30.4285 43.8915 30.4487 43.3522C30.4699 42.8119 30.0414 42.3453 29.5047 42.3231Z" fill="#747070" />
        <path d="M35.1878 42.3253C34.6511 42.3434 34.2033 42.8181 34.2286 43.3412C34.2548 43.8775 34.7279 44.3198 35.2534 44.3006C35.7952 44.2804 36.2238 43.8209 36.2046 43.2786C36.1864 42.7363 35.7285 42.3071 35.1878 42.3253Z" fill="#747070" />
        <path d="M41.0007 42.324C40.466 42.3119 39.992 42.7613 39.9859 43.2864C39.9798 43.8267 40.4215 44.2882 40.9552 44.2993C41.4959 44.3105 41.9508 43.8752 41.9629 43.3339C41.976 42.7905 41.5424 42.3361 41.0007 42.324Z" fill="#747070" />
        <path d="M46.7642 42.3227C46.2315 42.3056 45.7524 42.753 45.7433 43.2781C45.7332 43.8194 46.1699 44.283 46.7045 44.2991C47.2412 44.3143 47.7062 43.877 47.7234 43.3417C47.7405 42.8105 47.2978 42.3409 46.7642 42.3227Z" fill="#747070" />
        <path d="M52.5154 42.3226C51.9817 42.3075 51.5067 42.7569 51.4996 43.2831C51.4925 43.8244 51.9322 44.2869 52.4659 44.298C53.0036 44.3101 53.4655 43.8718 53.4796 43.3356C53.4938 42.8044 53.0501 42.3378 52.5154 42.3226Z" fill="#747070" />
        <path d="M58.2781 44.3C58.8118 44.2818 59.2525 43.8122 59.2353 43.28C59.2181 42.7498 58.7491 42.3094 58.2155 42.3236C57.6818 42.3377 57.233 42.8144 57.2573 43.3385C57.2826 43.8808 57.7475 44.3191 58.2781 44.3Z" fill="#747070" />
        <path d="M46.7826 6.85305C47.3081 6.84093 47.7458 6.36022 47.7195 5.82194C47.6942 5.2877 47.2232 4.85747 46.6886 4.87868C46.1599 4.89888 45.7203 5.37657 45.7425 5.90778C45.7637 6.41879 46.2549 6.86416 46.7826 6.85305Z" fill="#747070" />
        <path d="M52.4776 6.84943C53.0083 6.87064 53.4712 6.42426 53.4793 5.88497C53.4863 5.35174 53.0456 4.89728 52.5059 4.88315C51.9662 4.86901 51.5083 5.30731 51.4982 5.84761C51.4881 6.36569 51.9409 6.82823 52.4776 6.84943Z" fill="#747070" />
        <path d="M75.4947 42.3298C74.9539 42.351 74.4991 42.8216 74.5223 43.3346C74.5466 43.8769 75.0065 44.3132 75.5381 44.2991C76.0678 44.2859 76.5165 43.8174 76.5044 43.2902C76.4933 42.759 76.0202 42.3096 75.4947 42.3298Z" fill="#747070" />
        <path d="M58.2295 6.84954C58.7612 6.87377 59.2251 6.43042 59.2352 5.89012C59.2453 5.35588 58.8066 4.90042 58.2659 4.88325C57.7272 4.86709 57.2673 5.30337 57.2541 5.84367C57.242 6.36276 57.6898 6.8253 58.2295 6.84954Z" fill="#747070" />
        <path d="M63.9717 7.20549C64.5034 7.22973 64.9673 6.78638 64.9774 6.24608C64.9875 5.71184 64.5489 5.25637 64.0081 5.2392C63.4694 5.22305 63.0095 5.65933 62.9964 6.19963C62.9832 6.71973 63.431 7.18125 63.9717 7.20549Z" fill="#747070" />
        <path d="M170.554 140.425C169.999 140.404 169.548 140.818 169.526 141.368C169.505 141.894 169.953 142.378 170.48 142.4C170.998 142.421 171.484 141.967 171.506 141.439C171.53 140.898 171.105 140.444 170.554 140.425Z" fill="#747070" />
        <path d="M164.806 140.424C164.252 140.4 163.793 140.812 163.767 141.359C163.742 141.879 164.193 142.373 164.715 142.4C165.236 142.426 165.725 141.977 165.751 141.449C165.778 140.907 165.355 140.449 164.806 140.424Z" fill="#747070" />
        <path d="M141.757 140.425C141.204 140.411 140.752 140.837 140.741 141.381C140.73 141.906 141.189 142.387 141.711 142.4C142.232 142.412 142.715 141.95 142.727 141.428C142.739 140.884 142.308 140.437 141.757 140.425Z" fill="#747070" />
        <path d="M136.009 142.4C136.537 142.382 136.986 141.901 136.968 141.373C136.949 140.823 136.5 140.407 135.944 140.424C135.393 140.442 134.967 140.892 134.986 141.436C135.005 141.963 135.487 142.418 136.009 142.4Z" fill="#747070" />
        <path d="M130.274 140.418C129.735 140.39 129.255 140.816 129.228 141.35C129.202 141.867 129.653 142.371 130.168 142.399C130.695 142.429 131.181 141.988 131.208 141.457C131.236 140.918 130.813 140.446 130.274 140.418Z" fill="#747070" />
        <path d="M124.498 140.418C123.954 140.398 123.488 140.831 123.471 141.368C123.454 141.893 123.907 142.378 124.431 142.396C124.961 142.415 125.433 141.972 125.452 141.436C125.471 140.894 125.042 140.437 124.498 140.418Z" fill="#747070" />
        <path d="M118.697 140.418C118.148 140.424 117.709 140.87 117.718 141.412C117.726 141.939 118.196 142.4 118.722 142.395C119.251 142.39 119.708 141.921 119.7 141.391C119.693 140.847 119.244 140.411 118.697 140.418Z" fill="#747070" />
        <path d="M55.408 11.3307C55.9538 11.3175 56.3813 10.8691 56.3712 10.3197C56.3611 9.77036 55.9164 9.34216 55.3686 9.3563C54.8349 9.36943 54.3841 9.836 54.3952 10.3622C54.4063 10.8914 54.8784 11.3438 55.408 11.3307Z" fill="#747070" />
        <path d="M61.1901 11.3307C61.7329 11.3024 62.1523 10.8399 62.1291 10.2925C62.1069 9.74713 61.646 9.32903 61.0981 9.35731C60.5594 9.38458 60.1218 9.85923 60.147 10.3884C60.1733 10.9126 60.6655 11.3579 61.1901 11.3307Z" fill="#747070" />
        <path d="M66.9408 11.3324C67.4845 11.3071 67.906 10.8456 67.8858 10.2972C67.8666 9.75389 67.4037 9.32973 66.8589 9.35296C66.3262 9.37619 65.8795 9.85791 65.9027 10.3821C65.927 10.9062 66.4192 11.3566 66.9408 11.3324Z" fill="#747070" />
        <path d="M72.6297 11.3293C73.1725 11.3424 73.6293 10.9081 73.6425 10.3658C73.6566 9.82148 73.224 9.364 72.6813 9.35087C72.1496 9.33774 71.6736 9.78715 71.6604 10.3143C71.6473 10.8425 72.1001 11.3172 72.6297 11.3293Z" fill="#747070" />
        <path d="M78.3876 11.3242C78.9334 11.3374 79.3862 10.9081 79.4003 10.3638C79.4145 9.82047 78.9819 9.364 78.4381 9.35087C77.9075 9.33774 77.4314 9.78715 77.4183 10.3133C77.4041 10.8475 77.8499 11.3121 78.3876 11.3242Z" fill="#747070" />
        <path d="M84.1841 11.327C84.7218 11.3189 85.1665 10.8614 85.1584 10.3242C85.1504 9.78489 84.6945 9.34457 84.1548 9.35265C83.6181 9.36174 83.1683 9.82125 83.1764 10.3535C83.1845 10.8857 83.6504 11.3351 84.1841 11.327Z" fill="#747070" />
        <path d="M160.871 43.3583C160.898 43.8915 161.372 44.3217 161.907 44.2965C162.436 44.2722 162.871 43.7935 162.851 43.2593C162.832 42.7321 162.346 42.2938 161.812 42.3221C161.278 42.3514 160.845 42.8271 160.871 43.3583Z" fill="#747070" />
        <path d="M167.663 44.2969C168.192 44.2737 168.627 43.795 168.607 43.2607C168.587 42.7346 168.101 42.2942 167.569 42.3215C167.037 42.3488 166.601 42.8285 166.627 43.3567C166.653 43.8929 167.125 44.3211 167.663 44.2969Z" fill="#747070" />
        <path d="M173.397 44.299C173.931 44.2869 174.371 43.8244 174.364 43.2831C174.357 42.7579 173.881 42.3075 173.348 42.3226C172.814 42.3378 172.37 42.8044 172.384 43.3356C172.397 43.8718 172.86 44.3111 173.397 44.299Z" fill="#747070" />
        <path d="M179.152 44.2989C179.684 44.2878 180.127 43.8242 180.121 43.285C180.115 42.7608 179.639 42.3104 179.105 42.3235C178.561 42.3367 178.13 42.7911 178.143 43.3344C178.156 43.8757 178.611 44.311 179.152 44.2989Z" fill="#747070" />
        <path d="M184.92 44.2982C185.45 44.281 185.89 43.8104 185.878 43.2742C185.866 42.751 185.384 42.3047 184.851 42.3238C184.312 42.343 183.883 42.8056 183.9 43.3469C183.918 43.8862 184.377 44.3154 184.92 44.2982Z" fill="#747070" />
        <path d="M190.624 44.3053C191.145 44.3164 191.618 43.8589 191.635 43.3267C191.653 42.8016 191.196 42.333 190.659 42.3239C190.112 42.3148 189.664 42.747 189.656 43.2934C189.649 43.8387 190.084 44.2942 190.624 44.3053Z" fill="#747070" />
        <path d="M196.425 44.3036C196.947 44.2904 197.403 43.8107 197.395 43.2826C197.387 42.7604 196.904 42.307 196.373 42.3211C195.827 42.3363 195.397 42.7907 195.416 43.3351C195.434 43.8845 195.885 44.3177 196.425 44.3036Z" fill="#747070" />
        <path d="M36.2071 80.8132C36.2374 80.2729 35.8058 79.7801 35.2843 79.7599C34.7718 79.7407 34.2614 80.1951 34.2281 80.7011C34.1927 81.2273 34.6303 81.713 35.164 81.7413C35.7068 81.7716 36.1758 81.3535 36.2071 80.8132Z" fill="#747070" />
        <path d="M176.291 84.236C175.76 84.2269 175.291 84.6803 175.282 85.2105C175.273 85.7579 175.703 86.2002 176.255 86.2093C176.805 86.2184 177.258 85.7862 177.266 85.2479C177.275 84.7217 176.815 84.2451 176.291 84.236Z" fill="#747070" />
        <path d="M159.053 168.487C158.526 168.459 158.04 168.902 158.014 169.432C157.987 169.973 158.41 170.438 158.955 170.464C159.504 170.49 159.97 170.073 159.998 169.531C160.024 169.008 159.579 168.515 159.053 168.487Z" fill="#747070" />
        <path d="M46.7214 79.7726C46.1867 79.7918 45.7279 80.2593 45.7359 80.7754C45.745 81.3066 46.215 81.757 46.7467 81.7449C47.2874 81.7328 47.7261 81.2804 47.72 80.7411C47.715 80.1987 47.2551 79.7534 46.7214 79.7726Z" fill="#747070" />
        <path d="M52.5484 79.7682C52.0268 79.748 51.5285 80.1934 51.4972 80.7054C51.4659 81.2336 51.9025 81.7164 52.4341 81.7426C52.9648 81.7679 53.4489 81.3366 53.4813 80.8105C53.5126 80.2813 53.0709 79.7874 52.5484 79.7682Z" fill="#747070" />
        <path d="M148.48 169.431C148.453 168.901 147.966 168.459 147.44 168.487C146.915 168.514 146.47 169.007 146.497 169.531C146.526 170.075 146.991 170.49 147.54 170.463C148.085 170.437 148.509 169.973 148.48 169.431Z" fill="#747070" />
        <path d="M141.773 168.485C141.248 168.463 140.764 168.911 140.742 169.441C140.72 169.983 141.146 170.441 141.693 170.463C142.244 170.484 142.705 170.065 142.728 169.519C142.752 169 142.298 168.508 141.773 168.485Z" fill="#747070" />
        <path d="M135.956 170.464C136.508 170.476 136.955 170.05 136.967 169.501C136.979 168.97 136.527 168.497 135.997 168.487C135.474 168.476 134.996 168.936 134.985 169.463C134.974 170.005 135.408 170.453 135.956 170.464Z" fill="#747070" />
        <path d="M112.997 168.486C112.477 168.46 111.989 168.909 111.963 169.437C111.935 169.973 112.366 170.444 112.907 170.468C113.447 170.492 113.917 170.066 113.945 169.526C113.971 169 113.527 168.514 112.997 168.486Z" fill="#747070" />
        <path d="M207.883 164.023C207.34 164.036 206.914 164.49 206.929 165.04C206.943 165.588 207.392 166.015 207.939 166C208.47 165.984 208.918 165.514 208.905 164.984C208.89 164.457 208.414 164.01 207.883 164.023Z" fill="#747070" />
        <path d="M213.266 163.845C212.724 163.859 212.298 164.312 212.312 164.862C212.327 165.41 212.776 165.837 213.323 165.822C213.854 165.807 214.301 165.335 214.288 164.806C214.274 164.281 213.798 163.832 213.266 163.845Z" fill="#747070" />
        <path d="M216.061 168.516C215.518 168.529 215.093 168.982 215.107 169.533C215.121 170.08 215.571 170.507 216.118 170.492C216.648 170.477 217.096 170.005 217.083 169.476C217.069 168.95 216.592 168.503 216.061 168.516Z" fill="#747070" />
        <path d="M205.022 168.486C204.479 168.499 204.054 168.952 204.068 169.503C204.082 170.05 204.532 170.477 205.078 170.462C205.609 170.447 206.057 169.976 206.044 169.446C206.03 168.92 205.554 168.473 205.022 168.486Z" fill="#747070" />
        <path d="M193.232 167.633C192.689 167.646 192.264 168.1 192.278 168.65C192.292 169.197 192.742 169.625 193.288 169.609C193.819 169.594 194.267 169.124 194.254 168.593C194.241 168.068 193.763 167.621 193.232 167.633Z" fill="#747070" />
        <path d="M202.1 164.021C201.563 164.049 201.143 164.522 201.172 165.067C201.201 165.608 201.67 166.027 202.212 165.996C202.746 165.966 203.183 165.482 203.152 164.955C203.121 164.434 202.627 163.993 202.1 164.021Z" fill="#747070" />
        <path d="M9.31326 46.7898C8.7897 46.7999 8.34801 47.2745 8.36317 47.8118C8.37833 48.3592 8.83013 48.7904 9.36885 48.7732C9.91869 48.756 10.3361 48.3107 10.3179 47.7583C10.3018 47.2129 9.85097 46.7787 9.31326 46.7898Z" fill="#747070" />
        <path d="M6.58617 44.2962C7.13601 44.279 7.55345 43.8326 7.53525 43.2812C7.51807 42.7359 7.06728 42.3016 6.52957 42.3127C6.00601 42.3228 5.56432 42.7975 5.57948 43.3347C5.59666 43.8821 6.04745 44.3133 6.58617 44.2962Z" fill="#747070" />
        <path d="M58.2205 79.7734C57.6848 79.7966 57.2391 80.2642 57.2533 80.7874C57.2674 81.3216 57.7374 81.7629 58.2701 81.7437C58.8017 81.7246 59.2444 81.257 59.2333 80.7288C59.2232 80.1885 58.7572 79.7492 58.2205 79.7734Z" fill="#747070" />
        <path d="M21.8345 47.7664C21.8233 47.22 21.3715 46.7777 20.8359 46.7888C20.3133 46.7989 19.8514 47.2796 19.8595 47.8027C19.8676 48.338 20.3315 48.7833 20.8702 48.7722C21.4231 48.7601 21.8456 48.3208 21.8345 47.7664Z" fill="#747070" />
        <path d="M26.6663 46.7875C26.1599 46.7633 25.6474 47.2228 25.6151 47.7308C25.5818 48.265 26.0103 48.7387 26.5521 48.768C27.0989 48.7973 27.5628 48.3832 27.5911 47.8409C27.6204 47.2986 27.1939 46.8128 26.6663 46.7875Z" fill="#747070" />
        <path d="M32.4267 46.7852C31.9274 46.759 31.4069 47.2215 31.3715 47.7265C31.3331 48.2567 31.7617 48.7364 32.3024 48.7687C32.8523 48.802 33.3091 48.396 33.3415 47.8456C33.3738 47.2983 32.9554 46.8135 32.4267 46.7852Z" fill="#747070" />
        <path d="M38.1553 46.7868C37.6439 46.7757 37.1456 47.2423 37.1243 47.7533C37.1021 48.2855 37.5428 48.7511 38.0856 48.7693C38.6374 48.7874 39.0791 48.3714 39.0983 47.8159C39.1165 47.2665 38.689 46.7989 38.1553 46.7868Z" fill="#747070" />
        <path d="M44.8545 47.8071C44.8686 47.2577 44.434 46.7942 43.9024 46.7881C43.3869 46.7831 42.8947 47.2517 42.8835 47.7607C42.8714 48.3 43.3111 48.7574 43.8518 48.7696C44.4057 48.7827 44.8403 48.3646 44.8545 47.8071Z" fill="#747070" />
        <path d="M49.68 46.787C49.1736 46.7709 48.6642 47.2374 48.6389 47.7414C48.6116 48.2756 49.0462 48.7473 49.587 48.7705C50.1378 48.7937 50.5876 48.3807 50.6129 47.8293C50.6382 47.2809 50.2106 46.8042 49.68 46.787Z" fill="#747070" />
        <path d="M55.3501 48.7697C55.9009 48.7899 56.3537 48.3698 56.3709 47.8234C56.3881 47.2771 55.9545 46.7994 55.4299 46.7883C54.9205 46.7772 54.4162 47.2458 54.3949 47.7487C54.3717 48.2829 54.8093 48.7505 55.3501 48.7697Z" fill="#747070" />
        <path d="M64.0234 79.7715C63.4948 79.7705 63.0248 80.2199 63.0106 80.741C62.9955 81.2783 63.4331 81.7257 63.983 81.7357C64.5217 81.7448 64.9765 81.3106 64.9917 80.7733C65.0048 80.2371 64.5571 79.7725 64.0234 79.7715Z" fill="#747070" />
        <path d="M69.8017 79.7713C69.2721 79.7572 68.794 80.1955 68.7677 80.7186C68.7415 81.2508 69.1751 81.7154 69.7168 81.7356C70.2515 81.7558 70.7215 81.3276 70.7478 80.7944C70.7751 80.2642 70.3313 79.7855 69.8017 79.7713Z" fill="#747070" />
        <path d="M76.5037 80.7278C76.4926 80.1915 76.0297 79.7553 75.4889 79.7704C74.9563 79.7856 74.5115 80.2491 74.5206 80.7813C74.5297 81.3105 74.9987 81.7518 75.5374 81.7367C76.0721 81.7225 76.5148 81.26 76.5037 80.7278Z" fill="#747070" />
        <path d="M81.2425 79.7705C80.7078 79.7866 80.2621 80.2542 80.2783 80.7824C80.2944 81.3146 80.7624 81.7539 81.2981 81.7377C81.8318 81.7216 82.2734 81.257 82.2593 80.7258C82.2462 80.1916 81.7802 79.7543 81.2425 79.7705Z" fill="#747070" />
        <path d="M86.9969 79.7675C86.4663 79.7837 86.0155 80.2583 86.0327 80.7825C86.0509 81.3147 86.5219 81.755 87.0556 81.7378C87.5882 81.7207 88.0319 81.2531 88.0178 80.7239C88.0026 80.1927 87.5316 79.7514 86.9969 79.7675Z" fill="#747070" />
        <path d="M92.7406 79.768C92.209 79.7912 91.7663 80.2689 91.7916 80.7961C91.8158 81.3273 92.2939 81.7625 92.8255 81.7383C93.3582 81.7151 93.7969 81.2394 93.7746 80.7112C93.7514 80.182 93.2733 79.7447 92.7406 79.768Z" fill="#747070" />
        <path d="M98.5765 79.7678C98.0479 79.7476 97.5678 80.1909 97.5475 80.7181C97.5273 81.2442 97.9731 81.7189 98.5037 81.7381C99.0343 81.7573 99.5154 81.3149 99.5346 80.7898C99.5549 80.2656 99.1061 79.788 98.5765 79.7678Z" fill="#747070" />
        <path d="M101.391 46.8002C100.859 46.8386 100.419 47.3223 100.444 47.8414C100.469 48.3666 100.959 48.8039 101.49 48.7746C102.019 48.7453 102.452 48.2646 102.428 47.7334C102.404 47.1991 101.918 46.7618 101.391 46.8002Z" fill="#747070" />
        <path d="M108.185 47.8273C108.212 47.2961 107.769 46.8134 107.24 46.7982C106.711 46.7831 106.231 47.2244 106.206 47.7485C106.181 48.2828 106.613 48.7504 107.153 48.7726C107.688 48.7938 108.157 48.3646 108.185 47.8273Z" fill="#747070" />
        <path d="M112.991 46.7991C112.463 46.787 111.986 47.2273 111.961 47.7504C111.937 48.2816 112.372 48.7442 112.915 48.7633C113.451 48.7825 113.918 48.3533 113.942 47.8191C113.966 47.2879 113.522 46.8112 112.991 46.7991Z" fill="#747070" />
        <path d="M118.667 48.7634C119.203 48.7846 119.673 48.3543 119.699 47.8211C119.723 47.2909 119.284 46.8173 118.748 46.7971C118.219 46.7769 117.745 47.2121 117.718 47.7444C117.69 48.2796 118.121 48.7421 118.667 48.7634Z" fill="#747070" />
        <path d="M124.507 48.7652C125.039 48.742 125.475 48.2693 125.453 47.7381C125.431 47.2059 124.955 46.7737 124.421 46.7989C123.885 46.8242 123.445 47.2988 123.47 47.826C123.495 48.3562 123.972 48.7894 124.507 48.7652Z" fill="#747070" />
        <path d="M130.265 48.7652C130.797 48.741 131.233 48.2663 131.211 47.7371C131.188 47.2069 130.71 46.7727 130.177 46.7979C129.643 46.8232 129.204 47.2998 129.228 47.826C129.252 48.3562 129.731 48.7895 130.265 48.7652Z" fill="#747070" />
        <path d="M136.014 48.7653C136.546 48.7441 136.989 48.2695 136.969 47.7443C136.949 47.2161 136.472 46.7768 135.939 46.796C135.408 46.8152 134.963 47.2909 134.984 47.818C135.006 48.3513 135.48 48.7865 136.014 48.7653Z" fill="#747070" />
        <path d="M141.765 48.7653C142.297 48.7481 142.745 48.2754 142.726 47.7493C142.708 47.2231 142.23 46.7767 141.701 46.7929C141.172 46.8091 140.723 47.2847 140.74 47.8119C140.757 48.3451 141.228 48.7834 141.765 48.7653Z" fill="#747070" />
        <path d="M147.531 48.7666C148.068 48.7444 148.506 48.2718 148.481 47.7416C148.456 47.2114 147.976 46.7711 147.448 46.7933C146.921 46.8165 146.473 47.3013 146.495 47.8234C146.519 48.3516 147 48.7889 147.531 48.7666Z" fill="#747070" />
        <path d="M89.8764 11.3252C90.4121 11.3515 90.8891 10.9223 90.9164 10.3891C90.9437 9.85987 90.5081 9.38016 89.9744 9.35189C89.4438 9.32361 88.9596 9.75989 88.9334 10.2911C88.9061 10.8223 89.3397 11.298 89.8764 11.3252Z" fill="#747070" />
        <path d="M95.3379 12.0113C95.8736 12.0375 96.3506 11.6083 96.3779 11.0751C96.4052 10.5459 95.9696 10.0662 95.4359 10.0379C94.9053 10.0096 94.4211 10.4459 94.3949 10.9761C94.3666 11.5094 94.8002 11.985 95.3379 12.0113Z" fill="#747070" />
        <path d="M107.542 9.71663C107.012 9.68836 106.527 10.1246 106.501 10.6558C106.474 11.1881 106.909 11.6647 107.445 11.691C107.981 11.7173 108.458 11.288 108.485 10.7548C108.512 10.2246 108.076 9.74491 107.542 9.71663Z" fill="#747070" />
        <path d="M101.738 9.71126C101.207 9.68299 100.723 10.1193 100.697 10.6505C100.669 11.1837 101.104 11.6594 101.641 11.6856C102.176 11.7119 102.653 11.2827 102.681 10.7494C102.708 10.2192 102.272 9.73954 101.738 9.71126Z" fill="#747070" />
        <path d="M158.981 46.7932C158.453 46.8053 157.998 47.2789 158.009 47.8041C158.021 48.3323 158.492 48.7776 159.026 48.7645C159.559 48.7524 160.01 48.2848 159.997 47.7586C159.983 47.2345 159.507 46.7821 158.981 46.7932Z" fill="#747070" />
        <path d="M164.798 48.7654C165.33 48.7462 165.773 48.2726 165.754 47.7444C165.735 47.2172 165.254 46.7749 164.724 46.7951C164.193 46.8163 163.746 47.294 163.766 47.8191C163.785 48.3453 164.265 48.7856 164.798 48.7654Z" fill="#747070" />
        <path d="M170.564 46.7965C170.031 46.7703 169.552 47.2035 169.527 47.7348C169.502 48.2639 169.936 48.7396 170.469 48.7659C171.004 48.7921 171.484 48.3609 171.509 47.8307C171.535 47.3076 171.092 46.8228 170.564 46.7965Z" fill="#747070" />
        <path d="M176.311 48.7653C176.843 48.7461 177.286 48.2735 177.265 47.7453C177.245 47.2171 176.769 46.7778 176.237 46.796C175.701 46.8141 175.262 47.2847 175.283 47.818C175.306 48.3482 175.779 48.7845 176.311 48.7653Z" fill="#747070" />
        <path d="M182.004 48.7635C182.545 48.7776 183.01 48.3413 183.021 47.8081C183.032 47.2789 182.586 46.8123 182.054 46.7982C181.517 46.784 181.052 47.2234 181.041 47.7566C181.029 48.2908 181.468 48.7493 182.004 48.7635Z" fill="#747070" />
        <path d="M187.829 48.763C188.37 48.7418 188.804 48.2742 188.776 47.743C188.747 47.2098 188.273 46.7745 187.744 46.7967C187.208 46.8189 186.769 47.2936 186.796 47.8228C186.822 48.357 187.292 48.7842 187.829 48.763Z" fill="#747070" />
        <path d="M193.523 46.7998C192.989 46.8008 192.539 47.2644 192.553 47.8016C192.566 48.3409 193.02 48.7802 193.559 48.7722C194.098 48.7651 194.546 48.3066 194.535 47.7734C194.522 47.2512 194.052 46.7988 193.523 46.7998Z" fill="#747070" />
        <path d="M199.247 48.7733C199.777 48.8026 200.268 48.3653 200.292 47.8411C200.318 47.321 199.879 46.8393 199.346 46.8019C198.817 46.7656 198.334 47.1988 198.309 47.7321C198.287 48.2633 198.72 48.745 199.247 48.7733Z" fill="#747070" />
        <path d="M113.01 140.417C112.468 140.388 111.989 140.811 111.958 141.345C111.929 141.863 112.376 142.361 112.897 142.393C113.427 142.426 113.911 141.992 113.94 141.458C113.971 140.917 113.549 140.447 113.01 140.417Z" fill="#747070" />
        <path d="M107.184 140.418C106.634 140.427 106.192 140.875 106.204 141.412C106.215 141.945 106.682 142.402 107.208 142.396C107.739 142.39 108.194 141.921 108.185 141.39C108.176 140.839 107.734 140.409 107.184 140.418Z" fill="#747070" />
        <path d="M114.82 80.7098C114.794 81.241 115.229 81.7137 115.766 81.7379C116.298 81.7621 116.78 81.3259 116.804 80.7987C116.828 80.2735 116.386 79.7918 115.856 79.7676C115.324 79.7433 114.845 80.1786 114.82 80.7098Z" fill="#747070" />
        <path d="M121.605 81.7382C122.136 81.719 122.579 81.2433 122.559 80.7151C122.539 80.189 122.058 79.7456 121.529 79.7668C120.999 79.7881 120.552 80.2678 120.572 80.7919C120.593 81.3171 121.074 81.7584 121.605 81.7382Z" fill="#747070" />
        <path d="M127.278 81.738C127.811 81.7632 128.29 81.33 128.317 80.7988C128.344 80.2746 127.902 79.7929 127.37 79.7676C126.837 79.7424 126.358 80.1777 126.333 80.7069C126.309 81.2361 126.745 81.7127 127.278 81.738Z" fill="#747070" />
        <path d="M133.111 79.7706C132.575 79.7524 132.105 80.1907 132.089 80.7229C132.074 81.2521 132.517 81.7207 133.049 81.7389C133.583 81.7571 134.056 81.3177 134.073 80.7865C134.091 80.2573 133.646 79.7877 133.111 79.7706Z" fill="#747070" />
        <path d="M268.336 48.7728C268.87 48.794 269.352 48.3517 269.371 47.8255C269.389 47.3145 268.93 46.8207 268.41 46.7924C267.878 46.7631 267.416 47.1984 267.396 47.7458C267.376 48.3053 267.784 48.7516 268.336 48.7728Z" fill="#747070" />
        <path d="M274.1 48.7719C274.635 48.788 275.113 48.3437 275.127 47.8165C275.141 47.3025 274.68 46.8137 274.158 46.7904C273.626 46.7672 273.168 47.2045 273.152 47.7529C273.136 48.3134 273.547 48.7547 274.1 48.7719Z" fill="#747070" />
        <path d="M279.896 48.7706C280.436 48.7665 280.888 48.309 280.879 47.7748C280.871 47.2507 280.395 46.7831 279.874 46.7881C279.341 46.7932 278.898 47.2517 278.903 47.795C278.908 48.3464 279.344 48.7746 279.896 48.7706Z" fill="#747070" />
        <path d="M138.854 81.7379C139.39 81.7288 139.839 81.2683 139.826 80.7381C139.814 80.2079 139.349 79.7625 138.817 79.7706C138.277 79.7787 137.834 80.2362 137.846 80.7724C137.859 81.3037 138.32 81.746 138.854 81.7379Z" fill="#747070" />
        <path d="M291.421 48.7697C291.966 48.7585 292.387 48.3122 292.375 47.7587C292.362 47.2235 291.891 46.768 291.372 46.7872C290.833 46.8074 290.406 47.2659 290.418 47.8102C290.429 48.3596 290.871 48.7818 291.421 48.7697Z" fill="#747070" />
        <path d="M297.089 46.7874C296.553 46.8308 296.143 47.3085 296.175 47.8498C296.208 48.3951 296.668 48.8011 297.216 48.7678C297.759 48.7345 298.161 48.2729 298.127 47.7205C298.096 47.1893 297.601 46.7459 297.089 46.7874Z" fill="#747070" />
        <path d="M302.957 46.7908C302.419 46.7696 301.958 47.1958 301.934 47.7371C301.908 48.2935 302.313 48.746 302.861 48.7743C303.397 48.8025 303.857 48.3784 303.882 47.831C303.908 47.2857 303.484 46.811 302.957 46.7908Z" fill="#747070" />
        <path d="M196.371 164.023C195.828 164.036 195.398 164.493 195.416 165.039C195.433 165.586 195.885 166.013 196.429 165.998C196.964 165.982 197.41 165.514 197.395 164.983C197.378 164.452 196.906 164.01 196.371 164.023Z" fill="#747070" />
        <path d="M144.636 81.7376C145.178 81.7164 145.61 81.2519 145.583 80.7176C145.557 80.1925 145.079 79.7572 144.547 79.7713C144.018 79.7855 143.575 80.2672 143.603 80.7974C143.63 81.3276 144.103 81.7588 144.636 81.7376Z" fill="#747070" />
        <path d="M151.342 80.7873C151.353 80.2652 150.906 79.7976 150.372 79.7734C149.839 79.7492 149.368 80.1915 149.358 80.7247C149.347 81.261 149.783 81.7195 150.32 81.7357C150.861 81.7518 151.331 81.3155 151.342 80.7873Z" fill="#747070" />
        <path d="M156.056 81.7449C156.586 81.7732 157.073 81.3369 157.099 80.8117C157.124 80.2906 156.686 79.8119 156.152 79.7756C155.622 79.7392 155.139 80.1745 155.116 80.7077C155.093 81.2389 155.525 81.7156 156.056 81.7449Z" fill="#747070" />
        <path d="M162.854 80.7942C162.872 80.2731 162.427 79.8014 161.892 79.7742C161.357 79.7469 160.888 80.1842 160.874 80.7225C160.86 81.2537 161.298 81.7233 161.83 81.7445C162.363 81.7657 162.836 81.3274 162.854 80.7942Z" fill="#747070" />
        <path d="M167.66 81.7427C168.194 81.7235 168.634 81.2489 168.611 80.7177C168.588 80.2077 168.094 79.7552 167.572 79.7683C167.045 79.7815 166.603 80.2632 166.627 80.7974C166.65 81.3256 167.126 81.7609 167.66 81.7427Z" fill="#747070" />
        <path d="M173.397 81.7439C173.934 81.7348 174.381 81.2712 174.368 80.738C174.355 80.226 173.873 79.7695 173.346 79.7705C172.817 79.7715 172.371 80.2391 172.384 80.7794C172.399 81.3167 172.857 81.753 173.397 81.7439Z" fill="#747070" />
        <path d="M95.7123 86.2097C96.2672 86.1915 96.6857 85.7492 96.6685 85.1968C96.6523 84.6544 96.1894 84.2192 95.6496 84.2363C95.1291 84.2535 94.6703 84.7403 94.6844 85.2614C94.6986 85.7966 95.1655 86.2269 95.7123 86.2097Z" fill="#747070" />
        <path d="M184.899 79.7679C184.368 79.7457 183.911 80.19 183.901 80.7394C183.89 81.2888 184.318 81.7332 184.869 81.7453C185.404 81.7574 185.877 81.307 185.885 80.7778C185.892 80.2658 185.428 79.7901 184.899 79.7679Z" fill="#747070" />
        <path d="M52.4908 53.6676C53.0286 53.6666 53.4793 53.2111 53.4763 52.6729C53.4723 52.1164 53.0437 51.6953 52.4838 51.6963C51.9329 51.6983 51.4862 52.1406 51.4922 52.6779C51.4973 53.2031 51.9673 53.6686 52.4908 53.6676Z" fill="#747070" />
        <path d="M58.182 53.6671C58.7156 53.7014 59.1987 53.2732 59.2311 52.7369C59.2644 52.1896 58.8551 51.728 58.3053 51.6957C57.7585 51.6624 57.2763 52.0795 57.245 52.6127C57.2157 53.1227 57.6695 53.6347 58.182 53.6671Z" fill="#747070" />
        <path d="M63.9628 51.696C63.415 51.7162 62.9864 52.1737 63.0097 52.713C63.0319 53.2382 63.5191 53.6896 64.0396 53.6674C64.5693 53.6451 65.015 53.1634 64.9938 52.6362C64.9715 52.0869 64.5207 51.6758 63.9628 51.696Z" fill="#747070" />
        <path d="M69.7457 53.6678C70.2793 53.6728 70.7412 53.2214 70.7483 52.6882C70.7554 52.1388 70.3279 51.7055 69.7699 51.6964C69.2211 51.6873 68.7652 52.1246 68.7622 52.6619C68.7602 53.185 69.2271 53.6627 69.7457 53.6678Z" fill="#747070" />
        <path d="M74.5207 52.6325C74.4995 53.1516 74.9513 53.6454 75.4728 53.6707C75.9994 53.6969 76.4785 53.2546 76.5038 52.7204C76.5291 52.176 76.1086 51.7206 75.5588 51.6963C75.0089 51.6721 74.544 52.0912 74.5207 52.6325Z" fill="#747070" />
        <path d="M81.3198 53.6734C81.8403 53.6491 82.2901 53.1512 82.2638 52.6291C82.2365 52.0838 81.7746 51.6697 81.2227 51.6949C80.6769 51.7202 80.2514 52.1847 80.2807 52.724C80.31 53.2472 80.8023 53.6976 81.3198 53.6734Z" fill="#747070" />
        <path d="M87.0217 51.6939C86.4728 51.6979 86.0301 52.1413 86.0362 52.6826C86.0423 53.2067 86.5153 53.6743 87.0368 53.6713C87.5624 53.6682 88.0243 53.1976 88.0203 52.6694C88.0152 52.1201 87.5745 51.6898 87.0217 51.6939Z" fill="#747070" />
        <path d="M190.66 79.7676C190.129 79.7433 189.671 80.1826 189.657 80.733C189.642 81.2824 190.066 81.7288 190.617 81.745C191.152 81.7601 191.623 81.3158 191.636 80.7835C191.647 80.2654 191.193 79.7918 190.66 79.7676Z" fill="#747070" />
        <path d="M196.414 81.7436C196.953 81.7376 197.403 81.2791 197.393 80.7438C197.384 80.2369 196.899 79.7672 196.379 79.7632C195.852 79.7592 195.408 80.2227 195.415 80.7711C195.422 81.3205 195.862 81.7497 196.414 81.7436Z" fill="#747070" />
        <path d="M202.091 79.7596C201.573 79.7818 201.142 80.2797 201.171 80.819C201.201 81.3593 201.671 81.7753 202.215 81.743C202.751 81.7117 203.183 81.227 203.147 80.6998C203.113 80.1938 202.6 79.7373 202.091 79.7596Z" fill="#747070" />
        <path d="M207.949 79.7677C207.417 79.7323 206.957 80.1615 206.931 80.716C206.906 81.2674 207.319 81.7218 207.87 81.7461C208.399 81.7693 208.887 81.3249 208.909 80.8008C208.932 80.2908 208.477 79.802 207.949 79.7677Z" fill="#747070" />
        <path d="M213.613 81.7472C214.143 81.7775 214.63 81.3423 214.661 80.8111C214.692 80.299 214.248 79.8072 213.718 79.7668C213.185 79.7264 212.717 80.1516 212.688 80.701C212.66 81.2564 213.065 81.7149 213.613 81.7472Z" fill="#747070" />
        <path d="M219.452 79.7642C218.92 79.739 218.462 80.1763 218.446 80.7246C218.43 81.2841 218.842 81.7275 219.394 81.7457C219.927 81.7628 220.399 81.3215 220.418 80.7872C220.436 80.2732 219.979 79.7895 219.452 79.7642Z" fill="#747070" />
        <path d="M225.207 79.7642C224.677 79.739 224.218 80.1773 224.203 80.7246C224.186 81.2851 224.598 81.7265 225.151 81.7446C225.683 81.7618 226.162 81.3154 226.178 80.7893C226.192 80.2793 225.728 79.7895 225.207 79.7642Z" fill="#747070" />
        <path d="M230.936 81.7426C231.472 81.7437 231.934 81.2882 231.933 80.76C231.932 80.245 231.455 79.7683 230.938 79.7632C230.401 79.7582 229.96 80.2056 229.961 80.7549C229.96 81.3144 230.381 81.7416 230.936 81.7426Z" fill="#747070" />
        <path d="M138.808 51.6937C138.257 51.7089 137.83 52.1573 137.846 52.7037C137.861 53.2359 138.335 53.6873 138.861 53.6711C139.386 53.654 139.842 53.1723 139.829 52.6491C139.816 52.1068 139.357 51.6786 138.808 51.6937Z" fill="#747070" />
        <path d="M144.602 53.6718C145.125 53.6637 145.588 53.189 145.584 52.6649C145.58 52.1226 145.13 51.6883 144.581 51.6964C144.025 51.7045 143.595 52.1408 143.602 52.6891C143.608 53.2224 144.074 53.6799 144.602 53.6718Z" fill="#747070" />
        <path d="M150.302 51.6965C149.751 51.7227 149.335 52.1772 149.361 52.7246C149.386 53.2588 149.864 53.6941 150.398 53.6678C150.917 53.6426 151.374 53.1427 151.348 52.6286C151.321 52.0893 150.85 51.6702 150.302 51.6965Z" fill="#747070" />
        <path d="M156.076 51.6967C155.52 51.7139 155.101 52.1562 155.118 52.7056C155.134 53.2419 155.602 53.6832 156.137 53.6681C156.66 53.6529 157.118 53.1672 157.102 52.645C157.086 52.1057 156.626 51.6806 156.076 51.6967Z" fill="#747070" />
        <path d="M161.85 53.6677C162.373 53.6748 162.846 53.2132 162.857 52.6861C162.868 52.1498 162.425 51.7024 161.874 51.6964C161.315 51.6903 160.878 52.1114 160.871 52.6638C160.864 53.1971 161.315 53.6606 161.85 53.6677Z" fill="#747070" />
        <path d="M167.589 53.6677C168.11 53.6848 168.591 53.2314 168.612 52.7042C168.634 52.1679 168.2 51.7125 167.65 51.6963C167.09 51.6792 166.648 52.0902 166.629 52.6446C166.612 53.1809 167.052 53.6505 167.589 53.6677Z" fill="#747070" />
        <path d="M101.454 86.2093C102.012 86.1992 102.436 85.7639 102.427 85.2115C102.418 84.6773 101.954 84.2279 101.419 84.2359C100.896 84.244 100.436 84.7197 100.441 85.2469C100.448 85.7841 100.906 86.2194 101.454 86.2093Z" fill="#747070" />
        <path d="M243.429 80.7046C243.402 80.1804 242.907 79.729 242.393 79.7603C241.858 79.7937 241.438 80.2673 241.465 80.8106C241.491 81.3519 241.953 81.767 242.499 81.7408C243.039 81.7135 243.458 81.2479 243.429 80.7046Z" fill="#747070" />
        <path d="M248.203 79.7627C247.664 79.7647 247.225 80.2101 247.224 80.7564C247.223 81.3068 247.652 81.7401 248.201 81.7441C248.743 81.7472 249.18 81.3079 249.182 80.7575C249.183 80.2212 248.73 79.7607 248.203 79.7627Z" fill="#747070" />
        <path d="M254.935 80.7471C254.931 80.2068 254.475 79.7493 253.951 79.7615C253.412 79.7746 252.977 80.226 252.981 80.7683C252.986 81.3187 253.419 81.7469 253.969 81.7439C254.512 81.7409 254.939 81.3006 254.935 80.7471Z" fill="#747070" />
        <path d="M259.773 79.7638C259.235 79.7385 258.769 80.1607 258.739 80.6999C258.709 81.2534 259.113 81.7139 259.657 81.7482C260.191 81.7816 260.66 81.3574 260.689 80.8141C260.719 80.2687 260.3 79.788 259.773 79.7638Z" fill="#747070" />
        <path d="M107.189 86.2102C107.747 86.2143 108.18 85.7891 108.184 85.2347C108.188 84.7024 107.733 84.2399 107.2 84.2359C106.677 84.2318 106.203 84.7004 106.199 85.2236C106.195 85.7639 106.639 86.2062 107.189 86.2102Z" fill="#747070" />
        <path d="M92.7542 89.1396C92.2205 89.1547 91.7748 89.6243 91.792 90.1515C91.8092 90.6797 92.2852 91.1281 92.8118 91.1119C93.3435 91.0958 93.7922 90.6221 93.776 90.095C93.7599 89.5627 93.2899 89.1234 92.7542 89.1396Z" fill="#747070" />
        <path d="M190.686 164.026C190.146 163.998 189.682 164.423 189.657 164.966C189.632 165.511 190.053 165.971 190.601 165.998C191.128 166.024 191.614 165.583 191.64 165.053C191.666 164.53 191.223 164.053 190.686 164.026Z" fill="#747070" />
        <path d="M87.0663 89.1414C86.5276 89.1202 86.0556 89.5514 86.0344 90.0826C86.0141 90.6108 86.4569 91.0854 86.9875 91.1066C87.5232 91.1279 87.9962 90.6926 88.0164 90.1624C88.0376 89.6302 87.602 89.1626 87.0663 89.1414Z" fill="#747070" />
        <path d="M81.2414 91.1072C81.7781 91.1233 82.245 90.685 82.2592 90.1518C82.2734 89.6216 81.8327 89.155 81.299 89.1368C80.7623 89.1176 80.2933 89.557 80.2782 90.0902C80.264 90.6214 80.7087 91.091 81.2414 91.1072Z" fill="#747070" />
        <path d="M75.4846 91.1067C76.0243 91.1238 76.4913 90.6865 76.5034 90.1513C76.5165 89.616 76.0769 89.1505 75.5442 89.1333C75.0075 89.1161 74.5375 89.5565 74.5223 90.0887C74.5072 90.6199 74.9519 91.0905 75.4846 91.1067Z" fill="#747070" />
        <path d="M184.921 164.029C184.386 163.996 183.92 164.425 183.9 164.971C183.879 165.516 184.301 165.973 184.847 165.998C185.375 166.022 185.856 165.583 185.882 165.052C185.907 164.541 185.456 164.061 184.921 164.029Z" fill="#747070" />
        <path d="M179.165 164.029C178.629 163.998 178.163 164.428 178.143 164.971C178.124 165.516 178.547 165.973 179.091 165.998C179.62 166.023 180.099 165.583 180.124 165.051C180.15 164.54 179.699 164.06 179.165 164.029Z" fill="#747070" />
        <path d="M173.329 164.024C172.801 164.039 172.361 164.518 172.384 165.051C172.408 165.584 172.877 166.013 173.414 165.993C173.953 165.973 174.384 165.508 174.363 164.972C174.344 164.453 173.859 164.009 173.329 164.024Z" fill="#747070" />
        <path d="M176.418 168.003C175.889 168.018 175.45 168.497 175.473 169.03C175.497 169.562 175.966 169.991 176.503 169.971C177.042 169.952 177.473 169.486 177.453 168.95C177.433 168.431 176.948 167.988 176.418 168.003Z" fill="#747070" />
        <path d="M167.587 165.993C168.125 166.012 168.591 165.579 168.609 165.042C168.627 164.524 168.178 164.05 167.645 164.026C167.115 164.002 166.645 164.442 166.629 164.977C166.613 165.514 167.047 165.974 167.587 165.993Z" fill="#747070" />
        <path d="M161.889 164.026C161.36 164.002 160.886 164.445 160.872 164.977C160.857 165.511 161.293 165.974 161.831 165.993C162.37 166.012 162.835 165.58 162.853 165.041C162.87 164.522 162.422 164.05 161.889 164.026Z" fill="#747070" />
        <path d="M155.116 165.053C155.142 165.587 155.613 166.014 156.152 165.992C156.689 165.97 157.121 165.499 157.098 164.965C157.075 164.447 156.59 164.007 156.057 164.022C155.537 164.036 155.09 164.527 155.116 165.053Z" fill="#747070" />
        <path d="M101.404 140.418C100.855 140.439 100.42 140.899 100.445 141.433C100.469 141.963 100.95 142.414 101.472 142.396C102.003 142.378 102.449 141.899 102.429 141.368C102.409 140.819 101.954 140.397 101.404 140.418Z" fill="#747070" />
        <path d="M95.6411 140.419C95.0933 140.444 94.6587 140.91 94.689 141.441C94.7193 141.97 95.2065 142.42 95.724 142.398C96.2526 142.375 96.6973 141.89 96.6731 141.361C96.6488 140.809 96.193 140.393 95.6411 140.419Z" fill="#747070" />
        <path d="M3.56421 58.1439C4.10596 58.1489 4.55069 57.7147 4.5618 57.1703C4.57292 56.6118 4.15852 56.1746 3.60666 56.1614C3.06794 56.1483 2.6222 56.5815 2.61108 57.1289C2.59896 57.6712 3.03863 58.1388 3.56421 58.1439Z" fill="#747070" />
        <path d="M9.39344 56.1592C8.85978 56.1289 8.39181 56.5551 8.36452 57.0984C8.33723 57.6367 8.76578 58.1185 9.29136 58.1427C9.82705 58.1669 10.291 57.7407 10.3193 57.1994C10.3486 56.647 9.94126 56.1895 9.39344 56.1592Z" fill="#747070" />
        <path d="M15.118 56.1614C14.5772 56.1483 14.1305 56.5815 14.1193 57.1289C14.1082 57.6652 14.555 58.1378 15.0775 58.1439C15.6152 58.1499 16.064 57.7116 16.0771 57.1673C16.0903 56.6159 15.6698 56.1746 15.118 56.1614Z" fill="#747070" />
        <path d="M68.7664 90.1507C68.7886 90.685 69.2596 91.1253 69.7902 91.1091C70.329 91.092 70.7686 90.6234 70.7474 90.0891C70.7262 89.5478 70.2643 89.1176 69.7235 89.1348C69.1828 89.1519 68.7451 89.6165 68.7664 90.1507Z" fill="#747070" />
        <path d="M27.5913 57.076C27.5529 56.5337 27.0819 56.1277 26.5361 56.1661C25.9974 56.2035 25.5719 56.6902 25.6154 57.2184C25.6568 57.7234 26.1814 58.1788 26.6837 58.1455C27.2083 58.1102 27.6298 57.6143 27.5913 57.076Z" fill="#747070" />
        <path d="M32.3663 56.1631C31.8275 56.1611 31.3666 56.6145 31.3687 57.1437C31.3707 57.6588 31.8437 58.1344 32.3642 58.1435C32.8979 58.1526 33.3416 57.7032 33.3426 57.1528C33.3426 56.5913 32.9222 56.1641 32.3663 56.1631Z" fill="#747070" />
        <path d="M37.1241 57.1739C37.1423 57.6859 37.6355 58.1535 38.15 58.1454C38.6836 58.1363 39.1132 57.6728 39.098 57.1224C39.0819 56.5649 38.6452 56.1488 38.0914 56.164C37.5476 56.1781 37.1059 56.6396 37.1241 57.1739Z" fill="#747070" />
        <path d="M42.8836 57.2096C42.9149 57.7135 43.4324 58.173 43.9388 58.1478C44.4684 58.1215 44.8879 57.6368 44.8545 57.0904C44.8212 56.539 44.3663 56.134 43.8145 56.1664C43.2747 56.1977 42.8512 56.6723 42.8836 57.2096Z" fill="#747070" />
        <path d="M49.5841 58.1395C50.1157 58.1768 50.5867 57.7476 50.613 57.2023C50.6403 56.6468 50.233 56.1913 49.6841 56.161C49.1565 56.1318 48.6623 56.5721 48.634 57.0962C48.6067 57.6012 49.0625 58.1021 49.5841 58.1395Z" fill="#747070" />
        <path d="M55.4444 58.1463C55.9659 58.1261 56.3975 57.6363 56.3722 57.095C56.3469 56.5517 55.883 56.1356 55.3362 56.1629C54.7985 56.1902 54.3639 56.6689 54.3962 57.1991C54.4265 57.704 54.938 58.1665 55.4444 58.1463Z" fill="#747070" />
        <path d="M63.9575 89.1336C63.4198 89.1559 62.9842 89.6275 63.0115 90.1587C63.0377 90.6818 63.5219 91.1262 64.0475 91.111C64.572 91.0959 65.0178 90.6071 64.9905 90.0769C64.9632 89.5396 64.4942 89.1114 63.9575 89.1336Z" fill="#747070" />
        <path d="M58.2215 91.1066C58.7491 91.1309 59.2231 90.6815 59.2342 90.1462C59.2464 89.612 58.8057 89.1474 58.271 89.1323C57.7323 89.1161 57.2633 89.5564 57.2512 90.0887C57.2401 90.6108 57.6898 91.0824 58.2215 91.1066Z" fill="#747070" />
        <path d="M52.4454 89.1326C51.9097 89.1548 51.469 89.6295 51.4953 90.1576C51.5215 90.6707 52.0178 91.1272 52.5333 91.112C53.0589 91.0969 53.5056 90.6081 53.4783 90.0779C53.452 89.5396 52.9831 89.1114 52.4454 89.1326Z" fill="#747070" />
        <path d="M112.894 84.2365C112.384 84.2668 111.924 84.7808 111.953 85.2878C111.984 85.822 112.464 86.2411 113.011 86.2098C113.56 86.1785 113.972 85.717 113.941 85.1706C113.908 84.6354 113.427 84.2052 112.894 84.2365Z" fill="#747070" />
        <path d="M119.701 85.2532C119.715 84.726 119.263 84.2523 118.733 84.2372C118.211 84.222 117.733 84.6775 117.718 85.2047C117.703 85.746 118.134 86.1944 118.685 86.2095C119.238 86.2237 119.687 85.8015 119.701 85.2532Z" fill="#747070" />
        <path d="M125.457 85.2449C125.467 84.7147 125.014 84.2461 124.483 84.236C123.961 84.2259 123.485 84.6864 123.474 85.2116C123.463 85.7529 123.898 86.1983 124.449 86.2084C125.003 86.2185 125.447 85.7943 125.457 85.2449Z" fill="#747070" />
        <path d="M130.242 84.2326C129.721 84.2235 129.242 84.686 129.23 85.2101C129.217 85.7504 129.653 86.1988 130.203 86.2099C130.757 86.2221 131.203 85.7969 131.213 85.2475C131.223 84.7193 130.765 84.2416 130.242 84.2326Z" fill="#747070" />
        <path d="M100.445 57.1214C100.436 57.6445 100.888 58.1121 101.419 58.1313C101.955 58.1505 102.42 57.7062 102.426 57.1689C102.432 56.6346 101.988 56.1721 101.458 56.159C100.924 56.1458 100.454 56.5922 100.445 57.1214Z" fill="#747070" />
        <path d="M107.218 58.1332C107.749 58.1312 108.2 57.6616 108.184 57.1273C108.169 56.589 107.71 56.1497 107.175 56.1598C106.636 56.1699 106.191 56.6294 106.204 57.1627C106.218 57.6858 106.688 58.1362 107.218 58.1332Z" fill="#747070" />
        <path d="M112.925 56.1692C112.376 56.1813 111.942 56.6317 111.96 57.17C111.977 57.6931 112.448 58.1375 112.979 58.1325C113.512 58.1274 113.958 57.6588 113.941 57.1256C113.924 56.5903 113.465 56.1571 112.925 56.1692Z" fill="#747070" />
        <path d="M118.727 58.1354C119.265 58.1263 119.711 57.6668 119.698 57.1326C119.685 56.5953 119.228 56.159 118.688 56.1691C118.141 56.1792 117.702 56.6306 117.717 57.1669C117.732 57.7001 118.195 58.1445 118.727 58.1354Z" fill="#747070" />
        <path d="M124.428 56.1666C123.896 56.1847 123.453 56.6574 123.471 57.1845C123.489 57.7127 123.965 58.152 124.499 58.1349C125.035 58.1177 125.474 57.6481 125.454 57.1149C125.434 56.5847 124.962 56.1484 124.428 56.1666Z" fill="#747070" />
        <path d="M130.186 56.1685C129.654 56.1867 129.21 56.6573 129.229 57.1855C129.248 57.7137 129.726 58.158 130.255 58.1388C130.791 58.1197 131.233 57.648 131.213 57.1188C131.193 56.5876 130.72 56.1503 130.186 56.1685Z" fill="#747070" />
        <path d="M135.953 56.1672C135.422 56.1804 134.973 56.6479 134.985 57.1761C134.997 57.7033 135.47 58.1517 136.001 58.1376C136.534 58.1244 136.984 57.6548 136.971 57.1277C136.958 56.5985 136.487 56.1551 135.953 56.1672Z" fill="#747070" />
        <path d="M141.754 58.1375C142.286 58.1264 142.739 57.6588 142.728 57.1316C142.718 56.6044 142.247 56.1571 141.713 56.1672C141.182 56.1773 140.73 56.6438 140.741 57.171C140.752 57.7002 141.221 58.1476 141.754 58.1375Z" fill="#747070" />
        <path d="M179.128 137.905C179.658 137.907 180.121 137.451 180.124 136.924C180.127 136.392 179.676 135.947 179.132 135.945C178.589 135.943 178.148 136.38 178.143 136.924C178.138 137.466 178.578 137.904 179.128 137.905Z" fill="#747070" />
        <path d="M173.317 135.944C172.781 135.973 172.361 136.444 172.391 136.985C172.42 137.525 172.888 137.938 173.435 137.906C173.963 137.875 174.41 137.382 174.377 136.866C174.343 136.341 173.854 135.915 173.317 135.944Z" fill="#747070" />
        <path d="M158.97 56.1666C158.437 56.1858 157.991 56.6595 158.011 57.1846C158.032 57.7118 158.511 58.1571 159.038 58.139C159.566 58.1208 160.016 57.6411 159.998 57.1159C159.978 56.5867 159.503 56.1474 158.97 56.1666Z" fill="#747070" />
        <path d="M164.73 58.1372C165.26 58.1544 165.737 57.709 165.753 57.1818C165.769 56.6526 165.323 56.183 164.79 56.1669C164.259 56.1517 163.781 56.5951 163.765 57.1202C163.748 57.6464 164.198 58.12 164.73 58.1372Z" fill="#747070" />
        <path d="M170.484 56.1666C169.951 56.1847 169.509 56.6554 169.527 57.1835C169.544 57.7147 170.016 58.153 170.551 58.1359C171.082 58.1187 171.527 57.6461 171.51 57.1169C171.492 56.5867 171.019 56.1484 170.484 56.1666Z" fill="#747070" />
        <path d="M176.229 56.1671C175.697 56.1923 175.261 56.667 175.283 57.1962C175.306 57.7264 175.783 58.1596 176.317 58.1334C176.851 58.1081 177.291 57.6294 177.266 57.1053C177.241 56.5741 176.763 56.1418 176.229 56.1671Z" fill="#747070" />
        <path d="M182.001 56.1699C181.463 56.1861 181.026 56.6466 181.039 57.1819C181.052 57.7141 181.52 58.1514 182.058 58.1342C182.591 58.118 183.037 57.6494 183.022 57.1203C183.008 56.587 182.541 56.1528 182.001 56.1699Z" fill="#747070" />
        <path d="M187.765 58.1354C188.296 58.1455 188.76 57.7022 188.776 57.1689C188.792 56.6306 188.356 56.1802 187.807 56.1691C187.266 56.159 186.81 56.5923 186.796 57.1316C186.781 57.6648 187.226 58.1253 187.765 58.1354Z" fill="#747070" />
        <path d="M192.553 57.1099C192.527 57.6401 192.97 58.1208 193.499 58.135C194.026 58.1491 194.507 57.7078 194.533 57.1867C194.559 56.6545 194.127 56.1899 193.584 56.1697C193.049 56.1485 192.579 56.5767 192.553 57.1099Z" fill="#747070" />
        <path d="M199.326 58.1315C199.865 58.1063 200.307 57.6417 200.292 57.1136C200.278 56.5844 199.804 56.142 199.269 56.1602C198.74 56.1774 198.298 56.647 198.31 57.1792C198.321 57.7155 198.791 58.1558 199.326 58.1315Z" fill="#747070" />
        <path d="M204.065 57.1029C204.036 57.629 204.484 58.1239 205.006 58.1401C205.531 58.1562 206.02 57.7108 206.045 57.1907C206.071 56.6605 205.636 56.1849 205.102 56.1606C204.568 56.1364 204.094 56.5666 204.065 57.1029Z" fill="#747070" />
        <path d="M182.002 84.2363C181.473 84.2524 181.023 84.7281 181.037 85.2553C181.052 85.8047 181.501 86.2248 182.055 86.2086C182.606 86.1924 183.036 85.7441 183.019 85.2017C183.003 84.6756 182.523 84.2201 182.002 84.2363Z" fill="#747070" />
        <path d="M188.778 85.2561C188.791 84.732 188.334 84.2493 187.811 84.2361C187.278 84.223 186.809 84.6704 186.795 85.2026C186.781 85.75 187.207 86.1953 187.757 86.2105C188.307 86.2246 188.765 85.7964 188.778 85.2561Z" fill="#747070" />
        <path d="M193.522 84.2361C192.994 84.2492 192.542 84.7219 192.552 85.2511C192.562 85.7995 193.008 86.2216 193.564 86.2105C194.111 86.1994 194.551 85.7469 194.536 85.2097C194.521 84.6835 194.041 84.223 193.522 84.2361Z" fill="#747070" />
        <path d="M199.356 84.2374C198.822 84.2061 198.342 84.6364 198.313 85.1726C198.282 85.72 198.694 86.1785 199.243 86.2088C199.791 86.2391 200.265 85.824 200.297 85.2857C200.325 84.7717 199.873 84.2677 199.356 84.2374Z" fill="#747070" />
        <path d="M167.665 137.905C168.193 137.881 168.648 137.391 168.618 136.878C168.589 136.345 168.114 135.922 167.572 135.943C167.032 135.965 166.61 136.428 166.632 136.97C166.655 137.512 167.117 137.931 167.665 137.905Z" fill="#747070" />
        <path d="M268.328 56.1598C267.777 56.1871 267.373 56.6365 267.398 57.196C267.421 57.7413 267.889 58.1736 268.419 58.1392C268.937 58.1059 269.396 57.606 269.373 57.099C269.348 56.5739 268.859 56.1336 268.328 56.1598Z" fill="#747070" />
        <path d="M274.176 58.1402C274.693 58.1079 275.151 57.607 275.129 57.099C275.106 56.5739 274.615 56.1336 274.085 56.1598C273.533 56.1871 273.13 56.6375 273.154 57.196C273.177 57.7413 273.645 58.1736 274.176 58.1402Z" fill="#747070" />
        <path d="M205.093 86.2099C205.641 86.1907 206.074 85.7312 206.049 85.1949C206.023 84.6667 205.54 84.2163 205.02 84.2365C204.485 84.2577 204.043 84.7334 204.064 85.2656C204.084 85.814 204.538 86.229 205.093 86.2099Z" fill="#747070" />
        <path d="M285.648 58.1436C286.172 58.1436 286.626 57.6771 286.622 57.1408C286.618 56.5914 286.176 56.1551 285.633 56.1612C285.081 56.1673 284.654 56.6035 284.66 57.1549C284.665 57.6993 285.11 58.1436 285.648 58.1436Z" fill="#747070" />
        <path d="M291.392 58.1441C291.918 58.1511 292.373 57.6916 292.374 57.1523C292.375 56.5979 291.944 56.1606 291.399 56.1616C290.848 56.1626 290.418 56.5918 290.417 57.1433C290.417 57.6886 290.854 58.136 291.392 58.1441Z" fill="#747070" />
        <path d="M297.199 56.164C296.652 56.1408 296.199 56.5549 296.176 57.1002C296.153 57.6415 296.571 58.1111 297.108 58.1455C297.623 58.1778 298.106 57.7274 298.129 57.1951C298.151 56.6417 297.742 56.1872 297.199 56.164Z" fill="#747070" />
        <path d="M161.858 135.945C161.317 135.947 160.873 136.39 160.872 136.929C160.871 137.467 161.32 137.906 161.868 137.904C162.404 137.902 162.862 137.447 162.858 136.918C162.854 136.385 162.4 135.943 161.858 135.945Z" fill="#747070" />
        <path d="M35.1757 14.2509C34.6319 14.2721 34.2033 14.7326 34.2286 15.2678C34.2539 15.8061 34.7198 16.2262 35.2636 16.202C35.8124 16.1778 36.2349 15.7223 36.2096 15.183C36.1844 14.6387 35.7295 14.2286 35.1757 14.2509Z" fill="#747070" />
        <path d="M40.9177 14.2513C40.3719 14.2836 39.9463 14.7532 39.9837 15.2814C40.0232 15.8258 40.4881 16.2327 41.0349 16.2014C41.5797 16.1701 41.9991 15.7046 41.9668 15.1673C41.9324 14.626 41.4675 14.219 40.9177 14.2513Z" fill="#747070" />
        <path d="M46.7324 16.2006C47.2883 16.1995 47.7229 15.7724 47.7209 15.226C47.7199 14.6756 47.2903 14.2545 46.7314 14.2524C46.1775 14.2514 45.7358 14.6827 45.7368 15.224C45.7378 15.7612 46.1866 16.2016 46.7324 16.2006Z" fill="#747070" />
        <path d="M52.5374 16.2009C53.0821 16.1746 53.5097 15.7111 53.4804 15.1758C53.4511 14.6264 52.9972 14.2245 52.4383 14.2507C51.8905 14.277 51.468 14.7345 51.4933 15.2738C51.5185 15.806 51.9926 16.2271 52.5374 16.2009Z" fill="#747070" />
        <path d="M179.146 156.625C179.693 156.617 180.134 156.17 180.123 155.636C180.113 155.103 179.654 154.664 179.116 154.673C178.563 154.684 178.131 155.122 178.142 155.662C178.154 156.208 178.597 156.633 179.146 156.625Z" fill="#747070" />
        <path d="M173.382 154.673C172.827 154.669 172.387 155.098 172.387 155.641C172.387 156.184 172.824 156.621 173.37 156.626C173.914 156.63 174.371 156.189 174.371 155.658C174.371 155.127 173.919 154.676 173.382 154.673Z" fill="#747070" />
        <path d="M167.685 154.673C167.135 154.639 166.667 155.045 166.635 155.584C166.602 156.125 167.018 156.595 167.555 156.628C168.093 156.66 168.579 156.243 168.62 155.714C168.659 155.201 168.214 154.707 167.685 154.673Z" fill="#747070" />
        <path d="M161.908 156.627C162.452 156.6 162.879 156.138 162.855 155.605C162.83 155.072 162.358 154.647 161.817 154.672C161.276 154.697 160.84 155.167 160.869 155.694C160.897 156.232 161.367 156.653 161.908 156.627Z" fill="#747070" />
        <path d="M156.107 154.672C155.558 154.671 155.112 155.111 155.115 155.647C155.118 156.187 155.561 156.627 156.105 156.627C156.657 156.627 157.099 156.194 157.101 155.652C157.101 155.12 156.649 154.673 156.107 154.672Z" fill="#747070" />
        <path d="M150.384 156.627C150.934 156.607 151.36 156.156 151.341 155.615C151.321 155.08 150.854 154.653 150.313 154.672C149.77 154.692 149.329 155.157 149.355 155.683C149.382 156.225 149.842 156.647 150.384 156.627Z" fill="#747070" />
        <path d="M205.011 142.397C205.528 142.421 206.018 141.971 206.049 141.444C206.08 140.911 205.647 140.444 205.1 140.419C204.55 140.392 204.09 140.809 204.064 141.357C204.038 141.885 204.482 142.373 205.011 142.397Z" fill="#747070" />
        <path d="M199.31 140.418C198.761 140.41 198.317 140.841 198.309 141.391C198.301 141.923 198.755 142.391 199.287 142.396C199.81 142.401 200.282 141.94 200.294 141.411C200.306 140.878 199.858 140.426 199.31 140.418Z" fill="#747070" />
        <path d="M193.508 142.395C194.034 142.41 194.513 141.959 194.531 141.43C194.55 140.887 194.121 140.434 193.572 140.417C193.021 140.399 192.571 140.819 192.55 141.371C192.53 141.901 192.978 142.38 193.508 142.395Z" fill="#747070" />
        <path d="M187.744 140.418C187.199 140.439 186.775 140.897 186.797 141.442C186.818 141.976 187.294 142.417 187.825 142.394C188.351 142.371 188.799 141.884 188.779 141.361C188.757 140.822 188.291 140.397 187.744 140.418Z" fill="#747070" />
        <path d="M32.3751 1.95527C32.9341 1.94921 33.3444 1.52909 33.3404 0.968589C33.3364 0.4091 32.9139 -0.0069806 32.3559 8.87415e-05C31.8152 0.00614818 31.3705 0.456567 31.3755 0.991818C31.3816 1.53616 31.8223 1.96133 32.3751 1.95527Z" fill="#747070" />
        <path d="M31.378 103.965C31.3861 104.503 31.8389 104.943 32.3786 104.936C32.9325 104.929 33.3489 104.499 33.3419 103.942C33.3338 103.382 32.9133 102.973 32.3524 102.979C31.7975 102.985 31.3709 103.417 31.378 103.965Z" fill="#747070" />
        <path d="M38.0609 102.981C37.5131 103.013 37.0906 103.477 37.125 104.01C37.1584 104.533 37.6506 104.967 38.1772 104.939C38.724 104.91 39.1333 104.45 39.101 103.897C39.0707 103.347 38.6189 102.95 38.0609 102.981Z" fill="#747070" />
        <path d="M20.8418 11.3316C21.3866 11.3377 21.8273 10.9014 21.8344 10.352C21.8414 9.79962 21.4119 9.35627 20.8641 9.3492C20.3354 9.34213 19.8665 9.80164 19.8604 10.3298C19.8553 10.857 20.3142 11.3256 20.8418 11.3316Z" fill="#747070" />
        <path d="M26.6495 11.3343C27.1943 11.3101 27.6147 10.8476 27.5915 10.2992C27.5683 9.74677 27.1114 9.32261 26.5707 9.34988C26.036 9.37714 25.5943 9.85483 25.6165 10.38C25.6388 10.9082 26.1249 11.3576 26.6495 11.3343Z" fill="#747070" />
        <path d="M32.3451 11.3303C32.8909 11.3414 33.3377 10.9091 33.3488 10.3598C33.3599 9.80936 32.9283 9.35389 32.3886 9.34581C31.8579 9.33773 31.3839 9.79219 31.3728 10.3183C31.3607 10.8445 31.8165 11.3192 32.3451 11.3303Z" fill="#747070" />
        <path d="M38.1089 9.34685C37.5783 9.35695 37.1214 9.82454 37.1275 10.3507C37.1336 10.8809 37.6005 11.3374 38.1312 11.3303C38.681 11.3232 39.1045 10.8839 39.0984 10.3265C39.0924 9.77606 38.6487 9.33675 38.1089 9.34685Z" fill="#747070" />
        <path d="M43.8243 11.3282C44.3691 11.3555 44.831 10.9353 44.8553 10.39C44.8796 9.83857 44.4631 9.372 43.9234 9.34574C43.4059 9.32049 42.9086 9.77091 42.8793 10.292C42.85 10.8172 43.2927 11.3019 43.8243 11.3282Z" fill="#747070" />
        <path d="M49.6606 11.3302C50.2135 11.312 50.6309 10.8646 50.6117 10.3092C50.5925 9.75879 50.1407 9.32958 49.599 9.34876C49.0744 9.36694 48.6175 9.85069 48.6337 10.3708C48.6519 10.898 49.133 11.3474 49.6606 11.3302Z" fill="#747070" />
        <path d="M302.955 11.3274C303.496 11.3012 303.911 10.8406 303.885 10.2953C303.859 9.75499 303.395 9.33184 302.856 9.35809C302.308 9.38435 301.904 9.83679 301.931 10.3932C301.957 10.9386 302.412 11.3537 302.955 11.3274Z" fill="#747070" />
        <path d="M43.8187 102.982C43.2709 103.013 42.8474 103.477 42.8807 104.01C42.9131 104.532 43.4063 104.967 43.9339 104.938C44.4858 104.908 44.8921 104.452 44.8588 103.899C44.8264 103.346 44.3776 102.951 43.8187 102.982Z" fill="#747070" />
        <path d="M49.6315 104.935C50.1915 104.938 50.614 104.517 50.6129 103.957C50.6119 103.394 50.1996 102.982 49.6335 102.98C49.0847 102.979 48.6299 103.419 48.6299 103.951C48.6309 104.466 49.1049 104.932 49.6315 104.935Z" fill="#747070" />
        <path d="M55.3833 104.935C55.9402 104.941 56.3678 104.52 56.3698 103.962C56.3718 103.4 55.9595 102.986 55.3945 102.981C54.8446 102.977 54.3908 103.413 54.3867 103.948C54.3827 104.459 54.8557 104.929 55.3833 104.935Z" fill="#747070" />
        <path d="M61.1406 104.935C61.6995 104.94 62.124 104.521 62.1271 103.962C62.1301 103.401 61.7167 102.986 61.1527 102.981C60.6029 102.977 60.149 103.413 60.146 103.948C60.143 104.471 60.6049 104.93 61.1406 104.935Z" fill="#747070" />
        <path d="M66.8431 104.934C67.397 104.967 67.8528 104.563 67.8831 104.012C67.9134 103.462 67.5071 103.003 66.9613 102.972C66.4267 102.942 65.9334 103.37 65.9001 103.893C65.8677 104.401 66.3236 104.904 66.8431 104.934Z" fill="#747070" />
        <path d="M72.6196 104.937C73.1745 104.957 73.6223 104.543 73.6404 103.992C73.6597 103.443 73.2432 102.994 72.6964 102.973C72.1537 102.953 71.6796 103.382 71.6574 103.915C71.6372 104.43 72.095 104.917 72.6196 104.937Z" fill="#747070" />
        <path d="M77.4194 103.957C77.4235 104.482 77.8915 104.94 78.4201 104.936C78.9689 104.932 79.4055 104.491 79.3995 103.948C79.3934 103.394 78.9618 102.97 78.409 102.973C77.8652 102.976 77.4164 103.423 77.4194 103.957Z" fill="#747070" />
        <path d="M63.9502 14.2507C63.4034 14.278 62.9789 14.7375 63.0072 15.2738C63.0345 15.809 63.5045 16.2251 64.0513 16.1998C64.5951 16.1746 65.0246 15.708 64.9943 15.1758C64.963 14.6304 64.5041 14.2245 63.9502 14.2507Z" fill="#747070" />
        <path d="M69.7628 16.2056C70.3127 16.2046 70.7523 15.7673 70.7493 15.224C70.7463 14.6746 70.3127 14.2514 69.7558 14.2524C69.2019 14.2535 68.7632 14.6857 68.7622 15.23C68.7622 15.7562 69.2231 16.2066 69.7628 16.2056Z" fill="#747070" />
        <path d="M75.5787 16.2055C76.1164 16.1732 76.5449 15.6935 76.5085 15.1643C76.4711 14.624 76.0032 14.219 75.4523 14.2513C74.9075 14.2836 74.4891 14.7492 74.5235 15.2875C74.5578 15.8197 75.0379 16.2378 75.5787 16.2055Z" fill="#747070" />
        <path d="M81.3353 16.2044C81.869 16.1741 82.3006 15.6913 82.2652 15.1642C82.2288 14.6249 81.7608 14.2199 81.21 14.2512C80.6662 14.2825 80.2488 14.7491 80.2821 15.2874C80.3155 15.8176 80.7946 16.2357 81.3353 16.2044Z" fill="#747070" />
        <path d="M86.962 14.2498C86.4203 14.2852 86.0028 14.7568 86.0382 15.292C86.0736 15.8263 86.5507 16.2373 87.0965 16.204C87.6311 16.1707 88.0607 15.6859 88.0223 15.1597C87.9819 14.6194 87.5088 14.2135 86.962 14.2498Z" fill="#747070" />
        <path d="M92.728 14.2497C92.1842 14.28 91.7627 14.7436 91.793 15.2809C91.8224 15.8121 92.2984 16.2322 92.8412 16.2039C93.3728 16.1766 93.8105 15.6929 93.7791 15.1688C93.7448 14.6224 93.2839 14.2184 92.728 14.2497Z" fill="#747070" />
        <path d="M98.5628 16.2039C99.0995 16.1948 99.5462 15.7343 99.5341 15.2041C99.521 14.6578 99.0742 14.2376 98.5203 14.2498C97.9705 14.2619 97.5389 14.7042 97.548 15.2465C97.5561 15.7778 98.018 16.213 98.5628 16.2039Z" fill="#747070" />
        <path d="M124.523 104.947C125.048 104.914 125.492 104.415 125.458 103.895C125.422 103.357 124.948 102.942 124.403 102.973C123.854 103.006 123.438 103.471 123.475 104.01C123.513 104.547 123.997 104.979 124.523 104.947Z" fill="#747070" />
        <path d="M130.188 104.945C130.714 104.963 131.194 104.511 131.211 103.984C131.227 103.444 130.794 102.988 130.248 102.973C129.701 102.957 129.24 103.385 129.224 103.924C129.208 104.452 129.659 104.929 130.188 104.945Z" fill="#747070" />
        <path d="M115.75 14.2496C115.205 14.2799 114.787 14.7435 114.82 15.2838C114.851 15.821 115.323 16.2361 115.867 16.2038C116.398 16.1725 116.841 15.6837 116.808 15.1656C116.772 14.6283 116.3 14.2193 115.75 14.2496Z" fill="#747070" />
        <path d="M121.509 16.2042C122.052 16.2315 122.527 15.8124 122.556 15.2801C122.586 14.7429 122.164 14.2783 121.62 14.25C121.066 14.2207 120.604 14.6247 120.571 15.1701C120.541 15.6962 120.977 16.1769 121.509 16.2042Z" fill="#747070" />
        <path d="M127.336 16.2033C127.875 16.1952 128.327 15.7377 128.317 15.2105C128.307 14.6672 127.859 14.242 127.307 14.2501C126.757 14.2582 126.326 14.6945 126.332 15.2408C126.337 15.7771 126.79 16.2113 127.336 16.2033Z" fill="#747070" />
        <path d="M133.074 16.2032C133.619 16.2032 134.072 15.7598 134.072 15.2276C134.072 14.6833 133.633 14.251 133.081 14.25C132.528 14.248 132.091 14.6762 132.087 15.2236C132.083 15.7598 132.53 16.2032 133.074 16.2032Z" fill="#747070" />
        <path d="M138.831 16.2031C139.374 16.2031 139.83 15.7568 139.829 15.2276C139.828 14.6832 139.39 14.252 138.837 14.251C138.281 14.251 137.847 14.6751 137.843 15.2235C137.84 15.7628 138.285 16.2041 138.831 16.2031Z" fill="#747070" />
        <path d="M144.524 16.2057C145.062 16.2401 145.545 15.823 145.582 15.2928C145.618 14.7525 145.206 14.2859 144.659 14.2516C144.109 14.2172 143.639 14.6202 143.6 15.1605C143.561 15.6886 143.989 16.1714 144.524 16.2057Z" fill="#747070" />
        <path d="M156.167 16.2004C156.699 16.1701 157.138 15.6824 157.103 15.1633C157.067 14.626 156.594 14.219 156.044 14.2513C155.499 14.2836 155.087 14.7452 155.119 15.2875C155.15 15.8288 155.614 16.2317 156.167 16.2004Z" fill="#747070" />
        <path d="M135.983 104.947C136.51 104.942 136.974 104.474 136.97 103.948C136.966 103.406 136.517 102.969 135.97 102.973C135.423 102.977 134.986 103.416 134.984 103.963C134.982 104.491 135.448 104.95 135.983 104.947Z" fill="#747070" />
        <path d="M141.775 104.946C142.3 104.922 142.753 104.431 142.729 103.913C142.704 103.375 142.236 102.951 141.692 102.973C141.15 102.995 140.72 103.459 140.743 103.996C140.765 104.521 141.254 104.97 141.775 104.946Z" fill="#747070" />
        <path d="M147.497 104.947C148.021 104.943 148.486 104.473 148.484 103.949C148.482 103.41 148.031 102.971 147.485 102.974C146.939 102.978 146.495 103.422 146.498 103.962C146.5 104.485 146.973 104.951 147.497 104.947Z" fill="#747070" />
        <path d="M153.217 102.974C152.674 102.991 152.24 103.449 152.257 103.986C152.273 104.508 152.758 104.965 153.279 104.947C153.801 104.93 154.26 104.444 154.242 103.924C154.224 103.383 153.763 102.957 153.217 102.974Z" fill="#747070" />
        <path d="M159.015 104.947C159.542 104.941 160.005 104.468 159.996 103.943C159.989 103.4 159.539 102.966 158.991 102.974C158.446 102.981 158.003 103.431 158.009 103.968C158.015 104.491 158.489 104.953 159.015 104.947Z" fill="#747070" />
        <path d="M164.737 104.946C165.26 104.959 165.739 104.503 165.751 103.978C165.765 103.439 165.329 102.986 164.783 102.973C164.237 102.96 163.781 103.389 163.766 103.931C163.753 104.457 164.208 104.933 164.737 104.946Z" fill="#747070" />
        <path d="M26.5453 18.7262C25.9874 18.7595 25.5942 19.2049 25.6286 19.7634C25.6619 20.2875 26.1642 20.744 26.6666 20.7066C27.175 20.6683 27.6146 20.1573 27.5843 19.6392C27.552 19.0837 27.1062 18.6919 26.5453 18.7262Z" fill="#747070" />
        <path d="M31.3745 19.7003C31.3735 20.2275 31.8405 20.7021 32.36 20.7011C32.8775 20.7001 33.3394 20.2295 33.3414 19.7013C33.3434 19.1408 32.928 18.7247 32.364 18.7217C31.8101 18.7197 31.3765 19.1489 31.3745 19.7003Z" fill="#747070" />
        <path d="M38.1799 18.7201C37.6382 18.6868 37.1581 19.1049 37.1237 19.6402C37.0913 20.1461 37.5492 20.6652 38.0566 20.6975C38.5731 20.7308 39.0633 20.2905 39.0977 19.7623C39.132 19.2059 38.7348 18.7545 38.1799 18.7201Z" fill="#747070" />
        <path d="M43.8796 20.7011C44.3981 20.697 44.859 20.2224 44.857 19.6952C44.855 19.1367 44.4305 18.7196 43.8695 18.7227C43.3207 18.7267 42.8729 19.1701 42.878 19.7073C42.883 20.2224 43.3692 20.7051 43.8796 20.7011Z" fill="#747070" />
        <path d="M49.579 18.7232C49.0311 18.7515 48.6056 19.217 48.638 19.7523C48.6693 20.2663 49.1807 20.7329 49.681 20.7036C50.1985 20.6733 50.6402 20.1734 50.614 19.6493C50.5877 19.0958 50.1379 18.6939 49.579 18.7232Z" fill="#747070" />
        <path d="M55.3877 20.7015C55.9092 20.7005 56.3711 20.2309 56.3691 19.7027C56.3671 19.1392 55.9496 18.7241 55.3877 18.7241C54.8388 18.7241 54.3891 19.1665 54.3921 19.7027C54.3961 20.2198 54.8762 20.7025 55.3877 20.7015Z" fill="#747070" />
        <path d="M61.1436 18.7241C60.5968 18.7231 60.143 19.1695 60.147 19.7027C60.151 20.2208 60.6332 20.7015 61.1456 20.7015C61.6661 20.7005 62.1301 20.2299 62.1301 19.7017C62.1301 19.1473 61.7045 18.7251 61.1436 18.7241Z" fill="#747070" />
        <path d="M66.8567 20.6982C67.3934 20.7265 67.8553 20.2993 67.8836 19.7499C67.9119 19.1924 67.5086 18.7491 66.9487 18.7228C66.4029 18.6966 65.9238 19.1238 65.9026 19.656C65.8813 20.1619 66.3483 20.6719 66.8567 20.6982Z" fill="#747070" />
        <path d="M72.5946 18.7234C72.0518 18.7547 71.6273 19.2314 71.6677 19.7656C71.7061 20.2776 72.2236 20.7371 72.7239 20.7048C73.2485 20.6705 73.6801 20.1736 73.6467 19.6404C73.6124 19.096 73.1484 18.6921 72.5946 18.7234Z" fill="#747070" />
        <path d="M78.372 20.6971C78.9087 20.7224 79.3756 20.2911 79.3989 19.7478C79.4221 19.1984 79.0087 18.748 78.4579 18.7228C77.9131 18.6975 77.4421 19.1217 77.4178 19.6599C77.3946 20.171 77.8565 20.6729 78.372 20.6971Z" fill="#747070" />
        <path d="M84.2313 20.7028C84.7568 20.6704 85.1935 20.1726 85.1591 19.6444C85.1248 19.097 84.6618 18.6931 84.1079 18.7244C83.5672 18.7557 83.1437 19.2293 83.1761 19.7666C83.2064 20.2746 83.7239 20.7341 84.2313 20.7028Z" fill="#747070" />
        <path d="M89.8749 20.6974C90.4065 20.7287 90.8846 20.2944 90.9109 19.7562C90.9382 19.2068 90.5258 18.7513 89.976 18.723C89.4292 18.6948 88.9572 19.1149 88.9278 19.6532C88.9016 20.1652 89.3564 20.6671 89.8749 20.6974Z" fill="#747070" />
        <path d="M135.938 20.6997C136.463 20.723 136.945 20.2756 136.966 19.7454C136.987 19.2 136.564 18.7435 136.018 18.7234C135.469 18.7032 135.003 19.1263 134.983 19.6646C134.964 20.1847 135.417 20.6755 135.938 20.6997Z" fill="#747070" />
        <path d="M141.696 20.6997C142.22 20.723 142.702 20.2736 142.724 19.7444C142.747 19.201 142.32 18.7435 141.774 18.7233C141.226 18.7031 140.761 19.1293 140.74 19.6666C140.721 20.1867 141.174 20.6765 141.696 20.6997Z" fill="#747070" />
        <path d="M147.453 20.6986C147.974 20.7198 148.461 20.2663 148.481 19.7422C148.503 19.2019 148.074 18.7454 147.527 18.7262C146.975 18.707 146.516 19.1272 146.494 19.6685C146.474 20.1865 146.931 20.6774 147.453 20.6986Z" fill="#747070" />
        <path d="M153.212 20.7021C153.729 20.7223 154.218 20.2648 154.239 19.7417C154.26 19.2014 153.83 18.7459 153.282 18.7257C152.731 18.7065 152.272 19.1267 152.252 19.67C152.234 20.1911 152.69 20.6819 153.212 20.7021Z" fill="#747070" />
        <path d="M158.969 20.6986C159.488 20.7167 159.976 20.2603 159.995 19.7392C160.016 19.2009 159.585 18.7444 159.037 18.7262C158.484 18.707 158.029 19.1261 158.008 19.6715C157.99 20.1946 158.444 20.6814 158.969 20.6986Z" fill="#747070" />
        <path d="M164.791 20.7004C165.315 20.6812 165.77 20.1965 165.754 19.6744C165.737 19.1351 165.278 18.7089 164.728 18.7251C164.176 18.7412 163.754 19.1886 163.772 19.739C163.788 20.2692 164.265 20.7196 164.791 20.7004Z" fill="#747070" />
        <path d="M170.478 104.946C171.009 104.97 171.487 104.529 171.51 103.996C171.534 103.456 171.108 102.997 170.56 102.974C170.013 102.951 169.548 103.373 169.522 103.914C169.499 104.434 169.95 104.923 170.478 104.946Z" fill="#747070" />
        <path d="M176.264 102.973C175.716 102.979 175.276 103.424 175.282 103.966C175.288 104.497 175.755 104.953 176.288 104.946C176.82 104.939 177.275 104.473 177.269 103.942C177.263 103.401 176.814 102.967 176.264 102.973Z" fill="#747070" />
        <path d="M221.337 103.946C221.331 104.49 221.767 104.93 222.316 104.934C222.853 104.938 223.315 104.489 223.318 103.958C223.322 103.423 222.875 102.977 222.33 102.972C221.778 102.968 221.343 103.394 221.337 103.946Z" fill="#747070" />
        <path d="M228.021 104.938C228.547 104.969 229.038 104.535 229.071 104.011C229.105 103.478 228.68 103.008 228.138 102.975C227.59 102.943 227.127 103.35 227.092 103.896C227.057 104.434 227.477 104.905 228.021 104.938Z" fill="#747070" />
        <path d="M233.842 102.973C233.29 102.968 232.856 103.393 232.85 103.946C232.844 104.492 233.278 104.931 233.827 104.936C234.357 104.94 234.826 104.484 234.831 103.96C234.836 103.426 234.387 102.978 233.842 102.973Z" fill="#747070" />
        <path d="M239.521 104.937C240.049 104.972 240.542 104.549 240.584 104.023C240.626 103.49 240.208 103.018 239.662 102.981C239.102 102.945 238.645 103.336 238.608 103.884C238.57 104.439 238.97 104.899 239.521 104.937Z" fill="#747070" />
        <path d="M245.331 102.98C244.769 102.989 244.36 103.41 244.368 103.972C244.376 104.53 244.805 104.944 245.365 104.933C245.891 104.923 246.359 104.45 246.35 103.936C246.34 103.4 245.883 102.971 245.331 102.98Z" fill="#747070" />
        <path d="M251.041 102.979C250.485 103.014 250.092 103.46 250.126 104.02C250.16 104.578 250.607 104.97 251.168 104.932C251.691 104.896 252.145 104.393 252.11 103.888C252.075 103.367 251.581 102.946 251.041 102.979Z" fill="#747070" />
        <path d="M256.875 104.934C257.404 104.925 257.871 104.455 257.863 103.939C257.856 103.405 257.398 102.972 256.848 102.98C256.286 102.988 255.874 103.409 255.881 103.969C255.89 104.53 256.314 104.943 256.875 104.934Z" fill="#747070" />
        <path d="M262.616 102.98C262.052 102.984 261.638 103.397 261.638 103.958C261.638 104.515 262.065 104.939 262.621 104.935C263.147 104.932 263.619 104.464 263.618 103.949C263.616 103.414 263.165 102.977 262.616 102.98Z" fill="#747070" />
        <path d="M268.311 102.975C267.763 103.009 267.364 103.464 267.397 104.02C267.431 104.579 267.877 104.97 268.438 104.933C268.96 104.898 269.412 104.397 269.377 103.89C269.342 103.368 268.847 102.943 268.311 102.975Z" fill="#747070" />
        <path d="M273.151 103.954C273.15 104.509 273.576 104.938 274.127 104.938C274.667 104.937 275.114 104.493 275.115 103.954C275.116 103.405 274.685 102.978 274.13 102.979C273.57 102.979 273.152 103.395 273.151 103.954Z" fill="#747070" />
        <path d="M49.568 114.276C50.137 114.308 50.5767 113.929 50.6121 113.378C50.6474 112.826 50.2522 112.382 49.6953 112.351C49.1424 112.32 48.6724 112.719 48.6381 113.251C48.6047 113.781 49.0262 114.244 49.568 114.276Z" fill="#747070" />
        <path d="M23.6532 117.208C23.1478 117.24 22.6808 117.758 22.7071 118.259C22.7344 118.787 23.2216 119.214 23.7633 119.183C24.3213 119.152 24.7215 118.703 24.6882 118.146C24.6558 117.598 24.1878 117.174 23.6532 117.208Z" fill="#747070" />
        <path d="M29.5191 117.202C29.0137 117.175 28.5033 117.637 28.4719 118.15C28.4396 118.685 28.8681 119.154 29.4129 119.18C29.9678 119.208 30.4237 118.801 30.452 118.252C30.4803 117.718 30.0467 117.23 29.5191 117.202Z" fill="#747070" />
        <path d="M35.2741 119.181C35.831 119.15 36.2363 118.698 36.205 118.146C36.1746 117.603 35.7026 117.175 35.1679 117.207C34.6494 117.237 34.1956 117.739 34.2239 118.252C34.2532 118.792 34.7283 119.212 35.2741 119.181Z" fill="#747070" />
        <path d="M41.0269 119.182C41.5778 119.153 41.9882 118.697 41.9609 118.148C41.9336 117.608 41.4585 117.175 40.9259 117.208C40.4094 117.239 39.9495 117.745 39.9778 118.251C40.0081 118.789 40.4832 119.21 41.0269 119.182Z" fill="#747070" />
        <path d="M46.7821 119.185C47.3279 119.159 47.7433 118.699 47.718 118.15C47.6928 117.611 47.2157 117.176 46.6841 117.208C46.1645 117.238 45.7087 117.74 45.735 118.251C45.7623 118.787 46.2373 119.21 46.7821 119.185Z" fill="#747070" />
        <path d="M98.5744 117.209C98.0518 117.189 97.5697 117.641 97.5505 118.167C97.5303 118.706 97.9608 119.163 98.5077 119.181C99.0545 119.199 99.5143 118.774 99.5346 118.232C99.5538 117.709 99.102 117.228 98.5744 117.209Z" fill="#747070" />
        <path d="M104.339 119.181C104.882 119.159 105.311 118.697 105.288 118.158C105.266 117.631 104.779 117.184 104.254 117.206C103.733 117.228 103.277 117.723 103.301 118.242C103.326 118.783 103.789 119.203 104.339 119.181Z" fill="#747070" />
        <path d="M110.088 119.178C110.636 119.16 111.065 118.703 111.044 118.163C111.024 117.638 110.537 117.183 110.018 117.202C109.498 117.221 109.04 117.713 109.058 118.233C109.077 118.777 109.534 119.197 110.088 119.178Z" fill="#747070" />
        <path d="M115.834 117.206C115.31 117.195 114.831 117.652 114.818 118.175C114.805 118.719 115.236 119.166 115.786 119.179C116.337 119.192 116.792 118.766 116.805 118.224C116.818 117.701 116.356 117.217 115.834 117.206Z" fill="#747070" />
        <path d="M121.595 119.181C122.142 119.165 122.575 118.711 122.557 118.172C122.54 117.648 122.058 117.193 121.537 117.209C121.012 117.225 120.557 117.707 120.573 118.229C120.59 118.771 121.048 119.197 121.595 119.181Z" fill="#747070" />
        <path d="M173.432 117.208C172.902 117.171 172.42 117.603 172.389 118.141C172.359 118.688 172.771 119.154 173.315 119.185C173.855 119.216 174.338 118.793 174.372 118.259C174.405 117.752 173.95 117.243 173.432 117.208Z" fill="#747070" />
        <path d="M179.136 119.181C179.681 119.179 180.127 118.736 180.126 118.194C180.126 117.676 179.648 117.204 179.125 117.205C178.59 117.206 178.139 117.665 178.143 118.205C178.147 118.76 178.577 119.183 179.136 119.181Z" fill="#747070" />
        <path d="M184.869 117.204C184.335 117.212 183.892 117.674 183.899 118.216C183.908 118.767 184.343 119.187 184.901 119.18C185.449 119.174 185.889 118.726 185.879 118.184C185.869 117.663 185.388 117.196 184.869 117.204Z" fill="#747070" />
        <path d="M190.614 117.203C190.08 117.217 189.644 117.684 189.656 118.228C189.669 118.777 190.112 119.194 190.669 119.18C191.218 119.167 191.657 118.711 191.637 118.175C191.617 117.655 191.128 117.19 190.614 117.203Z" fill="#747070" />
        <path d="M196.461 117.208C195.928 117.17 195.456 117.592 195.419 118.139C195.382 118.695 195.779 119.147 196.337 119.182C196.876 119.217 197.37 118.792 197.401 118.265C197.43 117.764 196.968 117.244 196.461 117.208Z" fill="#747070" />
        <path d="M203.153 118.237C203.17 117.725 202.704 117.227 202.189 117.207C201.649 117.186 201.189 117.619 201.173 118.163C201.157 118.726 201.561 119.161 202.119 119.181C202.67 119.201 203.135 118.776 203.153 118.237Z" fill="#747070" />
        <path d="M207.889 119.181C208.439 119.193 208.9 118.762 208.909 118.225C208.918 117.71 208.447 117.218 207.932 117.205C207.392 117.191 206.939 117.63 206.93 118.176C206.922 118.741 207.33 119.168 207.889 119.181Z" fill="#747070" />
        <path d="M213.731 119.182C214.274 119.151 214.699 118.676 214.662 118.142C214.627 117.631 214.108 117.169 213.607 117.201C213.077 117.235 212.653 117.723 212.686 118.263C212.72 118.818 213.17 119.214 213.731 119.182Z" fill="#747070" />
        <path d="M219.486 117.208C218.952 117.17 218.478 117.594 218.445 118.138C218.411 118.697 218.807 119.149 219.362 119.184C219.901 119.219 220.393 118.794 220.426 118.265C220.459 117.764 219.996 117.243 219.486 117.208Z" fill="#747070" />
        <path d="M66.88 121.702C66.3443 121.711 65.8895 122.177 65.9026 122.701C65.9158 123.234 66.3847 123.674 66.9225 123.657C67.4672 123.639 67.8938 123.196 67.8877 122.652C67.8806 122.105 67.444 121.692 66.88 121.702Z" fill="#747070" />
        <path d="M72.7285 121.704C72.1908 121.667 71.7067 122.079 71.6662 122.609C71.6258 123.139 72.0493 123.621 72.584 123.653C73.1288 123.685 73.6028 123.281 73.6453 122.747C73.6887 122.21 73.2784 121.742 72.7285 121.704Z" fill="#747070" />
        <path d="M107.252 123.654C107.798 123.624 108.21 123.165 108.182 122.622C108.153 122.082 107.69 121.674 107.138 121.703C106.607 121.731 106.165 122.218 106.197 122.737C106.231 123.279 106.698 123.685 107.252 123.654Z" fill="#747070" />
        <path d="M113.01 123.657C113.556 123.627 113.97 123.166 113.94 122.621C113.911 122.079 113.447 121.672 112.894 121.703C112.365 121.733 111.918 122.223 111.95 122.741C111.981 123.271 112.462 123.686 113.01 123.657Z" fill="#747070" />
        <path d="M118.768 123.656C119.311 123.626 119.727 123.163 119.696 122.622C119.666 122.08 119.203 121.673 118.65 121.704C118.121 121.733 117.675 122.223 117.709 122.74C117.746 123.279 118.216 123.687 118.768 123.656Z" fill="#747070" />
        <path d="M124.531 123.656C125.074 123.622 125.487 123.154 125.452 122.614C125.418 122.077 124.942 121.664 124.4 121.699C123.872 121.733 123.431 122.23 123.469 122.746C123.508 123.281 123.986 123.69 124.531 123.656Z" fill="#747070" />
        <path d="M130.221 121.699C129.678 121.7 129.224 122.148 129.228 122.681C129.231 123.225 129.673 123.657 130.225 123.656C130.778 123.655 131.212 123.225 131.213 122.677C131.214 122.14 130.764 121.697 130.221 121.699Z" fill="#747070" />
        <path d="M136.013 121.7C135.471 121.682 135.002 122.113 134.985 122.646C134.968 123.186 135.395 123.637 135.943 123.656C136.497 123.675 136.949 123.261 136.971 122.715C136.992 122.186 136.55 121.717 136.013 121.7Z" fill="#747070" />
        <path d="M141.669 121.699C141.133 121.734 140.695 122.225 140.736 122.743C140.779 123.282 141.253 123.689 141.798 123.656C142.341 123.624 142.758 123.154 142.722 122.616C142.687 122.079 142.21 121.664 141.669 121.699Z" fill="#747070" />
        <path d="M147.555 123.655C148.096 123.622 148.514 123.151 148.479 122.615C148.445 122.081 147.966 121.664 147.427 121.699C146.899 121.733 146.458 122.227 146.494 122.744C146.532 123.28 147.009 123.689 147.555 123.655Z" fill="#747070" />
        <path d="M159.024 123.656C159.573 123.643 160.005 123.199 159.995 122.657C159.985 122.128 159.519 121.69 158.979 121.7C158.446 121.71 157.995 122.176 158.008 122.702C158.022 123.245 158.474 123.669 159.024 123.656Z" fill="#747070" />
        <path d="M164.78 123.656C165.329 123.643 165.762 123.2 165.752 122.658C165.743 122.127 165.279 121.69 164.737 121.7C164.202 121.71 163.754 122.171 163.766 122.703C163.779 123.248 164.226 123.669 164.78 123.656Z" fill="#747070" />
        <path d="M170.515 123.656C171.069 123.656 171.504 123.227 171.507 122.678C171.509 122.144 171.056 121.698 170.513 121.699C169.968 121.699 169.52 122.143 169.523 122.681C169.526 123.228 169.962 123.656 170.515 123.656Z" fill="#747070" />
        <path d="M176.296 121.698C175.751 121.685 175.295 122.116 175.282 122.654C175.27 123.2 175.697 123.643 176.247 123.655C176.8 123.668 177.251 123.246 177.268 122.704C177.284 122.182 176.829 121.711 176.296 121.698Z" fill="#747070" />
        <path d="M182.094 121.703C181.544 121.67 181.075 122.077 181.041 122.614C181.007 123.155 181.419 123.621 181.962 123.655C182.508 123.69 182.985 123.282 183.025 122.745C183.064 122.228 182.624 121.735 182.094 121.703Z" fill="#747070" />
        <path d="M187.844 123.656C188.389 123.625 188.808 123.16 188.776 122.622C188.745 122.087 188.27 121.673 187.725 121.705C187.181 121.736 186.756 122.209 186.792 122.74C186.829 123.285 187.29 123.687 187.844 123.656Z" fill="#747070" />
        <path d="M193.589 121.703C193.038 121.68 192.578 122.093 192.556 122.633C192.532 123.178 192.951 123.63 193.499 123.654C194.048 123.677 194.517 123.261 194.545 122.726C194.569 122.208 194.119 121.726 193.589 121.703Z" fill="#747070" />
        <path d="M228.092 123.653C228.635 123.653 229.08 123.206 229.074 122.669C229.069 122.135 228.614 121.697 228.07 121.704C227.514 121.71 227.083 122.143 227.092 122.686C227.101 123.227 227.542 123.653 228.092 123.653Z" fill="#747070" />
        <path d="M233.894 121.703C233.332 121.674 232.881 122.071 232.852 122.618C232.824 123.16 233.234 123.618 233.781 123.654C234.316 123.69 234.803 123.267 234.836 122.736C234.868 122.222 234.42 121.73 233.894 121.703Z" fill="#747070" />
        <path d="M219.384 126.572C218.835 126.595 218.421 127.048 218.443 127.604C218.464 128.152 218.921 128.576 219.464 128.551C219.992 128.527 220.442 128.042 220.42 127.522C220.399 126.999 219.909 126.549 219.384 126.572Z" fill="#747070" />
        <path d="M225.131 128.556C225.653 128.582 226.147 128.136 226.173 127.614C226.2 127.084 225.765 126.604 225.231 126.577C224.688 126.549 224.229 126.968 224.202 127.515C224.175 128.066 224.588 128.53 225.131 128.556Z" fill="#747070" />
        <path d="M230.885 126.571C230.341 126.601 229.924 127.068 229.953 127.616C229.981 128.166 230.444 128.587 230.983 128.554C231.515 128.522 231.956 128.036 231.929 127.514C231.9 126.987 231.409 126.541 230.885 126.571Z" fill="#747070" />
        <path d="M236.726 126.576C236.183 126.557 235.726 126.985 235.709 127.531C235.69 128.082 236.114 128.542 236.656 128.561C237.183 128.58 237.663 128.131 237.684 127.602C237.705 127.074 237.258 126.595 236.726 126.576Z" fill="#747070" />
        <path d="M242.417 126.572C241.871 126.59 241.449 127.043 241.466 127.597C241.484 128.146 241.931 128.569 242.481 128.557C243.011 128.545 243.457 128.069 243.44 127.535C243.423 127.005 242.945 126.555 242.417 126.572Z" fill="#747070" />
        <path d="M247.225 127.618C247.253 128.163 247.715 128.582 248.258 128.557C248.782 128.532 249.222 128.04 249.194 127.512C249.166 126.987 248.672 126.541 248.151 126.572C247.607 126.601 247.197 127.066 247.225 127.618Z" fill="#747070" />
        <path d="M253.993 128.554C254.529 128.537 254.956 128.072 254.936 127.528C254.916 126.99 254.45 126.549 253.921 126.572C253.374 126.594 252.961 127.045 252.98 127.599C252.999 128.147 253.449 128.571 253.993 128.554Z" fill="#747070" />
        <path d="M259.65 128.562C260.168 128.599 260.659 128.158 260.692 127.626C260.727 127.08 260.315 126.609 259.778 126.576C259.237 126.543 258.77 126.957 258.738 127.5C258.705 128.05 259.112 128.524 259.65 128.562Z" fill="#747070" />
        <path d="M266.447 127.568C266.451 127.025 266.012 126.577 265.476 126.574C264.931 126.572 264.502 127.003 264.497 127.557C264.493 128.116 264.909 128.543 265.465 128.55C266.008 128.557 266.443 128.122 266.447 127.568Z" fill="#747070" />
        <path d="M81.2078 135.947C80.6599 135.98 80.2405 136.449 80.2799 136.984C80.3193 137.515 80.8085 137.949 81.3311 137.916C81.8567 137.883 82.3004 137.384 82.266 136.865C82.2296 136.328 81.7515 135.913 81.2078 135.947Z" fill="#747070" />
        <path d="M87.0226 135.945C86.4717 135.947 86.0361 136.383 86.0371 136.93C86.0381 137.46 86.502 137.919 87.0327 137.916C87.5623 137.913 88.0222 137.448 88.0182 136.92C88.0151 136.377 87.5724 135.943 87.0226 135.945Z" fill="#747070" />
        <path d="M92.7943 135.945C92.2455 135.939 91.8018 136.37 91.7907 136.917C91.7806 137.44 92.2425 137.912 92.7731 137.917C93.3007 137.923 93.7717 137.462 93.7768 136.936C93.7808 136.397 93.3401 135.951 92.7943 135.945Z" fill="#747070" />
        <path d="M98.4846 137.917C99.0102 137.947 99.5004 137.51 99.5308 136.983C99.5621 136.449 99.1366 135.976 98.5979 135.947C98.0531 135.917 97.578 136.334 97.5457 136.871C97.5123 137.381 97.9672 137.887 98.4846 137.917Z" fill="#747070" />
        <path d="M104.246 135.947C103.706 135.973 103.278 136.44 103.307 136.977C103.334 137.5 103.826 137.945 104.347 137.918C104.864 137.891 105.32 137.388 105.293 136.876C105.264 136.339 104.791 135.919 104.246 135.947Z" fill="#747070" />
        <path d="M111.05 136.867C111.014 136.329 110.537 135.914 109.994 135.947C109.457 135.978 109.031 136.455 109.064 136.987C109.096 137.512 109.59 137.95 110.113 137.918C110.627 137.886 111.083 137.375 111.05 136.867Z" fill="#747070" />
        <path d="M115.798 135.947C115.254 135.954 114.811 136.401 114.818 136.939C114.825 137.463 115.298 137.924 115.823 137.918C116.349 137.912 116.811 137.44 116.804 136.915C116.796 136.372 116.347 135.94 115.798 135.947Z" fill="#747070" />
        <path d="M121.513 135.946C120.973 135.975 120.549 136.445 120.578 136.981C120.607 137.51 121.094 137.947 121.621 137.918C122.138 137.889 122.595 137.384 122.564 136.873C122.532 136.335 122.058 135.916 121.513 135.946Z" fill="#747070" />
        <path d="M127.282 137.918C127.813 137.942 128.297 137.498 128.315 136.97C128.334 136.427 127.91 135.968 127.366 135.946C126.817 135.923 126.353 136.343 126.329 136.884C126.305 137.404 126.757 137.893 127.282 137.918Z" fill="#747070" />
        <path d="M133.029 137.916C133.552 137.945 134.041 137.502 134.067 136.975C134.094 136.431 133.675 135.971 133.129 135.946C132.58 135.921 132.114 136.336 132.085 136.876C132.056 137.395 132.503 137.888 133.029 137.916Z" fill="#747070" />
        <path d="M138.78 135.945C138.237 135.976 137.816 136.443 137.847 136.983C137.878 137.514 138.364 137.947 138.893 137.915C139.408 137.883 139.862 137.378 139.835 136.865C139.808 136.335 139.326 135.915 138.78 135.945Z" fill="#747070" />
        <path d="M184.884 135.945C184.341 135.946 183.904 136.384 183.901 136.928C183.899 137.471 184.338 137.906 184.888 137.905C185.421 137.904 185.883 137.446 185.882 136.921C185.881 136.387 185.428 135.944 184.884 135.945Z" fill="#747070" />
        <path d="M190.606 137.906C191.138 137.925 191.614 137.487 191.636 136.958C191.657 136.424 191.224 135.966 190.679 135.946C190.137 135.926 189.677 136.349 189.655 136.889C189.632 137.43 190.06 137.887 190.606 137.906Z" fill="#747070" />
        <path d="M196.343 135.944C195.791 135.976 195.388 136.427 195.416 136.98C195.445 137.537 195.888 137.934 196.451 137.905C196.974 137.877 197.429 137.379 197.399 136.868C197.369 136.342 196.879 135.913 196.343 135.944Z" fill="#747070" />
        <path d="M202.129 135.945C201.573 135.961 201.162 136.393 201.174 136.951C201.186 137.512 201.614 137.918 202.18 137.903C202.714 137.889 203.172 137.417 203.156 136.896C203.141 136.363 202.672 135.93 202.129 135.945Z" fill="#747070" />
        <path d="M207.899 137.906C208.444 137.909 208.906 137.467 208.911 136.934C208.916 136.401 208.466 135.953 207.921 135.949C207.358 135.944 206.935 136.356 206.929 136.915C206.923 137.475 207.342 137.903 207.899 137.906Z" fill="#747070" />
        <path d="M213.658 135.948C213.097 135.955 212.682 136.376 212.686 136.934C212.69 137.499 213.111 137.911 213.675 137.903C214.206 137.896 214.675 137.431 214.671 136.913C214.668 136.386 214.202 135.942 213.658 135.948Z" fill="#747070" />
        <path d="M219.363 135.947C218.812 135.979 218.416 136.43 218.448 136.987C218.479 137.548 218.92 137.936 219.485 137.9C220.009 137.868 220.462 137.369 220.429 136.862C220.394 136.342 219.898 135.915 219.363 135.947Z" fill="#747070" />
        <path d="M225.205 135.952C224.643 135.937 224.213 136.343 224.2 136.902C224.186 137.457 224.603 137.895 225.157 137.906C225.685 137.917 226.165 137.467 226.18 136.947C226.194 136.414 225.755 135.966 225.205 135.952Z" fill="#747070" />
        <path d="M55.3341 140.42C54.7862 140.452 54.3607 140.921 54.3961 141.455C54.4295 141.965 54.9439 142.43 55.4463 142.402C55.9779 142.374 56.4004 141.891 56.3701 141.347C56.3377 140.787 55.891 140.387 55.3341 140.42Z" fill="#747070" />
        <path d="M12.1564 34.9237C12.7184 34.9469 13.155 34.549 13.1782 33.9916C13.2015 33.4371 12.7901 32.9897 12.2372 32.9675C11.7137 32.9463 11.2235 33.3927 11.1992 33.9128C11.175 34.446 11.6045 34.9005 12.1564 34.9237Z" fill="#747070" />
        <path d="M18.0085 34.9243C18.5694 34.892 18.9616 34.4537 18.9303 33.8932C18.8989 33.3337 18.4562 32.9388 17.8963 32.9722C17.3727 33.0035 16.9179 33.5014 16.9482 34.0094C16.9806 34.5365 17.4647 34.9556 18.0085 34.9243Z" fill="#747070" />
        <path d="M23.6734 32.9704C23.1448 32.9896 22.688 33.4703 22.7082 33.9874C22.7284 34.5236 23.1943 34.9437 23.7452 34.9235C24.3092 34.9023 24.7145 34.4711 24.6922 33.9146C24.67 33.3602 24.2293 32.9512 23.6734 32.9704Z" fill="#747070" />
        <path d="M29.4286 32.9564C28.9172 32.9776 28.4472 33.4795 28.4654 33.9855C28.4846 34.5217 28.9505 34.9449 29.5003 34.9247C30.0552 34.9045 30.4676 34.4632 30.4464 33.9108C30.4262 33.3634 29.9683 32.9342 29.4286 32.9564Z" fill="#747070" />
        <path d="M35.1967 32.9555C34.6822 32.9707 34.2122 33.4635 34.2244 33.9745C34.2375 34.5128 34.6954 34.939 35.2462 34.9248C35.8021 34.9097 36.2185 34.4734 36.2044 33.922C36.1902 33.3766 35.7354 32.9394 35.1967 32.9555Z" fill="#747070" />
        <path d="M41.0233 32.9541C40.5098 32.9289 40.0126 33.3833 39.9843 33.9024C39.956 34.4407 40.3784 34.9002 40.9273 34.9255C41.4862 34.9507 41.938 34.5468 41.9643 33.9974C41.9906 33.4732 41.5458 32.9804 41.0233 32.9541Z" fill="#747070" />
        <path d="M81.2765 34.9319C81.8233 34.9288 82.2639 34.4875 82.2619 33.9442C82.2599 33.417 81.794 32.9565 81.2643 32.9585C80.7367 32.9605 80.2748 33.4271 80.2778 33.9553C80.2819 34.4925 80.7337 34.9349 81.2765 34.9319Z" fill="#747070" />
        <path d="M87.0767 32.9574C86.546 32.9291 86.0639 33.3654 86.0356 33.8996C86.0063 34.4399 86.4288 34.9034 86.9756 34.9317C87.5224 34.96 87.9924 34.5419 88.0237 34.0006C88.052 33.4805 87.6033 32.9856 87.0767 32.9574Z" fill="#747070" />
        <path d="M98.5522 34.9314C99.097 34.9244 99.5387 34.476 99.5316 33.9377C99.5245 33.4125 99.0525 32.952 98.5269 32.9581C98.0013 32.9641 97.5384 33.4378 97.5455 33.9629C97.5536 34.5032 98.0054 34.9385 98.5522 34.9314Z" fill="#747070" />
        <path d="M103.306 33.9253C103.291 34.4636 103.728 34.918 104.272 34.9322C104.817 34.9463 105.275 34.5161 105.292 33.9748C105.308 33.4547 104.848 32.9719 104.322 32.9578C103.8 32.9447 103.319 33.4011 103.306 33.9253Z" fill="#747070" />
        <path d="M109.999 32.9575C109.484 32.9878 109.028 33.4958 109.058 34.0048C109.09 34.5421 109.566 34.9612 110.109 34.9319C110.647 34.9026 111.073 34.4279 111.043 33.8947C111.014 33.3706 110.52 32.9272 109.999 32.9575Z" fill="#747070" />
        <path d="M115.802 34.9319C116.348 34.937 116.798 34.4987 116.804 33.9584C116.809 33.4342 116.343 32.9616 115.817 32.9575C115.289 32.9535 114.819 33.416 114.816 33.9422C114.812 34.4805 115.257 34.9279 115.802 34.9319Z" fill="#747070" />
        <path d="M121.548 34.932C122.095 34.9432 122.551 34.5109 122.562 33.9696C122.573 33.4455 122.112 32.9678 121.588 32.9577C121.062 32.9476 120.586 33.4061 120.576 33.9302C120.565 34.4695 121.004 34.9209 121.548 34.932Z" fill="#747070" />
        <path d="M127.383 32.9587C126.86 32.9264 126.366 33.3657 126.334 33.8929C126.302 34.4281 126.728 34.9028 127.268 34.9321C127.813 34.9624 128.289 34.5453 128.321 34.008C128.353 33.4859 127.911 32.991 127.383 32.9587Z" fill="#747070" />
        <path d="M133.047 32.9581C132.517 32.9773 132.066 33.46 132.087 33.9852C132.108 34.5285 132.569 34.9517 133.118 34.9315C133.667 34.9113 134.094 34.4568 134.074 33.9155C134.053 33.3813 133.578 32.9389 133.047 32.9581Z" fill="#747070" />
        <path d="M180.127 33.9898C180.148 33.4767 179.689 32.9809 179.17 32.9566C178.633 32.9314 178.17 33.3616 178.146 33.9059C178.124 34.4563 178.537 34.9017 179.089 34.9239C179.642 34.9471 180.105 34.529 180.127 33.9898Z" fill="#747070" />
        <path d="M184.819 34.9255C185.365 34.9619 185.847 34.5499 185.883 34.0156C185.918 33.5137 185.461 32.9956 184.95 32.9583C184.416 32.9179 183.94 33.338 183.904 33.8813C183.867 34.4327 184.267 34.8882 184.819 34.9255Z" fill="#747070" />
        <path d="M190.707 32.9604C190.17 32.919 189.696 33.335 189.66 33.8804C189.624 34.4328 190.022 34.8883 190.575 34.9256C191.118 34.962 191.604 34.5479 191.64 34.0167C191.675 33.5118 191.224 32.9997 190.707 32.9604Z" fill="#747070" />
        <path d="M196.392 34.9234C196.942 34.9285 197.393 34.4932 197.396 33.957C197.398 33.4369 196.931 32.9733 196.402 32.9693C195.843 32.9652 195.418 33.3864 195.417 33.9428C195.417 34.5063 195.827 34.9184 196.392 34.9234Z" fill="#747070" />
        <path d="M202.197 34.9237C202.745 34.9015 203.175 34.445 203.15 33.9108C203.126 33.3907 202.637 32.9463 202.111 32.9675C201.56 32.9897 201.147 33.4402 201.171 33.9926C201.194 34.5501 201.631 34.9459 202.197 34.9237Z" fill="#747070" />
        <path d="M207.972 32.9714C207.413 32.9361 206.965 33.331 206.933 33.8884C206.9 34.4479 207.293 34.8933 207.85 34.9256C208.389 34.9569 208.885 34.5328 208.917 34.0137C208.948 33.5087 208.491 33.0048 207.972 32.9714Z" fill="#747070" />
        <path d="M213.483 34.9256C214.022 34.9569 214.518 34.5328 214.549 34.0137C214.581 33.5087 214.124 33.0048 213.604 32.9714C213.045 32.9361 212.598 33.331 212.565 33.8884C212.533 34.4479 212.926 34.8933 213.483 34.9256Z" fill="#747070" />
        <path d="M222.444 37.4661C221.885 37.4307 221.437 37.8256 221.405 38.3831C221.372 38.9425 221.766 39.3879 222.322 39.4202C222.861 39.4515 223.357 39.0274 223.389 38.5083C223.42 38.0033 222.963 37.4994 222.444 37.4661Z" fill="#747070" />
        <path d="M61.0921 140.42C60.5432 140.451 60.1208 140.918 60.1531 141.456C60.1834 141.967 60.6949 142.428 61.2002 142.402C61.7278 142.375 62.1574 141.888 62.1291 141.347C62.1018 140.794 61.646 140.387 61.0921 140.42Z" fill="#747070" />
        <path d="M230.918 34.9268C231.472 34.9369 231.913 34.5157 231.923 33.9694C231.933 33.4321 231.493 32.9776 230.952 32.9665C230.4 32.9554 229.968 33.3755 229.96 33.931C229.952 34.4925 230.361 34.9167 230.918 34.9268Z" fill="#747070" />
        <path d="M66.8776 140.418C66.3287 140.431 65.8891 140.886 65.9062 141.424C65.9224 141.946 66.4066 142.41 66.9251 142.4C67.4587 142.39 67.8964 141.924 67.8853 141.379C67.8752 140.826 67.4315 140.404 66.8776 140.418Z" fill="#747070" />
        <path d="M72.6617 140.418C72.1139 140.416 71.6631 140.858 71.6621 141.397C71.6611 141.92 72.1301 142.393 72.6536 142.396C73.1934 142.399 73.6391 141.952 73.6432 141.404C73.6462 140.852 73.2176 140.42 72.6617 140.418Z" fill="#747070" />
        <path d="M78.4537 142.397C78.9894 142.377 79.423 141.902 79.4018 141.361C79.3806 140.809 78.9298 140.396 78.3749 140.419C77.8291 140.441 77.3965 140.903 77.4177 141.441C77.4369 141.956 77.9373 142.418 78.4537 142.397Z" fill="#747070" />
        <path d="M84.1842 140.418C83.6404 140.41 83.1836 140.849 83.1735 141.387C83.1644 141.904 83.6323 142.385 84.1559 142.395C84.6946 142.405 85.1505 141.96 85.1555 141.416C85.1606 140.862 84.7351 140.425 84.1842 140.418Z" fill="#747070" />
        <path d="M89.8759 140.418C89.3331 140.445 88.9066 140.914 88.9349 141.452C88.9612 141.963 89.4686 142.423 89.979 142.397C90.5066 142.371 90.9453 141.881 90.915 141.351C90.8836 140.802 90.4247 140.39 89.8759 140.418Z" fill="#747070" />
        <path d="M147.467 140.425C146.917 140.438 146.484 140.886 146.498 141.428C146.513 141.954 146.993 142.412 147.514 142.4C148.039 142.387 148.497 141.907 148.483 141.382C148.471 140.836 148.02 140.411 147.467 140.425Z" fill="#747070" />
        <path d="M152.255 141.442C152.277 141.971 152.761 142.42 153.284 142.399C153.807 142.378 154.261 141.888 154.241 141.366C154.221 140.822 153.761 140.403 153.209 140.424C152.66 140.445 152.233 140.899 152.255 141.442Z" fill="#747070" />
        <path d="M158.009 141.382C157.999 141.907 158.459 142.387 158.982 142.398C159.502 142.411 159.981 141.949 159.995 141.423C160.008 140.88 159.576 140.435 159.023 140.423C158.47 140.412 158.021 140.837 158.009 141.382Z" fill="#747070" />
        <path d="M210.794 142.395C211.314 142.401 211.793 141.934 211.804 141.413C211.815 140.876 211.371 140.424 210.826 140.417C210.271 140.41 209.834 140.831 209.824 141.386C209.814 141.926 210.259 142.389 210.794 142.395Z" fill="#747070" />
        <path d="M216.537 140.416C215.989 140.433 215.567 140.881 215.582 141.431C215.596 141.975 216.058 142.412 216.597 142.393C217.12 142.374 217.582 141.887 217.564 141.371C217.546 140.834 217.08 140.4 216.537 140.416Z" fill="#747070" />
        <path d="M222.311 142.395C222.832 142.399 223.314 141.929 223.319 141.412C223.324 140.87 222.882 140.424 222.335 140.418C221.779 140.412 221.344 140.836 221.337 141.39C221.33 141.932 221.774 142.39 222.311 142.395Z" fill="#747070" />
        <path d="M228.023 142.398C228.533 142.427 229.039 141.973 229.071 141.456C229.105 140.919 228.681 140.45 228.135 140.419C227.583 140.387 227.124 140.794 227.092 141.344C227.062 141.879 227.494 142.368 228.023 142.398Z" fill="#747070" />
        <path d="M233.773 140.416C233.222 140.449 232.817 140.912 232.853 141.463C232.889 142.009 233.363 142.433 233.896 142.393C234.408 142.356 234.867 141.842 234.836 141.338C234.804 140.813 234.31 140.383 233.773 140.416Z" fill="#747070" />
        <path d="M239.568 142.399C240.087 142.408 240.572 141.944 240.587 141.423C240.602 140.883 240.165 140.431 239.615 140.417C239.061 140.403 238.619 140.825 238.609 141.377C238.597 141.926 239.034 142.39 239.568 142.399Z" fill="#747070" />
        <path d="M245.298 142.401C245.804 142.424 246.313 141.958 246.342 141.446C246.372 140.91 245.945 140.447 245.394 140.418C244.838 140.39 244.393 140.795 244.364 141.355C244.337 141.899 244.763 142.377 245.298 142.401Z" fill="#747070" />
        <path d="M251.052 140.416C250.502 140.443 250.098 140.896 250.125 141.455C250.153 142.001 250.621 142.429 251.155 142.394C251.668 142.362 252.131 141.851 252.104 141.347C252.078 140.821 251.59 140.389 251.052 140.416Z" fill="#747070" />
        <path d="M255.88 141.369C255.862 141.917 256.289 142.385 256.826 142.4C257.338 142.415 257.837 141.947 257.856 141.432C257.876 140.893 257.444 140.439 256.893 140.419C256.337 140.398 255.899 140.809 255.88 141.369Z" fill="#747070" />
        <path d="M261.639 141.354C261.613 141.901 262.033 142.373 262.571 142.401C263.077 142.426 263.581 141.961 263.608 141.445C263.635 140.901 263.217 140.447 262.664 140.419C262.105 140.392 261.666 140.793 261.639 141.354Z" fill="#747070" />
        <path d="M105.29 155.63C105.278 155.097 104.816 154.655 104.278 154.665C103.745 154.674 103.29 155.142 103.304 155.668C103.317 156.213 103.764 156.638 104.317 156.626C104.866 156.614 105.301 156.171 105.29 155.63Z" fill="#747070" />
        <path d="M98.7837 154.716C98.2511 154.725 97.7962 155.192 97.8094 155.718C97.8225 156.264 98.2703 156.688 98.8231 156.677C99.374 156.666 99.8076 156.221 99.7965 155.681C99.7854 155.148 99.3214 154.705 98.7837 154.716Z" fill="#747070" />
        <path d="M110.113 154.665C109.577 154.633 109.098 155.056 109.063 155.59C109.029 156.127 109.45 156.595 109.993 156.626C110.542 156.657 111.016 156.245 111.051 155.709C111.083 155.19 110.64 154.696 110.113 154.665Z" fill="#747070" />
        <path d="M115.821 156.626C116.372 156.62 116.808 156.181 116.803 155.638C116.798 155.107 116.335 154.659 115.798 154.665C115.264 154.671 114.81 155.132 114.816 155.661C114.822 156.205 115.267 156.632 115.821 156.626Z" fill="#747070" />
        <path d="M121.611 156.627C122.157 156.603 122.58 156.147 122.56 155.607C122.54 155.074 122.068 154.648 121.524 154.673C120.988 154.698 120.548 155.171 120.573 155.696C120.598 156.239 121.057 156.651 121.611 156.627Z" fill="#747070" />
        <path d="M127.374 156.627C127.923 156.598 128.339 156.144 128.311 155.599C128.285 155.063 127.812 154.642 127.271 154.672C126.733 154.702 126.298 155.179 126.328 155.704C126.359 156.246 126.822 156.655 127.374 156.627Z" fill="#747070" />
        <path d="M133.097 156.627C133.648 156.616 134.08 156.174 134.072 155.633C134.064 155.101 133.601 154.661 133.06 154.674C132.518 154.685 132.075 155.139 132.086 155.67C132.098 156.218 132.543 156.638 133.097 156.627Z" fill="#747070" />
        <path d="M138.816 156.628C139.365 156.639 139.817 156.213 139.83 155.671C139.844 155.146 139.39 154.683 138.854 154.673C138.306 154.664 137.851 155.095 137.844 155.631C137.837 156.169 138.272 156.617 138.816 156.628Z" fill="#747070" />
        <path d="M144.589 154.672C144.037 154.674 143.595 155.113 143.6 155.653C143.605 156.195 144.049 156.63 144.596 156.628C145.147 156.626 145.59 156.188 145.588 155.649C145.586 155.12 145.125 154.67 144.589 154.672Z" fill="#747070" />
        <path d="M184.944 156.624C185.485 156.595 185.91 156.126 185.878 155.593C185.845 155.061 185.368 154.642 184.831 154.672C184.287 154.703 183.865 155.168 183.898 155.704C183.93 156.238 184.401 156.652 184.944 156.624Z" fill="#747070" />
        <path d="M190.706 156.624C191.243 156.596 191.67 156.121 191.635 155.59C191.599 155.066 191.11 154.638 190.581 154.67C190.034 154.702 189.616 155.168 189.654 155.707C189.691 156.243 190.159 156.651 190.706 156.624Z" fill="#747070" />
        <path d="M196.454 156.623C196.992 156.6 197.423 156.129 197.395 155.597C197.367 155.076 196.879 154.643 196.348 154.669C195.793 154.696 195.38 155.152 195.413 155.696C195.446 156.237 195.906 156.648 196.454 156.623Z" fill="#747070" />
        <path d="M202.163 154.671C201.603 154.666 201.177 155.083 201.174 155.64C201.171 156.189 201.593 156.615 202.151 156.625C202.692 156.634 203.15 156.193 203.156 155.657C203.162 155.135 202.7 154.676 202.163 154.671Z" fill="#747070" />
        <path d="M265.471 164.023C264.917 164.026 264.496 164.455 264.498 165.012C264.5 165.569 264.924 165.999 265.472 166.001C266.013 166.003 266.449 165.562 266.448 165.013C266.446 164.461 266.011 164.02 265.471 164.023Z" fill="#747070" />
        <path d="M153.291 170.464C153.838 170.44 154.264 169.978 154.238 169.437C154.212 168.907 153.728 168.463 153.203 168.487C152.678 168.512 152.227 169.004 152.251 169.526C152.278 170.068 152.743 170.488 153.291 170.464Z" fill="#747070" />
        <path d="M262.665 168.491C262.126 168.46 261.666 168.883 261.64 169.431C261.615 169.99 262.016 170.439 262.567 170.469C263.102 170.499 263.584 170.065 263.61 169.534C263.636 169.021 263.184 168.521 262.665 168.491Z" fill="#747070" />
        <path d="M268.429 168.492C267.891 168.457 267.424 168.878 267.396 169.426C267.366 169.982 267.77 170.438 268.319 170.47C268.853 170.501 269.346 170.067 269.373 169.541C269.398 169.034 268.939 168.524 268.429 168.492Z" fill="#747070" />
        <path d="M274.068 168.484C273.534 168.517 273.118 168.999 273.152 169.544C273.186 170.1 273.638 170.5 274.192 170.465C274.739 170.432 275.158 169.961 275.122 169.423C275.087 168.913 274.57 168.453 274.068 168.484Z" fill="#747070" />
        <path d="M12.2365 42.3182C11.7139 42.295 11.2237 42.7464 11.2015 43.2706C11.1792 43.7978 11.624 44.2805 12.1536 44.3027C12.6933 44.3249 13.1542 43.8977 13.1785 43.3524C13.2017 42.806 12.7792 42.3415 12.2365 42.3182Z" fill="#747070" />
        <path d="M17.9829 42.3227C17.4523 42.3056 16.9732 42.753 16.956 43.2811C16.9389 43.8043 17.3927 44.288 17.9183 44.3052C18.458 44.3234 18.9148 43.8911 18.9361 43.3448C18.9563 42.7984 18.5277 42.3399 17.9829 42.3227Z" fill="#747070" />
        <path d="M202.175 42.3239C201.629 42.3128 201.179 42.7471 201.171 43.2934C201.163 43.8408 201.598 44.2953 202.138 44.3064C202.663 44.3175 203.142 43.856 203.152 43.3318C203.162 42.8047 202.706 42.334 202.175 42.3239Z" fill="#747070" />
        <path d="M208.904 43.3325C208.915 42.8083 208.458 42.3317 207.932 42.3206C207.39 42.3084 206.94 42.7437 206.931 43.2901C206.921 43.8405 207.348 44.2919 207.892 44.305C208.418 44.3171 208.893 43.8607 208.904 43.3325Z" fill="#747070" />
        <path d="M225.235 44.3008C225.763 44.2725 226.202 43.7837 226.172 43.2565C226.143 42.7385 225.639 42.286 225.125 42.3153C224.58 42.3466 224.17 42.8122 224.201 43.3646C224.231 43.911 224.694 44.3301 225.235 44.3008Z" fill="#747070" />
        <path d="M230.955 44.3036C231.481 44.2925 231.94 43.8148 231.929 43.2906C231.917 42.7655 231.437 42.306 230.913 42.3181C230.367 42.3302 229.936 42.7837 229.952 43.332C229.966 43.8824 230.412 44.3147 230.955 44.3036Z" fill="#747070" />
        <path d="M15.0663 46.7898C14.5427 46.802 14.101 47.2796 14.1192 47.8159C14.1374 48.3623 14.5922 48.7925 15.1289 48.7733C15.6798 48.7531 16.0962 48.3067 16.076 47.7553C16.0578 47.211 15.604 46.7767 15.0663 46.7898Z" fill="#747070" />
        <path d="M61.1963 48.7724C61.7472 48.7411 62.1565 48.2857 62.1272 47.7343C62.0979 47.1859 61.6299 46.7617 61.0963 46.7981C60.5727 46.8334 60.1199 47.3303 60.1472 47.8393C60.1755 48.3644 60.6667 48.8017 61.1963 48.7724Z" fill="#747070" />
        <path d="M66.914 48.7716C67.4679 48.7625 67.8924 48.3272 67.8863 47.7738C67.8802 47.2254 67.4295 46.7821 66.8928 46.7962C66.3682 46.8104 65.9012 47.286 65.9073 47.8001C65.9133 48.3343 66.3753 48.7807 66.914 48.7716Z" fill="#747070" />
        <path d="M72.6663 48.7715C73.2233 48.7644 73.6478 48.3322 73.6427 47.7788C73.6377 47.2284 73.1899 46.784 72.6522 46.7961C72.1266 46.8083 71.6556 47.2839 71.6617 47.796C71.6688 48.3332 72.1266 48.7786 72.6663 48.7715Z" fill="#747070" />
        <path d="M78.4402 46.794C77.9247 46.7879 77.4335 47.2545 77.4183 47.7645C77.4032 48.2978 77.8479 48.7593 78.3886 48.7704C78.9324 48.7815 79.3852 48.3513 79.3994 47.81C79.4145 47.2687 78.9718 46.8011 78.4402 46.794Z" fill="#747070" />
        <path d="M84.1826 48.7718C84.7274 48.7606 85.164 48.3122 85.158 47.7709C85.1519 47.2286 84.692 46.7792 84.1604 46.7964C83.6328 46.8135 83.1668 47.2872 83.1739 47.8012C83.18 48.3335 83.6479 48.7829 84.1826 48.7718Z" fill="#747070" />
        <path d="M89.9039 46.7972C89.3742 46.8204 88.9154 47.2971 88.9285 47.8121C88.9416 48.3434 89.4167 48.7887 89.9493 48.7726C90.4931 48.7554 90.9257 48.3009 90.9136 47.7596C90.9015 47.2183 90.4355 46.775 89.9039 46.7972Z" fill="#747070" />
        <path d="M95.7084 46.7954C95.1848 46.7924 94.7027 47.2529 94.6926 47.768C94.6815 48.3022 95.1262 48.7637 95.6639 48.7748C96.2016 48.786 96.6585 48.3477 96.6746 47.8074C96.6888 47.2721 96.236 46.7985 95.7084 46.7954Z" fill="#747070" />
        <path d="M205.07 46.797C204.538 46.7778 204.073 47.2272 204.067 47.7655C204.061 48.3007 204.505 48.7612 205.037 48.7734C205.573 48.7855 206.037 48.3401 206.047 47.8059C206.055 47.2858 205.599 46.8162 205.07 46.797Z" fill="#747070" />
        <path d="M210.86 48.7729C211.393 48.7467 211.832 48.268 211.804 47.7398C211.777 47.2298 211.274 46.7743 210.758 46.7925C210.235 46.8107 209.79 47.3045 209.82 47.8347C209.849 48.37 210.324 48.7982 210.86 48.7729Z" fill="#747070" />
        <path d="M223.316 47.7349C223.285 47.227 222.776 46.7705 222.266 46.7907C221.743 46.8109 221.306 47.3047 221.336 47.839C221.366 48.3762 221.836 48.7964 222.378 48.7701C222.914 48.7428 223.349 48.2621 223.316 47.7349Z" fill="#747070" />
        <path d="M229.074 47.7345C229.043 47.2296 228.528 46.7701 228.021 46.7923C227.492 46.8155 227.064 47.3003 227.093 47.8416C227.123 48.3839 227.586 48.7979 228.133 48.7707C228.671 48.7424 229.108 48.2617 229.074 47.7345Z" fill="#747070" />
        <path d="M233.888 48.7692C234.426 48.743 234.857 48.2683 234.828 47.7341C234.8 47.221 234.296 46.7686 233.78 46.7908C233.252 46.8141 232.821 47.2998 232.849 47.8391C232.876 48.3814 233.34 48.7965 233.888 48.7692Z" fill="#747070" />
        <path d="M239.614 48.7704C240.157 48.7603 240.6 48.3038 240.587 47.7666C240.574 47.2576 240.083 46.788 239.569 46.793C239.036 46.7981 238.598 47.2606 238.609 47.808C238.619 48.3553 239.062 48.7805 239.614 48.7704Z" fill="#747070" />
        <path d="M285.666 48.7692C286.207 48.7571 286.635 48.3056 286.622 47.7583C286.609 47.2301 286.13 46.7665 285.615 46.7857C285.076 46.8059 284.648 47.2654 284.66 47.8098C284.672 48.3582 285.116 48.7813 285.666 48.7692Z" fill="#747070" />
        <path d="M6.48918 53.6764C7.00567 53.6441 7.44837 53.1432 7.42209 52.619C7.3948 52.0666 6.94401 51.6647 6.38507 51.695C5.83726 51.7243 5.41275 52.1898 5.44509 52.7251C5.47642 53.2391 5.98988 53.7077 6.48918 53.6764Z" fill="#747070" />
        <path d="M11.2014 52.7149C11.2277 53.234 11.7311 53.7006 12.2364 53.6743C12.759 53.647 13.2017 53.1572 13.1774 52.6311C13.1521 52.0726 12.7125 51.6707 12.1525 51.6949C11.6037 51.7191 11.1741 52.1796 11.2014 52.7149Z" fill="#747070" />
        <path d="M17.9168 53.6702C18.4413 53.6874 18.9133 53.239 18.9305 52.7058C18.9487 52.1473 18.5404 51.711 17.9825 51.6939C17.4356 51.6767 16.9646 52.1079 16.9495 52.6391C16.9363 53.1481 17.4104 53.6531 17.9168 53.6702Z" fill="#747070" />
        <path d="M23.6726 53.6708C24.1941 53.6889 24.6682 53.2395 24.6904 52.7053C24.7137 52.1549 24.2983 51.7136 23.7403 51.6954C23.1925 51.6772 22.7235 52.1054 22.7084 52.6396C22.6932 53.1486 23.1652 53.6526 23.6726 53.6708Z" fill="#747070" />
        <path d="M29.4076 53.6694C29.9261 53.7017 30.4153 53.2593 30.4487 52.7291C30.483 52.1818 30.0747 51.7263 29.5198 51.695C28.977 51.6637 28.4949 52.0848 28.4646 52.6181C28.4363 53.1169 28.9043 53.6381 29.4076 53.6694Z" fill="#747070" />
        <path d="M35.1606 53.6634C35.6862 53.6967 36.1714 53.2635 36.2037 52.7312C36.2371 52.1839 35.8297 51.7294 35.2779 51.6961C34.7351 51.6638 34.255 52.0809 34.2207 52.6141C34.1903 53.117 34.6502 53.6311 35.1606 53.6634Z" fill="#747070" />
        <path d="M40.9857 53.6661C41.5123 53.6611 41.9702 53.1915 41.9631 52.6633C41.956 52.1109 41.5234 51.6928 40.9635 51.6968C40.4187 51.7008 39.976 52.1462 39.979 52.6855C39.9821 53.2006 40.4652 53.6712 40.9857 53.6661Z" fill="#747070" />
        <path d="M46.756 51.6966C46.2071 51.6835 45.7493 52.1096 45.7351 52.6489C45.722 53.164 46.1879 53.6518 46.7075 53.6659C47.2371 53.6811 47.704 53.2337 47.7182 52.6984C47.7313 52.148 47.3109 51.7107 46.756 51.6966Z" fill="#747070" />
        <path d="M92.7522 53.6717C93.2778 53.6908 93.7559 53.2414 93.7741 52.7092C93.7933 52.1649 93.3657 51.7124 92.8169 51.6953C92.2671 51.6791 91.8062 52.1043 91.789 52.6436C91.7728 53.1647 92.2297 53.6525 92.7522 53.6717Z" fill="#747070" />
        <path d="M98.5779 53.6715C99.1015 53.6523 99.5553 53.1615 99.5341 52.6394C99.5118 52.092 99.054 51.6739 98.5021 51.6951C97.9523 51.7173 97.5227 52.1748 97.549 52.7121C97.5743 53.2423 98.0554 53.6907 98.5779 53.6715Z" fill="#747070" />
        <path d="M104.264 51.695C103.719 51.7142 103.286 52.1716 103.304 52.7089C103.322 53.232 103.809 53.6875 104.33 53.6683C104.855 53.6491 105.311 53.1634 105.292 52.6423C105.272 52.0979 104.815 51.6758 104.264 51.695Z" fill="#747070" />
        <path d="M110.074 51.6995C109.521 51.6874 109.07 52.1125 109.058 52.6559C109.046 53.18 109.506 53.6577 110.032 53.6688C110.554 53.6799 111.034 53.2214 111.045 52.6983C111.058 52.158 110.623 51.7106 110.074 51.6995Z" fill="#747070" />
        <path d="M114.818 52.6932C114.826 53.2164 115.303 53.6779 115.826 53.6698C116.35 53.6617 116.812 53.1851 116.804 52.6599C116.796 52.1206 116.341 51.6864 115.793 51.6955C115.243 51.7045 114.808 52.1489 114.818 52.6932Z" fill="#747070" />
        <path d="M121.581 51.6955C121.027 51.6864 120.583 52.1125 120.574 52.6619C120.565 53.1891 121.025 53.6638 121.55 53.6718C122.072 53.6789 122.548 53.2164 122.558 52.6922C122.568 52.1519 122.129 51.7045 121.581 51.6955Z" fill="#747070" />
        <path d="M127.366 53.6707C127.888 53.6445 128.34 53.1496 128.317 52.6316C128.293 52.0913 127.826 51.6701 127.278 51.6944C126.731 51.7186 126.308 52.1791 126.334 52.7204C126.358 53.2547 126.841 53.697 127.366 53.6707Z" fill="#747070" />
        <path d="M92.821 34.9317C93.3617 34.9115 93.7943 34.4489 93.7741 33.9117C93.7539 33.3835 93.2718 32.9361 92.7452 32.9583C92.2237 32.9795 91.7719 33.4673 91.789 33.9904C91.8042 34.5247 92.2732 34.9519 92.821 34.9317Z" fill="#747070" />
        <path d="M174.369 52.6779C174.372 52.1386 173.928 51.6983 173.38 51.6963C172.82 51.6953 172.39 52.1154 172.387 52.6688C172.384 53.198 172.844 53.6646 173.371 53.6666C173.89 53.6686 174.367 53.197 174.369 52.6779Z" fill="#747070" />
        <path d="M179.074 53.6686C179.581 53.6989 180.089 53.2424 180.12 52.7283C180.152 52.1921 179.729 51.7245 179.186 51.6962C178.63 51.668 178.173 52.0699 178.141 52.6163C178.111 53.1374 178.555 53.6373 179.074 53.6686Z" fill="#747070" />
        <path d="M184.871 51.6965C184.313 51.7076 183.892 52.1368 183.901 52.6892C183.909 53.2194 184.374 53.6728 184.902 53.6658C185.42 53.6587 185.888 53.176 185.881 52.6569C185.872 52.1176 185.419 51.6864 184.871 51.6965Z" fill="#747070" />
        <path d="M190.575 53.6753C191.072 53.7137 191.594 53.2522 191.634 52.7411C191.675 52.2079 191.254 51.7302 190.711 51.6959C190.157 51.6616 189.689 52.0635 189.655 52.6048C189.62 53.1239 190.061 53.6359 190.575 53.6753Z" fill="#747070" />
        <path d="M196.359 53.6742C196.865 53.6984 197.365 53.2329 197.392 52.7128C197.42 52.1795 196.989 51.718 196.44 51.6948C195.882 51.6716 195.431 52.0816 195.412 52.63C195.395 53.1581 195.84 53.649 196.359 53.6742Z" fill="#747070" />
        <path d="M202.147 51.6954C201.586 51.7004 201.17 52.1226 201.173 52.6831C201.176 53.2112 201.642 53.6758 202.164 53.6718C202.675 53.6677 203.155 53.1789 203.153 52.6639C203.153 52.1306 202.695 51.6893 202.147 51.6954Z" fill="#747070" />
        <path d="M207.901 53.6738C208.41 53.6839 208.898 53.2042 208.908 52.6861C208.918 52.1499 208.474 51.7015 207.925 51.6954C207.365 51.6883 206.936 52.1044 206.928 52.6619C206.922 53.1901 207.376 53.6637 207.901 53.6738Z" fill="#747070" />
        <path d="M213.946 52.5362C213.956 52 213.511 51.5516 212.962 51.5455C212.403 51.5384 211.974 51.9545 211.966 52.512C211.959 53.0412 212.414 53.5148 212.937 53.5249C213.448 53.534 213.936 53.0543 213.946 52.5362Z" fill="#747070" />
        <path d="M223.136 56.1578C222.576 56.1507 222.148 56.5668 222.14 57.1243C222.133 57.6535 222.587 58.1271 223.111 58.1372C223.62 58.1473 224.109 57.6676 224.119 57.1495C224.13 56.6133 223.684 56.1649 223.136 56.1578Z" fill="#747070" />
        <path d="M225.169 51.6939C224.606 51.6979 224.195 52.1201 224.202 52.6816C224.209 53.2108 224.677 53.6783 225.192 53.6713C225.702 53.6642 226.181 53.1714 226.174 52.6604C226.168 52.117 225.723 51.6898 225.169 51.6939Z" fill="#747070" />
        <path d="M230.937 53.6723C231.455 53.6672 231.927 53.1885 231.925 52.6694C231.923 52.121 231.486 51.6918 230.932 51.6939C230.371 51.6959 229.96 52.113 229.961 52.6785C229.961 53.2289 230.402 53.6763 230.937 53.6723Z" fill="#747070" />
        <path d="M236.626 53.6765C237.131 53.7108 237.635 53.2574 237.67 52.7373C237.706 52.1768 237.316 51.7304 236.757 51.6951C236.2 51.6597 235.751 52.0546 235.715 52.609C235.68 53.1473 236.102 53.6412 236.626 53.6765Z" fill="#747070" />
        <path d="M242.35 53.5261C242.856 53.5605 243.36 53.107 243.394 52.5869C243.431 52.0264 243.041 51.58 242.482 51.5447C241.925 51.5093 241.476 51.9042 241.44 52.4586C241.404 52.9959 241.827 53.4898 242.35 53.5261Z" fill="#747070" />
        <path d="M20.8334 58.1427C21.3761 58.1488 21.8239 57.7115 21.833 57.1651C21.8431 56.6117 21.4206 56.1714 20.8687 56.1613C20.3331 56.1512 19.8661 56.5986 19.858 57.1298C19.8499 57.6529 20.3169 58.1367 20.8334 58.1427Z" fill="#747070" />
        <path d="M60.149 57.1303C60.1419 57.6433 60.6099 58.122 61.1335 58.1371C61.6692 58.1533 62.1169 57.713 62.128 57.1606C62.1381 56.6081 61.7146 56.1708 61.1608 56.1618C60.622 56.1527 60.1551 56.599 60.149 57.1303Z" fill="#747070" />
        <path d="M66.8246 56.1642C66.292 56.2036 65.8655 56.6955 65.9099 57.2226C65.9524 57.7306 66.4729 58.178 66.9813 58.1427C67.5069 58.1073 67.9284 57.6084 67.8869 57.0711C67.8445 56.5329 67.3664 56.1249 66.8246 56.1642Z" fill="#747070" />
        <path d="M72.6481 56.1616C72.1073 56.1647 71.6586 56.6171 71.6636 57.1564C71.6677 57.6674 72.1498 58.136 72.6723 58.138C73.208 58.14 73.6497 57.6846 73.6436 57.1362C73.6376 56.5858 73.202 56.1586 72.6481 56.1616Z" fill="#747070" />
        <path d="M78.401 56.1616C77.8613 56.1657 77.4095 56.6222 77.4176 57.1554C77.4257 57.6664 77.9088 58.135 78.4283 58.137C78.961 58.139 79.4077 57.6785 79.3996 57.1352C79.3916 56.5929 78.9468 56.1576 78.401 56.1616Z" fill="#747070" />
        <path d="M84.226 58.1416C84.7486 58.1214 85.1883 57.6286 85.1569 57.0954C85.1266 56.5611 84.6516 56.137 84.1149 56.1622C83.5812 56.1875 83.1446 56.6702 83.1759 57.1984C83.2062 57.7053 83.7126 58.1608 84.226 58.1416Z" fill="#747070" />
        <path d="M89.9068 56.1612C89.369 56.1673 88.9193 56.6278 88.9294 57.163C88.9395 57.671 89.4256 58.1386 89.9452 58.1386C90.4748 58.1386 90.9246 57.671 90.9134 57.1317C90.9023 56.5944 90.4465 56.1551 89.9068 56.1612Z" fill="#747070" />
        <path d="M94.6911 57.1626C94.7002 57.6746 95.1813 58.1382 95.7038 58.1382C96.2314 58.1372 96.6862 57.6645 96.6721 57.1313C96.6579 56.589 96.2041 56.1517 95.6654 56.1598C95.1287 56.1689 94.682 56.6284 94.6911 57.1626Z" fill="#747070" />
        <path d="M210.787 56.16C210.249 56.1752 209.809 56.6357 209.822 57.173C209.834 57.7102 210.304 58.1586 210.832 58.1364C211.363 58.1142 211.821 57.6365 211.808 57.1215C211.793 56.5893 211.321 56.1449 210.787 56.16Z" fill="#747070" />
        <path d="M213.567 61.0639C213.029 61.079 212.59 61.5395 212.602 62.0768C212.614 62.6141 213.084 63.0625 213.613 63.0402C214.143 63.018 214.601 62.5403 214.588 62.0253C214.575 61.4931 214.103 61.0487 213.567 61.0639Z" fill="#747070" />
        <path d="M216.59 67.7302C217.12 67.7079 217.578 67.2302 217.565 66.7152C217.551 66.184 217.08 65.7396 216.544 65.7548C216.006 65.7699 215.567 66.2304 215.579 66.7677C215.591 67.304 216.061 67.7524 216.59 67.7302Z" fill="#747070" />
        <path d="M227.094 57.1032C227.071 57.6476 227.502 58.1242 228.033 58.1414C228.542 58.1576 229.051 57.692 229.075 57.1891C229.101 56.6589 228.662 56.1852 228.123 56.164C227.573 56.1408 227.117 56.5579 227.094 57.1032Z" fill="#747070" />
        <path d="M233.788 56.161C233.235 56.1892 232.824 56.6437 232.851 57.1941C232.878 57.7425 233.344 58.1676 233.88 58.1343C234.409 58.101 234.859 57.6122 234.833 57.0982C234.805 56.57 234.319 56.1327 233.788 56.161Z" fill="#747070" />
        <path d="M239.541 56.1601C238.991 56.1904 238.58 56.6479 238.609 57.1973C238.638 57.7436 239.108 58.1708 239.64 58.1344C240.164 58.0981 240.616 57.6032 240.589 57.0932C240.562 56.5681 240.071 56.1308 239.541 56.1601Z" fill="#747070" />
        <path d="M279.866 56.1614C279.315 56.1745 278.891 56.6159 278.903 57.1673C278.914 57.7126 279.365 58.1499 279.905 58.1388C280.427 58.1277 280.891 57.646 280.88 57.1259C280.868 56.5926 280.402 56.1483 279.866 56.1614Z" fill="#747070" />
        <path d="M29.4802 70.4219C28.9313 70.4169 28.4755 70.8491 28.4674 71.3844C28.4593 71.9297 28.8889 72.3599 29.4508 72.368C30.0118 72.3761 30.4393 71.9661 30.4505 71.4086C30.4626 70.8531 30.0441 70.427 29.4802 70.4219Z" fill="#747070" />
        <path d="M268.377 84.2324C267.859 84.2334 267.398 84.705 267.396 85.2322C267.393 85.7927 267.811 86.2098 268.374 86.2108C268.924 86.2128 269.365 85.7776 269.366 85.2322C269.367 84.7111 268.892 84.2314 268.377 84.2324Z" fill="#747070" />
        <path d="M41.01 81.7452C41.5498 81.723 41.9803 81.2615 41.9632 80.7212C41.946 80.1809 41.476 79.7425 40.9443 79.7708C40.4157 79.7991 39.9599 80.2829 39.9781 80.7949C39.9973 81.322 40.4804 81.7664 41.01 81.7452Z" fill="#747070" />
        <path d="M179.115 79.7676C178.581 79.7656 178.135 80.2261 178.145 80.7694C178.154 81.3097 178.605 81.749 179.145 81.744C179.683 81.7389 180.136 81.2804 180.128 80.7472C180.12 80.2392 179.634 79.7696 179.115 79.7676Z" fill="#747070" />
        <path d="M236.626 81.7452C237.155 81.7816 237.651 81.3503 237.687 80.8252C237.721 80.3132 237.274 79.8082 236.756 79.7718C236.22 79.7345 235.743 80.1546 235.71 80.6939C235.675 81.2413 236.083 81.7078 236.626 81.7452Z" fill="#747070" />
        <path d="M265.544 79.7631C265.004 79.7298 264.533 80.1458 264.497 80.6861C264.461 81.2426 264.856 81.7041 265.404 81.7445C265.939 81.7829 266.42 81.3618 266.453 80.8265C266.486 80.2903 266.063 79.7954 265.544 79.7631Z" fill="#747070" />
        <path d="M26.614 84.2291C26.0995 84.221 25.6306 84.6927 25.6265 85.2239C25.6215 85.7823 26.0419 86.2085 26.5978 86.2105C27.1608 86.2126 27.5792 85.8015 27.5833 85.241C27.5883 84.7159 27.1295 84.2372 26.614 84.2291Z" fill="#747070" />
        <path d="M32.4198 86.2137C32.9757 86.1824 33.375 85.732 33.3406 85.1755C33.3072 84.6464 32.8201 84.204 32.3026 84.2333C31.7972 84.2626 31.3373 84.7807 31.3676 85.2887C31.399 85.8259 31.875 86.244 32.4198 86.2137Z" fill="#747070" />
        <path d="M38.119 86.2108C38.681 86.2108 39.0994 85.7937 39.0984 85.2332C39.0974 84.7051 38.6355 84.2344 38.118 84.2324C37.6035 84.2304 37.1285 84.7101 37.1274 85.2322C37.1254 85.7756 37.5661 86.2098 38.119 86.2108Z" fill="#747070" />
        <path d="M44.8546 85.1705C44.8203 84.6423 44.327 84.201 43.8116 84.2364C43.3052 84.2707 42.8413 84.7938 42.8726 85.2937C42.9059 85.8189 43.3992 86.2441 43.9369 86.2128C44.4908 86.1794 44.891 85.726 44.8546 85.1705Z" fill="#747070" />
        <path d="M49.693 84.2284C49.1967 84.194 48.6772 84.6586 48.6398 85.1706C48.6003 85.7058 49.0208 86.1755 49.5686 86.2088C50.1286 86.2431 50.5824 85.8482 50.6158 85.2958C50.6461 84.7727 50.2064 84.2637 49.693 84.2284Z" fill="#747070" />
        <path d="M55.4451 86.2119C55.999 86.1806 56.3993 85.7312 56.369 85.1738C56.3396 84.6456 55.8504 84.2032 55.3309 84.2356C54.8266 84.2679 54.3606 84.788 54.3889 85.2879C54.4172 85.8181 54.9034 86.2422 55.4451 86.2119Z" fill="#747070" />
        <path d="M61.126 84.2336C60.6136 84.2437 60.1385 84.7365 60.1456 85.2515C60.1527 85.7848 60.6166 86.2221 61.1634 86.211C61.7223 86.1998 62.1408 85.7676 62.1297 85.2132C62.1185 84.684 61.6465 84.2235 61.126 84.2336Z" fill="#747070" />
        <path d="M67.8864 85.2332C67.8823 84.701 67.4214 84.2324 66.9019 84.2324C66.3885 84.2324 65.9104 84.7121 65.9053 85.2312C65.9003 85.7654 66.3541 86.2108 66.9009 86.2098C67.4619 86.2088 67.8904 85.7836 67.8864 85.2332Z" fill="#747070" />
        <path d="M72.7115 84.2303C72.2061 84.201 71.6977 84.6626 71.6664 85.1786C71.633 85.7149 72.0606 86.1835 72.6054 86.2097C73.1602 86.236 73.6181 85.828 73.6454 85.2826C73.6727 84.7484 73.2391 84.2606 72.7115 84.2303Z" fill="#747070" />
        <path d="M79.3995 85.2312C79.4035 84.6899 78.9548 84.2344 78.4181 84.2324C77.8995 84.2314 77.4225 84.7061 77.4194 85.2262C77.4164 85.7665 77.8632 86.2098 78.41 86.2088C78.9689 86.2088 79.3964 85.7877 79.3995 85.2312Z" fill="#747070" />
        <path d="M84.1623 86.2088C84.7253 86.2118 85.1508 85.7947 85.1569 85.2362C85.162 84.6959 84.7132 84.2344 84.1795 84.2334C83.661 84.2324 83.1759 84.7091 83.1738 85.2211C83.1718 85.7634 83.6145 86.2057 84.1623 86.2088Z" fill="#747070" />
        <path d="M89.9333 84.2388C89.4067 84.2358 88.9357 84.6993 88.9317 85.2224C88.9276 85.7648 89.3693 86.2071 89.9181 86.2091C90.4771 86.2121 90.9066 85.791 90.9127 85.2356C90.9188 84.6963 90.473 84.2418 89.9333 84.2388Z" fill="#747070" />
        <path d="M135.994 84.2335C135.473 84.2254 134.996 84.688 134.986 85.2121C134.974 85.7524 135.412 86.1998 135.961 86.2099C136.515 86.22 136.958 85.7958 136.968 85.2444C136.979 84.7172 136.52 84.2416 135.994 84.2335Z" fill="#747070" />
        <path d="M141.699 86.2088C142.252 86.229 142.708 85.8099 142.727 85.2616C142.746 84.7384 142.291 84.2496 141.768 84.2325C141.248 84.2143 140.766 84.6667 140.742 85.1949C140.718 85.7332 141.149 86.1886 141.699 86.2088Z" fill="#747070" />
        <path d="M147.508 84.2335C146.987 84.2254 146.506 84.691 146.497 85.2111C146.487 85.7524 146.925 86.1978 147.474 86.2069C148.026 86.2159 148.475 85.7898 148.484 85.2454C148.493 84.7203 148.032 84.2416 147.508 84.2335Z" fill="#747070" />
        <path d="M154.242 85.2764C154.27 84.7614 153.816 84.2615 153.295 84.2332C152.775 84.2049 152.284 84.6503 152.256 85.1785C152.228 85.7178 152.651 86.1813 153.197 86.2066C153.749 86.2318 154.214 85.8177 154.242 85.2764Z" fill="#747070" />
        <path d="M158.969 84.2335C158.446 84.2517 157.991 84.7415 158.01 85.2626C158.029 85.81 158.485 86.2301 159.037 86.2099C159.584 86.1907 160.016 85.7312 159.995 85.1949C159.975 84.6718 159.487 84.2143 158.969 84.2335Z" fill="#747070" />
        <path d="M164.806 84.2342C164.281 84.2059 163.796 84.6473 163.771 85.1795C163.745 85.7248 164.165 86.1853 164.71 86.2096C165.259 86.2338 165.728 85.8137 165.753 85.2764C165.779 84.7594 165.326 84.2625 164.806 84.2342Z" fill="#747070" />
        <path d="M216.556 84.2325C216.021 84.2375 215.574 84.7 215.581 85.2393C215.588 85.7938 216.02 86.2149 216.579 86.2099C217.128 86.2048 217.569 85.7594 217.562 85.2181C217.556 84.699 217.073 84.2274 216.556 84.2325Z" fill="#747070" />
        <path d="M222.379 84.2364C221.846 84.2011 221.369 84.6303 221.339 85.1716C221.309 85.721 221.717 86.1775 222.266 86.2098C222.809 86.2421 223.29 85.821 223.322 85.2867C223.352 84.7828 222.892 84.2707 222.379 84.2364Z" fill="#747070" />
        <path d="M228.079 86.2098C228.626 86.2128 229.076 85.7705 229.073 85.2312C229.071 84.7121 228.593 84.2334 228.078 84.2324C227.541 84.2324 227.093 84.6849 227.093 85.2292C227.093 85.7846 227.518 86.2068 228.079 86.2098Z" fill="#747070" />
        <path d="M233.836 86.2098C234.383 86.2118 234.838 85.7655 234.833 85.2312C234.828 84.7111 234.351 84.2334 233.834 84.2324C233.298 84.2314 232.854 84.6828 232.851 85.2302C232.849 85.7847 233.275 86.2088 233.836 86.2098Z" fill="#747070" />
        <path d="M239.656 86.2089C240.2 86.1766 240.625 85.7009 240.586 85.1707C240.547 84.6577 240.03 84.1931 239.535 84.2285C239.022 84.2638 238.579 84.7728 238.608 85.2939C238.636 85.8393 239.099 86.2422 239.656 86.2089Z" fill="#747070" />
        <path d="M246.343 85.1783C246.308 84.6632 245.796 84.1986 245.296 84.2289C244.777 84.2603 244.336 84.7601 244.366 85.2843C244.397 85.8407 244.844 86.2387 245.403 86.2084C245.949 86.1791 246.378 85.7095 246.343 85.1783Z" fill="#747070" />
        <path d="M251.168 84.2364C250.65 84.201 250.159 84.6413 250.125 85.1705C250.09 85.727 250.489 86.1784 251.045 86.2117C251.587 86.2441 252.075 85.8219 252.107 85.2937C252.138 84.7918 251.674 84.2707 251.168 84.2364Z" fill="#747070" />
        <path d="M256.927 84.236C256.41 84.1996 255.919 84.6389 255.883 85.1681C255.846 85.7236 256.246 86.1791 256.799 86.2124C257.337 86.2447 257.829 85.8205 257.863 85.2944C257.897 84.7935 257.435 84.2713 256.927 84.236Z" fill="#747070" />
        <path d="M262.649 84.2343C262.131 84.2191 261.657 84.6736 261.638 85.2038C261.619 85.7612 262.032 86.1965 262.589 86.2106C263.139 86.2238 263.603 85.7956 263.618 85.2603C263.633 84.7523 263.157 84.2494 262.649 84.2343Z" fill="#747070" />
        <path d="M181.974 102.973C181.433 103.004 181.01 103.473 181.041 104.01C181.071 104.541 181.559 104.978 182.086 104.946C182.602 104.914 183.058 104.407 183.028 103.896C182.996 103.361 182.519 102.943 181.974 102.973Z" fill="#747070" />
        <path d="M274.127 84.2315C273.611 84.2376 273.152 84.7112 273.154 85.2394C273.156 85.7999 273.574 86.2129 274.137 86.2119C274.691 86.2109 275.121 85.7787 275.118 85.2273C275.115 84.6961 274.647 84.2254 274.127 84.2315Z" fill="#747070" />
        <path d="M12.1371 89.1358C11.5984 89.1661 11.1668 89.6448 11.2011 90.175C11.2335 90.68 11.75 91.1425 12.2523 91.1163C12.7789 91.089 13.2064 90.6012 13.1781 90.0619C13.1498 89.5206 12.6839 89.1055 12.1371 89.1358Z" fill="#747070" />
        <path d="M17.8753 89.1354C17.3427 89.1738 16.9161 89.6657 16.9596 90.1918C17.001 90.7008 17.5206 91.1482 18.029 91.1138C18.5555 91.0785 18.975 90.5826 18.9356 90.0423C18.8972 89.5041 18.4201 89.0961 17.8753 89.1354Z" fill="#747070" />
        <path d="M23.6994 91.1098C24.2361 91.1219 24.6869 90.6766 24.6929 90.1302C24.698 89.5768 24.2735 89.1425 23.7196 89.1334C23.1809 89.1243 22.719 89.5707 22.7139 90.106C22.7089 90.62 23.1778 91.0977 23.6994 91.1098Z" fill="#747070" />
        <path d="M29.4552 89.1343C28.9144 89.1373 28.4667 89.5888 28.4707 90.127C28.4748 90.6391 28.9569 91.1097 29.4764 91.1107C30.0121 91.1107 30.4548 90.6562 30.4498 90.1089C30.4447 89.5574 30.0101 89.1313 29.4552 89.1343Z" fill="#747070" />
        <path d="M35.1678 89.1355C34.6301 89.1618 34.1944 89.6405 34.2268 90.1687C34.2571 90.6746 34.7685 91.1341 35.2769 91.1139C35.8005 91.0927 36.2382 90.6009 36.2088 90.0657C36.1795 89.5274 35.7105 89.1083 35.1678 89.1355Z" fill="#747070" />
        <path d="M40.9284 89.1334C40.3867 89.1597 39.9541 89.6313 39.9844 90.1645C40.0127 90.6715 40.5211 91.132 41.0305 91.1128C41.5551 91.0937 41.9938 90.6038 41.9655 90.0676C41.9372 89.5253 41.4742 89.1082 40.9284 89.1334Z" fill="#747070" />
        <path d="M47.7203 90.1625C47.7426 89.6232 47.316 89.1576 46.7753 89.1324C46.2436 89.1071 45.7585 89.5465 45.7352 90.0736C45.712 90.5867 46.1638 91.0734 46.6934 91.1068C47.221 91.1401 47.6971 90.7018 47.7203 90.1625Z" fill="#747070" />
        <path d="M167.57 89.1326C167.037 89.1609 166.607 89.6336 166.63 90.1698C166.654 90.7061 167.135 91.1434 167.663 91.107C168.192 91.0717 168.643 90.5809 168.616 90.0698C168.589 89.5427 168.101 89.1044 167.57 89.1326Z" fill="#747070" />
        <path d="M173.341 89.1319C172.802 89.1511 172.37 89.6126 172.386 90.1529C172.402 90.6922 172.874 91.1345 173.404 91.1073C173.931 91.08 174.389 90.5952 174.372 90.0832C174.353 89.5571 173.874 89.1137 173.341 89.1319Z" fill="#747070" />
        <path d="M179.112 89.1325C178.569 89.1426 178.135 89.592 178.143 90.1353C178.151 90.6787 178.612 91.1261 179.144 91.1079C179.67 91.0897 180.134 90.613 180.126 90.098C180.118 89.5668 179.651 89.1224 179.112 89.1325Z" fill="#747070" />
        <path d="M184.86 89.1332C184.308 89.1494 183.888 89.5938 183.902 90.1452C183.915 90.6925 184.374 91.1308 184.908 91.1086C185.435 91.0864 185.894 90.6057 185.882 90.0906C185.87 89.5594 185.398 89.1171 184.86 89.1332Z" fill="#747070" />
        <path d="M190.651 89.1343C190.095 89.1323 189.663 89.5574 189.658 90.1099C189.653 90.6572 190.094 91.1117 190.631 91.1107C191.15 91.1097 191.632 90.638 191.636 90.126C191.641 89.5877 191.192 89.1363 190.651 89.1343Z" fill="#747070" />
        <path d="M196.388 91.1107C196.908 91.1077 197.388 90.6371 197.393 90.124C197.398 89.5888 196.946 89.1343 196.407 89.1333C195.854 89.1323 195.42 89.5595 195.415 90.1119C195.409 90.6593 195.853 91.1137 196.388 91.1107Z" fill="#747070" />
        <path d="M201.173 90.1119C201.171 90.6623 201.612 91.1147 202.147 91.1127C202.667 91.1107 203.146 90.6401 203.151 90.125C203.156 89.5918 202.702 89.1363 202.163 89.1343C201.608 89.1313 201.175 89.5595 201.173 90.1119Z" fill="#747070" />
        <path d="M207.864 89.1325C207.312 89.1608 206.903 89.6143 206.929 90.1677C206.955 90.7161 207.419 91.1413 207.957 91.11C208.475 91.0797 208.934 90.5797 208.91 90.0708C208.886 89.5456 208.394 89.1053 207.864 89.1325Z" fill="#747070" />
        <path d="M213.665 89.1333C213.109 89.1374 212.689 89.5625 212.689 90.124C212.689 90.6744 213.131 91.1188 213.671 91.1127C214.189 91.1067 214.667 90.627 214.665 90.115C214.663 89.5797 214.205 89.1303 213.665 89.1333Z" fill="#747070" />
        <path d="M219.359 89.1311C218.812 89.1684 218.412 89.63 218.445 90.1854C218.478 90.7277 218.954 91.1499 219.486 91.1095C220.002 91.0701 220.451 90.5611 220.416 90.0531C220.381 89.5229 219.89 89.0947 219.359 89.1311Z" fill="#747070" />
        <path d="M254.005 91.1105C254.539 91.0863 254.961 90.6147 254.935 90.0724C254.908 89.526 254.45 89.1039 253.912 89.1311C253.363 89.1594 252.958 89.6108 252.983 90.1653C253.008 90.7086 253.469 91.1348 254.005 91.1105Z" fill="#747070" />
        <path d="M271.171 91.1167C271.687 91.15 272.17 90.7077 272.201 90.1724C272.233 89.619 271.832 89.1655 271.283 89.1362C270.737 89.107 270.281 89.516 270.251 90.0623C270.223 90.6047 270.639 91.0823 271.171 91.1167Z" fill="#747070" />
        <path d="M9.28704 95.5869C9.82678 95.6131 10.2897 95.191 10.318 94.6477C10.3473 94.0963 9.93796 93.6307 9.39519 93.5984C8.8686 93.5661 8.39356 94.0003 8.36424 94.5406C8.33493 95.086 8.75337 95.5606 9.28704 95.5869Z" fill="#747070" />
        <path d="M15.0509 95.5881C15.5937 95.6083 16.0516 95.1831 16.0758 94.6368C16.1001 94.0833 15.6847 93.6238 15.1378 93.5986C14.6153 93.5744 14.1271 94.0238 14.1049 94.5519C14.0816 95.0842 14.5243 95.5679 15.0509 95.5881Z" fill="#747070" />
        <path d="M20.8244 93.602C20.2968 93.6171 19.8461 94.0938 19.8612 94.621C19.8754 95.1461 20.3585 95.6036 20.881 95.5874C21.4248 95.5703 21.8503 95.1148 21.8342 94.5644C21.818 94.017 21.3672 93.5868 20.8244 93.602Z" fill="#747070" />
        <path d="M26.5624 93.6041C26.0308 93.6283 25.5901 94.1101 25.6164 94.6393C25.6416 95.1604 26.1389 95.6148 26.6554 95.5896C27.1971 95.5633 27.6166 95.0967 27.5913 94.5473C27.5671 94.004 27.1031 93.5788 26.5624 93.6041Z" fill="#747070" />
        <path d="M32.3809 93.6012C31.8513 93.5921 31.3793 94.0476 31.3712 94.5778C31.3631 95.1029 31.819 95.5736 32.3496 95.5867C32.8913 95.5998 33.3381 95.1625 33.3482 94.6111C33.3593 94.0607 32.9267 93.6103 32.3809 93.6012Z" fill="#747070" />
        <path d="M38.098 93.6023C37.5703 93.6144 37.1165 94.0881 37.1287 94.6152C37.1408 95.1414 37.6148 95.5959 38.1434 95.5878C38.6822 95.5797 39.1117 95.1232 39.0996 94.5718C39.0865 94.0174 38.6448 93.5902 38.098 93.6023Z" fill="#747070" />
        <path d="M42.8786 94.5547C42.8584 95.0748 43.3112 95.5616 43.8368 95.5838C44.3765 95.607 44.8333 95.1798 44.8556 94.6304C44.8778 94.078 44.4614 93.6236 43.9116 93.6003C43.387 93.5791 42.8988 94.0305 42.8786 94.5547Z" fill="#747070" />
        <path d="M49.599 93.6055C49.0653 93.6216 48.6176 94.0963 48.6358 94.6245C48.655 95.1486 49.1391 95.6021 49.6627 95.5859C50.2055 95.5698 50.63 95.1113 50.6118 94.5619C50.5946 94.0074 50.1509 93.5883 49.599 93.6055Z" fill="#747070" />
        <path d="M251.148 95.5829C251.677 95.5587 252.123 95.0739 252.099 94.5508C252.075 94.0226 251.589 93.5783 251.063 93.6025C250.512 93.6288 250.101 94.0812 250.124 94.6367C250.146 95.183 250.607 95.6082 251.148 95.5829Z" fill="#747070" />
        <path d="M256.906 95.583C257.435 95.5577 257.882 95.073 257.859 94.5488C257.837 94.0257 257.345 93.5773 256.82 93.6015C256.268 93.6278 255.858 94.0802 255.881 94.6367C255.905 95.184 256.364 95.6092 256.906 95.583Z" fill="#747070" />
        <path d="M262.601 95.5876C263.131 95.5926 263.603 95.1362 263.612 94.61C263.621 94.0828 263.163 93.6102 262.635 93.6031C262.087 93.596 261.646 94.0263 261.638 94.5777C261.629 95.1301 262.06 95.5825 262.601 95.5876Z" fill="#747070" />
        <path d="M268.427 95.5837C268.961 95.5494 269.397 95.0636 269.366 94.5374C269.336 94.0133 268.838 93.5669 268.317 93.5982C267.774 93.6305 267.358 94.1022 267.389 94.6495C267.42 95.1939 267.892 95.618 268.427 95.5837Z" fill="#747070" />
        <path d="M274.116 95.5876C274.646 95.5896 275.115 95.1321 275.122 94.6049C275.128 94.0768 274.668 93.6071 274.139 93.6021C273.593 93.597 273.15 94.0343 273.145 94.5837C273.141 95.1362 273.575 95.5845 274.116 95.5876Z" fill="#747070" />
        <path d="M279.933 95.5833C280.46 95.557 280.904 95.0683 280.877 94.5441C280.85 94.019 280.357 93.5716 279.836 93.5988C279.294 93.6271 278.877 94.0927 278.904 94.6421C278.929 95.1854 279.394 95.6106 279.933 95.5833Z" fill="#747070" />
        <path d="M285.702 93.6039C285.162 93.5706 284.691 93.9917 284.661 94.533C284.63 95.0794 285.043 95.553 285.584 95.5914C286.099 95.6278 286.6 95.1794 286.634 94.6532C286.667 94.124 286.234 93.6362 285.702 93.6039Z" fill="#747070" />
        <path d="M25.6226 103.957C25.6256 104.498 26.0713 104.939 26.6131 104.938C27.1639 104.936 27.5884 104.505 27.5854 103.952C27.5824 103.393 27.1639 102.978 26.604 102.979C26.0511 102.979 25.6205 103.408 25.6226 103.957Z" fill="#747070" />
        <path d="M259.768 70.4239C259.193 70.3997 258.767 70.7794 258.738 71.342C258.71 71.9004 259.104 72.3397 259.662 72.3711C260.218 72.4024 260.674 71.9984 260.701 71.451C260.728 70.8946 260.322 70.4482 259.768 70.4239Z" fill="#747070" />
        <path d="M228.043 74.9035C227.49 74.9237 227.072 75.364 227.092 75.9053C227.112 76.4578 227.548 76.8506 228.114 76.8254C228.68 76.8001 229.102 76.3649 229.078 75.8336C229.054 75.3055 228.584 74.8833 228.043 74.9035Z" fill="#747070" />
        <path d="M245.334 121.7C244.775 121.706 244.358 122.132 244.365 122.692C244.372 123.233 244.813 123.659 245.366 123.656C245.906 123.654 246.354 123.201 246.347 122.664C246.341 122.14 245.871 121.693 245.334 121.7Z" fill="#747070" />
        <path d="M302.899 37.4493C302.335 37.4574 301.923 37.8755 301.93 38.433C301.936 38.9915 302.351 39.3954 302.919 39.3954C303.484 39.3954 303.893 38.9824 303.886 38.4168C303.878 37.8432 303.469 37.4413 302.899 37.4493Z" fill="#747070" />
        <path d="M305.721 42.3649C305.157 42.3729 304.745 42.791 304.752 43.3485C304.758 43.907 305.174 44.311 305.742 44.311C306.307 44.311 306.715 43.8979 306.708 43.3334C306.701 42.7587 306.291 42.3568 305.721 42.3649Z" fill="#747070" />
        <path d="M308.239 46.9733C307.675 46.9813 307.263 47.3994 307.27 47.9569C307.276 48.5154 307.692 48.9194 308.26 48.9194C308.825 48.9194 309.233 48.5063 309.226 47.9408C309.22 47.3671 308.809 46.9652 308.239 46.9733Z" fill="#747070" />
        <path d="M305.721 51.7081C305.157 51.7162 304.745 52.1343 304.752 52.6917C304.758 53.2502 305.174 53.6542 305.742 53.6542C306.307 53.6542 306.715 53.2411 306.708 52.6756C306.701 52.103 306.291 51.701 305.721 51.7081Z" fill="#747070" />
        <path d="M302.899 56.1822C302.335 56.1903 301.923 56.6084 301.93 57.1659C301.937 57.7244 302.351 58.1283 302.919 58.1283C303.484 58.1283 303.893 57.7153 303.886 57.1497C303.879 56.5761 303.47 56.1742 302.899 56.1822Z" fill="#747070" />
        <path d="M300 60.8024C299.436 60.8104 299.023 61.2285 299.03 61.786C299.036 62.3445 299.452 62.7485 300.02 62.7485C300.585 62.7485 300.993 62.3354 300.986 61.7709C300.98 61.1962 300.57 60.7943 300 60.8024Z" fill="#747070" />
        <path d="M296.694 65.4679C296.13 65.476 295.717 65.8941 295.724 66.4515C295.731 67.01 296.146 67.414 296.714 67.414C297.279 67.414 297.687 67.0009 297.68 66.4354C297.674 65.8618 297.265 65.4598 296.694 65.4679Z" fill="#747070" />
        <path d="M251.156 121.701C250.587 121.671 250.154 122.058 250.126 122.624C250.098 123.165 250.512 123.624 251.058 123.657C251.585 123.689 252.083 123.252 252.112 122.73C252.14 122.221 251.683 121.729 251.156 121.701Z" fill="#747070" />
        <path d="M207.935 147.249C208.483 147.24 208.92 146.798 208.912 146.261C208.904 145.735 208.44 145.309 207.889 145.322C207.326 145.335 206.915 145.757 206.928 146.308C206.941 146.864 207.365 147.26 207.935 147.249Z" fill="#747070" />
        <path d="M256.838 121.7C256.278 121.71 255.862 122.141 255.878 122.697C255.893 123.24 256.338 123.662 256.888 123.656C257.425 123.65 257.874 123.189 257.862 122.657C257.85 122.136 257.373 121.69 256.838 121.7Z" fill="#747070" />
        <path d="M23.7011 16.2023C24.2631 16.2063 24.6957 15.7852 24.6937 15.2378C24.6916 14.6924 24.2601 14.2582 23.7132 14.2501C23.1735 14.242 22.7157 14.6844 22.7086 15.2196C22.7015 15.7438 23.1614 16.1992 23.7011 16.2023Z" fill="#747070" />
        <path d="M18.1561 14.8263C17.6164 14.8182 17.1585 15.2605 17.1514 15.7958C17.1454 16.3199 17.6042 16.7754 18.144 16.7784C18.7059 16.7825 19.1385 16.3613 19.1365 15.814C19.1335 15.2686 18.7029 14.8344 18.1561 14.8263Z" fill="#747070" />
        <path d="M29.4168 14.2507C28.8781 14.2709 28.4455 14.7365 28.4708 15.2707C28.496 15.8029 28.9691 16.2271 29.5098 16.2029C30.0617 16.1786 30.4821 15.7252 30.4528 15.1849C30.4245 14.6486 29.9606 14.2305 29.4168 14.2507Z" fill="#747070" />
        <path d="M230.896 61.0673C230.34 61.0885 229.93 61.5258 229.953 62.0762C229.974 62.6296 230.407 63.0194 230.976 62.9972C231.536 62.975 231.96 62.5316 231.933 61.9944C231.906 61.4561 231.446 61.045 230.896 61.0673Z" fill="#747070" />
        <path d="M25.6144 75.9243C25.6498 76.4615 26.1137 76.8665 26.6625 76.8382C27.2215 76.8099 27.6187 76.3716 27.5894 75.8162C27.5601 75.2597 27.1265 74.88 26.5534 74.9093C25.9965 74.9376 25.579 75.389 25.6144 75.9243Z" fill="#747070" />
        <path d="M78.446 37.4553C77.9012 37.4432 77.4352 37.8694 77.418 38.3986C77.3998 38.9348 77.8294 39.3752 78.3873 39.3944C78.9443 39.4135 79.3829 39.0065 79.4011 38.4541C79.4193 37.9118 78.9938 37.4685 78.446 37.4553Z" fill="#747070" />
        <path d="M85.1565 38.3922C85.1414 37.8449 84.7007 37.4419 84.1387 37.4611C83.5858 37.4803 83.1543 37.9277 83.1735 38.4619C83.1927 38.9992 83.6475 39.4102 84.2064 39.3961C84.7563 39.382 85.1717 38.9426 85.1565 38.3922Z" fill="#747070" />
        <path d="M89.9253 39.3935C90.4822 39.3945 90.9148 38.9734 90.9168 38.427C90.9189 37.8867 90.4822 37.4565 89.9324 37.4575C89.3896 37.4585 88.9287 37.9019 88.9287 38.423C88.9267 38.9593 89.3724 39.3925 89.9253 39.3935Z" fill="#747070" />
        <path d="M52.4229 72.3661C52.9869 72.3985 53.4347 72.0137 53.4741 71.4633C53.5125 70.926 53.0981 70.4574 52.5573 70.4261C52.0126 70.3938 51.5325 70.8058 51.4981 71.333C51.4637 71.8733 51.8771 72.3348 52.4229 72.3661Z" fill="#747070" />
        <path d="M43.8501 112.353C43.2953 112.367 42.8616 112.806 42.8728 113.342C42.8839 113.866 43.3508 114.292 43.8997 114.28C44.4667 114.267 44.873 113.848 44.8599 113.29C44.8467 112.734 44.4222 112.339 43.8501 112.353Z" fill="#747070" />
        <path d="M20.9211 18.7207C20.3662 18.6833 19.9063 19.0812 19.8699 19.6286C19.8366 20.1376 20.2773 20.6516 20.7877 20.6981C21.287 20.7435 21.7883 20.2941 21.8247 19.768C21.8631 19.2125 21.4699 18.7581 20.9211 18.7207Z" fill="#747070" />
        <path d="M46.7487 72.3669C47.3036 72.3589 47.7271 71.9296 47.7201 71.3813C47.713 70.841 47.2703 70.4178 46.7194 70.4279C46.1797 70.437 45.7269 70.8905 45.734 71.4146C45.7421 71.9478 46.1959 72.375 46.7487 72.3669Z" fill="#747070" />
        <path d="M55.3888 112.353C54.8339 112.352 54.3841 112.784 54.3892 113.312C54.3953 113.85 54.841 114.281 55.3898 114.279C55.9548 114.277 56.3763 113.865 56.3773 113.314C56.3783 112.767 55.9548 112.355 55.3888 112.353Z" fill="#747070" />
        <path d="M18.9362 24.5508C18.9139 23.9993 18.4793 23.6075 17.9113 23.6277C17.3554 23.6469 16.9279 24.0923 16.9511 24.6265C16.9743 25.1658 17.4251 25.5657 17.9891 25.5465C18.5541 25.5273 18.9584 25.1032 18.9362 24.5508Z" fill="#747070" />
        <path d="M40.9631 70.4304C40.4214 70.4405 39.9696 70.8919 39.9756 71.4161C39.9827 71.9483 40.4396 72.3765 40.9914 72.3684C41.5463 72.3603 41.9708 71.9301 41.9627 71.3827C41.9557 70.8394 41.515 70.4193 40.9631 70.4304Z" fill="#747070" />
        <path d="M35.2341 72.3678C35.792 72.3587 36.2135 71.9326 36.2085 71.3822C36.2034 70.8348 35.7678 70.4207 35.2068 70.4308C34.662 70.4399 34.2123 70.8883 34.2183 71.4155C34.2234 71.9477 34.6812 72.3769 35.2341 72.3678Z" fill="#747070" />
        <path d="M3.55405 67.483C4.12309 67.4971 4.54457 67.1032 4.56074 66.5407C4.57691 65.9752 4.18273 65.5561 3.6157 65.5359C3.05677 65.5157 2.6171 65.9227 2.60295 66.4761C2.58779 67.0356 2.99612 67.4688 3.55405 67.483Z" fill="#747070" />
        <path d="M209.826 66.5488C209.848 67.0941 210.296 67.5001 210.85 67.4769C211.397 67.4537 211.833 66.9952 211.806 66.468C211.78 65.9237 211.327 65.5177 210.769 65.5389C210.218 65.5591 209.805 66.0014 209.826 66.5488Z" fill="#747070" />
        <path d="M288.547 72.3669C289.113 72.3397 289.494 71.9185 289.469 71.3459C289.445 70.7794 289.017 70.3936 288.446 70.4219C287.878 70.4501 287.496 70.8753 287.524 71.4469C287.553 72.0145 287.975 72.3952 288.547 72.3669Z" fill="#747070" />
        <path d="M291.281 77.0086C291.847 76.9813 292.228 76.5602 292.202 75.9875C292.178 75.421 291.751 75.0352 291.181 75.0635C290.613 75.0917 290.23 75.5169 290.259 76.0885C290.286 76.6561 290.708 77.0358 291.281 77.0086Z" fill="#747070" />
        <path d="M288.651 79.7827C288.083 79.811 287.701 80.2362 287.729 80.8078C287.756 81.3753 288.179 81.7551 288.751 81.7278C289.317 81.7005 289.698 81.2794 289.673 80.7068C289.648 80.1412 289.221 79.7544 288.651 79.7827Z" fill="#747070" />
        <path d="M294.051 81.801C294.617 81.7738 294.998 81.3526 294.973 80.78C294.949 80.2135 294.521 79.8277 293.95 79.8559C293.382 79.8842 293 80.3094 293.028 80.881C293.057 81.4486 293.479 81.8283 294.051 81.801Z" fill="#747070" />
        <path d="M291.465 84.2524C290.897 84.2807 290.515 84.7059 290.543 85.2775C290.57 85.8451 290.993 86.2248 291.565 86.1975C292.131 86.1702 292.512 85.7491 292.487 85.1765C292.463 84.611 292.036 84.2242 291.465 84.2524Z" fill="#747070" />
        <path d="M179.144 70.4308C178.585 70.4207 178.148 70.8358 178.143 71.3812C178.138 71.9295 178.56 72.3598 179.115 72.3678C179.666 72.3759 180.123 71.9477 180.132 71.4155C180.141 70.8914 179.688 70.4399 179.144 70.4308Z" fill="#747070" />
        <path d="M174.368 71.4396C174.39 70.9125 173.954 70.4509 173.412 70.4277C172.857 70.4045 172.411 70.8095 172.389 71.3558C172.368 71.9032 172.781 72.3465 173.331 72.3667C173.888 72.3879 174.347 71.9779 174.368 71.4396Z" fill="#747070" />
        <path d="M167.621 72.3668C168.173 72.3638 168.615 71.9315 168.615 71.3932C168.615 70.8691 168.155 70.4268 167.61 70.4268C167.067 70.4278 166.624 70.8661 166.627 71.4013C166.631 71.9477 167.065 72.3698 167.621 72.3668Z" fill="#747070" />
        <path d="M71.664 66.4563C71.6347 66.9895 72.0592 67.448 72.6101 67.4753C73.1731 67.5036 73.6178 67.1077 73.642 66.5583C73.6653 66.0049 73.261 65.5615 72.7091 65.5363C72.1542 65.511 71.6944 65.916 71.664 66.4563Z" fill="#747070" />
        <path d="M77.4188 66.4561C77.3905 66.9873 77.817 67.4458 78.3669 67.472C78.9299 67.4993 79.3756 67.1044 79.3988 66.5561C79.4221 66.0057 79.0127 65.5573 78.4659 65.535C77.9151 65.5128 77.4471 65.9249 77.4188 66.4561Z" fill="#747070" />
        <path d="M84.1615 67.4746C84.7184 67.4827 85.156 67.0646 85.1591 66.5203C85.1621 65.9709 84.7386 65.5447 84.1827 65.5386C83.6288 65.5326 83.176 65.9598 83.171 66.495C83.1649 67.0202 83.6167 67.4666 84.1615 67.4746Z" fill="#747070" />
        <path d="M89.9193 65.5386C89.3654 65.5426 88.9247 65.9769 88.9277 66.5142C88.9308 67.0373 89.3927 67.4766 89.9365 67.4746C90.4883 67.4726 90.9209 67.0413 90.9159 66.5C90.9108 65.9527 90.4762 65.5346 89.9193 65.5386Z" fill="#747070" />
        <path d="M190.58 70.4198C190.028 70.4491 189.618 70.9066 189.655 71.455C189.691 72.0054 190.143 72.3992 190.702 72.3669C191.255 72.3346 191.67 71.8771 191.638 71.3378C191.604 70.8127 191.12 70.3915 190.58 70.4198Z" fill="#747070" />
        <path d="M222.36 67.4726C222.913 67.4504 223.343 67 223.32 66.4668C223.296 65.9335 222.835 65.5195 222.282 65.5366C221.733 65.5538 221.319 65.9962 221.337 66.5466C221.357 67.0949 221.797 67.4948 222.36 67.4726Z" fill="#747070" />
        <path d="M228.083 65.5381C227.529 65.5341 227.096 65.9552 227.091 66.5046C227.086 67.0459 227.522 67.4771 228.074 67.4771C228.62 67.4771 229.075 67.0378 229.078 66.5107C229.079 65.9704 228.642 65.5422 228.083 65.5381Z" fill="#747070" />
        <path d="M265.523 173.392C264.965 173.369 264.518 173.773 264.494 174.323C264.469 174.862 264.897 175.347 265.422 175.377C265.939 175.406 266.419 174.957 266.444 174.422C266.471 173.85 266.083 173.415 265.523 173.392Z" fill="#747070" />
        <path d="M210.865 37.4583C210.308 37.427 209.858 37.8218 209.827 38.3672C209.797 38.9156 210.203 39.366 210.751 39.3933C211.302 39.4205 211.769 39.0156 211.802 38.4803C211.837 37.9552 211.409 37.4886 210.865 37.4583Z" fill="#747070" />
        <path d="M265.488 89.1344C264.94 89.1273 264.505 89.5505 264.495 90.0998C264.485 90.6462 264.913 91.1007 265.451 91.1158C265.978 91.1299 266.431 90.6805 266.439 90.1372C266.448 89.5767 266.035 89.1414 265.488 89.1344Z" fill="#747070" />
        <path d="M202.125 147.252C202.668 147.269 203.126 146.85 203.149 146.313C203.173 145.778 202.743 145.339 202.184 145.325C201.612 145.31 201.185 145.702 201.168 146.258C201.152 146.805 201.566 147.235 202.125 147.252Z" fill="#747070" />
        <path d="M277.035 91.1096C277.562 91.0873 277.985 90.6117 277.962 90.0673C277.938 89.528 277.474 89.1029 276.936 89.1301C276.39 89.1584 275.987 89.6119 276.014 90.1673C276.04 90.7147 276.493 91.1328 277.035 91.1096Z" fill="#747070" />
        <path d="M271.18 100.457C271.747 100.485 272.18 100.092 272.205 99.5282C272.229 98.9587 271.843 98.5345 271.278 98.5113C270.705 98.488 270.278 98.8708 270.251 99.4323C270.224 99.9978 270.615 100.43 271.18 100.457Z" fill="#747070" />
        <path d="M265.524 98.5112C264.954 98.489 264.522 98.8727 264.493 99.4302C264.463 99.9907 264.859 100.428 265.421 100.456C265.988 100.485 266.414 100.097 266.443 99.5282C266.473 98.9576 266.09 98.5344 265.524 98.5112Z" fill="#747070" />
        <path d="M259.704 98.51C259.134 98.52 258.732 98.9291 258.737 99.4936C258.742 100.053 259.16 100.459 259.727 100.454C260.295 100.449 260.702 100.035 260.696 99.4663C260.69 98.9038 260.272 98.4999 259.704 98.51Z" fill="#747070" />
        <path d="M253.9 98.5084C253.34 98.5427 252.953 98.978 252.986 99.5405C253.017 100.104 253.447 100.482 254.019 100.449C254.572 100.419 254.975 99.9697 254.947 99.4173C254.917 98.875 254.457 98.4741 253.9 98.5084Z" fill="#747070" />
        <path d="M179.058 98.5086C178.511 98.5399 178.112 98.9953 178.148 99.5477C178.183 100.094 178.636 100.481 179.197 100.444C179.746 100.407 180.161 99.9426 180.122 99.4094C180.083 98.8752 179.612 98.4773 179.058 98.5086Z" fill="#747070" />
        <path d="M190.689 61.0799C190.123 61.0486 189.686 61.4324 189.657 61.9868C189.628 62.5291 190.039 62.9715 190.594 62.9967C191.138 63.021 191.609 62.6099 191.64 62.0817C191.672 61.5525 191.254 61.1112 190.689 61.0799Z" fill="#747070" />
        <path d="M202.182 62.9973C202.734 62.9831 203.174 62.5378 203.158 62.0076C203.142 61.4733 202.691 61.0643 202.129 61.0764C201.566 61.0886 201.158 61.5087 201.17 62.0621C201.182 62.6095 201.617 63.0124 202.182 62.9973Z" fill="#747070" />
        <path d="M72.7027 76.8274C73.2555 76.8133 73.6679 76.375 73.6447 75.8266C73.6214 75.2823 73.1777 74.8844 72.6157 74.9056C72.0689 74.9258 71.6353 75.3813 71.6576 75.9105C71.6798 76.4366 72.1427 76.8426 72.7027 76.8274Z" fill="#747070" />
        <path d="M66.8762 74.907C66.3264 74.9202 65.8827 75.3666 65.8968 75.8937C65.911 76.4249 66.3628 76.835 66.9278 76.8279C67.4847 76.8218 67.8991 76.3967 67.887 75.8432C67.8758 75.2989 67.4382 74.8939 66.8762 74.907Z" fill="#747070" />
        <path d="M271.276 79.7646C270.734 79.7485 270.279 80.1696 270.255 80.7119C270.229 81.2653 270.637 81.7198 271.182 81.747C271.717 81.7733 272.185 81.3431 272.205 80.8048C272.226 80.2605 271.798 79.7808 271.276 79.7646Z" fill="#747070" />
        <path d="M228.083 39.3955C228.642 39.3905 229.08 38.9633 229.078 38.423C229.076 37.8948 228.62 37.4575 228.073 37.4575C227.521 37.4575 227.087 37.8877 227.091 38.4301C227.096 38.9784 227.53 39.3996 228.083 39.3955Z" fill="#747070" />
        <path d="M225.124 119.185C225.667 119.217 226.145 118.798 226.175 118.265C226.204 117.769 225.735 117.242 225.234 117.209C224.724 117.175 224.235 117.622 224.204 118.151C224.171 118.706 224.568 119.153 225.124 119.185Z" fill="#747070" />
        <path d="M230.999 117.207C230.491 117.165 229.995 117.612 229.961 118.141C229.924 118.697 230.321 119.151 230.872 119.185C231.416 119.218 231.9 118.802 231.933 118.271C231.963 117.77 231.505 117.248 230.999 117.207Z" fill="#747070" />
        <path d="M274.185 76.833C274.743 76.8057 275.154 76.3613 275.125 75.817C275.097 75.2858 274.627 74.8768 274.075 74.9031C273.516 74.9303 273.116 75.3706 273.146 75.9241C273.176 76.4825 273.609 76.8613 274.185 76.833Z" fill="#747070" />
        <path d="M237.672 118.193C237.661 117.677 237.174 117.193 236.674 117.2C236.167 117.207 235.71 117.694 235.717 118.22C235.725 118.78 236.144 119.188 236.706 119.181C237.265 119.175 237.684 118.747 237.672 118.193Z" fill="#747070" />
        <path d="M242.493 119.18C243.056 119.157 243.456 118.719 243.425 118.16C243.395 117.642 242.892 117.17 242.397 117.196C241.89 117.223 241.442 117.73 241.466 118.251C241.489 118.801 241.936 119.204 242.493 119.18Z" fill="#747070" />
        <path d="M249.185 118.266C249.212 117.75 248.766 117.24 248.259 117.206C247.754 117.17 247.257 117.625 247.228 118.147C247.196 118.699 247.6 119.156 248.145 119.186C248.703 119.216 249.155 118.816 249.185 118.266Z" fill="#747070" />
        <path d="M26.6651 30.0303C27.223 30.002 27.6223 29.5607 27.5899 29.0062C27.5576 28.4487 27.124 28.07 26.5499 28.1003C25.999 28.1296 25.5816 28.5821 25.6119 29.1193C25.6412 29.6495 26.1132 30.0575 26.6651 30.0303Z" fill="#747070" />
        <path d="M262.623 58.1425C263.142 58.1293 263.617 57.6476 263.61 57.1366C263.605 56.6438 263.164 56.1681 262.735 56.1933C262.308 56.2196 261.67 56.7821 261.643 57.1578C261.606 57.6688 262.091 58.1566 262.623 58.1425Z" fill="#747070" />
        <path d="M12.1261 62.9964C12.6942 63.0328 13.1409 62.6531 13.1753 62.1047C13.2096 61.5513 12.8165 61.1079 12.2616 61.0756C11.7067 61.0433 11.2367 61.4361 11.1973 61.9673C11.1589 62.4955 11.5793 62.9611 12.1261 62.9964Z" fill="#747070" />
        <path d="M278.909 103.964C278.912 104.521 279.338 104.944 279.892 104.938C280.438 104.933 280.875 104.489 280.87 103.945C280.864 103.394 280.433 102.974 279.876 102.979C279.317 102.985 278.906 103.403 278.909 103.964Z" fill="#747070" />
        <path d="M219.363 154.669C218.804 154.7 218.406 155.147 218.44 155.706C218.473 156.254 218.933 156.66 219.482 156.626C220.027 156.593 220.452 156.123 220.418 155.592C220.385 155.067 219.898 154.641 219.363 154.669Z" fill="#747070" />
        <path d="M216.496 159.144C215.937 159.174 215.539 159.622 215.573 160.181C215.606 160.729 216.066 161.135 216.615 161.101C217.16 161.068 217.585 160.597 217.551 160.067C217.518 159.541 217.029 159.115 216.496 159.144Z" fill="#747070" />
        <path d="M55.374 30.0278C55.937 30.0359 56.3685 29.6268 56.3736 29.0805C56.3786 28.524 55.9642 28.1079 55.4033 28.1069C54.8423 28.1059 54.3885 28.525 54.3814 29.0522C54.3744 29.5753 54.8251 30.0197 55.374 30.0278Z" fill="#747070" />
        <path d="M61.1627 28.106C60.6017 28.1039 60.1469 28.5231 60.1388 29.0492C60.1307 29.5734 60.5815 30.0197 61.1283 30.0278C61.6913 30.0359 62.1239 29.6279 62.1309 29.0815C62.139 28.5281 61.7216 28.108 61.1627 28.106Z" fill="#747070" />
        <path d="M213.651 154.671C213.091 154.678 212.676 155.104 212.686 155.663C212.697 156.222 213.121 156.632 213.684 156.627C214.228 156.622 214.676 156.17 214.667 155.637C214.66 155.114 214.187 154.665 213.651 154.671Z" fill="#747070" />
        <path d="M72.7027 30.03C73.2555 30.0057 73.6689 29.5614 73.6437 29.019C73.6184 28.4656 73.1797 28.0778 72.6137 28.1081C72.0497 28.1374 71.6303 28.5777 71.6586 29.1089C71.6889 29.6371 72.1609 30.0542 72.7027 30.03Z" fill="#747070" />
        <path d="M254.015 117.206C253.492 117.165 253.017 117.595 252.983 118.141C252.95 118.692 253.353 119.154 253.895 119.186C254.454 119.218 254.909 118.819 254.941 118.269C254.971 117.751 254.533 117.247 254.015 117.206Z" fill="#747070" />
        <path d="M17.9265 107.877C17.3807 107.889 16.9339 108.34 16.9471 108.865C16.9602 109.394 17.414 109.806 17.978 109.8C18.5349 109.794 18.9483 109.369 18.9372 108.815C18.9251 108.268 18.4905 107.864 17.9265 107.877Z" fill="#747070" />
        <path d="M15.6359 112.612C15.0901 112.624 14.6434 113.074 14.6565 113.599C14.6697 114.129 15.1235 114.541 15.6875 114.534C16.2444 114.528 16.6578 114.103 16.6457 113.55C16.6345 113.002 16.1989 112.599 15.6359 112.612Z" fill="#747070" />
        <path d="M259.773 117.206C259.245 117.169 258.77 117.597 258.738 118.141C258.706 118.693 259.11 119.156 259.654 119.186C260.215 119.219 260.663 118.822 260.695 118.263C260.725 117.734 260.298 117.242 259.773 117.206Z" fill="#747070" />
        <path d="M29.4954 109.8C30.0502 109.791 30.4667 109.361 30.4515 108.811C30.4363 108.27 29.9936 107.863 29.4347 107.877C28.8889 107.89 28.4452 108.343 28.4614 108.869C28.4775 109.398 28.9344 109.809 29.4954 109.8Z" fill="#747070" />
        <path d="M35.2255 107.875C34.6837 107.871 34.2238 108.31 34.2188 108.836C34.2137 109.368 34.6524 109.789 35.2194 109.799C35.7753 109.808 36.2089 109.392 36.212 108.845C36.215 108.308 35.7794 107.879 35.2255 107.875Z" fill="#747070" />
        <path d="M265.49 117.202C264.959 117.189 264.508 117.633 264.497 118.183C264.485 118.744 264.896 119.175 265.452 119.184C266.016 119.193 266.439 118.785 266.45 118.224C266.459 117.685 266.018 117.215 265.49 117.202Z" fill="#747070" />
        <path d="M207.909 154.672C207.347 154.67 206.928 155.088 206.929 155.648C206.93 156.195 207.359 156.621 207.914 156.626C208.455 156.63 208.913 156.183 208.912 155.649C208.912 155.127 208.447 154.673 207.909 154.672Z" fill="#747070" />
        <path d="M15.0936 84.2282C14.5852 84.2383 14.1152 84.7281 14.1172 85.2452C14.1192 85.8006 14.5478 86.2177 15.1077 86.2116C15.6646 86.2056 16.076 85.7834 16.073 85.2199C16.0709 84.6766 15.6191 84.2181 15.0936 84.2282Z" fill="#747070" />
        <path d="M20.7986 86.2084C21.3565 86.2387 21.7972 85.8458 21.8295 85.2873C21.8608 84.7501 21.4363 84.2572 20.9148 84.2259C20.4205 84.1966 19.9081 84.6672 19.8748 85.1793C19.8394 85.7357 20.2356 86.177 20.7986 86.2084Z" fill="#747070" />
        <path d="M285.599 84.2245C285.078 84.2407 284.642 84.7264 284.66 85.2657C284.679 85.8202 285.116 86.2272 285.672 86.21C286.235 86.1928 286.642 85.7596 286.619 85.2011C286.597 84.6891 286.096 84.2084 285.599 84.2245Z" fill="#747070" />
        <path d="M280.866 85.247C280.87 84.735 280.394 84.2381 279.892 84.2311C279.385 84.223 278.915 84.6976 278.911 85.2238C278.906 85.7863 279.313 86.2034 279.874 86.2105C280.432 86.2175 280.862 85.7994 280.866 85.247Z" fill="#747070" />
        <path d="M17.8864 62.9948C18.4525 63.0291 18.9002 62.6464 18.9336 62.1C18.9669 61.5476 18.5717 61.1063 18.0158 61.076C17.4589 61.0447 16.993 61.4375 16.9556 61.9707C16.9172 62.4989 17.3376 62.9615 17.8864 62.9948Z" fill="#747070" />
        <path d="M46.7611 100.446C47.315 100.435 47.7354 100 47.7203 99.454C47.7061 98.9036 47.2664 98.4946 46.7105 98.5128C46.1506 98.531 45.722 98.9662 45.7362 99.5045C45.7503 100.03 46.2143 100.456 46.7611 100.446Z" fill="#747070" />
        <path d="M225.154 62.999C225.703 63.0101 226.168 62.585 226.181 62.0598C226.193 61.5185 225.764 61.0822 225.209 61.0701C224.634 61.058 224.211 61.4508 224.198 62.0053C224.185 62.5668 224.591 62.9879 225.154 62.999Z" fill="#747070" />
        <path d="M245.371 114.279C245.917 114.27 246.355 113.827 246.348 113.29C246.341 112.757 245.889 112.346 245.325 112.358C244.757 112.371 244.348 112.787 244.362 113.339C244.376 113.886 244.807 114.289 245.371 114.279Z" fill="#747070" />
        <path d="M250.131 113.198C250.067 113.75 250.437 114.215 250.988 114.276C251.545 114.338 252.007 113.981 252.083 113.429C252.156 112.896 251.771 112.415 251.223 112.358C250.653 112.298 250.195 112.65 250.131 113.198Z" fill="#747070" />
        <path d="M256.876 112.353C256.31 112.345 255.882 112.751 255.876 113.302C255.869 113.858 256.282 114.272 256.85 114.279C257.401 114.286 257.862 113.856 257.867 113.33C257.87 112.795 257.43 112.36 256.876 112.353Z" fill="#747070" />
        <path d="M262.607 112.352C262.042 112.357 261.625 112.774 261.632 113.325C261.639 113.879 262.061 114.284 262.628 114.279C263.182 114.273 263.625 113.838 263.619 113.305C263.613 112.776 263.158 112.348 262.607 112.352Z" fill="#747070" />
        <path d="M184.947 72.3663C185.496 72.337 185.911 71.8795 185.877 71.3392C185.844 70.806 185.373 70.399 184.823 70.4282C184.271 70.4575 183.866 70.911 183.902 71.4573C183.94 72.0108 184.383 72.3976 184.947 72.3663Z" fill="#747070" />
        <path d="M239.613 121.701C239.047 121.689 238.616 122.1 238.611 122.654C238.607 123.203 239.029 123.642 239.576 123.657C240.114 123.672 240.581 123.232 240.594 122.699C240.607 122.182 240.145 121.712 239.613 121.701Z" fill="#747070" />
        <path d="M39.9817 99.4291C39.9544 99.9623 40.3789 100.418 40.9308 100.444C41.4917 100.471 41.9385 100.074 41.9617 99.527C41.985 98.9766 41.5746 98.5292 41.0278 98.508C40.475 98.4878 40.009 98.8979 39.9817 99.4291Z" fill="#747070" />
        <path d="M246.345 75.8107C246.31 75.2795 245.839 74.8745 245.29 74.9028C244.737 74.932 244.334 75.3784 244.368 75.9258C244.402 76.4863 244.836 76.861 245.406 76.8246C245.966 76.7882 246.379 76.3429 246.345 75.8107Z" fill="#747070" />
        <path d="M207.879 63.0011C208.427 63.0163 208.89 62.5971 208.909 62.0669C208.929 61.5277 208.507 61.0964 207.945 61.0783C207.373 61.0601 206.949 61.4469 206.929 62.0043C206.908 62.5537 207.32 62.985 207.879 63.0011Z" fill="#747070" />
        <path d="M23.7652 63.0005C24.3171 62.9702 24.7224 62.5218 24.6901 61.9775C24.6557 61.415 24.2261 61.0433 23.651 61.0807C23.0891 61.1161 22.6777 61.5604 22.7131 62.0936C22.7474 62.6228 23.2194 63.0308 23.7652 63.0005Z" fill="#747070" />
        <path d="M29.4357 62.9955C30.0017 63.0087 30.4373 62.6087 30.4514 62.0634C30.4656 61.514 30.0492 61.0858 29.4933 61.0777C28.9313 61.0707 28.4744 61.4817 28.4603 62.0078C28.4461 62.538 28.8858 62.9834 29.4357 62.9955Z" fill="#747070" />
        <path d="M282.738 100.455C283.311 100.459 283.717 100.056 283.716 99.4833C283.714 98.9107 283.312 98.5068 282.746 98.5098C282.169 98.5128 281.773 98.9057 281.769 99.4793C281.765 100.044 282.169 100.451 282.738 100.455Z" fill="#747070" />
        <path d="M276.911 100.455C277.481 100.496 277.916 100.119 277.954 99.5533C277.992 98.9756 277.624 98.5474 277.059 98.5121C276.486 98.4767 276.049 98.8484 276.01 99.4069C275.968 99.9734 276.349 100.415 276.911 100.455Z" fill="#747070" />
        <path d="M239.54 149.796C238.995 149.813 238.585 150.259 238.609 150.81C238.634 151.361 239.073 151.751 239.639 151.724C240.19 151.699 240.617 151.244 240.589 150.712C240.561 150.185 240.095 149.777 239.54 149.796Z" fill="#747070" />
        <path d="M213.682 147.25C214.232 147.244 214.675 146.802 214.666 146.272C214.657 145.744 214.198 145.314 213.653 145.322C213.089 145.331 212.675 145.748 212.683 146.302C212.69 146.855 213.114 147.257 213.682 147.25Z" fill="#747070" />
        <path d="M239.548 76.8275C240.108 76.8427 240.57 76.4377 240.593 75.9116C240.616 75.3814 240.187 74.9299 239.636 74.9077C239.072 74.8845 238.631 75.2784 238.609 75.8257C238.587 76.3771 238.994 76.8124 239.548 76.8275Z" fill="#747070" />
        <path d="M184.85 107.875C184.297 107.895 183.878 108.337 183.899 108.879C183.92 109.432 184.357 109.826 184.92 109.799C185.488 109.771 185.909 109.338 185.885 108.805C185.86 108.278 185.39 107.855 184.85 107.875Z" fill="#747070" />
        <path d="M191.637 108.78C191.602 108.251 191.125 107.842 190.581 107.873C190.028 107.906 189.623 108.355 189.658 108.899C189.694 109.459 190.129 109.833 190.7 109.796C191.259 109.761 191.673 109.313 191.637 108.78Z" fill="#747070" />
        <path d="M196.436 109.797C197.002 109.771 197.422 109.335 197.397 108.801C197.371 108.272 196.904 107.854 196.359 107.873C195.803 107.892 195.391 108.331 195.414 108.878C195.437 109.435 195.87 109.824 196.436 109.797Z" fill="#747070" />
        <path d="M202.2 107.878C201.634 107.854 201.195 108.246 201.173 108.795C201.151 109.349 201.556 109.786 202.109 109.801C202.666 109.817 203.131 109.41 203.156 108.884C203.181 108.353 202.753 107.901 202.2 107.878Z" fill="#747070" />
        <path d="M233.79 74.9038C233.239 74.928 232.823 75.3734 232.85 75.9147C232.878 76.4691 233.315 76.8559 233.88 76.8256C234.444 76.7953 234.865 76.355 234.835 75.8248C234.805 75.2976 234.332 74.8805 233.79 74.9038Z" fill="#747070" />
        <path d="M49.6438 123.657C50.1916 123.644 50.6182 123.206 50.6131 122.659C50.6091 122.102 50.1815 121.691 49.6175 121.7C49.0829 121.709 48.6199 122.176 48.629 122.694C48.6391 123.226 49.1071 123.67 49.6438 123.657Z" fill="#747070" />
        <path d="M55.3884 121.699C54.8527 121.7 54.3857 122.157 54.3867 122.679C54.3877 123.214 54.8456 123.661 55.3863 123.656C55.9402 123.651 56.3708 123.222 56.3708 122.675C56.3718 122.117 55.9493 121.697 55.3884 121.699Z" fill="#747070" />
        <path d="M61.191 123.658C61.7368 123.627 62.1502 123.17 62.1239 122.628C62.0977 122.063 61.6651 121.673 61.096 121.702C60.5674 121.728 60.1136 122.217 60.1419 122.728C60.1712 123.253 60.6614 123.688 61.191 123.658Z" fill="#747070" />
        <path d="M230.914 107.876C230.355 107.886 229.943 108.311 229.954 108.864C229.966 109.419 230.39 109.817 230.958 109.805C231.51 109.794 231.946 109.355 231.937 108.819C231.929 108.298 231.461 107.866 230.914 107.876Z" fill="#747070" />
        <path d="M61.1793 74.9038C60.6335 74.8876 60.1665 75.3077 60.1453 75.8339C60.1241 76.3651 60.5496 76.8004 61.1136 76.8246C61.6796 76.8488 62.1102 76.457 62.1304 75.9005C62.1496 75.3572 61.7342 74.9209 61.1793 74.9038Z" fill="#747070" />
        <path d="M27.589 38.4787C27.6204 37.9141 27.2282 37.4768 26.6642 37.4485C26.1124 37.4213 25.6454 37.8323 25.6171 38.3696C25.5888 38.9149 26.0002 39.3643 26.553 39.3936C27.119 39.4229 27.5577 39.0371 27.589 38.4787Z" fill="#747070" />
        <path d="M56.3698 75.9199C56.4002 75.3726 55.9948 74.9282 55.441 74.903C54.8931 74.8777 54.4221 75.2867 54.3908 75.8159C54.3595 76.3471 54.7769 76.7915 55.3369 76.8248C55.9069 76.8581 56.3395 76.4794 56.3698 75.9199Z" fill="#747070" />
        <path d="M282.794 72.3676C283.359 72.3423 283.736 71.9232 283.713 71.3455C283.69 70.779 283.261 70.3922 282.689 70.4215C282.123 70.4498 281.737 70.88 281.767 71.4485C281.798 72.0121 282.222 72.3928 282.794 72.3676Z" fill="#747070" />
        <path d="M277.96 71.4337C277.983 70.8631 277.591 70.441 277.02 70.4248C276.446 70.4086 276.029 70.7954 276.012 71.36C275.995 71.9316 276.38 72.3477 276.949 72.3709C277.514 72.3941 277.938 72.0003 277.96 71.4337Z" fill="#747070" />
        <path d="M271.241 72.3686C271.804 72.3676 272.206 71.9556 272.202 71.385C272.198 70.8114 271.79 70.4125 271.216 70.4226C270.647 70.4316 270.243 70.8457 270.252 71.4072C270.261 71.9697 270.672 72.3696 271.241 72.3686Z" fill="#747070" />
        <path d="M265.534 72.3666C266.095 72.3373 266.477 71.9061 266.443 71.3375C266.409 70.769 265.977 70.3852 265.411 70.4185C264.851 70.4518 264.456 70.8972 264.491 71.4547C264.526 72.0061 264.972 72.3959 265.534 72.3666Z" fill="#747070" />
        <path d="M118.726 159.161C118.176 159.151 117.722 159.577 117.714 160.109C117.706 160.653 118.132 161.078 118.691 161.086C119.253 161.094 119.693 160.684 119.703 160.145C119.711 159.609 119.276 159.171 118.726 159.161Z" fill="#747070" />
        <path d="M124.455 159.165C123.9 159.168 123.459 159.6 123.467 160.135C123.475 160.674 123.917 161.091 124.474 161.085C125.037 161.079 125.462 160.66 125.457 160.118C125.453 159.584 125.01 159.163 124.455 159.165Z" fill="#747070" />
        <path d="M136.042 161.088C136.593 161.054 137 160.602 136.965 160.064C136.931 159.523 136.475 159.137 135.912 159.17C135.36 159.202 134.948 159.658 134.988 160.194C135.029 160.735 135.483 161.122 136.042 161.088Z" fill="#747070" />
        <path d="M141.775 161.087C142.328 161.065 142.748 160.623 142.727 160.086C142.706 159.551 142.251 159.148 141.692 159.169C141.135 159.19 140.714 159.633 140.74 160.17C140.765 160.712 141.214 161.109 141.775 161.087Z" fill="#747070" />
        <path d="M147.448 159.169C146.891 159.19 146.471 159.632 146.497 160.17C146.524 160.713 146.97 161.109 147.532 161.087C148.085 161.066 148.504 160.625 148.482 160.085C148.461 159.551 148.006 159.148 147.448 159.169Z" fill="#747070" />
        <path d="M153.253 159.169C152.695 159.166 152.251 159.59 152.251 160.123C152.251 160.663 152.684 161.084 153.242 161.087C153.799 161.09 154.241 160.671 154.246 160.134C154.251 159.6 153.812 159.172 153.253 159.169Z" fill="#747070" />
        <path d="M104.334 16.1822C104.891 16.1631 105.314 15.7207 105.288 15.1834C105.263 14.6411 104.808 14.2291 104.258 14.2503C103.713 14.2715 103.287 14.7219 103.305 15.2582C103.324 15.7985 103.773 16.2014 104.334 16.1822Z" fill="#747070" />
        <path d="M109.942 16.1818C110.495 16.2383 110.977 15.8637 111.036 15.3314C111.096 14.8002 110.7 14.3094 110.164 14.2518C109.614 14.1923 109.139 14.5659 109.071 15.1092C109.007 15.6415 109.4 16.1262 109.942 16.1818Z" fill="#747070" />
        <path d="M159.029 159.168C158.475 159.155 158.02 159.57 158.008 160.102C157.995 160.642 158.42 161.073 158.977 161.088C159.536 161.102 159.983 160.694 160 160.155C160.017 159.617 159.588 159.181 159.029 159.168Z" fill="#747070" />
        <path d="M305.703 16.2038C306.267 16.2341 306.698 15.8473 306.727 15.2828C306.757 14.7112 306.38 14.283 305.82 14.2527C305.253 14.2224 304.827 14.6021 304.794 15.1676C304.762 15.7312 305.151 16.1745 305.703 16.2038Z" fill="#747070" />
        <path d="M307.945 11.3293C308.509 11.3596 308.939 10.9728 308.969 10.4083C308.999 9.83665 308.622 9.40845 308.062 9.37816C307.495 9.34786 307.068 9.72758 307.036 10.2931C307.004 10.8567 307.393 11.3 307.945 11.3293Z" fill="#747070" />
        <path d="M164.718 161.088C165.279 161.11 165.728 160.714 165.755 160.171C165.781 159.633 165.361 159.19 164.804 159.169C164.245 159.148 163.79 159.549 163.768 160.085C163.746 160.622 164.166 161.065 164.718 161.088Z" fill="#747070" />
        <path d="M170.577 161.088C171.121 161.059 171.538 160.602 171.507 160.068C171.476 159.531 171.017 159.139 170.457 159.17C169.907 159.2 169.492 159.655 169.528 160.189C169.564 160.736 170.01 161.118 170.577 161.088Z" fill="#747070" />
        <path d="M176.237 161.086C176.797 161.106 177.246 160.705 177.267 160.165C177.289 159.627 176.864 159.182 176.311 159.164C175.756 159.146 175.304 159.554 175.283 160.089C175.263 160.632 175.679 161.066 176.237 161.086Z" fill="#747070" />
        <path d="M302.901 20.7061C303.411 20.7041 303.878 20.2254 303.882 19.7003C303.886 19.1367 303.472 18.7206 302.909 18.7227C302.349 18.7257 301.932 19.1418 301.929 19.7013C301.928 20.2446 302.375 20.7071 302.901 20.7061Z" fill="#747070" />
        <path d="M300.537 14.5044C299.977 14.5074 299.56 14.9235 299.557 15.483C299.555 16.0263 300.002 16.4899 300.527 16.4879C301.038 16.4858 301.505 16.0071 301.509 15.482C301.514 14.9185 301.099 14.5024 300.537 14.5044Z" fill="#747070" />
        <path d="M307.686 19.6456C307.657 20.1808 308.087 20.6777 308.603 20.708C309.105 20.7373 309.607 20.2748 309.638 19.7557C309.673 19.1861 309.288 18.7538 308.721 18.7246C308.167 18.6963 307.717 19.0962 307.686 19.6456Z" fill="#747070" />
        <path d="M23.7465 25.5471C24.3105 25.528 24.7179 25.0998 24.6936 24.5504C24.6693 24.002 24.2287 23.6051 23.6667 23.6263C23.1138 23.6465 22.6822 24.0969 22.7055 24.6281C22.7287 25.1624 23.1866 25.5663 23.7465 25.5471Z" fill="#747070" />
        <path d="M29.4769 23.6251C28.9301 23.617 28.4682 24.0473 28.4591 24.5744C28.45 25.1077 28.8867 25.5389 29.4456 25.546C30.0136 25.553 30.4432 25.1521 30.4533 24.6047C30.4634 24.0624 30.0359 23.6332 29.4769 23.6251Z" fill="#747070" />
        <path d="M35.1969 25.5458C35.767 25.5559 36.1955 25.159 36.2097 24.6106C36.2238 24.0653 35.8054 23.6421 35.2404 23.631C34.6855 23.6209 34.2306 24.038 34.2185 24.5692C34.2064 25.1065 34.637 25.5357 35.1969 25.5458Z" fill="#747070" />
        <path d="M40.9956 23.632C40.4356 23.6229 39.9879 24.0359 39.9768 24.5722C39.9657 25.1085 40.3972 25.5367 40.9572 25.5458C41.5252 25.5548 41.9578 25.1539 41.9679 24.6106C41.977 24.0652 41.5555 23.6411 40.9956 23.632Z" fill="#747070" />
        <path d="M46.7156 23.6329C46.1586 23.641 45.722 24.0733 45.7321 24.6075C45.7422 25.1448 46.189 25.5568 46.7489 25.5457C47.3109 25.5356 47.7354 25.1114 47.7263 24.5721C47.7182 24.0329 47.2795 23.6249 46.7156 23.6329Z" fill="#747070" />
        <path d="M52.495 23.6328C51.9361 23.6298 51.4924 24.0499 51.4883 24.5852C51.4843 25.1214 51.9239 25.5446 52.4829 25.5456C53.0448 25.5466 53.4825 25.1315 53.4855 24.5953C53.4896 24.059 53.055 23.6359 52.495 23.6328Z" fill="#747070" />
        <path d="M58.2158 25.5455C58.7839 25.5587 59.2215 25.1618 59.2377 24.6194C59.2538 24.0781 58.8334 23.6469 58.2755 23.6318C57.7185 23.6166 57.2657 24.0266 57.2485 24.5609C57.2314 25.0971 57.6589 25.5324 58.2158 25.5455Z" fill="#747070" />
        <path d="M64.0047 25.546C64.5656 25.542 64.9982 25.1239 64.9962 24.5866C64.9942 24.0473 64.5565 23.6313 63.9976 23.6343C63.4407 23.6373 62.997 24.0655 63 24.5957C63.002 25.1269 63.4488 25.5491 64.0047 25.546Z" fill="#747070" />
        <path d="M69.8051 25.5429C70.354 25.5258 70.7775 25.0794 70.7502 24.5441C70.7229 24.0018 70.2792 23.612 69.7132 23.6342C69.1562 23.6564 68.7348 24.0978 68.7631 24.631C68.7904 25.1672 69.2401 25.5601 69.8051 25.5429Z" fill="#747070" />
        <path d="M75.5143 23.6333C74.9544 23.6343 74.5127 24.0544 74.5127 24.5867C74.5127 25.1169 74.9534 25.536 75.5164 25.542C76.0723 25.5471 76.5129 25.1249 76.5129 24.5887C76.5119 24.0544 76.0723 23.6333 75.5143 23.6333Z" fill="#747070" />
        <path d="M182.017 159.163C181.466 159.17 181.029 159.606 181.036 160.14C181.043 160.682 181.48 161.092 182.043 161.087C182.601 161.082 183.029 160.656 183.024 160.114C183.019 159.582 182.566 159.155 182.017 159.163Z" fill="#747070" />
        <path d="M187.75 159.162C187.197 159.18 186.776 159.623 186.794 160.162C186.813 160.703 187.257 161.102 187.822 161.084C188.376 161.067 188.795 160.632 188.779 160.088C188.764 159.558 188.304 159.144 187.75 159.162Z" fill="#747070" />
        <path d="M193.581 159.158C193.03 159.14 192.574 159.55 192.553 160.085C192.532 160.629 192.951 161.072 193.5 161.087C194.06 161.102 194.509 160.7 194.535 160.16C194.56 159.627 194.132 159.177 193.581 159.158Z" fill="#747070" />
        <path d="M199.361 159.158C198.81 159.128 198.347 159.527 198.313 160.063C198.279 160.605 198.69 161.062 199.235 161.088C199.792 161.115 200.25 160.722 200.289 160.182C200.328 159.649 199.911 159.189 199.361 159.158Z" fill="#747070" />
        <path d="M205.065 159.157C204.518 159.153 204.07 159.58 204.063 160.114C204.055 160.655 204.487 161.085 205.041 161.087C205.599 161.089 206.047 160.668 206.055 160.134C206.063 159.607 205.612 159.161 205.065 159.157Z" fill="#747070" />
        <path d="M110.028 23.6341C109.466 23.6462 109.041 24.0754 109.056 24.6147C109.071 25.159 109.51 25.5589 110.077 25.5458C110.638 25.5327 111.064 25.1035 111.051 24.5642C111.039 24.0249 110.595 23.6219 110.028 23.6341Z" fill="#747070" />
        <path d="M256.838 159.156C256.282 159.169 255.866 159.597 255.878 160.145C255.89 160.697 256.321 161.101 256.883 161.087C257.438 161.074 257.87 160.638 257.857 160.102C257.844 159.57 257.386 159.145 256.838 159.156Z" fill="#747070" />
        <path d="M263.609 160.186C263.645 159.649 263.231 159.193 262.679 159.16C262.108 159.126 261.676 159.501 261.642 160.061C261.608 160.623 261.994 161.059 262.555 161.09C263.104 161.121 263.574 160.718 263.609 160.186Z" fill="#747070" />
        <path d="M268.356 159.157C267.799 159.165 267.383 159.59 267.392 160.143C267.401 160.695 267.83 161.102 268.392 161.091C268.941 161.081 269.38 160.641 269.374 160.108C269.368 159.582 268.905 159.149 268.356 159.157Z" fill="#747070" />
        <path d="M6.41011 62.9983C6.97613 63.0135 7.40771 62.6146 7.42085 62.0652C7.43399 61.5087 7.02868 61.0896 6.46672 61.0765C5.90273 61.0634 5.45699 61.4683 5.44082 62.0066C5.42566 62.5439 5.85421 62.9832 6.41011 62.9983Z" fill="#747070" />
        <path d="M144.542 23.6319C143.993 23.6562 143.572 24.1056 143.6 24.6388C143.629 25.1912 144.069 25.573 144.641 25.5416C145.2 25.5114 145.618 25.067 145.588 24.5368C145.559 24.0046 145.099 23.6077 144.542 23.6319Z" fill="#747070" />
        <path d="M150.393 23.6342C149.827 23.612 149.384 24.0008 149.357 24.5451C149.33 25.0804 149.752 25.5268 150.302 25.5439C150.863 25.5611 151.317 25.1632 151.345 24.632C151.371 24.1028 150.944 23.6564 150.393 23.6342Z" fill="#747070" />
        <path d="M156.16 25.5458C156.709 25.5215 157.129 25.0701 157.099 24.5379C157.069 24.0067 156.606 23.6067 156.052 23.632C155.498 23.6582 155.086 24.1036 155.117 24.6439C155.148 25.1973 155.581 25.571 156.16 25.5458Z" fill="#747070" />
        <path d="M162.853 24.6469C162.887 24.1126 162.471 23.6622 161.918 23.6339C161.342 23.6046 160.905 23.9813 160.872 24.5327C160.84 25.074 161.247 25.5164 161.804 25.5457C162.363 25.577 162.819 25.1851 162.853 24.6469Z" fill="#747070" />
        <path d="M167.647 25.5455C168.207 25.5324 168.632 25.0991 168.615 24.5618C168.598 24.0296 168.143 23.6176 167.587 23.6327C167.028 23.6479 166.607 24.0791 166.623 24.6194C166.64 25.1597 167.082 25.5586 167.647 25.5455Z" fill="#747070" />
        <path d="M173.348 25.5454C173.912 25.5595 174.358 25.1556 174.373 24.6163C174.389 24.08 173.958 23.6458 173.4 23.6326C172.835 23.6195 172.395 24.0204 172.381 24.5628C172.367 25.1041 172.788 25.5313 173.348 25.5454Z" fill="#747070" />
        <path d="M179.122 23.6319C178.558 23.6359 178.132 24.054 178.137 24.5973C178.142 25.1407 178.572 25.5477 179.139 25.5446C179.699 25.5416 180.137 25.1174 180.132 24.5822C180.127 24.0409 179.688 23.6278 179.122 23.6319Z" fill="#747070" />
        <path d="M184.898 23.6329C184.331 23.6278 183.9 24.0348 183.896 24.5781C183.892 25.1265 184.311 25.5396 184.877 25.5456C185.438 25.5527 185.883 25.1366 185.889 24.6004C185.897 24.0661 185.458 23.6379 184.898 23.6329Z" fill="#747070" />
        <path d="M187.752 30.5012C188.313 30.5073 188.758 30.0922 188.764 29.556C188.771 29.0217 188.332 28.5925 187.773 28.5874C187.206 28.5824 186.774 28.9894 186.77 29.5327C186.768 30.0821 187.186 30.4942 187.752 30.5012Z" fill="#747070" />
        <path d="M193.076 30.0198C193.637 30.0258 194.082 29.6108 194.088 29.0745C194.095 28.5403 193.655 28.1111 193.096 28.106C192.529 28.101 192.098 28.508 192.094 29.0513C192.092 29.6007 192.51 30.0127 193.076 30.0198Z" fill="#747070" />
        <path d="M300.011 53.6734C300.529 53.6693 300.978 53.1997 300.976 52.6645C300.974 52.108 300.552 51.6869 300 51.6919C299.451 51.696 299.04 52.1211 299.041 52.6827C299.042 53.228 299.481 53.6774 300.011 53.6734Z" fill="#747070" />
        <path d="M305.804 23.6359C305.209 23.6177 304.816 23.9752 304.796 24.5529C304.775 25.1296 305.149 25.5295 305.728 25.5487C306.305 25.5679 306.725 25.1871 306.745 24.6286C306.766 24.0631 306.376 23.6541 305.804 23.6359Z" fill="#747070" />
        <path d="M282.7 53.6825C283.202 53.7007 283.688 53.2321 283.712 52.707C283.739 52.1424 283.344 51.7162 282.778 51.696C282.215 51.6758 281.787 52.0748 281.768 52.6373C281.749 53.1796 282.181 53.6644 282.7 53.6825Z" fill="#747070" />
        <path d="M276.923 51.689C276.375 51.7234 275.978 52.1799 276.012 52.7353C276.047 53.2837 276.516 53.7109 277.04 53.6715C277.556 53.6321 277.988 53.1342 277.962 52.6091C277.935 52.0597 277.476 51.6547 276.923 51.689Z" fill="#747070" />
        <path d="M299.965 42.3242C299.408 42.3495 299.017 42.7868 299.045 43.3523C299.072 43.9118 299.508 44.3198 300.053 44.2966C300.588 44.2733 301.001 43.8148 300.977 43.2675C300.955 42.7242 300.498 42.3 299.965 42.3242Z" fill="#747070" />
        <path d="M9.34726 84.2276C8.83886 84.2306 8.36887 84.7123 8.36381 85.2344C8.35876 85.7949 8.77923 86.214 9.33917 86.21C9.89912 86.206 10.3155 85.7899 10.3176 85.2304C10.3206 84.6871 9.87385 84.2245 9.34726 84.2276Z" fill="#747070" />
        <path d="M7.40691 90.1464C7.40893 89.6021 6.96218 89.1396 6.4366 89.1426C5.9282 89.1456 5.45821 89.6273 5.45315 90.1495C5.44911 90.71 5.86857 91.1291 6.42851 91.125C6.98947 91.122 7.40489 90.7059 7.40691 90.1464Z" fill="#747070" />
        <path d="M3.75791 84.2261C3.24951 84.2291 2.77951 84.7108 2.77446 85.234C2.76941 85.7945 3.18987 86.2136 3.74982 86.2095C4.30977 86.2055 4.72619 85.7894 4.72821 85.2299C4.73023 84.6846 4.28349 84.2231 3.75791 84.2261Z" fill="#747070" />
        <path d="M15.0431 30.0247C15.6152 30.0519 16.0498 29.6702 16.0771 29.1178C16.1044 28.5603 15.7122 28.125 15.1543 28.0958C14.5964 28.0665 14.1405 28.4654 14.1132 29.0057C14.0849 29.5662 14.4801 29.9984 15.0431 30.0247Z" fill="#747070" />
        <path d="M20.8468 30.0276C21.4118 30.0306 21.8373 29.6196 21.8393 29.0702C21.8413 28.5188 21.4209 28.1017 20.8589 28.0987C20.3081 28.0946 19.8532 28.5269 19.8482 29.0571C19.8441 29.5852 20.2959 30.0246 20.8468 30.0276Z" fill="#747070" />
        <path d="M32.3293 30.0266C32.9004 30.0458 33.3229 29.658 33.3411 29.0965C33.3593 28.541 32.956 28.1149 32.395 28.0977C31.8422 28.0805 31.3883 28.4926 31.3651 29.0298C31.3428 29.5651 31.7714 30.0074 32.3293 30.0266Z" fill="#747070" />
        <path d="M38.0745 30.0258C38.6455 30.0511 39.0721 29.6683 39.0973 29.1068C39.1226 28.5504 38.7244 28.1202 38.1624 28.0989C37.6055 28.0777 37.1517 28.4807 37.1223 29.019C37.0951 29.5542 37.5175 30.0016 38.0745 30.0258Z" fill="#747070" />
        <path d="M43.9095 30.0306C44.4655 30.0164 44.8728 29.5862 44.8576 29.0298C44.8425 28.4723 44.4169 28.0855 43.8408 28.1047C43.2799 28.1239 42.8574 28.5581 42.8756 29.0974C42.8927 29.6195 43.3637 30.0447 43.9095 30.0306Z" fill="#747070" />
        <path d="M49.6151 28.1069C49.048 28.123 48.6195 28.5492 48.6336 29.0834C48.6468 29.6136 49.1046 30.0388 49.6525 30.0287C50.2094 30.0186 50.6298 29.5904 50.6177 29.0461C50.6036 28.4916 50.177 28.0917 49.6151 28.1069Z" fill="#747070" />
        <path d="M66.883 28.1069C66.315 28.1241 65.8895 28.5482 65.9026 29.0865C65.9148 29.6127 66.3787 30.0409 66.9245 30.0298C67.4814 30.0187 67.9029 29.5874 67.8887 29.0441C67.8756 28.4907 67.445 28.0908 66.883 28.1069Z" fill="#747070" />
        <path d="M78.4395 30.0279C78.9954 30.0128 79.4179 29.5795 79.4037 29.0382C79.3896 28.4909 78.9519 28.0879 78.391 28.1071C77.824 28.1263 77.3964 28.5555 77.4146 29.0887C77.4328 29.6189 77.8957 30.0431 78.4395 30.0279Z" fill="#747070" />
        <path d="M84.1972 30.0276C84.7521 30.0124 85.1756 29.5782 85.1614 29.0369C85.1483 28.4915 84.7056 28.0855 84.1487 28.1068C83.5797 28.128 83.1562 28.5552 83.1723 29.0894C83.1885 29.6176 83.6524 30.0427 84.1972 30.0276Z" fill="#747070" />
        <path d="M271.189 165.999C271.72 166.023 272.179 165.591 272.197 165.049C272.216 164.497 271.8 164.042 271.262 164.025C270.712 164.008 270.273 164.419 270.253 164.974C270.232 165.527 270.642 165.976 271.189 165.999Z" fill="#747070" />
        <path d="M294.288 44.2973C294.824 44.2791 295.237 43.8257 295.219 43.2753C295.2 42.7259 294.756 42.3037 294.216 42.3239C293.665 42.3451 293.263 42.7865 293.287 43.3439C293.31 43.9044 293.743 44.3165 294.288 44.2973Z" fill="#747070" />
        <path d="M288.508 44.2998C289.042 44.2937 289.469 43.8464 289.462 43.299C289.455 42.7526 289.013 42.3133 288.48 42.3224C287.929 42.3315 287.515 42.7668 287.528 43.3222C287.541 43.8857 287.962 44.3059 288.508 44.2998Z" fill="#747070" />
        <path d="M282.691 44.3037C283.222 44.332 283.683 43.9038 283.708 43.3594C283.734 42.809 283.325 42.3505 282.786 42.3263C282.24 42.301 281.788 42.7171 281.769 43.2615C281.75 43.8189 282.154 44.2754 282.691 44.3037Z" fill="#747070" />
        <path d="M222.307 76.8267C222.871 76.8277 223.32 76.4116 223.325 75.8824C223.331 75.3522 222.884 74.9109 222.337 74.9058C221.782 74.9008 221.34 75.3179 221.331 75.8551C221.323 76.3995 221.751 76.8256 222.307 76.8267Z" fill="#747070" />
        <path d="M216.531 74.9056C215.977 74.9268 215.561 75.3661 215.58 75.9074C215.6 76.4558 216.041 76.8527 216.602 76.8254C217.169 76.7981 217.588 76.3659 217.565 75.8316C217.543 75.3045 217.075 74.8843 216.531 74.9056Z" fill="#747070" />
        <path d="M124.429 30.0165C124.994 30.0316 125.436 29.6347 125.457 29.0944C125.477 28.5541 125.053 28.1158 124.502 28.1047C123.936 28.0936 123.488 28.4935 123.468 29.0278C123.446 29.565 123.871 30.0013 124.429 30.0165Z" fill="#747070" />
        <path d="M130.228 28.105C129.661 28.108 129.223 28.5211 129.22 29.0553C129.217 29.5926 129.653 30.0147 130.214 30.0157C130.777 30.0178 131.215 29.6027 131.218 29.0654C131.221 28.5251 130.785 28.102 130.228 28.105Z" fill="#747070" />
        <path d="M135.932 30.0157C136.498 30.0379 136.948 29.643 136.971 29.1047C136.995 28.5664 136.576 28.1231 136.024 28.1019C135.458 28.0807 135.009 28.4735 134.983 29.0128C134.957 29.5511 135.377 29.9945 135.932 30.0157Z" fill="#747070" />
        <path d="M141.783 28.1011C141.219 28.0778 140.769 28.4707 140.741 29.011C140.713 29.5473 141.132 29.9926 141.686 30.0159C142.25 30.0391 142.702 29.6442 142.727 29.1069C142.752 28.5697 142.334 28.1243 141.783 28.1011Z" fill="#747070" />
        <path d="M147.434 28.1003C146.884 28.1296 146.467 28.582 146.498 29.1153C146.53 29.6525 146.986 30.0444 147.548 30.0151C148.099 29.9868 148.517 29.5344 148.483 29.0001C148.449 28.4548 148.001 28.07 147.434 28.1003Z" fill="#747070" />
        <path d="M153.247 28.1006C152.688 28.1016 152.247 28.5237 152.246 29.058C152.245 29.5942 152.686 30.0154 153.246 30.0164C153.808 30.0164 154.246 29.5963 154.245 29.058C154.245 28.5217 153.805 28.0996 153.247 28.1006Z" fill="#747070" />
        <path d="M159.025 30.0166C159.584 30.0065 160.014 29.5743 160 29.037C159.986 28.4967 159.543 28.0897 158.981 28.1018C158.421 28.1139 157.996 28.5401 158.008 29.0814C158.018 29.6258 158.454 30.0267 159.025 30.0166Z" fill="#747070" />
        <path d="M164.705 30.0155C165.268 30.0437 165.72 29.6549 165.753 29.1136C165.785 28.5804 165.368 28.13 164.816 28.1017C164.243 28.0724 163.805 28.4491 163.77 29.0035C163.734 29.5398 164.148 29.9872 164.705 30.0155Z" fill="#747070" />
        <path d="M170.496 30.0167C171.063 30.0288 171.504 29.6238 171.515 29.0815C171.526 28.5442 171.099 28.115 170.539 28.1019C169.977 28.0887 169.535 28.4947 169.522 29.037C169.509 29.5763 169.936 30.0055 170.496 30.0167Z" fill="#747070" />
        <path d="M176.22 30.0154C176.784 30.0426 177.237 29.6528 177.268 29.1135C177.299 28.5803 176.887 28.14 176.325 28.1056C175.76 28.0713 175.314 28.4561 175.283 29.0055C175.252 29.5417 175.667 29.9891 176.22 30.0154Z" fill="#747070" />
        <path d="M205.075 39.3956C205.628 39.3866 206.06 38.9503 206.054 38.408C206.048 37.8858 205.581 37.4496 205.036 37.4566C204.492 37.4637 204.051 37.9111 204.062 38.4453C204.072 38.9866 204.518 39.4047 205.075 39.3956Z" fill="#747070" />
        <path d="M199.314 37.4572C198.769 37.4461 198.316 37.8733 198.308 38.4096C198.299 38.9519 198.726 39.3852 199.281 39.3953C199.834 39.4064 200.281 38.9872 200.296 38.4439C200.31 37.9178 199.862 37.4673 199.314 37.4572Z" fill="#747070" />
        <path d="M193.498 37.4554C192.959 37.4766 192.526 37.9391 192.549 38.4693C192.572 39.0096 193.026 39.4156 193.583 39.3954C194.132 39.3752 194.554 38.9268 194.534 38.3855C194.515 37.8563 194.044 37.4341 193.498 37.4554Z" fill="#747070" />
        <path d="M187.829 37.4574C187.282 37.4352 186.819 37.8523 186.797 38.3855C186.774 38.9258 187.192 39.3722 187.744 39.3964C188.299 39.4207 188.752 39.0157 188.78 38.4713C188.805 37.9401 188.374 37.4796 187.829 37.4574Z" fill="#747070" />
        <path d="M270.253 174.374C270.259 174.892 270.73 175.371 271.236 175.372C271.756 175.374 272.2 174.908 272.195 174.365C272.191 173.8 271.774 173.381 271.223 173.388C270.671 173.395 270.247 173.826 270.253 174.374Z" fill="#747070" />
        <path d="M170.52 74.917C169.959 74.916 169.521 75.3341 169.519 75.8714C169.518 76.4096 169.953 76.8318 170.512 76.8318C171.069 76.8328 171.516 76.4086 171.517 75.8784C171.519 75.3422 171.079 74.918 170.52 74.917Z" fill="#747070" />
        <path d="M276.992 173.39C276.432 173.392 276.013 173.809 276.011 174.365C276.01 174.916 276.445 175.37 276.977 175.372C277.501 175.374 277.951 174.916 277.957 174.374C277.964 173.811 277.55 173.389 276.992 173.39Z" fill="#747070" />
        <path d="M297.031 30.0226C297.601 30.0883 298.047 29.7419 298.117 29.1814C298.187 28.6178 297.831 28.1644 297.273 28.1068C296.693 28.0472 296.248 28.3876 296.181 28.943C296.115 29.5035 296.472 29.958 297.031 30.0226Z" fill="#747070" />
        <path d="M300.224 23.8168C299.643 23.7572 299.199 24.0975 299.132 24.653C299.065 25.2135 299.423 25.668 299.981 25.7326C300.551 25.7982 300.997 25.4518 301.067 24.8913C301.137 24.3278 300.782 23.8744 300.224 23.8168Z" fill="#747070" />
        <path d="M302.931 28.1036C302.353 28.0956 301.941 28.4813 301.929 29.0408C301.917 29.5953 302.326 30.0164 302.887 30.0285C303.452 30.0406 303.882 29.6357 303.893 29.0843C303.904 28.5248 303.499 28.1117 302.931 28.1036Z" fill="#747070" />
        <path d="M308.614 28.1048C308.043 28.1402 307.663 28.5583 307.691 29.1198C307.72 29.6793 308.141 30.0519 308.718 30.0246C309.283 29.9984 309.673 29.5712 309.643 29.0117C309.612 28.4502 309.18 28.0695 308.614 28.1048Z" fill="#747070" />
        <path d="M182.038 37.4576C181.492 37.4525 181.041 37.8858 181.038 38.419C181.034 38.9633 181.463 39.3905 182.02 39.3966C182.57 39.4037 183.018 38.9765 183.026 38.4382C183.034 37.912 182.581 37.4626 182.038 37.4576Z" fill="#747070" />
        <path d="M176.343 39.3964C176.885 39.3621 177.295 38.8985 177.259 38.3602C177.222 37.8189 176.766 37.4352 176.2 37.4705C175.648 37.5049 175.244 37.9583 175.287 38.5006C175.329 39.0409 175.79 39.4318 176.343 39.3964Z" fill="#747070" />
        <path d="M170.515 37.4722C169.95 37.4732 169.521 37.8882 169.523 38.4326C169.525 38.9759 169.96 39.3971 170.517 39.3971C171.071 39.3971 171.509 38.9709 171.512 38.4296C171.515 37.8913 171.077 37.4712 170.515 37.4722Z" fill="#747070" />
        <path d="M164.725 39.3966C165.279 39.4148 165.731 39.0058 165.754 38.4655C165.776 37.9262 165.352 37.4879 164.795 37.4717C164.222 37.4546 163.784 37.8494 163.767 38.3968C163.749 38.9331 164.175 39.3784 164.725 39.3966Z" fill="#747070" />
        <path d="M282.796 34.9236C283.362 34.8963 283.743 34.4711 283.714 33.8975C283.686 33.3562 283.215 32.9199 282.691 32.9502C282.159 32.9805 281.74 33.4572 281.767 33.9995C281.795 34.556 282.234 34.9498 282.796 34.9236Z" fill="#747070" />
        <path d="M159.012 39.3971C159.569 39.392 160.003 38.9648 159.999 38.4235C159.996 37.8832 159.557 37.4682 158.994 37.4722C158.434 37.4762 157.999 37.9024 158.006 38.4407C158.016 38.981 158.457 39.4021 159.012 39.3971Z" fill="#747070" />
        <path d="M294.204 34.9246C294.765 34.9518 295.199 34.558 295.223 33.9975C295.246 33.4511 294.832 32.9815 294.302 32.9563C293.761 32.93 293.315 33.3461 293.288 33.8985C293.261 34.463 293.648 34.8973 294.204 34.9246Z" fill="#747070" />
        <path d="M300.025 32.9523C299.488 32.9432 299.053 33.3764 299.043 33.9289C299.033 34.4924 299.434 34.9166 299.989 34.9287C300.546 34.9408 300.97 34.5298 300.977 33.9703C300.983 33.431 300.545 32.9614 300.025 32.9523Z" fill="#747070" />
        <path d="M305.719 32.9573C305.179 32.9856 304.772 33.441 304.794 33.9914C304.817 34.5438 305.253 34.9518 305.803 34.9327C306.349 34.9135 306.75 34.4691 306.727 33.9096C306.705 33.3633 306.246 32.93 305.719 32.9573Z" fill="#747070" />
        <path d="M9.39651 39.3972C9.95646 39.3649 10.3466 38.9286 10.3173 38.3671C10.288 37.8046 9.85842 37.4239 9.28634 37.4522C8.73549 37.4794 8.32513 37.9329 8.35343 38.4823C8.38072 39.0246 8.84162 39.4296 9.39651 39.3972Z" fill="#747070" />
        <path d="M15.1583 39.399C15.7162 39.3647 16.1054 38.9254 16.075 38.3628C16.0447 37.8023 15.6121 37.4236 15.038 37.4549C14.4892 37.4852 14.0758 37.9427 14.1071 38.4881C14.1375 39.0274 14.6054 39.4333 15.1583 39.399Z" fill="#747070" />
        <path d="M20.8948 37.4486C20.3308 37.4264 19.8952 37.8263 19.872 38.3868C19.8497 38.9463 20.25 39.3755 20.813 39.3947C21.38 39.4139 21.8096 39.0221 21.8358 38.4636C21.8621 37.9061 21.4578 37.4709 20.8948 37.4486Z" fill="#747070" />
        <path d="M32.3694 39.3968C32.9385 39.3928 33.3438 38.9858 33.3428 38.4182C33.3418 37.8537 32.9274 37.4467 32.3583 37.4507C31.8065 37.4548 31.3597 37.8961 31.3658 38.4303C31.3729 38.9737 31.8166 39.4008 32.3694 39.3968Z" fill="#747070" />
        <path d="M38.1827 37.4464C37.6399 37.4161 37.1669 37.8291 37.1315 38.3654C37.0961 38.9027 37.5105 39.3612 38.0614 39.3935C38.6264 39.4258 39.0721 39.038 39.1045 38.4836C39.1368 37.9261 38.7386 37.4777 38.1827 37.4464Z" fill="#747070" />
        <path d="M43.8895 39.3964C44.4535 39.3894 44.8629 38.9723 44.8568 38.4098C44.8507 37.8493 44.4293 37.4413 43.8633 37.4493C43.3255 37.4574 42.8626 37.9189 42.8707 38.439C42.8788 38.9682 43.3407 39.4025 43.8895 39.3964Z" fill="#747070" />
        <path d="M153.253 37.4722C152.689 37.4692 152.252 37.8852 152.251 38.4255C152.25 38.9668 152.684 39.394 153.241 39.3971C153.797 39.4011 154.237 38.979 154.244 38.4377C154.25 37.8984 153.815 37.4742 153.253 37.4722Z" fill="#747070" />
        <path d="M279.938 39.3929C280.498 39.3667 280.894 38.9304 280.864 38.3729C280.833 37.8144 280.392 37.4175 279.831 37.4468C279.269 37.4751 278.875 37.9134 278.905 38.4759C278.935 39.0314 279.372 39.4192 279.938 39.3929Z" fill="#747070" />
        <path d="M277.156 42.2968C276.594 42.3251 276.2 42.7634 276.231 43.3259C276.26 43.8814 276.698 44.2702 277.264 44.2429C277.824 44.2167 278.22 43.7804 278.189 43.2229C278.159 42.6644 277.717 42.2686 277.156 42.2968Z" fill="#747070" />
        <path d="M285.64 39.3958C286.202 39.3988 286.626 38.9827 286.625 38.4273C286.624 37.8698 286.2 37.4487 285.639 37.4497C285.073 37.4507 284.655 37.8648 284.659 38.4222C284.663 38.9848 285.074 39.3928 285.64 39.3958Z" fill="#747070" />
        <path d="M291.39 37.4483C290.827 37.4534 290.412 37.8725 290.417 38.4299C290.423 38.9864 290.842 39.3964 291.405 39.3964C291.966 39.3964 292.384 38.9773 292.38 38.4208C292.375 37.8583 291.951 37.4422 291.39 37.4483Z" fill="#747070" />
        <path d="M297.203 39.3948C297.766 39.3715 298.156 38.9433 298.129 38.3778C298.101 37.8122 297.67 37.4194 297.104 37.4466C296.544 37.4739 296.143 37.9142 296.172 38.4717C296.201 39.0251 296.639 39.417 297.203 39.3948Z" fill="#747070" />
        <path d="M148.481 38.3885C148.459 37.8442 148.015 37.4503 147.448 37.4715C146.89 37.4927 146.472 37.9341 146.499 38.4733C146.527 39.0187 146.975 39.4186 147.534 39.3964C148.083 39.3742 148.503 38.9278 148.481 38.3885Z" fill="#747070" />
        <path d="M144.538 182.773C143.987 182.793 143.574 183.239 143.604 183.779C143.634 184.32 144.08 184.71 144.643 184.686C145.198 184.662 145.617 184.216 145.584 183.682C145.55 183.145 145.099 182.752 144.538 182.773Z" fill="#747070" />
        <path d="M150.358 182.775C149.801 182.762 149.359 183.176 149.355 183.715C149.35 184.257 149.776 184.679 150.335 184.686C150.9 184.694 151.342 184.282 151.347 183.745C151.351 183.206 150.925 182.788 150.358 182.775Z" fill="#747070" />
        <path d="M271.29 182.771C270.715 182.749 270.287 183.122 270.253 183.675C270.22 184.232 270.611 184.673 271.167 184.703C271.726 184.733 272.173 184.339 272.201 183.791C272.231 183.221 271.848 182.792 271.29 182.771Z" fill="#747070" />
        <path d="M274.2 187.225C273.625 187.202 273.196 187.576 273.163 188.128C273.129 188.686 273.52 189.126 274.076 189.157C274.635 189.187 275.082 188.793 275.11 188.245C275.141 187.675 274.758 187.246 274.2 187.225Z" fill="#747070" />
        <path d="M73.6403 38.4835C73.6757 37.9331 73.2714 37.4807 72.7195 37.4534C72.1747 37.4262 71.6987 37.8402 71.6663 38.3694C71.634 38.9097 72.0454 39.3601 72.6013 39.3935C73.1653 39.4278 73.6049 39.043 73.6403 38.4835Z" fill="#747070" />
        <path d="M95.7269 37.4543C95.1801 37.4341 94.7111 37.8532 94.6889 38.3834C94.6657 38.9197 95.0902 39.3711 95.641 39.3943C96.1959 39.4186 96.6477 39.0116 96.672 38.4673C96.6952 37.93 96.2707 37.4745 95.7269 37.4543Z" fill="#747070" />
        <path d="M101.381 39.3953C101.947 39.4236 102.393 39.0348 102.426 38.4834C102.458 37.9471 102.037 37.4815 101.496 37.4553C100.949 37.429 100.47 37.8441 100.444 38.3703C100.418 38.9065 100.838 39.367 101.381 39.3953Z" fill="#747070" />
        <path d="M107.205 37.4565C106.657 37.4545 106.2 37.8918 106.199 38.419C106.198 38.9633 106.633 39.3936 107.187 39.3966C107.744 39.3996 108.184 38.9765 108.188 38.4362C108.193 37.9009 107.748 37.4586 107.205 37.4565Z" fill="#747070" />
        <path d="M112.969 37.4572C112.425 37.4481 111.966 37.8813 111.958 38.4125C111.95 38.9559 112.38 39.3881 112.936 39.3952C113.491 39.4023 113.937 38.9831 113.947 38.4438C113.957 37.9147 113.513 37.4663 112.969 37.4572Z" fill="#747070" />
        <path d="M119.695 38.4937C119.733 37.9594 119.318 37.4908 118.775 37.4565C118.231 37.4232 117.755 37.8322 117.72 38.3634C117.685 38.9017 118.098 39.3642 118.642 39.3965C119.202 39.4288 119.655 39.04 119.695 38.4937Z" fill="#747070" />
        <path d="M124.495 39.3965C125.048 39.3793 125.471 38.939 125.454 38.3987C125.437 37.8665 124.977 37.4403 124.435 37.4555C123.892 37.4706 123.452 37.9291 123.471 38.4573C123.49 39.0006 123.941 39.4137 124.495 39.3965Z" fill="#747070" />
        <path d="M130.279 39.3962C130.828 39.3659 131.239 38.9145 131.208 38.3732C131.178 37.8258 130.733 37.441 130.164 37.4703C129.612 37.4986 129.196 37.953 129.232 38.4863C129.27 39.0357 129.719 39.4265 130.279 39.3962Z" fill="#747070" />
        <path d="M135.995 37.4718C135.419 37.4637 134.991 37.8606 134.983 38.4151C134.975 38.9543 135.407 39.3886 135.96 39.3977C136.515 39.4068 136.962 38.9887 136.973 38.4494C136.984 37.9101 136.554 37.4799 135.995 37.4718Z" fill="#747070" />
        <path d="M142.727 38.3876C142.704 37.8463 142.256 37.4494 141.691 37.4716C141.133 37.4928 140.714 37.9351 140.741 38.4744C140.769 39.0188 141.219 39.4197 141.778 39.3965C142.326 39.3743 142.749 38.9248 142.727 38.3876Z" fill="#747070" />
        <path d="M161.864 70.4278C161.32 70.4227 160.87 70.86 160.87 71.3943C160.869 71.9396 161.301 72.3638 161.86 72.3668C162.411 72.3698 162.855 71.9426 162.863 71.4013C162.869 70.8802 162.409 70.4318 161.864 70.4278Z" fill="#747070" />
        <path d="M156.12 70.4279C155.577 70.4178 155.121 70.8511 155.114 71.3833C155.107 71.9266 155.535 72.3578 156.09 72.3679C156.641 72.377 157.092 71.9529 157.106 71.4136C157.119 70.8935 156.662 70.438 156.12 70.4279Z" fill="#747070" />
        <path d="M150.347 72.3667C150.904 72.3677 151.342 71.9426 151.344 71.3992C151.346 70.8721 150.891 70.4307 150.345 70.4287C149.801 70.4277 149.353 70.866 149.354 71.3982C149.356 71.9405 149.791 72.3657 150.347 72.3667Z" fill="#747070" />
        <path d="M145.586 71.4369C145.609 70.9077 145.174 70.4512 144.628 70.43C144.079 70.4088 143.622 70.8238 143.604 71.3611C143.584 71.9044 144.002 72.3458 144.555 72.367C145.111 72.3882 145.561 71.9822 145.586 71.4369Z" fill="#747070" />
        <path d="M147.485 187.23C146.935 187.232 146.496 187.665 146.495 188.205C146.495 188.746 146.932 189.163 147.496 189.16C148.06 189.158 148.491 188.736 148.485 188.193C148.48 187.653 148.04 187.228 147.485 187.23Z" fill="#747070" />
        <path d="M141.723 187.217C141.174 187.219 140.735 187.652 140.734 188.192C140.734 188.733 141.171 189.15 141.735 189.147C142.299 189.145 142.73 188.723 142.724 188.18C142.719 187.638 142.278 187.215 141.723 187.217Z" fill="#747070" />
        <path d="M139.159 182.468C138.609 182.47 138.17 182.902 138.169 183.442C138.169 183.984 138.606 184.401 139.17 184.398C139.734 184.396 140.166 183.974 140.16 183.43C140.155 182.889 139.714 182.465 139.159 182.468Z" fill="#747070" />
        <path d="M133.411 173.413C132.862 173.415 132.423 173.847 132.422 174.388C132.422 174.929 132.859 175.346 133.422 175.343C133.986 175.341 134.418 174.919 134.412 174.376C134.407 173.835 133.966 173.411 133.411 173.413Z" fill="#747070" />
        <path d="M130.215 168.434C129.665 168.436 129.226 168.868 129.225 169.409C129.225 169.95 129.662 170.367 130.226 170.365C130.79 170.362 131.221 169.941 131.215 169.398C131.21 168.855 130.771 168.431 130.215 168.434Z" fill="#747070" />
        <path d="M153.189 187.229C152.644 187.258 152.228 187.718 152.258 188.257C152.287 188.797 152.744 189.194 153.304 189.165C153.852 189.137 154.272 188.676 154.236 188.143C154.199 187.6 153.741 187.2 153.189 187.229Z" fill="#747070" />
        <path d="M155.866 192.127C155.321 192.156 154.906 192.615 154.935 193.155C154.964 193.695 155.421 194.092 155.981 194.063C156.53 194.034 156.949 193.574 156.913 193.041C156.877 192.496 156.418 192.096 155.866 192.127Z" fill="#747070" />
        <path d="M150.332 192.129C149.784 192.139 149.346 192.581 149.355 193.114C149.364 193.655 149.803 194.068 150.364 194.06C150.923 194.053 151.349 193.627 151.344 193.08C151.339 192.551 150.88 192.119 150.332 192.129Z" fill="#747070" />
        <path d="M276.967 81.7441C277.51 81.7552 277.955 81.323 277.961 80.7776C277.967 80.2283 277.529 79.7627 277.007 79.7627C276.457 79.7627 276.022 80.1899 276.013 80.7393C276.003 81.2967 276.418 81.733 276.967 81.7441Z" fill="#747070" />
        <path d="M282.703 91.1078C283.231 91.135 283.687 90.7008 283.709 90.1544C283.73 89.6 283.322 89.1546 282.778 89.1354C282.231 89.1162 281.791 89.5293 281.77 90.0807C281.747 90.6381 282.148 91.0795 282.703 91.1078Z" fill="#747070" />
        <path d="M288.518 89.133C287.97 89.1229 287.539 89.541 287.527 90.0954C287.514 90.6549 287.922 91.0912 288.476 91.1094C289.004 91.1276 289.452 90.6852 289.463 90.1358C289.474 89.5804 289.062 89.1431 288.518 89.133Z" fill="#747070" />
        <path d="M291.291 95.6978C291.819 95.716 292.267 95.2736 292.278 94.7242C292.29 94.1678 291.878 93.7305 291.333 93.7204C290.785 93.7103 290.354 94.1284 290.342 94.6828C290.33 95.2423 290.738 95.6796 291.291 95.6978Z" fill="#747070" />
        <path d="M294.087 98.5861C293.539 98.576 293.109 98.9941 293.096 99.5485C293.083 100.108 293.492 100.544 294.046 100.562C294.573 100.581 295.021 100.138 295.032 99.5889C295.043 99.0335 294.632 98.5962 294.087 98.5861Z" fill="#747070" />
        <path d="M230.99 70.4262C230.415 70.3939 229.988 70.7726 229.96 71.3402C229.932 71.9068 230.321 72.343 230.882 72.3723C231.424 72.4006 231.904 71.9835 231.936 71.4523C231.967 70.9282 231.533 70.4565 230.99 70.4262Z" fill="#747070" />
        <path d="M138.87 72.3694C139.419 72.3512 139.845 71.9048 139.828 71.3656C139.81 70.8354 139.343 70.4092 138.8 70.4284C138.258 70.4475 137.823 70.906 137.846 71.4352C137.868 71.9806 138.314 72.3876 138.87 72.3694Z" fill="#747070" />
        <path d="M133.062 70.4303C132.519 70.4394 132.076 70.8888 132.087 71.419C132.098 71.9613 132.54 72.3794 133.095 72.3703C133.648 72.3613 134.079 71.927 134.072 71.3847C134.065 70.8555 133.605 70.4212 133.062 70.4303Z" fill="#747070" />
        <path d="M128.314 71.364C128.296 70.8217 127.852 70.4258 127.284 70.445C126.723 70.4642 126.309 70.8995 126.334 71.4418C126.359 71.9871 126.806 72.3901 127.362 72.3699C127.908 72.3497 128.331 71.9023 128.314 71.364Z" fill="#747070" />
        <path d="M121.628 72.3704C122.172 72.338 122.588 71.8785 122.556 71.3433C122.525 70.803 122.071 70.4162 121.505 70.4465C120.948 70.4768 120.54 70.9252 120.579 71.4655C120.618 72.0118 121.072 72.4027 121.628 72.3704Z" fill="#747070" />
        <path d="M115.744 70.4448C115.188 70.4771 114.781 70.9305 114.822 71.4688C114.864 72.0111 115.325 72.404 115.877 72.3696C116.422 72.3363 116.835 71.8758 116.8 71.3375C116.766 70.7972 116.31 70.4124 115.744 70.4448Z" fill="#747070" />
        <path d="M110.06 70.4444C109.485 70.4413 109.06 70.8463 109.059 71.3967C109.058 71.939 109.492 72.3662 110.047 72.3692C110.6 72.3733 111.043 71.9491 111.05 71.4088C111.057 70.8705 110.623 70.4474 110.06 70.4444Z" fill="#747070" />
        <path d="M285.632 74.9048C285.069 74.9099 284.648 75.329 284.655 75.8784C284.662 76.4338 285.083 76.8388 285.649 76.8348C286.206 76.8307 286.645 76.4005 286.64 75.8632C286.634 75.326 286.186 74.9008 285.632 74.9048Z" fill="#747070" />
        <path d="M279.878 74.9048C279.317 74.9089 278.892 75.33 278.898 75.8774C278.903 76.4267 279.33 76.8378 279.893 76.8348C280.447 76.8317 280.892 76.3975 280.888 75.8642C280.885 75.334 280.428 74.9008 279.878 74.9048Z" fill="#747070" />
        <path d="M268.324 74.9027C267.766 74.9259 267.369 75.3612 267.395 75.9207C267.421 76.4792 267.852 76.8609 268.425 76.8347C268.98 76.8094 269.397 76.363 269.371 75.8217C269.345 75.2935 268.874 74.8805 268.324 74.9027Z" fill="#747070" />
        <path d="M262.674 76.8274C263.234 76.7971 263.644 76.3537 263.613 75.8134C263.583 75.2842 263.113 74.8763 262.561 74.9015C262.006 74.9267 261.61 75.3671 261.638 75.9245C261.667 76.4871 262.095 76.8587 262.674 76.8274Z" fill="#747070" />
        <path d="M256.865 76.8277C257.426 76.8256 257.866 76.4025 257.863 75.8672C257.86 75.337 257.406 74.9028 256.857 74.9048C256.297 74.9068 255.873 75.3249 255.875 75.8723C255.877 76.4237 256.297 76.8297 256.865 76.8277Z" fill="#747070" />
        <path d="M12.2486 107.873C11.7018 107.847 11.2298 108.258 11.1984 108.787C11.1671 109.319 11.5835 109.764 12.1435 109.799C12.7095 109.833 13.1441 109.452 13.1764 108.893C13.2088 108.344 12.8045 107.9 12.2486 107.873Z" fill="#747070" />
        <path d="M23.754 107.874C23.2103 107.851 22.7382 108.269 22.714 108.795C22.6887 109.336 23.1021 109.769 23.6671 109.798C24.2301 109.827 24.6667 109.437 24.693 108.883C24.7193 108.338 24.3089 107.898 23.754 107.874Z" fill="#747070" />
        <path d="M40.9997 109.8C41.5536 109.797 41.9751 109.37 41.964 108.821C41.9529 108.28 41.5132 107.869 40.9563 107.877C40.4044 107.886 39.9627 108.327 39.9749 108.86C39.986 109.39 40.4358 109.802 40.9997 109.8Z" fill="#747070" />
        <path d="M81.3192 107.888C80.7553 107.863 80.3045 108.254 80.2762 108.794C80.2489 109.326 80.6653 109.768 81.2253 109.799C81.7872 109.83 82.236 109.44 82.2633 108.893C82.2896 108.358 81.8731 107.914 81.3192 107.888Z" fill="#747070" />
        <path d="M87.0724 107.887C86.5114 107.864 86.0586 108.261 86.0323 108.799C86.0071 109.334 86.4265 109.778 86.9814 109.802C87.5464 109.827 87.9922 109.435 88.0184 108.89C88.0457 108.354 87.6273 107.91 87.0724 107.887Z" fill="#747070" />
        <path d="M92.7344 107.888C92.1795 107.912 91.7621 108.359 91.7904 108.895C91.8197 109.444 92.2604 109.827 92.8345 109.803C93.3854 109.78 93.8038 109.332 93.7775 108.796C93.7502 108.261 93.2944 107.864 92.7344 107.888Z" fill="#747070" />
        <path d="M98.4845 109.802C99.0566 109.831 99.4962 109.452 99.5326 108.901C99.567 108.366 99.1516 107.918 98.5967 107.89C98.0347 107.86 97.5799 108.251 97.5485 108.791C97.5172 109.325 97.9336 109.775 98.4845 109.802Z" fill="#747070" />
        <path d="M104.276 109.803C104.835 109.814 105.278 109.405 105.292 108.865C105.306 108.329 104.877 107.899 104.317 107.889C103.748 107.879 103.312 108.281 103.301 108.826C103.29 109.364 103.717 109.792 104.276 109.803Z" fill="#747070" />
        <path d="M110.024 109.802C110.584 109.817 111.03 109.413 111.049 108.874C111.067 108.337 110.641 107.904 110.082 107.89C109.514 107.874 109.073 108.274 109.058 108.817C109.042 109.353 109.468 109.787 110.024 109.802Z" fill="#747070" />
        <path d="M115.867 109.803C116.417 109.773 116.833 109.321 116.802 108.787C116.77 108.251 116.313 107.859 115.752 107.888C115.194 107.918 114.782 108.365 114.819 108.902C114.855 109.454 115.299 109.834 115.867 109.803Z" fill="#747070" />
        <path d="M121.568 107.889C121.008 107.889 120.566 108.311 120.567 108.846C120.568 109.384 121.007 109.805 121.564 109.804C122.123 109.803 122.567 109.381 122.567 108.849C122.567 108.311 122.125 107.888 121.568 107.889Z" fill="#747070" />
        <path d="M127.368 107.888C126.805 107.866 126.355 108.262 126.331 108.801C126.306 109.339 126.724 109.783 127.277 109.803C127.842 109.824 128.292 109.429 128.317 108.891C128.344 108.353 127.924 107.91 127.368 107.888Z" fill="#747070" />
        <path d="M133.033 107.888C132.477 107.911 132.061 108.354 132.087 108.891C132.115 109.449 132.55 109.829 133.124 109.799C133.686 109.769 134.102 109.331 134.074 108.796C134.047 108.257 133.598 107.864 133.033 107.888Z" fill="#747070" />
        <path d="M138.798 109.801C139.363 109.813 139.811 109.414 139.833 108.878C139.855 108.341 139.431 107.905 138.872 107.889C138.309 107.873 137.864 108.27 137.843 108.811C137.821 109.348 138.247 109.79 138.798 109.801Z" fill="#747070" />
        <path d="M179.118 107.877C178.564 107.885 178.131 108.315 178.137 108.855C178.143 109.399 178.583 109.814 179.138 109.8C179.705 109.786 180.138 109.363 180.131 108.829C180.124 108.305 179.66 107.869 179.118 107.877Z" fill="#747070" />
        <path d="M207.933 107.877C207.367 107.867 206.935 108.271 206.927 108.818C206.919 109.374 207.335 109.798 207.89 109.802C208.446 109.806 208.903 109.387 208.915 108.86C208.929 108.335 208.482 107.887 207.933 107.877Z" fill="#747070" />
        <path d="M213.719 109.798C214.284 109.765 214.693 109.324 214.66 108.787C214.627 108.251 214.164 107.848 213.612 107.873C213.057 107.899 212.657 108.34 212.687 108.892C212.717 109.455 213.147 109.832 213.719 109.798Z" fill="#747070" />
        <path d="M219.453 107.878C218.884 107.861 218.461 108.253 218.444 108.814C218.427 109.369 218.836 109.795 219.395 109.804C219.952 109.813 220.406 109.4 220.425 108.868C220.443 108.338 220.007 107.895 219.453 107.878Z" fill="#747070" />
        <path d="M225.238 107.879C224.666 107.848 224.237 108.224 224.205 108.785C224.172 109.347 224.56 109.777 225.124 109.805C225.683 109.833 226.137 109.441 226.174 108.898C226.21 108.365 225.792 107.911 225.238 107.879Z" fill="#747070" />
        <path d="M236.739 109.804C237.293 109.781 237.713 109.334 237.687 108.797C237.66 108.261 237.199 107.851 236.646 107.873C236.091 107.896 235.682 108.337 235.709 108.887C235.735 109.444 236.167 109.828 236.739 109.804Z" fill="#747070" />
        <path d="M242.409 109.808C242.96 109.828 243.423 109.413 243.443 108.881C243.464 108.338 243.043 107.897 242.485 107.878C241.916 107.859 241.481 108.252 241.464 108.802C241.447 109.355 241.854 109.788 242.409 109.808Z" fill="#747070" />
        <path d="M248.174 109.803C248.74 109.82 249.178 109.418 249.189 108.871C249.201 108.313 248.791 107.89 248.228 107.878C247.659 107.866 247.233 108.262 247.221 108.813C247.209 109.372 247.608 109.786 248.174 109.803Z" fill="#747070" />
        <path d="M253.984 107.877C253.412 107.867 252.99 108.262 252.979 108.815C252.969 109.377 253.365 109.787 253.933 109.803C254.501 109.818 254.935 109.418 254.945 108.869C254.955 108.308 254.547 107.887 253.984 107.877Z" fill="#747070" />
        <path d="M259.659 107.873C259.1 107.902 258.709 108.336 258.738 108.895C258.766 109.444 259.199 109.822 259.772 109.801C260.333 109.779 260.722 109.349 260.689 108.788C260.656 108.228 260.224 107.843 259.659 107.873Z" fill="#747070" />
        <path d="M265.396 107.874C264.838 107.913 264.458 108.354 264.499 108.915C264.538 109.471 264.968 109.831 265.55 109.8C266.107 109.769 266.484 109.334 266.444 108.767C266.407 108.216 265.958 107.834 265.396 107.874Z" fill="#747070" />
        <path d="M271.18 107.874C270.616 107.899 270.225 108.325 270.251 108.885C270.278 109.439 270.699 109.815 271.277 109.798C271.84 109.782 272.228 109.363 272.205 108.794C272.181 108.241 271.745 107.849 271.18 107.874Z" fill="#747070" />
        <path d="M276.996 107.877C276.426 107.87 276.013 108.265 276.007 108.826C276.001 109.387 276.397 109.785 276.974 109.8C277.54 109.814 277.959 109.414 277.967 108.855C277.975 108.298 277.564 107.884 276.996 107.877Z" fill="#747070" />
        <path d="M282.704 107.873C282.143 107.891 281.747 108.314 281.765 108.876C281.783 109.431 282.198 109.809 282.78 109.799C283.345 109.789 283.736 109.375 283.717 108.806C283.699 108.248 283.271 107.855 282.704 107.873Z" fill="#747070" />
        <path d="M288.457 107.873C287.896 107.892 287.505 108.314 287.524 108.879C287.542 109.436 287.955 109.809 288.541 109.798C289.105 109.788 289.495 109.371 289.474 108.802C289.452 108.248 289.021 107.854 288.457 107.873Z" fill="#747070" />
        <path d="M20.8524 112.352C20.2945 112.351 19.8457 112.779 19.8457 113.312C19.8457 113.843 20.2965 114.277 20.8494 114.278C21.4164 114.279 21.8389 113.868 21.8399 113.316C21.8399 112.765 21.4184 112.353 20.8524 112.352Z" fill="#747070" />
        <path d="M26.5956 112.352C26.0377 112.358 25.598 112.791 25.6051 113.327C25.6121 113.857 26.07 114.287 26.6219 114.279C27.1869 114.271 27.6033 113.854 27.5972 113.3C27.5912 112.751 27.1636 112.346 26.5956 112.352Z" fill="#747070" />
        <path d="M32.3161 112.354C31.7582 112.379 31.3398 112.825 31.3681 113.364C31.3964 113.897 31.8653 114.307 32.4152 114.281C32.9711 114.254 33.3774 113.813 33.3521 113.264C33.3248 112.707 32.8933 112.328 32.3161 112.354Z" fill="#747070" />
        <path d="M38.1336 112.351C37.5827 112.344 37.1259 112.771 37.1178 113.301C37.1097 113.835 37.5514 114.271 38.1063 114.278C38.6713 114.285 39.0938 113.883 39.1039 113.327C39.115 112.776 38.6986 112.358 38.1336 112.351Z" fill="#747070" />
        <path d="M176.328 76.8268C176.889 76.7915 177.3 76.3481 177.267 75.8149C177.234 75.2776 176.779 74.8878 176.216 74.9171C175.662 74.9454 175.25 75.3927 175.282 75.93C175.316 76.4844 175.758 76.8632 176.328 76.8268Z" fill="#747070" />
        <path d="M61.2041 114.283C61.757 114.252 62.1593 113.806 62.1269 113.257C62.0946 112.7 61.666 112.331 61.0849 112.361C60.5269 112.389 60.1146 112.837 60.154 113.371C60.1944 113.911 60.6593 114.315 61.2041 114.283Z" fill="#747070" />
        <path d="M66.8491 112.36C66.2922 112.385 65.8687 112.836 65.9061 113.364C65.9435 113.896 66.4145 114.309 66.9532 114.283C67.5041 114.256 67.9124 113.809 67.8872 113.264C67.8599 112.712 67.4273 112.335 66.8491 112.36Z" fill="#747070" />
        <path d="M72.6193 112.36C72.0583 112.378 71.6318 112.818 71.6611 113.349C71.6904 113.883 72.1493 114.294 72.6951 114.275C73.256 114.256 73.6664 113.826 73.6431 113.28C73.6209 112.727 73.1913 112.342 72.6193 112.36Z" fill="#747070" />
        <path d="M78.4334 114.273C78.9964 114.263 79.4148 113.841 79.4027 113.297C79.3906 112.749 78.963 112.352 78.395 112.359C77.834 112.367 77.3974 112.8 77.4156 113.329C77.4348 113.873 77.8775 114.284 78.4334 114.273Z" fill="#747070" />
        <path d="M84.1861 112.358C83.6242 112.348 83.1744 112.765 83.1724 113.296C83.1703 113.836 83.604 114.265 84.1568 114.271C84.7218 114.277 85.1534 113.873 85.1615 113.329C85.1706 112.786 84.7501 112.368 84.1861 112.358Z" fill="#747070" />
        <path d="M89.87 112.359C89.3131 112.386 88.9007 112.833 88.9401 113.368C88.9805 113.912 89.4333 114.303 89.9872 114.273C90.5381 114.243 90.9495 113.792 90.9161 113.256C90.8817 112.702 90.4512 112.332 89.87 112.359Z" fill="#747070" />
        <path d="M95.6542 112.361C95.0942 112.374 94.6657 112.81 94.6899 113.342C94.7152 113.883 95.164 114.291 95.7148 114.273C96.2707 114.257 96.6922 113.823 96.676 113.285C96.6599 112.745 96.2192 112.348 95.6542 112.361Z" fill="#747070" />
        <path d="M101.384 112.359C100.831 112.384 100.412 112.837 100.451 113.367C100.492 113.908 100.947 114.302 101.498 114.273C102.053 114.244 102.461 113.799 102.428 113.258C102.395 112.705 101.964 112.333 101.384 112.359Z" fill="#747070" />
        <path d="M107.221 112.359C106.661 112.345 106.206 112.757 106.199 113.286C106.191 113.825 106.621 114.259 107.172 114.271C107.735 114.283 108.173 113.881 108.187 113.34C108.202 112.801 107.781 112.373 107.221 112.359Z" fill="#747070" />
        <path d="M112.997 112.359C112.427 112.335 111.978 112.728 111.958 113.268C111.938 113.801 112.363 114.25 112.908 114.271C113.475 114.292 113.917 113.903 113.943 113.36C113.969 112.825 113.55 112.381 112.997 112.359Z" fill="#747070" />
        <path d="M118.836 112.363C118.269 112.298 117.791 112.651 117.725 113.184C117.661 113.707 118.057 114.206 118.584 114.265C119.156 114.329 119.617 113.986 119.692 113.441C119.763 112.909 119.381 112.427 118.836 112.363Z" fill="#747070" />
        <path d="M124.443 112.363C123.871 112.383 123.445 112.81 123.471 113.339C123.498 113.895 123.927 114.286 124.493 114.272C125.05 114.258 125.474 113.827 125.46 113.291C125.447 112.749 125.001 112.344 124.443 112.363Z" fill="#747070" />
        <path d="M176.302 114.27C176.864 114.255 177.287 113.826 177.272 113.288C177.256 112.753 176.813 112.355 176.241 112.362C175.687 112.369 175.26 112.806 175.278 113.345C175.296 113.886 175.737 114.285 176.302 114.27Z" fill="#747070" />
        <path d="M182.057 114.271C182.606 114.258 183.032 113.823 183.025 113.284C183.018 112.76 182.562 112.353 181.995 112.363C181.443 112.374 181.017 112.809 181.035 113.347C181.053 113.885 181.495 114.284 182.057 114.271Z" fill="#747070" />
        <path d="M187.787 112.36C187.227 112.361 186.789 112.779 186.787 113.314C186.786 113.847 187.225 114.27 187.782 114.272C188.334 114.273 188.775 113.852 188.785 113.314C188.796 112.787 188.348 112.359 187.787 112.36Z" fill="#747070" />
        <path d="M193.515 114.272C194.076 114.285 194.512 113.885 194.534 113.335C194.556 112.805 194.121 112.369 193.564 112.359C193.001 112.349 192.56 112.752 192.547 113.29C192.534 113.827 192.959 114.259 193.515 114.272Z" fill="#747070" />
        <path d="M199.253 114.273C199.804 114.296 200.254 113.895 200.288 113.354C200.32 112.825 199.896 112.379 199.341 112.36C198.774 112.339 198.334 112.727 198.308 113.272C198.283 113.807 198.702 114.251 199.253 114.273Z" fill="#747070" />
        <path d="M205.123 114.269C205.664 114.236 206.078 113.774 206.044 113.241C206.01 112.711 205.539 112.32 204.981 112.358C204.427 112.395 204.025 112.846 204.066 113.385C204.107 113.935 204.549 114.304 205.123 114.269Z" fill="#747070" />
        <path d="M210.807 114.272C211.361 114.274 211.801 113.853 211.809 113.313C211.817 112.782 211.373 112.357 210.812 112.359C210.251 112.36 209.816 112.776 209.815 113.313C209.813 113.849 210.249 114.27 210.807 114.272Z" fill="#747070" />
        <path d="M216.625 114.27C217.173 114.242 217.585 113.792 217.557 113.253C217.529 112.721 217.065 112.326 216.507 112.358C215.952 112.391 215.545 112.835 215.58 113.374C215.614 113.928 216.048 114.299 216.625 114.27Z" fill="#747070" />
        <path d="M222.344 114.271C222.894 114.262 223.327 113.827 223.322 113.289C223.317 112.758 222.864 112.346 222.302 112.358C221.737 112.371 221.322 112.789 221.333 113.335C221.344 113.881 221.777 114.281 222.344 114.271Z" fill="#747070" />
        <path d="M229.076 113.344C229.102 112.812 228.675 112.375 228.112 112.361C227.542 112.346 227.112 112.736 227.092 113.284C227.071 113.828 227.486 114.257 228.047 114.274C228.595 114.29 229.049 113.88 229.076 113.344Z" fill="#747070" />
        <path d="M233.762 112.357C233.203 112.395 232.811 112.839 232.853 113.39C232.896 113.941 233.339 114.315 233.909 114.277C234.442 114.242 234.866 113.766 234.83 113.241C234.794 112.713 234.319 112.32 233.762 112.357Z" fill="#747070" />
        <path d="M268.355 114.28C268.904 114.29 269.364 113.862 269.375 113.333C269.385 112.796 268.949 112.361 268.391 112.353C267.82 112.344 267.393 112.744 267.385 113.296C267.376 113.85 267.79 114.269 268.355 114.28Z" fill="#747070" />
        <path d="M274.144 112.352C273.575 112.344 273.146 112.748 273.139 113.297C273.132 113.85 273.548 114.269 274.112 114.279C274.663 114.288 275.122 113.86 275.131 113.33C275.141 112.795 274.701 112.36 274.144 112.352Z" fill="#747070" />
        <path d="M279.839 112.35C279.277 112.374 278.874 112.808 278.902 113.361C278.928 113.917 279.362 114.301 279.932 114.277C280.49 114.254 280.909 113.808 280.883 113.269C280.857 112.735 280.393 112.326 279.839 112.35Z" fill="#747070" />
        <path d="M285.693 112.355C285.118 112.329 284.687 112.709 284.66 113.267C284.633 113.821 285.035 114.258 285.593 114.283C286.147 114.307 286.609 113.901 286.638 113.365C286.667 112.821 286.254 112.381 285.693 112.355Z" fill="#747070" />
        <path d="M164.816 76.8328C165.366 76.8036 165.785 76.3511 165.752 75.8189C165.72 75.2796 165.264 74.8888 164.703 74.918C164.144 74.9463 163.734 75.3927 163.768 75.931C163.805 76.4794 164.249 76.8621 164.816 76.8328Z" fill="#747070" />
        <path d="M159.063 74.9179C158.501 74.8897 158.043 75.2805 158.012 75.8158C157.981 76.352 158.397 76.8045 158.945 76.8317C159.513 76.86 159.963 76.4762 159.997 75.9329C160.031 75.3987 159.614 74.9462 159.063 74.9179Z" fill="#747070" />
        <path d="M153.244 74.917C152.684 74.918 152.244 75.3422 152.246 75.8784C152.249 76.4137 152.691 76.8338 153.25 76.8328C153.81 76.8318 154.247 76.4096 154.246 75.8724C154.244 75.3341 153.805 74.916 153.244 74.917Z" fill="#747070" />
        <path d="M148.484 75.924C148.512 75.3867 148.094 74.9413 147.54 74.9171C146.975 74.8928 146.525 75.2847 146.496 75.826C146.468 76.3612 146.888 76.8086 147.44 76.8319C148.017 76.8571 148.454 76.4764 148.484 75.924Z" fill="#747070" />
        <path d="M105.29 71.4329C105.31 70.8946 104.886 70.4593 104.326 70.4452C103.753 70.4311 103.318 70.8259 103.304 71.3743C103.29 71.9146 103.715 72.3539 104.267 72.3691C104.822 72.3852 105.27 71.9752 105.29 71.4329Z" fill="#747070" />
        <path d="M98.5987 72.3702C99.1425 72.341 99.5599 71.8825 99.5316 71.3462C99.5023 70.8039 99.0525 70.4171 98.4835 70.4464C97.9276 70.4747 97.5132 70.9251 97.5496 71.4623C97.586 72.0087 98.0388 72.4005 98.5987 72.3702Z" fill="#747070" />
        <path d="M92.7482 70.4454C92.1883 70.4625 91.7658 70.9008 91.7901 71.4381C91.8143 71.9794 92.2661 72.3884 92.819 72.3702C93.3688 72.3521 93.7903 71.9107 93.7731 71.3694C93.7559 70.8221 93.3183 70.4282 92.7482 70.4454Z" fill="#747070" />
        <path d="M87.0411 72.3703C87.597 72.3622 88.0246 71.933 88.0185 71.3887C88.0124 70.8564 87.5586 70.4232 87.0149 70.4303C86.4731 70.4373 86.0213 70.8888 86.0314 71.4149C86.0415 71.9532 86.4903 72.3784 87.0411 72.3703Z" fill="#747070" />
        <path d="M81.2897 70.4284C80.7479 70.4183 80.285 70.8546 80.2779 71.3828C80.2699 71.9241 80.7014 72.3603 81.2543 72.3694C81.8092 72.3795 82.2519 71.9614 82.2651 71.4181C82.2762 70.8889 81.8335 70.4385 81.2897 70.4284Z" fill="#747070" />
        <path d="M75.5042 70.4283C74.9624 70.4344 74.5086 70.8868 74.5177 71.412C74.5268 71.9523 74.9736 72.3754 75.5264 72.3673C76.0823 72.3592 76.5109 71.931 76.5058 71.3877C76.5018 70.8565 76.048 70.4212 75.5042 70.4283Z" fill="#747070" />
        <path d="M69.7923 72.3673C70.3442 72.3502 70.7646 71.9078 70.7474 71.3655C70.7303 70.8272 70.2764 70.4101 69.7266 70.4283C69.1818 70.4455 68.7432 70.904 68.7634 71.4322C68.7846 71.9755 69.2354 72.3855 69.7923 72.3673Z" fill="#747070" />
        <path d="M63.9676 70.4284C63.4218 70.4476 62.9801 70.9081 63.0044 71.4322C63.0307 71.9766 63.4825 72.3866 64.0353 72.3674C64.5872 72.3482 65.0087 71.9059 64.9905 71.3635C64.9723 70.8293 64.5124 70.4092 63.9676 70.4284Z" fill="#747070" />
        <path d="M141.792 76.8327C142.34 76.8054 142.756 76.354 142.726 75.8167C142.696 75.2814 142.238 74.8896 141.676 74.9169C141.118 74.9451 140.706 75.3905 140.741 75.9308C140.775 76.4762 141.224 76.8609 141.792 76.8327Z" fill="#747070" />
        <path d="M135.982 74.917C135.423 74.914 134.98 75.3341 134.978 75.8714C134.975 76.4036 135.418 76.8298 135.976 76.8328C136.534 76.8358 136.974 76.4157 136.978 75.8784C136.981 75.3442 136.542 74.92 135.982 74.917Z" fill="#747070" />
        <path d="M129.226 75.9219C129.255 76.4542 129.709 76.846 130.274 76.8278C130.823 76.8096 131.241 76.3612 131.212 75.823C131.183 75.2847 130.732 74.8928 130.169 74.9171C129.614 74.9413 129.196 75.3867 129.226 75.9219Z" fill="#747070" />
        <path d="M124.443 76.8276C125.002 76.8468 125.447 76.4398 125.46 75.8985C125.472 75.3592 125.05 74.933 124.489 74.9179C123.926 74.9027 123.481 75.3067 123.467 75.847C123.453 76.3792 123.88 76.8084 124.443 76.8276Z" fill="#747070" />
        <path d="M58.1926 70.4292C57.6448 70.4595 57.2152 70.9251 57.2506 71.4502C57.287 71.9986 57.7418 72.3965 58.2977 72.3672C58.8465 72.339 59.2599 71.8865 59.2326 71.3442C59.2053 70.8059 58.7434 70.3999 58.1926 70.4292Z" fill="#747070" />
        <path d="M294.21 70.4219C293.646 70.4451 293.26 70.8672 293.282 71.4368C293.304 71.9963 293.729 72.3861 294.294 72.3659C294.855 72.3457 295.239 71.9246 295.219 71.353C295.199 70.7925 294.773 70.3986 294.21 70.4219Z" fill="#747070" />
        <path d="M90.9141 75.8187C90.8848 75.2804 90.433 74.8836 89.8751 74.9078C89.3303 74.931 88.9038 75.3895 88.9331 75.9197C88.9614 76.4489 89.4213 76.8468 89.9832 76.8276C90.53 76.8105 90.9434 76.3621 90.9141 75.8187Z" fill="#747070" />
        <path d="M84.1813 76.827C84.7382 76.8291 85.1668 76.4079 85.1607 75.8626C85.1557 75.3263 84.716 74.9062 84.1611 74.9082C83.6093 74.9102 83.1615 75.3465 83.1665 75.8777C83.1706 76.4069 83.6173 76.825 84.1813 76.827Z" fill="#747070" />
        <path d="M78.4285 76.8266C78.9874 76.8266 79.411 76.4065 79.4049 75.8581C79.3988 75.3178 78.9612 74.9027 78.4032 74.9068C77.8574 74.9098 77.4057 75.3531 77.4097 75.8803C77.4137 76.4065 77.8655 76.8266 78.4285 76.8266Z" fill="#747070" />
        <path d="M277.051 117.208C276.522 117.167 276.051 117.588 276.014 118.135C275.975 118.693 276.369 119.151 276.918 119.187C277.472 119.224 277.931 118.821 277.963 118.272C277.993 117.733 277.577 117.248 277.051 117.208Z" fill="#747070" />
        <path d="M282.765 117.202C282.232 117.186 281.785 117.624 281.769 118.177C281.754 118.735 282.165 119.172 282.718 119.186C283.274 119.199 283.707 118.784 283.717 118.227C283.727 117.686 283.293 117.217 282.765 117.202Z" fill="#747070" />
        <path d="M32.3662 121.701C31.8245 121.703 31.3697 122.149 31.3687 122.678C31.3676 123.212 31.8184 123.655 32.3632 123.655C32.9181 123.655 33.3396 123.233 33.3416 122.676C33.3436 122.115 32.9272 121.699 32.3662 121.701Z" fill="#747070" />
        <path d="M38.1072 121.702C37.5625 121.712 37.1107 122.166 37.1198 122.693C37.1278 123.226 37.5918 123.669 38.1305 123.657C38.6763 123.644 39.1038 123.205 39.1008 122.66C39.0968 122.102 38.6712 121.692 38.1072 121.702Z" fill="#747070" />
        <path d="M43.8607 121.705C43.322 121.715 42.8621 122.176 42.8712 122.695C42.8803 123.223 43.3513 123.669 43.887 123.656C44.4328 123.644 44.8593 123.205 44.8553 122.659C44.8523 122.1 44.4288 121.694 43.8607 121.705Z" fill="#747070" />
        <path d="M49.5776 74.9078C49.0258 74.9361 48.6094 75.3875 48.6387 75.9238C48.668 76.4601 49.1269 76.8559 49.6848 76.8297C50.2437 76.8034 50.6379 76.3702 50.6106 75.8137C50.5843 75.2623 50.1477 74.8785 49.5776 74.9078Z" fill="#747070" />
        <path d="M43.8946 74.9049C43.3457 74.8978 42.8818 75.327 42.8727 75.8512C42.8636 76.3894 43.2952 76.8176 43.8551 76.8287C44.4292 76.8399 44.8507 76.445 44.8588 75.8895C44.8669 75.33 44.4575 74.9119 43.8946 74.9049Z" fill="#747070" />
        <path d="M38.1754 74.9031C37.6256 74.8768 37.1536 75.2858 37.1233 75.815C37.0919 76.3563 37.5013 76.7976 38.0622 76.8279C38.6424 76.8592 39.0679 76.4886 39.0972 75.9261C39.1265 75.3676 38.7303 74.9283 38.1754 74.9031Z" fill="#747070" />
        <path d="M32.3163 74.908C31.7584 74.9333 31.3379 75.3796 31.3682 75.9159C31.3976 76.4562 31.8544 76.8602 32.4073 76.8369C32.9672 76.8137 33.3655 76.3815 33.3402 75.825C33.3149 75.2625 32.8904 74.8818 32.3163 74.908Z" fill="#747070" />
        <path d="M20.9044 74.903C20.3495 74.8777 19.8876 75.2827 19.8583 75.82C19.83 76.3572 20.2474 76.8056 20.8003 76.8329C21.3734 76.8602 21.805 76.4804 21.8343 75.921C21.8646 75.3695 21.4623 74.9292 20.9044 74.903Z" fill="#747070" />
        <path d="M15.1056 74.9049C14.5476 74.8988 14.115 75.3159 14.109 75.8653C14.1019 76.4106 14.5294 76.8318 15.0914 76.8348C15.6574 76.8368 16.0769 76.4318 16.0829 75.8774C16.089 75.33 15.6675 74.9099 15.1056 74.9049Z" fill="#747070" />
        <path d="M9.36593 76.8308C9.93396 76.8167 10.3332 76.4057 10.3221 75.8452C10.312 75.2917 9.88848 74.8959 9.31843 74.906C8.75343 74.916 8.34408 75.3362 8.35419 75.8947C8.36328 76.442 8.79992 76.845 9.36593 76.8308Z" fill="#747070" />
        <path d="M3.57848 76.8279C4.16572 76.8279 4.57406 76.4229 4.56496 75.8493C4.55586 75.2858 4.12024 74.8869 3.53704 74.9091C3.00135 74.9303 2.63041 75.2989 2.62031 75.82C2.6102 76.4108 3.00641 76.8279 3.57848 76.8279Z" fill="#747070" />
        <path d="M0.916963 70.4341C0.381273 70.4553 0.0103355 70.8239 0.000228194 71.345C-0.0108899 71.9358 0.385316 72.3529 0.958403 72.3529C1.54665 72.3529 1.95398 71.9479 1.94488 71.3743C1.93579 70.8097 1.49915 70.4108 0.916963 70.4341Z" fill="#747070" />
        <path d="M251.046 76.8293C251.606 76.8525 252.067 76.4566 252.099 75.9244C252.131 75.3892 251.714 74.9377 251.162 74.9074C250.595 74.8771 250.154 75.2609 250.125 75.8103C250.096 76.3637 250.495 76.8061 251.046 76.8293Z" fill="#747070" />
        <path d="M253.976 70.4219C253.409 70.4169 252.988 70.8229 252.982 71.3803C252.977 71.9428 253.383 72.3589 253.946 72.37C254.51 72.3811 254.935 71.9731 254.943 71.4136C254.951 70.8521 254.536 70.427 253.976 70.4219Z" fill="#747070" />
        <path d="M248.243 72.367C248.806 72.3488 249.204 71.9216 249.182 71.3601C249.16 70.7935 248.73 70.3977 248.165 70.4209C247.6 70.4451 247.202 70.8733 247.223 71.4348C247.245 71.9893 247.678 72.3852 248.243 72.367Z" fill="#747070" />
        <path d="M242.496 70.4215C241.924 70.3972 241.492 70.7851 241.468 71.3435C241.444 71.9081 241.836 72.3413 242.397 72.3706C242.95 72.3989 243.412 71.9899 243.437 71.4475C243.463 70.9012 243.044 70.4447 242.496 70.4215Z" fill="#747070" />
        <path d="M225.224 70.4252C224.651 70.3979 224.226 70.7817 224.202 71.3493C224.177 71.9158 224.57 72.347 225.132 72.3723C225.673 72.3965 226.156 71.9704 226.182 71.4452C226.208 70.9221 225.765 70.4515 225.224 70.4252Z" fill="#747070" />
        <path d="M262.595 121.7C262.035 121.708 261.62 122.139 261.637 122.696C261.653 123.24 262.096 123.662 262.645 123.656C263.185 123.65 263.629 123.193 263.616 122.658C263.603 122.136 263.13 121.692 262.595 121.7Z" fill="#747070" />
        <path d="M268.434 123.654C268.981 123.623 269.399 123.157 269.364 122.62C269.328 122.084 268.856 121.668 268.313 121.698C267.756 121.727 267.356 122.176 267.391 122.736C267.425 123.281 267.883 123.685 268.434 123.654Z" fill="#747070" />
        <path d="M130.176 161.088C130.738 161.109 131.186 160.713 131.212 160.171C131.238 159.636 130.817 159.188 130.264 159.164C129.71 159.14 129.252 159.547 129.229 160.083C129.206 160.617 129.629 161.067 130.176 161.088Z" fill="#747070" />
        <path d="M153.265 123.655C153.815 123.645 154.248 123.206 154.24 122.666C154.232 122.121 153.797 121.716 153.228 121.723C152.664 121.731 152.238 122.157 152.251 122.702C152.264 123.241 152.715 123.665 153.265 123.655Z" fill="#747070" />
        <path d="M219.485 72.3661C220.032 72.3347 220.449 71.8722 220.413 71.3359C220.378 70.8007 219.905 70.3897 219.359 70.419C218.804 70.4482 218.405 70.8977 218.438 71.4561C218.473 72.0085 218.921 72.3984 219.485 72.3661Z" fill="#747070" />
        <path d="M213.605 70.4187C213.052 70.447 212.65 70.9005 212.683 71.4559C212.716 72.0073 213.167 72.4002 213.729 72.3658C214.274 72.3335 214.699 71.8649 214.664 71.3357C214.628 70.8116 214.143 70.3915 213.605 70.4187Z" fill="#747070" />
        <path d="M207.972 72.3669C208.518 72.3336 208.941 71.866 208.906 71.3358C208.871 70.8097 208.387 70.3906 207.848 70.4188C207.298 70.4471 206.891 70.9046 206.924 71.457C206.957 72.0044 207.413 72.4003 207.972 72.3669Z" fill="#747070" />
        <path d="M202.191 70.4238C201.617 70.4015 201.194 70.7883 201.172 71.3569C201.15 71.9215 201.548 72.3507 202.113 72.3709C202.658 72.3901 203.136 71.9629 203.159 71.4357C203.183 70.9166 202.732 70.446 202.191 70.4238Z" fill="#747070" />
        <path d="M196.451 72.367C197.005 72.3387 197.424 71.8853 197.394 71.346C197.365 70.8198 196.883 70.3957 196.344 70.4189C195.791 70.4431 195.381 70.8955 195.412 71.448C195.443 72.0004 195.89 72.3953 196.451 72.367Z" fill="#747070" />
        <path d="M23.7706 70.4199C23.2309 70.3906 22.7468 70.8107 22.7134 71.3369C22.679 71.8752 23.0955 72.3337 23.6473 72.367C24.2073 72.4003 24.6601 72.0065 24.6955 71.4571C24.7298 70.9057 24.3235 70.4492 23.7706 70.4199Z" fill="#747070" />
        <path d="M18.0097 72.371C18.5667 72.3417 18.9609 71.9013 18.9326 71.3368C18.9043 70.7763 18.4686 70.3915 17.8976 70.4249C17.3598 70.4552 16.9171 70.9359 16.9505 71.454C16.9818 71.9771 17.47 72.3992 18.0097 72.371Z" fill="#747070" />
        <path d="M12.2574 70.4178C11.7177 70.3905 11.2346 70.8096 11.2002 71.3368C11.1658 71.867 11.5893 72.3336 12.1351 72.3669C12.6941 72.4002 13.1489 72.0043 13.1812 71.4559C13.2146 70.9035 12.8073 70.446 12.2574 70.4178Z" fill="#747070" />
        <path d="M6.49245 72.3712C7.05138 72.3429 7.44658 71.9036 7.41727 71.3421C7.38796 70.7725 6.96143 70.3948 6.38632 70.4261C5.8476 70.4554 5.40187 70.9341 5.4332 71.4502C5.46554 71.9723 5.95575 72.3985 6.49245 72.3712Z" fill="#747070" />
        <path d="M291.388 67.4862C291.95 67.4933 292.373 67.0792 292.378 66.5157C292.384 65.9562 291.969 65.5391 291.406 65.5381C290.841 65.5371 290.423 65.9451 290.416 66.5026C290.411 67.058 290.826 67.4791 291.388 67.4862Z" fill="#747070" />
        <path d="M285.585 65.5349C285.024 65.5672 284.635 66.0005 284.661 66.565C284.688 67.1245 285.12 67.5083 285.693 67.48C286.243 67.4527 286.659 66.9962 286.63 66.4509C286.602 65.9106 286.138 65.5036 285.585 65.5349Z" fill="#747070" />
        <path d="M279.944 65.5392C279.377 65.5099 278.938 65.8947 278.905 66.4511C278.871 67.0136 279.265 67.456 279.825 67.4873C280.382 67.5186 280.829 67.1217 280.864 66.5652C280.897 66.0088 280.502 65.5684 279.944 65.5392Z" fill="#747070" />
        <path d="M274.069 67.4871C274.617 67.5174 275.089 67.1053 275.122 66.5691C275.154 66.0247 274.744 65.5713 274.192 65.54C273.625 65.5077 273.182 65.8934 273.149 66.4499C273.113 67.0104 273.509 67.4558 274.069 67.4871Z" fill="#747070" />
        <path d="M268.352 67.4854C268.896 67.4955 269.356 67.0622 269.368 66.528C269.381 65.9857 268.949 65.5484 268.394 65.5383C267.829 65.5282 267.406 65.9311 267.393 66.4906C267.379 67.0541 267.786 67.4753 268.352 67.4854Z" fill="#747070" />
        <path d="M262.67 67.4811C263.206 67.4539 263.647 66.9762 263.618 66.454C263.588 65.9289 263.104 65.5068 262.563 65.535C262.001 65.5633 261.61 65.9986 261.64 66.5641C261.668 67.1307 262.096 67.5114 262.67 67.4811Z" fill="#747070" />
        <path d="M256.856 67.4852C257.397 67.4852 257.861 67.0368 257.863 66.5116C257.865 65.9794 257.413 65.5381 256.864 65.5381C256.297 65.5381 255.88 65.9481 255.878 66.5076C255.877 67.0681 256.293 67.4852 256.856 67.4852Z" fill="#747070" />
        <path d="M251.085 67.4829C251.634 67.4889 252.096 67.0547 252.105 66.5245C252.114 65.9902 251.673 65.5469 251.122 65.5368C250.559 65.5267 250.13 65.9357 250.121 66.4912C250.112 67.0527 250.525 67.4768 251.085 67.4829Z" fill="#747070" />
        <path d="M245.262 67.4861C245.803 67.5245 246.291 67.1205 246.34 66.5923C246.39 66.0662 245.975 65.5854 245.433 65.54C244.87 65.4935 244.418 65.8702 244.368 66.4267C244.319 66.9811 244.711 67.4467 245.262 67.4861Z" fill="#747070" />
        <path d="M205.04 65.5387C204.484 65.5468 204.058 65.977 204.065 66.5223C204.072 67.0606 204.521 67.4868 205.069 67.4767C205.614 67.4666 206.069 67.0132 206.057 66.4931C206.044 65.9517 205.595 65.5306 205.04 65.5387Z" fill="#747070" />
        <path d="M200.294 66.4628C200.261 65.9154 199.81 65.5124 199.255 65.5367C198.705 65.5609 198.286 66.0103 198.311 66.5506C198.334 67.0889 198.795 67.502 199.343 67.4757C199.891 67.4505 200.324 66.9889 200.294 66.4628Z" fill="#747070" />
        <path d="M193.502 67.4773C194.05 67.4955 194.517 67.0733 194.535 66.5451C194.554 66.0018 194.131 65.5574 193.581 65.5382C193.024 65.5191 192.576 65.9261 192.551 66.4694C192.527 67.0016 192.959 67.4591 193.502 67.4773Z" fill="#747070" />
        <path d="M187.801 65.5357C187.246 65.5296 186.799 65.9498 186.79 66.488C186.781 67.0172 187.227 67.4677 187.769 67.4757C188.311 67.4838 188.773 67.0485 188.779 66.5194C188.787 65.977 188.354 65.5428 187.801 65.5357Z" fill="#747070" />
        <path d="M181.974 65.5363C181.426 65.5656 181.014 66.0201 181.042 66.5604C181.071 67.0966 181.536 67.5046 182.084 67.4763C182.627 67.4481 183.054 66.9805 183.02 66.4503C182.985 65.8989 182.535 65.506 181.974 65.5363Z" fill="#747070" />
        <path d="M175.285 66.4558C175.258 66.989 175.682 67.4516 176.224 67.4768C176.769 67.5021 177.238 67.085 177.263 66.5538C177.288 66.0155 176.868 65.561 176.322 65.5368C175.757 65.5115 175.313 65.9044 175.285 66.4558Z" fill="#747070" />
        <path d="M170.579 65.5361C170.018 65.5037 169.569 65.8905 169.53 66.4389C169.493 66.9812 169.899 67.4306 170.454 67.4609C171.017 67.4922 171.474 67.1024 171.506 66.5631C171.538 66.0279 171.122 65.5684 170.579 65.5361Z" fill="#747070" />
        <path d="M28.4669 99.4463C28.4487 99.9745 28.8893 100.431 29.4351 100.448C29.9981 100.466 30.4348 100.062 30.4489 99.5109C30.4631 98.9585 30.0497 98.5233 29.4978 98.5101C28.9368 98.496 28.4861 98.905 28.4669 99.4463Z" fill="#747070" />
        <path d="M34.2236 99.503C34.2398 100.033 34.7037 100.46 35.2495 100.447C35.7994 100.435 36.2259 99.9928 36.2087 99.4515C36.1915 98.9021 35.7519 98.4931 35.195 98.5082C34.6401 98.5244 34.2065 98.9687 34.2236 99.503Z" fill="#747070" />
        <path d="M271.282 126.579C270.738 126.548 270.283 126.958 270.254 127.503C270.223 128.061 270.614 128.508 271.165 128.546C271.702 128.582 272.165 128.167 272.195 127.623C272.226 127.076 271.817 126.61 271.282 126.579Z" fill="#747070" />
        <path d="M276.944 128.549C277.484 128.573 277.933 128.15 277.952 127.6C277.971 127.055 277.55 126.597 277.015 126.577C276.472 126.557 276.029 126.975 276.01 127.526C275.991 128.087 276.391 128.525 276.944 128.549Z" fill="#747070" />
        <path d="M164.719 67.4628C165.282 67.483 165.731 67.0841 165.752 66.5438C165.773 66.0065 165.35 65.5581 164.802 65.5369C164.246 65.5157 163.794 65.9197 163.767 66.463C163.74 66.9993 164.161 67.4416 164.719 67.4628Z" fill="#747070" />
        <path d="M159.019 65.5373C158.463 65.5282 158.02 65.9452 158.008 66.4866C157.995 67.0238 158.428 67.4541 158.987 67.4621C159.565 67.4702 159.99 67.0723 159.998 66.5179C160.006 65.9756 159.576 65.5453 159.019 65.5373Z" fill="#747070" />
        <path d="M153.234 67.4616C153.799 67.4677 154.236 67.0556 154.241 66.5143C154.246 65.971 153.817 65.5428 153.26 65.5357C152.706 65.5287 152.26 65.9498 152.25 66.4891C152.24 67.0253 152.676 67.4566 153.234 67.4616Z" fill="#747070" />
        <path d="M147.482 65.5372C146.925 65.5422 146.492 65.9694 146.495 66.5107C146.498 67.051 146.936 67.4661 147.499 67.462C148.059 67.458 148.494 67.0318 148.486 66.4936C148.479 65.9543 148.035 65.5311 147.482 65.5372Z" fill="#747070" />
        <path d="M141.708 67.4604C142.284 67.4735 142.716 67.0776 142.728 66.5272C142.739 65.9879 142.312 65.5496 141.76 65.5355C141.206 65.5214 140.755 65.9354 140.738 66.4747C140.721 67.013 141.149 67.4483 141.708 67.4604Z" fill="#747070" />
        <path d="M135.942 65.5357C135.393 65.5539 134.967 66.0003 134.985 66.5366C135.003 67.0819 135.445 67.4798 136.013 67.4616C136.574 67.4445 136.99 67.0102 136.968 66.4659C136.946 65.9215 136.5 65.5176 135.942 65.5357Z" fill="#747070" />
        <path d="M130.172 65.5363C129.627 65.5616 129.206 66.015 129.231 66.5513C129.255 67.0906 129.708 67.4874 130.271 67.4622C130.825 67.438 131.239 66.9916 131.21 66.4523C131.18 65.9019 130.737 65.5111 130.172 65.5363Z" fill="#747070" />
        <path d="M124.524 65.5364C123.967 65.5051 123.513 65.899 123.476 66.4463C123.439 66.9745 123.866 67.4461 124.406 67.4764C124.953 67.5067 125.418 67.0997 125.451 66.5625C125.482 66.0222 125.072 65.5677 124.524 65.5364Z" fill="#747070" />
        <path d="M118.7 67.4757C119.245 67.4798 119.697 67.0455 119.7 66.5133C119.704 65.969 119.274 65.5428 118.717 65.5357C118.166 65.5296 117.719 65.9558 117.711 66.4951C117.703 67.0213 118.157 67.4717 118.7 67.4757Z" fill="#747070" />
        <path d="M112.899 65.5369C112.353 65.5631 111.934 66.0196 111.963 66.5579C111.991 67.0891 112.463 67.5042 113.008 67.4759C113.55 67.4486 113.972 66.9831 113.941 66.4498C113.909 65.9065 113.457 65.5106 112.899 65.5369Z" fill="#747070" />
        <path d="M107.242 67.4791C107.782 67.4579 108.211 66.9954 108.185 66.4621C108.159 65.9178 107.708 65.5148 107.15 65.5381C106.603 65.5603 106.182 66.0107 106.203 66.552C106.225 67.0802 106.695 67.5003 107.242 67.4791Z" fill="#747070" />
        <path d="M101.406 67.4768C101.95 67.497 102.409 67.0769 102.427 66.5406C102.446 65.9983 102.024 65.556 101.472 65.5368C100.918 65.5176 100.467 65.9286 100.441 66.473C100.418 66.9971 100.86 67.4566 101.406 67.4768Z" fill="#747070" />
        <path d="M95.6532 65.5397C95.0963 65.5599 94.6728 65.9992 94.6889 66.5395C94.7051 67.0657 95.1731 67.4919 95.7189 67.4777C96.2606 67.4636 96.6922 67.0111 96.674 66.4749C96.6548 65.9316 96.206 65.5205 95.6532 65.5397Z" fill="#747070" />
        <path d="M61.1614 67.4833C61.7274 67.4783 62.1388 67.0581 62.1277 66.4956C62.1166 65.9351 61.6931 65.5291 61.1301 65.5372C60.5711 65.5453 60.1375 65.9786 60.1466 66.5209C60.1547 67.0541 60.6126 67.4884 61.1614 67.4833Z" fill="#747070" />
        <path d="M55.3864 67.4822C55.9493 67.4882 56.3698 67.0752 56.3718 66.5137C56.3739 65.9572 55.9544 65.5381 55.3945 65.5371C54.8436 65.5361 54.3908 65.9704 54.3867 66.5036C54.3827 67.0257 54.8426 67.4761 55.3864 67.4822Z" fill="#747070" />
        <path d="M49.5729 67.4783C50.148 67.5147 50.5766 67.139 50.6109 66.5704C50.6453 66.0089 50.2531 65.5656 49.6942 65.5343C49.1555 65.504 48.6642 65.9241 48.6279 66.4462C48.5935 66.9643 49.0332 67.445 49.5729 67.4783Z" fill="#747070" />
        <path d="M43.8631 65.5382C43.3123 65.5452 42.8665 65.9876 42.8746 66.5208C42.8827 67.053 43.3436 67.4913 43.8904 67.4853C44.4534 67.4792 44.8668 67.0571 44.8587 66.4966C44.8516 65.9401 44.4261 65.5311 43.8631 65.5382Z" fill="#747070" />
        <path d="M38.0618 65.539C37.5119 65.5703 37.0965 66.0288 37.1309 66.5661C37.1653 67.1003 37.6393 67.5154 38.1821 67.4861C38.739 67.4558 39.1372 67.0094 39.1039 66.4499C39.0715 65.8945 38.6278 65.5067 38.0618 65.539Z" fill="#747070" />
        <path d="M32.3094 67.4821C32.8845 67.5114 33.312 67.1317 33.3403 66.5652C33.3686 65.9986 32.9795 65.5633 32.4185 65.5351C31.8738 65.5068 31.3977 65.9229 31.3654 66.4541C31.334 66.9782 31.7717 67.4549 32.3094 67.4821Z" fill="#747070" />
        <path d="M26.6261 67.4853C27.1931 67.4752 27.5995 67.0561 27.5873 66.4926C27.5762 65.9301 27.1568 65.5292 26.5897 65.5372C26.0359 65.5453 25.6063 65.9796 25.6134 66.5259C25.6204 67.0602 26.0793 67.4944 26.6261 67.4853Z" fill="#747070" />
        <path d="M20.9174 65.5349C20.3655 65.4995 19.8965 65.9035 19.8642 66.4438C19.8308 66.9881 20.2432 67.4486 20.791 67.4799C21.3662 67.5123 21.7998 67.1356 21.8321 66.5751C21.8634 66.0125 21.4743 65.5702 20.9174 65.5349Z" fill="#747070" />
        <path d="M15.0438 67.4814C15.6159 67.5097 16.0495 67.1259 16.0757 66.5665C16.102 66.0019 15.7129 65.5677 15.1519 65.5364C14.5991 65.5051 14.1351 65.912 14.1068 66.4533C14.0785 66.9977 14.4939 67.4542 15.0438 67.4814Z" fill="#747070" />
        <path d="M9.28582 67.481C9.85689 67.5093 10.2875 67.1266 10.3158 66.564C10.3451 66.0015 9.95493 65.5652 9.39498 65.5339C8.83908 65.5026 8.38121 65.9056 8.35392 66.4509C8.32562 67.0023 8.73396 67.4538 9.28582 67.481Z" fill="#747070" />
        <path d="M294.32 61.0803C293.733 61.056 293.315 61.4155 293.284 61.972C293.252 62.5396 293.631 62.9698 294.189 63.0001C294.752 63.0304 295.191 62.6466 295.224 62.0962C295.258 61.5216 294.887 61.1045 294.32 61.0803Z" fill="#747070" />
        <path d="M288.442 63.0007C289.007 63.028 289.441 62.6412 289.47 62.0867C289.499 61.5172 289.118 61.098 288.553 61.0788C287.966 61.0597 287.55 61.4242 287.524 61.9817C287.499 62.5483 287.882 62.9744 288.442 63.0007Z" fill="#747070" />
        <path d="M282.685 63.0022C283.249 63.0304 283.686 62.6416 283.714 62.0892C283.742 61.5186 283.361 61.0985 282.797 61.0803C282.212 61.0611 281.792 61.4277 281.766 61.9822C281.74 62.5447 282.127 62.9749 282.685 63.0022Z" fill="#747070" />
        <path d="M276.928 63.0018C277.493 63.0301 277.93 62.6433 277.958 62.0898C277.988 61.5203 277.606 61.0981 277.042 61.0789C276.457 61.0587 276.038 61.4243 276.009 61.9808C275.98 62.5453 276.366 62.9735 276.928 63.0018Z" fill="#747070" />
        <path d="M271.291 62.9954C271.859 62.9631 272.239 62.5359 272.202 61.9714C272.165 61.4088 271.737 61.0402 271.162 61.0756C270.596 61.1109 270.221 61.5341 270.253 62.1006C270.286 62.6601 270.713 63.0287 271.291 62.9954Z" fill="#747070" />
        <path d="M265.503 62.9967C266.068 62.9806 266.472 62.5594 266.452 62.002C266.433 61.4425 266.011 61.0567 265.439 61.0759C264.867 61.0951 264.479 61.5021 264.496 62.0666C264.513 62.6271 264.93 63.0119 265.503 62.9967Z" fill="#747070" />
        <path d="M259.745 62.9957C260.31 62.9816 260.716 62.5584 260.7 62.002C260.683 61.4495 260.253 61.0567 259.683 61.0759C259.113 61.094 258.721 61.5051 258.735 62.0666C258.749 62.621 259.173 63.0109 259.745 62.9957Z" fill="#747070" />
        <path d="M236.689 61.0698C236.127 61.0719 235.705 61.4869 235.705 62.0373C235.705 62.5857 236.131 62.9998 236.695 62.9988C237.246 62.9977 237.699 62.5594 237.697 62.0303C237.693 61.499 237.241 61.0678 236.689 61.0698Z" fill="#747070" />
        <path d="M196.431 61.0801C195.866 61.0569 195.432 61.4497 195.413 62.0031C195.394 62.5475 195.81 62.9827 196.365 63.0009C196.909 63.0181 197.379 62.5949 197.397 62.0708C197.417 61.5325 196.998 61.1033 196.431 61.0801Z" fill="#747070" />
        <path d="M52.4779 100.443C53.0328 100.451 53.4735 100.033 53.4796 99.4909C53.4856 98.9445 53.0561 98.5123 52.5052 98.5103C51.9483 98.5072 51.4965 98.9314 51.4874 99.4646C51.4793 99.9938 51.9261 100.435 52.4779 100.443Z" fill="#747070" />
        <path d="M58.2103 100.443C58.7642 100.463 59.213 100.055 59.2332 99.5131C59.2534 98.9697 58.832 98.5264 58.2821 98.5102C57.7272 98.4941 57.2734 98.9041 57.2481 99.4434C57.2229 99.9746 57.6544 100.423 58.2103 100.443Z" fill="#747070" />
        <path d="M63.0102 99.4347C62.9809 99.968 63.4074 100.422 63.9593 100.446C64.5091 100.469 64.968 100.058 64.9913 99.5196C65.0145 98.9793 64.5961 98.5329 64.0462 98.5097C63.4863 98.4875 63.0405 98.8854 63.0102 99.4347Z" fill="#747070" />
        <path d="M69.7253 100.447C70.2721 100.464 70.731 100.044 70.7492 99.5097C70.7674 98.9704 70.3449 98.5291 69.791 98.5099C69.2371 98.4907 68.7874 98.8998 68.7631 99.4451C68.7398 99.9753 69.1765 100.43 69.7253 100.447Z" fill="#747070" />
        <path d="M75.5619 100.447C76.1067 100.422 76.5312 99.9635 76.505 99.4293C76.4777 98.8849 76.0279 98.486 75.469 98.5093C74.9222 98.5315 74.5007 98.9839 74.5249 99.5222C74.5482 100.056 75.0151 100.473 75.5619 100.447Z" fill="#747070" />
        <path d="M81.3134 100.431C81.8713 100.413 82.2857 99.9769 82.2614 99.4326C82.2372 98.8892 81.7904 98.4893 81.2315 98.5095C80.6826 98.5287 80.2622 98.9741 80.2814 99.5144C80.2996 100.055 80.7453 100.451 81.3134 100.431Z" fill="#747070" />
        <path d="M86.9781 98.5094C86.4323 98.5336 86.0129 98.985 86.0371 99.5233C86.0614 100.064 86.5122 100.458 87.0762 100.434C87.6311 100.41 88.0445 99.9656 88.0161 99.4263C87.9868 98.881 87.5381 98.4851 86.9781 98.5094Z" fill="#747070" />
        <path d="M91.7929 99.4188C91.7595 99.9541 92.176 100.408 92.7278 100.435C93.3029 100.463 93.7426 100.082 93.7729 99.5309C93.8022 98.9937 93.3868 98.5392 92.84 98.5109C92.276 98.4806 91.8283 98.8694 91.7929 99.4188Z" fill="#747070" />
        <path d="M98.5037 98.5099C97.9498 98.528 97.5283 98.9704 97.5475 99.5097C97.5677 100.058 98.0054 100.452 98.5764 100.434C99.1323 100.417 99.5579 99.9742 99.5346 99.438C99.5114 98.8956 99.0626 98.4917 98.5037 98.5099Z" fill="#747070" />
        <path d="M103.304 99.5097C103.322 100.058 103.76 100.452 104.332 100.434C104.89 100.417 105.313 99.9773 105.289 99.439C105.266 98.8956 104.818 98.4917 104.26 98.5099C103.71 98.528 103.286 98.9704 103.304 99.5097Z" fill="#747070" />
        <path d="M110.039 100.434C110.616 100.441 111.043 100.041 111.048 99.4884C111.053 98.9451 110.625 98.5169 110.068 98.5099C109.513 98.5028 109.068 98.9209 109.057 99.4612C109.046 99.9974 109.48 100.428 110.039 100.434Z" fill="#747070" />
        <path d="M184.927 62.9953C185.478 62.9771 185.908 62.5287 185.886 61.9965C185.865 61.4703 185.404 61.0643 184.844 61.0775C184.291 61.0906 183.877 61.5279 183.899 62.0752C183.921 62.6155 184.365 63.0134 184.927 62.9953Z" fill="#747070" />
        <path d="M179.105 62.997C179.651 63.0122 180.111 62.5911 180.129 62.0609C180.146 61.5286 179.719 61.0994 179.152 61.0802C178.594 61.0611 178.151 61.467 178.137 62.0094C178.125 62.5486 178.549 62.9819 179.105 62.997Z" fill="#747070" />
        <path d="M173.348 62.9976C173.894 63.0128 174.353 62.5926 174.37 62.0614C174.387 61.5252 173.965 61.099 173.397 61.0788C172.837 61.0596 172.399 61.4615 172.386 62.0079C172.372 62.5492 172.793 62.9814 173.348 62.9976Z" fill="#747070" />
        <path d="M167.573 62.9887C168.13 63.0119 168.584 62.612 168.609 62.0767C168.634 61.5395 168.221 61.1072 167.656 61.08C167.094 61.0527 166.65 61.4445 166.627 61.9899C166.604 62.5292 167.016 62.9655 167.573 62.9887Z" fill="#747070" />
        <path d="M115.795 98.5099C115.238 98.5179 114.811 98.9461 114.816 99.4884C114.822 100.031 115.259 100.44 115.825 100.433C116.384 100.426 116.817 99.9954 116.807 99.4592C116.796 98.9199 116.351 98.5028 115.795 98.5099Z" fill="#747070" />
        <path d="M121.556 100.434C122.12 100.438 122.558 100.025 122.562 99.4834C122.566 98.9421 122.135 98.5149 121.576 98.5098C121.022 98.5048 120.575 98.9269 120.568 99.4642C120.56 99.9994 120.999 100.43 121.556 100.434Z" fill="#747070" />
        <path d="M127.261 98.5092C126.712 98.5415 126.304 98.9949 126.337 99.5362C126.37 100.088 126.811 100.465 127.385 100.433C127.937 100.403 128.35 99.9483 128.312 99.412C128.274 98.8697 127.816 98.4769 127.261 98.5092Z" fill="#747070" />
        <path d="M132.089 99.437C132.066 99.9813 132.481 100.416 133.04 100.432C133.609 100.449 134.052 100.053 134.071 99.5117C134.09 98.9734 133.665 98.528 133.117 98.5099C132.559 98.4917 132.113 98.8946 132.089 99.437Z" fill="#747070" />
        <path d="M137.849 99.5155C137.871 100.05 138.331 100.467 138.876 100.447C139.421 100.426 139.854 99.9689 139.829 99.4377C139.803 98.8924 139.355 98.4884 138.798 98.5106C138.245 98.5318 137.826 98.9742 137.849 99.5155Z" fill="#747070" />
        <path d="M144.531 98.5093C143.98 98.5426 143.572 98.995 143.605 99.5363C143.638 100.075 144.102 100.478 144.653 100.446C145.194 100.416 145.619 99.9464 145.581 99.4162C145.543 98.8718 145.085 98.476 144.531 98.5093Z" fill="#747070" />
        <path d="M150.31 100.448C150.857 100.467 151.325 100.045 151.342 99.5147C151.36 98.9723 150.936 98.527 150.387 98.5098C149.828 98.4926 149.378 98.8976 149.357 99.4389C149.335 99.9732 149.767 100.429 150.31 100.448Z" fill="#747070" />
        <path d="M156.073 98.5103C155.519 98.5275 155.098 98.9678 155.116 99.5091C155.134 100.044 155.591 100.463 156.138 100.446C156.686 100.428 157.123 99.9746 157.1 99.4455C157.077 98.9001 156.629 98.4931 156.073 98.5103Z" fill="#747070" />
        <path d="M230.874 135.949C230.324 135.982 229.929 136.431 229.961 136.988C229.994 137.549 230.43 137.935 230.995 137.901C231.533 137.869 231.958 137.396 231.926 136.864C231.893 136.324 231.423 135.915 230.874 135.949Z" fill="#747070" />
        <path d="M235.714 136.928C235.713 137.485 236.136 137.905 236.693 137.903C237.246 137.9 237.678 137.469 237.677 136.921C237.676 136.372 237.245 135.948 236.69 135.951C236.13 135.954 235.714 136.37 235.714 136.928Z" fill="#747070" />
        <path d="M161.902 98.5113C161.346 98.487 160.894 98.893 160.871 99.4374C160.848 99.9777 161.269 100.426 161.819 100.445C162.373 100.464 162.838 100.049 162.857 99.5212C162.877 98.9829 162.454 98.5355 161.902 98.5113Z" fill="#747070" />
        <path d="M168.61 99.52C168.632 98.9807 168.213 98.5383 167.656 98.5121C167.099 98.4848 166.655 98.8857 166.63 99.4351C166.606 99.9815 167.021 100.425 167.572 100.444C168.125 100.464 168.589 100.05 168.61 99.52Z" fill="#747070" />
        <path d="M172.387 99.4415C172.364 99.9869 172.778 100.428 173.332 100.446C173.881 100.464 174.348 100.045 174.368 99.5173C174.389 98.979 173.968 98.5377 173.409 98.5134C172.852 98.4872 172.41 98.8891 172.387 99.4415Z" fill="#747070" />
        <path d="M184.878 98.5098C184.321 98.5138 183.897 98.939 183.899 99.4884C183.901 100.035 184.337 100.455 184.894 100.449C185.435 100.443 185.892 99.9933 185.889 99.4672C185.887 98.938 185.43 98.5058 184.878 98.5098Z" fill="#747070" />
        <path d="M190.703 98.512C190.136 98.4767 189.699 98.8564 189.661 99.4179C189.623 99.9693 190.023 100.42 190.578 100.45C191.124 100.48 191.6 100.068 191.635 99.5381C191.669 98.9988 191.258 98.5464 190.703 98.512Z" fill="#747070" />
        <path d="M127.373 61.0767C126.8 61.0434 126.364 61.4221 126.33 61.9786C126.299 62.5149 126.714 62.9602 127.27 62.9865C127.837 63.0137 128.286 62.6269 128.317 62.0846C128.348 61.5514 127.934 61.1091 127.373 61.0767Z" fill="#747070" />
        <path d="M121.541 61.0735C120.983 61.0846 120.558 61.5158 120.57 62.0551C120.582 62.6035 121.016 62.9983 121.591 62.9872C122.149 62.9761 122.577 62.5439 122.564 62.0056C122.551 61.4714 122.102 61.0624 121.541 61.0735Z" fill="#747070" />
        <path d="M115.835 61.0735C115.271 61.0614 114.83 61.4643 114.814 62.0077C114.798 62.5439 115.226 62.9772 115.786 62.9893C116.355 63.0014 116.794 62.6005 116.807 62.0551C116.82 61.5159 116.394 61.0856 115.835 61.0735Z" fill="#747070" />
        <path d="M109.061 61.9799C109.031 62.5171 109.448 62.9625 110.004 62.9867C110.57 63.012 111.017 62.6212 111.047 62.0788C111.075 61.5436 110.656 61.0972 110.104 61.073C109.526 61.0477 109.091 61.4264 109.061 61.9799Z" fill="#747070" />
        <path d="M104.302 62.988C104.861 62.986 105.299 62.5628 105.297 62.0266C105.295 61.4913 104.853 61.0722 104.293 61.0742C103.733 61.0762 103.296 61.4984 103.298 62.0346C103.3 62.5699 103.742 62.99 104.302 62.988Z" fill="#747070" />
        <path d="M98.5093 62.988C99.0803 63.0032 99.52 62.6063 99.5352 62.0609C99.5503 61.5206 99.1278 61.0884 98.5699 61.0732C98.0069 61.0571 97.5612 61.459 97.543 61.9993C97.5258 62.5396 97.9493 62.9739 98.5093 62.988Z" fill="#747070" />
        <path d="M92.728 61.0735C92.1782 61.1027 91.7597 61.5552 91.789 62.0874C91.8194 62.6267 92.2742 63.0165 92.8392 62.9872C93.3981 62.9589 93.8105 62.5126 93.7761 61.9753C93.7418 61.4269 93.295 61.0432 92.728 61.0735Z" fill="#747070" />
        <path d="M87.0761 61.0778C86.5091 61.0596 86.0583 61.4524 86.032 61.9877C86.0047 62.5239 86.4252 62.9693 86.9781 62.9895C87.5461 63.0107 87.9908 62.6219 88.0181 62.0806C88.0464 61.5403 87.628 61.0949 87.0761 61.0778Z" fill="#747070" />
        <path d="M52.5158 61.0782C51.9468 61.0741 51.5041 61.4791 51.4919 62.0123C51.4798 62.5476 51.9124 62.9798 52.4683 62.9869C53.0465 62.995 53.4689 62.6061 53.4811 62.0537C53.4932 61.5114 53.0687 61.0812 52.5158 61.0782Z" fill="#747070" />
        <path d="M225.217 100.446C225.765 100.427 226.196 99.9733 226.172 99.4381C226.15 98.9018 225.69 98.4888 225.139 98.508C224.581 98.5282 224.182 98.9574 224.201 99.5159C224.222 100.073 224.65 100.466 225.217 100.446Z" fill="#747070" />
        <path d="M229.958 99.4347C229.933 99.9942 230.333 100.428 230.892 100.45C231.437 100.471 231.906 100.051 231.929 99.5206C231.951 98.9762 231.536 98.5339 230.981 98.5107C230.414 98.4885 229.982 98.8783 229.958 99.4347Z" fill="#747070" />
        <path d="M236.715 98.511C236.152 98.4999 235.727 98.9018 235.713 99.4563C235.699 100.017 236.105 100.438 236.67 100.448C237.218 100.458 237.679 100.029 237.688 99.4987C237.699 98.9584 237.269 98.5221 236.715 98.511Z" fill="#747070" />
        <path d="M248.162 100.458C248.727 100.48 249.16 100.083 249.183 99.5193C249.208 98.9578 248.809 98.5306 248.245 98.5114C247.682 98.4922 247.248 98.8871 247.221 99.4415C247.193 99.997 247.599 100.435 248.162 100.458Z" fill="#747070" />
        <path d="M64.003 145.332C63.4441 145.33 63.0044 145.75 63.0034 146.286C63.0024 146.823 63.4411 147.243 64.001 147.244C64.5589 147.245 64.9986 146.824 64.9986 146.288C64.9996 145.753 64.562 145.333 64.003 145.332Z" fill="#747070" />
        <path d="M58.443 145.331C57.884 145.329 57.4444 145.749 57.4434 146.285C57.4424 146.821 57.881 147.241 58.441 147.242C58.9989 147.243 59.4386 146.822 59.4386 146.287C59.4396 145.753 59.0019 145.333 58.443 145.331Z" fill="#747070" />
        <path d="M69.7338 145.332C69.1748 145.342 68.7503 145.77 68.7625 146.31C68.7746 146.85 69.2183 147.256 69.7803 147.243C70.3402 147.229 70.7657 146.801 70.7516 146.263C70.7395 145.725 70.2978 145.321 69.7338 145.332Z" fill="#747070" />
        <path d="M75.5393 145.332C74.9723 145.318 74.5357 145.715 74.5195 146.26C74.5033 146.803 74.9208 147.23 75.4837 147.243C76.0568 147.257 76.4834 146.867 76.4985 146.316C76.5137 145.763 76.1074 145.346 75.5393 145.332Z" fill="#747070" />
        <path d="M78.5916 149.213C78.0245 149.199 77.5879 149.596 77.5717 150.141C77.5556 150.684 77.973 151.11 78.536 151.124C79.1091 151.138 79.5356 150.748 79.5508 150.195C79.5659 149.645 79.1586 149.228 78.5916 149.213Z" fill="#747070" />
        <path d="M87.0529 145.33C86.4859 145.315 86.0513 145.713 86.0361 146.26C86.0199 146.805 86.4364 147.229 86.9993 147.241C87.5724 147.253 87.999 146.864 88.0141 146.312C88.0293 145.764 87.6199 145.346 87.0529 145.33Z" fill="#747070" />
        <path d="M81.295 145.33C80.728 145.315 80.2934 145.713 80.2782 146.26C80.2631 146.805 80.6785 147.229 81.2415 147.241C81.8146 147.253 82.2411 146.864 82.2562 146.312C82.2724 145.764 81.8621 145.346 81.295 145.33Z" fill="#747070" />
        <path d="M133.03 145.33C132.467 145.357 132.066 145.788 132.096 146.337C132.125 146.891 132.556 147.266 133.133 147.241C133.693 147.217 134.099 146.78 134.069 146.234C134.038 145.681 133.605 145.304 133.03 145.33Z" fill="#747070" />
        <path d="M139.83 146.311C139.845 145.766 139.428 145.342 138.864 145.33C138.292 145.318 137.865 145.711 137.851 146.26C137.837 146.811 138.246 147.228 138.813 147.241C139.38 147.255 139.815 146.857 139.83 146.311Z" fill="#747070" />
        <path d="M143.609 146.236C143.58 146.786 143.98 147.217 144.542 147.243C145.114 147.269 145.553 146.887 145.582 146.338C145.612 145.795 145.201 145.355 144.644 145.331C144.067 145.306 143.639 145.68 143.609 146.236Z" fill="#747070" />
        <path d="M150.373 147.242C150.933 147.229 151.357 146.801 151.342 146.261C151.326 145.718 150.887 145.316 150.322 145.33C149.763 145.345 149.338 145.774 149.353 146.311C149.369 146.851 149.811 147.255 150.373 147.242Z" fill="#747070" />
        <path d="M156.057 147.244C156.614 147.266 157.069 146.868 157.096 146.332C157.123 145.795 156.705 145.353 156.151 145.331C155.583 145.309 155.141 145.698 155.113 146.24C155.087 146.775 155.508 147.222 156.057 147.244Z" fill="#747070" />
        <path d="M160.87 146.341C160.901 146.886 161.344 147.268 161.913 147.241C162.468 147.216 162.88 146.771 162.849 146.232C162.817 145.692 162.366 145.301 161.805 145.331C161.249 145.358 160.839 145.801 160.87 146.341Z" fill="#747070" />
        <path d="M167.637 147.243C168.19 147.234 168.618 146.804 168.612 146.262C168.606 145.73 168.157 145.318 167.595 145.33C167.035 145.343 166.612 145.768 166.624 146.308C166.635 146.847 167.074 147.252 167.637 147.243Z" fill="#747070" />
        <path d="M173.444 147.241C173.989 147.207 174.397 146.749 174.361 146.211C174.327 145.68 173.859 145.291 173.299 145.329C172.747 145.367 172.345 145.82 172.386 146.358C172.429 146.908 172.87 147.276 173.444 147.241Z" fill="#747070" />
        <path d="M179.13 145.331C178.565 145.332 178.137 145.744 178.138 146.285C178.139 146.829 178.565 147.242 179.128 147.244C179.678 147.246 180.122 146.82 180.13 146.284C180.137 145.757 179.689 145.33 179.13 145.331Z" fill="#747070" />
        <path d="M184.83 145.33C184.274 145.358 183.872 145.796 183.9 146.343C183.929 146.895 184.361 147.27 184.939 147.243C185.482 147.217 185.906 146.762 185.88 146.23C185.855 145.701 185.389 145.302 184.83 145.33Z" fill="#747070" />
        <path d="M190.612 145.327C190.048 145.343 189.64 145.765 189.655 146.316C189.67 146.871 190.093 147.257 190.67 147.243C191.219 147.23 191.654 146.787 191.643 146.254C191.632 145.729 191.168 145.311 190.612 145.327Z" fill="#747070" />
        <path d="M196.354 147.253C196.895 147.276 197.36 146.861 197.393 146.328C197.425 145.797 197 145.351 196.441 145.33C195.863 145.309 195.437 145.686 195.413 146.243C195.389 146.792 195.797 147.23 196.354 147.253Z" fill="#747070" />
        <path d="M219.441 145.325C218.871 145.317 218.45 145.716 218.441 146.271C218.431 146.828 218.84 147.243 219.407 147.252C219.953 147.261 220.415 146.83 220.426 146.303C220.436 145.774 219.991 145.332 219.441 145.325Z" fill="#747070" />
        <path d="M225.195 145.325C224.623 145.317 224.201 145.715 224.191 146.27C224.181 146.824 224.593 147.241 225.16 147.252C225.707 147.263 226.171 146.833 226.181 146.304C226.192 145.772 225.748 145.332 225.195 145.325Z" fill="#747070" />
        <path d="M230.989 145.327C230.414 145.298 229.98 145.675 229.949 146.232C229.919 146.783 230.318 147.223 230.877 147.253C231.426 147.282 231.897 146.875 231.929 146.342C231.964 145.807 231.543 145.354 230.989 145.327Z" fill="#747070" />
        <path d="M236.743 145.327C236.171 145.299 235.736 145.682 235.708 146.236C235.68 146.782 236.089 147.227 236.641 147.253C237.19 147.28 237.658 146.869 237.687 146.337C237.717 145.801 237.298 145.353 236.743 145.327Z" fill="#747070" />
        <path d="M242.504 145.325C241.935 145.296 241.496 145.679 241.466 146.231C241.436 146.78 241.841 147.225 242.394 147.251C242.945 147.277 243.412 146.871 243.444 146.338C243.477 145.801 243.063 145.355 242.504 145.325Z" fill="#747070" />
        <path d="M248.26 145.327C247.69 145.298 247.253 145.68 247.224 146.234C247.195 146.784 247.598 147.227 248.152 147.253C248.709 147.278 249.169 146.877 249.2 146.34C249.232 145.798 248.82 145.355 248.26 145.327Z" fill="#747070" />
        <path d="M254.011 145.325C253.437 145.3 253.008 145.679 252.98 146.234C252.953 146.786 253.355 147.225 253.908 147.251C254.475 147.278 254.913 146.89 254.942 146.335C254.972 145.78 254.577 145.351 254.011 145.325Z" fill="#747070" />
        <path d="M259.764 145.326C259.19 145.299 258.765 145.678 258.739 146.237C258.712 146.788 259.114 147.227 259.667 147.252C260.236 147.278 260.671 146.892 260.696 146.335C260.723 145.78 260.326 145.351 259.764 145.326Z" fill="#747070" />
        <path d="M265.425 145.32C264.872 145.344 264.471 145.781 264.496 146.333C264.522 146.895 264.944 147.273 265.517 147.247C266.083 147.223 266.475 146.8 266.45 146.24C266.425 145.686 265.991 145.296 265.425 145.32Z" fill="#747070" />
        <path d="M108.182 150.818C108.215 150.278 107.805 149.825 107.259 149.8C106.7 149.774 106.245 150.162 106.205 150.7C106.165 151.236 106.574 151.683 107.135 151.712C107.703 151.741 108.15 151.36 108.182 150.818Z" fill="#747070" />
        <path d="M112.988 151.712C113.55 151.694 113.962 151.266 113.943 150.72C113.923 150.178 113.478 149.779 112.918 149.802C112.357 149.826 111.94 150.257 111.957 150.796C111.974 151.33 112.424 151.73 112.988 151.712Z" fill="#747070" />
        <path d="M117.729 150.639C117.656 151.171 118.043 151.655 118.587 151.713C119.142 151.773 119.62 151.41 119.688 150.876C119.757 150.337 119.378 149.864 118.828 149.805C118.26 149.742 117.803 150.088 117.729 150.639Z" fill="#747070" />
        <path d="M123.473 150.701C123.439 151.234 123.858 151.688 124.406 151.715C124.969 151.742 125.42 151.353 125.451 150.812C125.481 150.271 125.074 149.828 124.52 149.8C123.955 149.772 123.507 150.156 123.473 150.701Z" fill="#747070" />
        <path d="M130.176 151.714C130.737 151.735 131.188 151.338 131.212 150.8C131.237 150.262 130.819 149.822 130.264 149.799C129.704 149.776 129.252 150.172 129.226 150.712C129.201 151.249 129.621 151.693 130.176 151.714Z" fill="#747070" />
        <path d="M136.026 149.799C135.462 149.774 135.013 150.166 134.984 150.708C134.955 151.245 135.375 151.692 135.928 151.715C136.487 151.739 136.945 151.34 136.97 150.806C136.996 150.27 136.577 149.824 136.026 149.799Z" fill="#747070" />
        <path d="M141.716 149.799C141.157 149.809 140.729 150.235 140.738 150.774C140.747 151.314 141.189 151.724 141.752 151.715C142.311 151.706 142.744 151.275 142.732 150.741C142.719 150.201 142.275 149.79 141.716 149.799Z" fill="#747070" />
        <path d="M147.546 151.713C148.099 151.686 148.516 151.235 148.48 150.7C148.444 150.148 148.004 149.769 147.433 149.798C146.883 149.827 146.468 150.276 146.498 150.811C146.528 151.348 146.986 151.742 147.546 151.713Z" fill="#747070" />
        <path d="M153.275 151.714C153.833 151.701 154.261 151.266 154.243 150.729C154.226 150.19 153.78 149.785 153.22 149.799C152.664 149.813 152.241 150.244 152.254 150.784C152.265 151.32 152.713 151.727 153.275 151.714Z" fill="#747070" />
        <path d="M159.001 149.799C158.442 149.801 158.007 150.221 158.006 150.76C158.005 151.295 158.446 151.715 159.007 151.715C159.568 151.714 160.007 151.292 160.004 150.754C160 150.217 159.559 149.797 159.001 149.799Z" fill="#747070" />
        <path d="M163.767 150.71C163.739 151.243 164.161 151.69 164.715 151.713C165.273 151.736 165.727 151.337 165.754 150.8C165.779 150.265 165.359 149.82 164.806 149.797C164.242 149.776 163.796 150.168 163.767 150.71Z" fill="#747070" />
        <path d="M170.465 149.798C169.913 149.826 169.499 150.271 169.527 150.808C169.556 151.345 170.01 151.739 170.571 151.713C171.125 151.688 171.542 151.238 171.512 150.701C171.481 150.153 171.039 149.771 170.465 149.798Z" fill="#747070" />
        <path d="M176.253 151.715C176.81 151.726 177.258 151.312 177.269 150.776C177.28 150.241 176.849 149.809 176.294 149.799C175.732 149.79 175.29 150.198 175.278 150.737C175.266 151.279 175.689 151.704 176.253 151.715Z" fill="#747070" />
        <path d="M182.007 151.714C182.571 151.727 183.012 151.32 183.023 150.779C183.035 150.237 182.612 149.811 182.052 149.8C181.488 149.789 181.049 150.192 181.036 150.732C181.024 151.274 181.447 151.701 182.007 151.714Z" fill="#747070" />
        <path d="M187.819 149.802C187.26 149.779 186.812 150.18 186.794 150.72C186.776 151.264 187.189 151.694 187.75 151.713C188.312 151.732 188.764 151.329 188.781 150.795C188.797 150.257 188.38 149.825 187.819 149.802Z" fill="#747070" />
        <path d="M193.491 149.801C192.94 149.821 192.524 150.267 192.554 150.805C192.584 151.347 193.028 151.735 193.592 151.711C194.151 151.688 194.568 151.246 194.535 150.711C194.501 150.175 194.049 149.78 193.491 149.801Z" fill="#747070" />
        <path d="M199.353 149.802C198.79 149.768 198.341 150.155 198.31 150.702C198.28 151.239 198.696 151.697 199.24 151.726C199.791 151.757 200.261 151.351 200.29 150.82C200.321 150.283 199.91 149.837 199.353 149.802Z" fill="#747070" />
        <path d="M222.284 151.727C222.824 151.748 223.298 151.325 223.322 150.8C223.347 150.268 222.924 149.827 222.363 149.8C221.801 149.773 221.362 150.168 221.339 150.719C221.315 151.261 221.733 151.705 222.284 151.727Z" fill="#747070" />
        <path d="M228.097 151.727C228.639 151.719 229.092 151.266 229.081 150.742C229.07 150.213 228.616 149.794 228.056 149.798C227.503 149.801 227.083 150.229 227.091 150.781C227.099 151.325 227.535 151.735 228.097 151.727Z" fill="#747070" />
        <path d="M245.41 149.801C244.844 149.76 244.408 150.136 244.369 150.699C244.33 151.248 244.729 151.696 245.284 151.728C245.832 151.759 246.307 151.354 246.343 150.824C246.378 150.29 245.969 149.841 245.41 149.801Z" fill="#747070" />
        <path d="M251.164 149.801C250.595 149.762 250.161 150.137 250.123 150.699C250.087 151.251 250.484 151.697 251.041 151.728C251.589 151.759 252.065 151.352 252.099 150.822C252.136 150.289 251.724 149.84 251.164 149.801Z" fill="#747070" />
        <path d="M256.881 149.797C256.32 149.781 255.888 150.186 255.878 150.739C255.867 151.287 256.286 151.718 256.839 151.727C257.385 151.736 257.847 151.308 257.86 150.78C257.873 150.245 257.443 149.814 256.881 149.797Z" fill="#747070" />
        <path d="M262.599 149.795C262.042 149.801 261.627 150.224 261.636 150.778C261.646 151.337 262.066 151.735 262.634 151.725C263.185 151.715 263.625 151.276 263.616 150.742C263.608 150.21 263.158 149.79 262.599 149.795Z" fill="#747070" />
        <path d="M287.526 99.4522C287.511 100.019 287.905 100.441 288.466 100.457C289.029 100.473 289.45 100.072 289.463 99.5087C289.476 98.9321 289.09 98.521 288.525 98.5109C287.953 98.5008 287.541 98.8886 287.526 99.4522Z" fill="#747070" />
        <path d="M45.7372 61.9975C45.7149 62.5337 46.1384 62.971 46.6953 62.9872C47.2684 63.0043 47.698 62.6206 47.7192 62.0702C47.7404 61.5238 47.326 61.0896 46.7732 61.0764C46.2112 61.0643 45.7594 61.4663 45.7372 61.9975Z" fill="#747070" />
        <path d="M41.0162 61.0774C40.4542 61.0663 39.9974 61.4712 39.9782 61.9994C39.9589 62.5306 40.3926 62.98 40.9404 62.9962C41.4973 63.0124 41.945 62.6084 41.9663 62.0701C41.9875 61.5268 41.568 61.0885 41.0162 61.0774Z" fill="#747070" />
        <path d="M35.2789 62.9978C35.8328 62.9655 36.2391 62.5181 36.2068 61.9768C36.1734 61.4214 35.7347 61.0447 35.1657 61.081C34.6037 61.1174 34.1913 61.5628 34.2267 62.093C34.2621 62.6262 34.7311 63.0291 35.2789 62.9978Z" fill="#747070" />
        <path d="M225.164 154.671C224.603 154.676 224.19 155.101 224.2 155.661C224.21 156.217 224.641 156.636 225.193 156.629C225.738 156.622 226.183 156.172 226.174 155.638C226.165 155.107 225.706 154.667 225.164 154.671Z" fill="#747070" />
        <path d="M228.291 158.342C227.73 158.347 227.317 158.772 227.327 159.332C227.337 159.887 227.768 160.307 228.32 160.3C228.865 160.292 229.31 159.843 229.301 159.309C229.292 158.777 228.832 158.337 228.291 158.342Z" fill="#747070" />
        <path d="M222.398 158.811C221.837 158.816 221.423 159.24 221.433 159.801C221.443 160.356 221.874 160.775 222.427 160.768C222.972 160.761 223.416 160.312 223.407 159.778C223.398 159.245 222.939 158.806 222.398 158.811Z" fill="#747070" />
        <path d="M230.92 154.669C230.364 154.675 229.95 155.103 229.958 155.662C229.967 156.217 230.396 156.635 230.95 156.628C231.495 156.622 231.93 156.179 231.922 155.635C231.913 155.091 231.47 154.663 230.92 154.669Z" fill="#747070" />
        <path d="M107.232 159.158C106.682 159.138 106.219 159.554 106.201 160.084C106.183 160.624 106.598 161.059 107.159 161.085C107.715 161.11 108.164 160.707 108.184 160.163C108.205 159.625 107.782 159.178 107.232 159.158Z" fill="#747070" />
        <path d="M112.961 159.163C112.406 159.157 111.955 159.583 111.954 160.115C111.953 160.659 112.383 161.082 112.941 161.088C113.5 161.093 113.941 160.676 113.946 160.138C113.95 159.603 113.513 159.169 112.961 159.163Z" fill="#747070" />
        <path d="M216.608 30.0252C217.157 30.0081 217.584 29.5577 217.562 29.0234C217.54 28.4932 217.084 28.0892 216.524 28.1034C215.973 28.1175 215.558 28.5568 215.582 29.1022C215.604 29.6435 216.05 30.0434 216.608 30.0252Z" fill="#747070" />
        <path d="M222.264 29.9188C222.813 29.9016 223.24 29.4512 223.218 28.917C223.196 28.3868 222.74 27.9828 222.18 27.9969C221.629 28.0111 221.214 28.4504 221.238 28.9957C221.261 29.536 221.706 29.936 222.264 29.9188Z" fill="#747070" />
        <path d="M187.752 74.9158C187.19 74.936 186.776 75.3642 186.792 75.9075C186.81 76.4488 187.256 76.8497 187.817 76.8255C188.379 76.8013 188.8 76.369 188.782 75.8338C188.763 75.2884 188.323 74.8956 187.752 74.9158Z" fill="#747070" />
        <path d="M271.185 117.203C270.655 117.226 270.232 117.698 270.253 118.241C270.273 118.798 270.707 119.199 271.266 119.178C271.823 119.158 272.215 118.728 272.194 118.16C272.173 117.614 271.715 117.18 271.185 117.203Z" fill="#747070" />
        <path d="M95.6617 74.9171C95.1048 74.9252 94.6732 75.3564 94.6863 75.8917C94.6995 76.4249 95.1442 76.8319 95.7092 76.8278C96.2611 76.8238 96.6866 76.3936 96.6744 75.8503C96.6623 75.3009 96.2368 74.909 95.6617 74.9171Z" fill="#747070" />
        <path d="M294.244 53.6743C294.759 53.6764 295.216 53.2078 295.22 52.6745C295.224 52.114 294.811 51.6939 294.256 51.6919C293.706 51.6909 293.29 52.109 293.284 52.6695C293.279 53.2158 293.716 53.6723 294.244 53.6743Z" fill="#747070" />
        <path d="M288.521 53.6702C289.036 53.651 289.48 53.1683 289.467 52.6421C289.454 52.0907 289.019 51.6777 288.466 51.6938C287.918 51.709 287.514 52.1442 287.529 52.7037C287.544 53.2561 287.989 53.6904 288.521 53.6702Z" fill="#747070" />
        <path d="M101.429 74.917C100.874 74.919 100.435 75.3462 100.441 75.8804C100.447 76.4177 100.884 76.8257 101.453 76.8277C102.008 76.8298 102.438 76.4056 102.432 75.8633C102.426 75.321 101.997 74.915 101.429 74.917Z" fill="#747070" />
        <path d="M271.212 53.6794C271.718 53.6814 272.188 53.2037 272.193 52.6796C272.199 52.1161 271.789 51.6939 271.233 51.6919C270.681 51.6909 270.256 52.113 270.251 52.6654C270.248 53.2098 270.69 53.6774 271.212 53.6794Z" fill="#747070" />
        <path d="M106.203 75.9247C106.239 76.4599 106.69 76.8498 107.254 76.8285C107.8 76.8084 108.215 76.3569 108.183 75.8166C108.151 75.2723 107.708 74.8905 107.138 74.9168C106.58 74.941 106.168 75.3864 106.203 75.9247Z" fill="#747070" />
        <path d="M259.707 135.945C259.154 135.951 258.735 136.378 258.736 136.933C258.737 137.486 259.166 137.91 259.721 137.905C260.281 137.901 260.697 137.476 260.691 136.915C260.686 136.357 260.263 135.939 259.707 135.945Z" fill="#747070" />
        <path d="M81.2523 25.5423C81.8112 25.5595 82.2549 25.1525 82.266 24.6112C82.2782 24.0749 81.8506 23.6447 81.2957 23.6326C80.7317 23.6205 80.3011 24.0154 80.2759 24.5698C80.2516 25.096 80.6822 25.5241 81.2523 25.5423Z" fill="#747070" />
        <path d="M88.0192 24.6426C88.0475 24.1064 87.6331 23.66 87.0832 23.6317C86.5303 23.6045 86.0826 23.9933 86.0391 24.5396C85.9967 25.0688 86.4101 25.5101 86.9791 25.5425C87.5481 25.5738 87.9909 25.192 88.0192 24.6426Z" fill="#747070" />
        <path d="M93.7772 24.5421C93.7478 23.9977 93.3062 23.613 92.7381 23.6352C92.1964 23.6564 91.7729 24.1048 91.7901 24.64C91.8072 25.1632 92.2691 25.5621 92.8362 25.5429C93.387 25.5247 93.8065 25.0783 93.7772 24.5421Z" fill="#747070" />
        <path d="M98.5673 25.5426C99.1232 25.5376 99.5507 25.1084 99.5386 24.5681C99.5265 24.0318 99.0848 23.6258 98.5218 23.6339C97.974 23.642 97.5434 24.0732 97.5444 24.6135C97.5465 25.1407 97.9932 25.5477 98.5673 25.5426Z" fill="#747070" />
        <path d="M104.275 25.5415C104.835 25.5617 105.28 25.1567 105.293 24.6164C105.307 24.0792 104.885 23.651 104.325 23.6348C103.767 23.6176 103.33 24.0155 103.302 24.5649C103.277 25.0931 103.703 25.5213 104.275 25.5415Z" fill="#747070" />
        <path d="M116.791 24.7206C116.855 24.1964 116.459 23.6955 115.936 23.6389C115.368 23.5773 114.904 23.9217 114.826 24.463C114.751 24.9963 115.134 25.482 115.675 25.5396C116.244 25.5982 116.728 25.2437 116.791 24.7206Z" fill="#747070" />
        <path d="M121.567 25.543C122.137 25.535 122.578 25.1108 122.563 24.5877C122.548 24.0464 122.108 23.6293 121.557 23.6343C121.001 23.6394 120.562 24.0635 120.566 24.5958C120.569 25.133 121.011 25.5511 121.567 25.543Z" fill="#747070" />
        <path d="M127.341 25.5429C127.912 25.5257 128.341 25.0975 128.316 24.5693C128.29 24.0068 127.867 23.62 127.296 23.6342C126.739 23.6473 126.314 24.0775 126.325 24.6128C126.337 25.1521 126.782 25.559 127.341 25.5429Z" fill="#747070" />
        <path d="M133.098 25.5428C133.669 25.5247 134.097 25.0975 134.073 24.5703C134.047 24.0118 133.621 23.621 133.053 23.6341C132.497 23.6462 132.071 24.0775 132.084 24.6127C132.097 25.154 132.54 25.56 133.098 25.5428Z" fill="#747070" />
        <path d="M138.89 25.5418C139.451 25.5075 139.864 25.0641 139.831 24.5329C139.798 23.9997 139.338 23.6058 138.781 23.6321C138.231 23.6584 137.814 24.1108 137.844 24.644C137.876 25.1964 138.32 25.5762 138.89 25.5418Z" fill="#747070" />
        <path d="M118.741 74.9158C118.17 74.8956 117.73 75.2884 117.711 75.8348C117.692 76.37 118.114 76.8023 118.676 76.8255C119.236 76.8487 119.686 76.4468 119.703 75.9065C119.72 75.3672 119.301 74.936 118.741 74.9158Z" fill="#747070" />
        <path d="M311.467 23.6357C310.929 23.662 310.575 24.0145 310.554 24.5457C310.53 25.1294 310.924 25.5434 311.508 25.5495C312.092 25.5556 312.507 25.1435 312.496 24.5699C312.484 23.9912 312.062 23.6075 311.467 23.6357Z" fill="#747070" />
        <path d="M313.785 27.8994C313.246 27.9257 312.892 28.2781 312.871 28.8093C312.848 29.3931 313.241 29.8071 313.825 29.8132C314.409 29.8192 314.825 29.4072 314.814 28.8336C314.802 28.2549 314.379 27.8711 313.785 27.8994Z" fill="#747070" />
        <path d="M316.971 23.5298C316.432 23.556 316.078 23.9085 316.057 24.4397C316.034 25.0234 316.427 25.4375 317.011 25.4436C317.595 25.4496 318.011 25.0376 318 24.4639C317.988 23.8853 317.565 23.5015 316.971 23.5298Z" fill="#747070" />
        <path d="M239.525 112.357C238.964 112.391 238.571 112.831 238.611 113.381C238.65 113.933 239.092 114.312 239.659 114.277C240.193 114.245 240.62 113.772 240.588 113.247C240.556 112.723 240.081 112.324 239.525 112.357Z" fill="#747070" />
        <path d="M242.441 135.951C241.88 135.956 241.47 136.372 241.472 136.932C241.474 137.49 241.897 137.911 242.451 137.906C243.003 137.901 243.432 137.468 243.431 136.917C243.428 136.368 242.997 135.946 242.441 135.951Z" fill="#747070" />
        <path d="M204.986 76.8284C205.549 76.8536 206.007 76.4658 206.045 75.9326C206.082 75.3993 205.667 74.9449 205.118 74.9176C204.546 74.8883 204.104 75.264 204.069 75.8094C204.032 76.3527 204.439 76.8041 204.986 76.8284Z" fill="#747070" />
        <path d="M89.9748 28.1034C89.4138 28.0883 88.957 28.4892 88.9317 29.0184C88.9065 29.5506 89.334 30.0041 89.8808 30.0243C90.4387 30.0445 90.8865 29.6466 90.9118 29.1063C90.937 28.5609 90.5246 28.1196 89.9748 28.1034Z" fill="#747070" />
        <path d="M95.6471 28.1079C95.0791 28.1361 94.6657 28.5654 94.6889 29.1026C94.7122 29.6379 95.167 30.0398 95.7229 30.0176C96.2829 29.9954 96.6952 29.5631 96.673 29.0208C96.6508 28.4755 96.206 28.0796 95.6471 28.1079Z" fill="#747070" />
        <path d="M101.319 30.0133C101.885 30.0709 102.352 29.7174 102.419 29.1801C102.487 28.6388 102.104 28.1591 101.563 28.1066C100.995 28.0521 100.527 28.4055 100.457 28.9428C100.388 29.4771 100.775 29.9578 101.319 30.0133Z" fill="#747070" />
        <path d="M107.194 28.1066C106.626 28.1167 106.196 28.5308 106.196 29.0681C106.196 29.6033 106.638 30.0214 107.198 30.0174C107.76 30.0133 108.189 29.5962 108.189 29.0559C108.189 28.5146 107.752 28.0965 107.194 28.1066Z" fill="#747070" />
        <path d="M112.995 30.0177C113.552 29.9935 113.966 29.5572 113.944 29.0159C113.921 28.4726 113.474 28.0777 112.911 28.107C112.351 28.1353 111.934 28.5736 111.956 29.1088C111.98 29.6461 112.431 30.042 112.995 30.0177Z" fill="#747070" />
        <path d="M118.694 28.1059C118.13 28.122 117.701 28.5462 117.71 29.0814C117.719 29.6187 118.165 30.0287 118.727 30.0166C119.288 30.0045 119.714 29.5803 119.706 29.041C119.698 28.5038 119.251 28.0907 118.694 28.1059Z" fill="#747070" />
        <path d="M181.996 30.0167C182.569 30.0369 183.006 29.646 183.026 29.0956C183.046 28.5624 182.622 28.1292 182.06 28.1059C181.5 28.0827 181.05 28.4857 181.034 29.026C181.018 29.5673 181.435 29.9965 181.996 30.0167Z" fill="#747070" />
        <path d="M130.272 114.272C130.824 114.246 131.24 113.802 131.212 113.264C131.184 112.711 130.742 112.33 130.171 112.363C129.608 112.396 129.194 112.836 129.225 113.369C129.257 113.904 129.712 114.297 130.272 114.272Z" fill="#747070" />
        <path d="M199.308 30.0177C199.867 30.0157 200.303 29.5915 200.297 29.0552C200.29 28.519 199.854 28.109 199.287 28.1069C198.733 28.1049 198.301 28.5301 198.307 29.0704C198.312 29.6107 198.744 30.0197 199.308 30.0177Z" fill="#747070" />
        <path d="M204.994 30.0179C205.549 30.0502 206.011 29.6573 206.045 29.1221C206.079 28.5868 205.673 28.1455 205.11 28.1082C204.542 28.0698 204.101 28.4465 204.067 29.0009C204.033 29.5422 204.436 29.9856 204.994 30.0179Z" fill="#747070" />
        <path d="M208.379 24.0876C207.811 24.0493 207.37 24.426 207.336 24.9804C207.301 25.5217 207.704 25.964 208.262 25.9974C208.817 26.0297 209.279 25.6368 209.314 25.1016C209.348 24.5663 208.941 24.125 208.379 24.0876Z" fill="#747070" />
        <path d="M210.761 30.0276C211.306 30.0559 211.773 29.6459 211.803 29.1137C211.833 28.5764 211.424 28.1421 210.857 28.1088C210.295 28.0765 209.85 28.4653 209.823 29.0137C209.795 29.5499 210.211 29.9994 210.761 30.0276Z" fill="#747070" />
        <path d="M233.795 39.3966C234.347 39.4168 234.803 39.0068 234.83 38.4665C234.855 37.9363 234.424 37.4818 233.873 37.4576C233.311 37.4334 232.87 37.8333 232.852 38.3837C232.834 38.9361 233.245 39.3764 233.795 39.3966Z" fill="#747070" />
        <path d="M173.382 107.877C172.828 107.875 172.39 108.298 172.386 108.836C172.381 109.383 172.809 109.804 173.365 109.8C173.93 109.796 174.374 109.379 174.378 108.848C174.381 108.319 173.929 107.878 173.382 107.877Z" fill="#747070" />
        <path d="M193.548 74.916C192.984 74.916 192.553 75.326 192.547 75.8684C192.541 76.4087 192.976 76.8298 193.535 76.8278C194.1 76.8247 194.539 76.4107 194.544 75.8754C194.549 75.3432 194.106 74.916 193.548 74.916Z" fill="#747070" />
        <path d="M248.182 137.909C248.733 137.919 249.171 137.497 249.184 136.943C249.196 136.387 248.783 135.96 248.224 135.952C247.666 135.944 247.235 136.356 247.222 136.906C247.211 137.456 247.635 137.899 248.182 137.909Z" fill="#747070" />
        <path d="M253.9 135.947C253.352 135.981 252.95 136.435 252.981 136.986C253.013 137.539 253.459 137.935 254.016 137.902C254.561 137.869 254.972 137.409 254.942 136.865C254.911 136.314 254.456 135.913 253.9 135.947Z" fill="#747070" />
        <path d="M288.494 34.9276C289.055 34.9306 289.465 34.5186 289.465 33.952C289.465 33.4047 289.027 32.9502 288.499 32.9502C287.961 32.9512 287.529 33.3925 287.527 33.9439C287.526 34.5065 287.937 34.9246 288.494 34.9276Z" fill="#747070" />
        <path d="M291.638 29.0381C291.1 29.0391 290.669 29.4804 290.667 30.0318C290.665 30.5943 291.076 31.0114 291.633 31.0155C292.194 31.0185 292.604 30.6065 292.604 30.0399C292.604 29.4915 292.166 29.0371 291.638 29.0381Z" fill="#747070" />
        <path d="M265.47 137.908C266.029 137.909 266.446 137.488 266.446 136.924C266.446 136.363 266.03 135.945 265.472 135.947C264.918 135.949 264.496 136.371 264.493 136.926C264.491 137.477 264.918 137.907 265.47 137.908Z" fill="#747070" />
        <path d="M49.6779 37.4539C49.1341 37.4327 48.659 37.8558 48.6348 38.383C48.6105 38.9152 49.0391 39.3707 49.5869 39.3939C50.1519 39.4182 50.5905 39.0243 50.6138 38.4709C50.637 37.9174 50.2307 37.4751 49.6779 37.4539Z" fill="#747070" />
        <path d="M56.3712 38.4708C56.3944 37.9184 55.9861 37.474 55.4352 37.4538C54.8904 37.4336 54.4174 37.8548 54.3932 38.3829C54.3679 38.9152 54.7964 39.3696 55.3453 39.3939C55.9082 39.4191 56.3479 39.0232 56.3712 38.4708Z" fill="#747070" />
        <path d="M62.1249 38.4687C62.1512 37.9143 61.7449 37.4729 61.19 37.4538C60.6462 37.4346 60.1742 37.8567 60.1499 38.3849C60.1257 38.9262 60.5441 39.3706 61.101 39.3938C61.667 39.418 62.0986 39.0282 62.1249 38.4687Z" fill="#747070" />
        <path d="M66.9391 39.3964C67.4909 39.3783 67.9023 38.941 67.8861 38.3886C67.87 37.8371 67.4313 37.4352 66.8683 37.4574C66.3205 37.4786 65.8839 37.9351 65.9061 38.4623C65.9293 39.0026 66.3852 39.4146 66.9391 39.3964Z" fill="#747070" />
        <path d="M210.844 76.8256C211.412 76.7993 211.826 76.3721 211.805 75.8338C211.784 75.2986 211.33 74.8946 210.774 74.9158C210.213 74.937 209.801 75.3663 209.821 75.9086C209.84 76.4529 210.285 76.8508 210.844 76.8256Z" fill="#747070" />
        <path d="M282.694 81.7434C283.23 81.7697 283.69 81.3455 283.711 80.8052C283.733 80.2548 283.312 79.7811 282.788 79.767C282.243 79.7519 281.793 80.171 281.77 80.7123C281.748 81.2718 282.147 81.7171 282.694 81.7434Z" fill="#747070" />
        <path d="M199.37 74.9171C198.8 74.8838 198.353 75.2574 198.312 75.8018C198.272 76.3421 198.68 76.8026 199.223 76.8299C199.787 76.8581 200.245 76.4754 200.286 75.9401C200.33 75.4039 199.921 74.9494 199.37 74.9171Z" fill="#747070" />
        <path d="M274.186 123.653C274.739 123.622 275.142 123.17 275.109 122.622C275.076 122.075 274.619 121.669 274.069 121.698C273.515 121.726 273.117 122.174 273.149 122.734C273.181 123.28 273.636 123.684 274.186 123.653Z" fill="#747070" />
        <path d="M239.646 67.4741C240.195 67.4428 240.619 66.9812 240.586 66.451C240.552 65.9138 240.087 65.5078 239.537 65.5361C238.985 65.5643 238.583 66.0087 238.611 66.5631C238.638 67.1145 239.083 67.5064 239.646 67.4741Z" fill="#747070" />
        <path d="M233.888 67.475C234.438 67.4447 234.861 66.9832 234.828 66.453C234.793 65.9127 234.332 65.5087 233.781 65.536C233.23 65.5632 232.827 66.0086 232.854 66.563C232.879 67.1124 233.326 67.5053 233.888 67.475Z" fill="#747070" />
        <path d="M170.588 114.27C171.141 114.234 171.549 113.78 171.509 113.246C171.468 112.711 171.009 112.332 170.44 112.362C169.891 112.391 169.482 112.848 169.526 113.388C169.569 113.941 170.01 114.306 170.588 114.27Z" fill="#747070" />
        <path d="M159.996 113.247C159.957 112.711 159.499 112.333 158.929 112.362C158.38 112.39 157.972 112.848 158.012 113.388C158.053 113.929 158.507 114.307 159.074 114.272C159.627 114.236 160.036 113.783 159.996 113.247Z" fill="#747070" />
        <path d="M202.142 100.449C202.685 100.454 203.149 100.015 203.155 99.4889C203.161 98.9496 202.725 98.5174 202.167 98.5113C201.609 98.5052 201.18 98.9163 201.169 99.4687C201.158 100.016 201.582 100.443 202.142 100.449Z" fill="#747070" />
        <path d="M206.931 99.5163C206.952 100.074 207.384 100.47 207.948 100.447C208.495 100.426 208.933 99.9667 208.909 99.4405C208.886 98.9083 208.421 98.4902 207.873 98.5084C207.321 98.5276 206.911 98.9649 206.931 99.5163Z" fill="#747070" />
        <path d="M213.631 98.5083C213.075 98.5255 212.669 98.9597 212.688 99.5142C212.707 100.074 213.137 100.469 213.703 100.447C214.247 100.427 214.687 99.9666 214.666 99.4415C214.644 98.9103 214.18 98.4912 213.631 98.5083Z" fill="#747070" />
        <path d="M95.7269 149.799C95.1629 149.784 94.7121 150.184 94.6889 150.72C94.6656 151.255 95.0952 151.706 95.643 151.724C96.1959 151.742 96.6507 151.334 96.672 150.799C96.6952 150.256 96.2778 149.814 95.7269 149.799Z" fill="#747070" />
        <path d="M101.48 149.8C100.918 149.785 100.47 150.185 100.444 150.723C100.419 151.256 100.847 151.706 101.398 151.725C101.946 151.743 102.403 151.332 102.426 150.798C102.451 150.256 102.033 149.814 101.48 149.8Z" fill="#747070" />
        <path d="M205.023 151.726C205.566 151.745 206.029 151.323 206.047 150.791C206.067 150.253 205.647 149.823 205.082 149.802C204.523 149.781 204.078 150.185 204.062 150.728C204.048 151.262 204.478 151.708 205.023 151.726Z" fill="#747070" />
        <path d="M210.819 149.802C210.263 149.789 209.823 150.204 209.816 150.748C209.808 151.278 210.249 151.717 210.797 151.726C211.338 151.734 211.803 151.297 211.81 150.773C211.818 150.237 211.386 149.814 210.819 149.802Z" fill="#747070" />
        <path d="M216.627 149.801C216.059 149.764 215.619 150.142 215.583 150.699C215.548 151.237 215.959 151.695 216.507 151.727C217.053 151.76 217.527 151.352 217.563 150.82C217.597 150.286 217.186 149.839 216.627 149.801Z" fill="#747070" />
        <path d="M81.2481 62.9884C81.8212 62.9965 82.2518 62.6006 82.267 62.0523C82.2811 61.513 81.8526 61.0828 81.2946 61.0777C80.7276 61.0716 80.2829 61.4776 80.2708 62.0129C80.2586 62.5491 80.6912 62.9814 81.2481 62.9884Z" fill="#747070" />
        <path d="M75.5462 61.0773C74.9741 61.0672 74.5354 61.4631 74.5162 62.0064C74.497 62.5427 74.9266 62.978 75.4845 62.9891C76.0536 63.0002 76.4892 62.6053 76.5084 62.06C76.5276 61.5227 76.1011 61.0864 75.5462 61.0773Z" fill="#747070" />
        <path d="M233.774 149.795C233.229 149.818 232.823 150.269 232.851 150.82C232.881 151.367 233.327 151.756 233.892 151.725C234.443 151.696 234.862 151.24 234.826 150.705C234.79 150.164 234.335 149.77 233.774 149.795Z" fill="#747070" />
        <path d="M167.552 109.801C168.116 109.825 168.573 109.436 168.607 108.901C168.642 108.37 168.22 107.907 167.678 107.879C167.123 107.851 166.665 108.246 166.63 108.782C166.596 109.325 167.006 109.778 167.552 109.801Z" fill="#747070" />
        <path d="M69.6975 62.988C70.2686 63.0152 70.7093 62.6386 70.7467 62.0912C70.7831 61.5499 70.3737 61.1015 69.8219 61.0772C69.2619 61.053 68.803 61.4438 68.7656 61.9761C68.7272 62.5103 69.1426 62.9607 69.6975 62.988Z" fill="#747070" />
        <path d="M63.9473 61.0794C63.3884 61.1137 62.979 61.5561 63.0083 62.0913C63.0386 62.6266 63.4955 63.0184 64.0564 62.9891C64.6103 62.9599 65.0207 62.5145 64.9894 61.9772C64.957 61.4278 64.5113 61.0441 63.9473 61.0794Z" fill="#747070" />
        <path d="M58.2743 61.0777C57.7093 61.0716 57.2625 61.4776 57.2484 62.0098C57.2342 62.5461 57.6638 62.9783 58.2207 62.9874C58.7989 62.9965 59.2224 62.6087 59.2375 62.0573C59.2517 61.517 58.8272 61.0838 58.2743 61.0777Z" fill="#747070" />
        <path d="M112.962 74.9171C112.401 74.909 111.958 75.3231 111.953 75.8593C111.949 76.3936 112.38 76.8167 112.943 76.8278C113.501 76.839 113.944 76.4239 113.947 75.8856C113.951 75.3443 113.525 74.9252 112.962 74.9171Z" fill="#747070" />
        <path d="M164.733 112.363C164.176 112.369 163.746 112.801 163.763 113.34C163.779 113.88 164.218 114.281 164.785 114.271C165.344 114.261 165.772 113.832 165.759 113.293C165.747 112.756 165.306 112.357 164.733 112.363Z" fill="#747070" />
        <path d="M161.901 109.797C162.467 109.767 162.881 109.335 162.854 108.798C162.829 108.263 162.373 107.862 161.816 107.887C161.257 107.913 160.846 108.348 160.87 108.888C160.896 109.435 161.34 109.827 161.901 109.797Z" fill="#747070" />
        <path d="M156.093 107.887C155.532 107.896 155.105 108.319 155.112 108.857C155.119 109.396 155.564 109.812 156.117 109.798C156.685 109.783 157.108 109.367 157.103 108.828C157.098 108.291 156.654 107.879 156.093 107.887Z" fill="#747070" />
        <path d="M219.389 98.5083C218.832 98.5265 218.429 98.9567 218.446 99.5122C218.464 100.07 218.892 100.465 219.458 100.445C220.004 100.427 220.439 99.9727 220.418 99.4415C220.398 98.9062 219.939 98.4912 219.389 98.5083Z" fill="#747070" />
        <path d="M195.416 99.4426C195.395 99.993 195.806 100.432 196.361 100.449C196.907 100.467 197.375 100.044 197.395 99.5133C197.416 98.973 196.993 98.5317 196.436 98.5115C195.871 98.4913 195.437 98.8872 195.416 99.4426Z" fill="#747070" />
        <path d="M156.143 182.774C155.583 182.745 155.138 183.141 155.115 183.687C155.093 184.23 155.504 184.661 156.064 184.684C156.626 184.706 157.079 184.306 157.099 183.773C157.119 183.233 156.708 182.803 156.143 182.774Z" fill="#747070" />
        <path d="M16.0718 103.949C16.0668 103.389 15.6514 102.979 15.0904 102.979C14.5315 102.98 14.1171 103.399 14.1211 103.958C14.1252 104.512 14.5557 104.94 15.1046 104.938C15.6554 104.937 16.0769 104.504 16.0718 103.949Z" fill="#747070" />
        <path d="M9.34823 103.007C8.78929 103.008 8.37489 103.427 8.37894 103.987C8.38298 104.54 8.81355 104.969 9.36238 104.967C9.91222 104.965 10.3347 104.532 10.3286 103.977C10.3246 103.417 9.90919 103.006 9.34823 103.007Z" fill="#747070" />
        <path d="M20.8405 102.979C20.2826 102.983 19.8621 103.409 19.8702 103.964C19.8773 104.51 20.32 104.943 20.8658 104.938C21.4177 104.932 21.8371 104.501 21.829 103.944C21.821 103.384 21.4025 102.975 20.8405 102.979Z" fill="#747070" />
        <path d="M145.586 61.981C145.552 61.4448 145.098 61.0539 144.535 61.0772C143.982 61.0994 143.568 61.5458 143.601 62.0861C143.634 62.6264 144.082 63.0131 144.647 62.9889C145.2 62.9647 145.619 62.5132 145.586 61.981Z" fill="#747070" />
        <path d="M12.1976 98.5103C11.6437 98.5072 11.194 98.9375 11.1909 99.4727C11.1879 99.9948 11.6498 100.444 12.1916 100.448C12.7535 100.452 13.177 100.037 13.178 99.4818C13.1801 98.9314 12.7586 98.5133 12.1976 98.5103Z" fill="#747070" />
        <path d="M6.82458 98.5098C6.27069 98.5068 5.82092 98.937 5.81789 99.4722C5.81485 99.9943 6.27676 100.444 6.81851 100.448C7.38048 100.452 7.80398 100.037 7.80499 99.4813C7.80701 98.9299 7.38554 98.5118 6.82458 98.5098Z" fill="#747070" />
        <path d="M17.9299 100.448C18.4918 100.461 18.9275 100.053 18.9366 99.5023C18.9456 98.9498 18.5302 98.5206 17.9753 98.5095C17.4184 98.4974 16.9656 98.9155 16.9525 99.4538C16.9383 99.981 17.3851 100.435 17.9299 100.448Z" fill="#747070" />
        <path d="M138.957 61.0812C138.395 61.0126 137.921 61.367 137.852 61.9073C137.785 62.4406 138.169 62.9203 138.713 62.9839C139.278 63.0496 139.748 62.6991 139.82 62.1578C139.892 61.6276 139.507 61.1479 138.957 61.0812Z" fill="#747070" />
        <path d="M150.376 107.887C149.806 107.876 149.375 108.27 149.357 108.819C149.338 109.359 149.763 109.793 150.318 109.8C150.885 109.807 151.327 109.405 151.346 108.869C151.364 108.333 150.935 107.899 150.376 107.887Z" fill="#747070" />
        <path d="M144.6 107.887C144.026 107.887 143.605 108.288 143.599 108.84C143.593 109.381 144.025 109.803 144.584 109.801C145.146 109.799 145.588 109.382 145.595 108.849C145.6 108.315 145.157 107.887 144.6 107.887Z" fill="#747070" />
        <path d="M75.4873 109.8C76.0473 109.821 76.493 109.418 76.5082 108.876C76.5233 108.333 76.1069 107.905 75.5449 107.887C74.9779 107.869 74.5342 108.268 74.518 108.812C74.5019 109.348 74.9243 109.778 75.4873 109.8Z" fill="#747070" />
        <path d="M69.766 107.887C69.204 107.88 68.7623 108.296 68.7603 108.833C68.7582 109.372 69.1848 109.787 69.7538 109.799C70.3107 109.81 70.7534 109.393 70.7545 108.854C70.7545 108.312 70.3269 107.894 69.766 107.887Z" fill="#747070" />
        <path d="M64.0478 107.887C63.4879 107.862 63.0331 108.26 63.0098 108.795C62.9856 109.335 63.3959 109.767 63.9599 109.798C64.5229 109.829 64.9676 109.438 64.9919 108.891C65.0161 108.352 64.6017 107.913 64.0478 107.887Z" fill="#747070" />
        <path d="M133.047 61.0773C132.492 61.0874 132.067 61.5227 132.086 62.063C132.104 62.6073 132.542 63.0022 133.111 62.9891C133.669 62.976 134.095 62.5417 134.077 62.0034C134.059 61.4621 133.62 61.0672 133.047 61.0773Z" fill="#747070" />
        <path d="M52.4858 107.877C51.937 107.876 51.4892 108.312 51.4902 108.845C51.4923 109.378 51.9309 109.794 52.4979 109.799C53.0538 109.803 53.4854 109.382 53.4824 108.838C53.4794 108.304 53.0387 107.878 52.4858 107.877Z" fill="#747070" />
        <path d="M46.7417 109.799C47.2996 109.803 47.7271 109.384 47.7231 108.837C47.719 108.299 47.2824 107.877 46.7275 107.877C46.1787 107.877 45.7299 108.314 45.7319 108.846C45.735 109.38 46.1736 109.795 46.7417 109.799Z" fill="#747070" />
        <path d="M291.456 102.98C290.904 102.949 290.447 103.35 290.414 103.894C290.382 104.441 290.794 104.911 291.334 104.942C291.873 104.974 292.336 104.558 292.369 104.012C292.403 103.447 292.018 103.012 291.456 102.98Z" fill="#747070" />
        <path d="M284.667 104C284.69 104.559 285.127 104.96 285.684 104.934C286.22 104.91 286.649 104.444 286.627 103.908C286.605 103.364 286.149 102.953 285.595 102.978C285.043 103.002 284.644 103.442 284.667 104Z" fill="#747070" />
        <path d="M242.468 100.446C243.022 100.436 243.449 99.9977 243.437 99.4534C243.426 98.909 242.982 98.496 242.424 98.5101C241.866 98.5243 241.457 98.9464 241.466 99.5029C241.475 100.054 241.902 100.457 242.468 100.446Z" fill="#747070" />
        <path d="M66.8875 67.4762C67.4484 67.4873 67.88 67.0753 67.8861 66.5239C67.8922 65.9715 67.4737 65.5453 66.9178 65.5372C66.3609 65.5291 65.9142 65.9493 65.903 66.4906C65.8919 67.0167 66.3407 67.4661 66.8875 67.4762Z" fill="#747070" />
        <path d="M236.749 154.675C236.182 154.642 235.748 155.025 235.717 155.589C235.687 156.142 236.087 156.599 236.631 156.632C237.173 156.665 237.647 156.248 237.679 155.711C237.711 155.172 237.294 154.708 236.749 154.675Z" fill="#747070" />
        <path d="M242.454 154.672C241.895 154.667 241.471 155.082 241.47 155.64C241.468 156.194 241.889 156.623 242.439 156.63C242.985 156.636 243.426 156.205 243.43 155.66C243.434 155.107 243.01 154.677 242.454 154.672Z" fill="#747070" />
        <path d="M248.212 154.672C247.654 154.667 247.227 155.084 247.224 155.639C247.221 156.19 247.644 156.622 248.193 156.63C248.74 156.638 249.182 156.206 249.187 155.661C249.192 155.106 248.77 154.677 248.212 154.672Z" fill="#747070" />
        <path d="M253.966 154.668C253.413 154.664 252.982 155.089 252.979 155.64C252.977 156.192 253.4 156.622 253.951 156.629C254.502 156.636 254.93 156.214 254.936 155.658C254.942 155.093 254.53 154.672 253.966 154.668Z" fill="#747070" />
        <path d="M259.651 154.662C259.108 154.695 258.697 155.165 258.732 155.711C258.767 156.257 259.227 156.658 259.777 156.624C260.338 156.59 260.721 156.154 260.688 155.587C260.655 155.036 260.199 154.629 259.651 154.662Z" fill="#747070" />
        <path d="M265.527 156.624C266.081 156.59 266.473 156.148 266.443 155.589C266.413 155.038 265.961 154.63 265.412 154.661C264.869 154.692 264.459 155.157 264.491 155.705C264.523 156.253 264.979 156.657 265.527 156.624Z" fill="#747070" />
        <path d="M147.516 112.363C146.945 112.359 146.504 112.762 146.492 113.297C146.48 113.836 146.908 114.261 147.47 114.272C148.035 114.283 148.477 113.879 148.49 113.34C148.503 112.797 148.073 112.367 147.516 112.363Z" fill="#747070" />
        <path d="M141.736 112.363C141.166 112.37 140.731 112.785 140.733 113.319C140.735 113.856 141.175 114.273 141.735 114.27C142.296 114.267 142.732 113.85 142.734 113.314C142.736 112.777 142.293 112.356 141.736 112.363Z" fill="#747070" />
        <path d="M136.003 112.363C135.431 112.358 134.991 112.759 134.979 113.296C134.967 113.834 135.395 114.261 135.956 114.271C136.519 114.281 136.962 113.877 136.975 113.338C136.989 112.798 136.56 112.367 136.003 112.363Z" fill="#747070" />
        <path d="M58.2437 109.798C58.7986 109.808 59.2362 109.391 59.2392 108.849C59.2423 108.308 58.8157 107.893 58.2507 107.887C57.6918 107.881 57.2511 108.298 57.2471 108.836C57.2451 109.371 57.6756 109.788 58.2437 109.798Z" fill="#747070" />
        <path d="M23.6709 100.448C24.2349 100.47 24.6745 100.069 24.6907 99.5188C24.7079 98.9664 24.2985 98.5291 23.7467 98.5099C23.1928 98.4907 22.7349 98.9028 22.7117 99.44C22.6905 99.9702 23.1241 100.427 23.6709 100.448Z" fill="#747070" />
        <path d="M236.679 72.3697C237.24 72.3788 237.671 71.9637 237.677 71.4083C237.683 70.8569 237.256 70.4236 236.701 70.4175C236.135 70.4115 235.719 70.8185 235.716 71.382C235.713 71.9516 236.112 72.3606 236.679 72.3697Z" fill="#747070" />
        <path d="M161.832 62.9877C162.399 63.0049 162.839 62.607 162.856 62.0627C162.873 61.5234 162.456 61.1012 161.885 61.079C161.326 61.0568 160.885 61.4597 160.87 62.0041C160.853 62.5474 161.268 62.9716 161.832 62.9877Z" fill="#747070" />
        <path d="M155.117 61.9819C155.089 62.5191 155.501 62.9615 156.055 62.9877C156.612 63.015 157.068 62.6201 157.097 62.0839C157.126 61.5476 156.718 61.1123 156.153 61.08C155.591 61.0477 155.146 61.4355 155.117 61.9819Z" fill="#747070" />
        <path d="M92.7861 145.335C92.209 145.343 91.7885 145.747 91.7905 146.29C91.7915 146.835 92.2201 147.244 92.7841 147.243C93.3521 147.241 93.7736 146.834 93.7736 146.288C93.7736 145.738 93.3481 145.327 92.7861 145.335Z" fill="#747070" />
        <path d="M121.566 147.243C122.13 147.245 122.559 146.835 122.561 146.29C122.563 145.747 122.141 145.343 121.564 145.335C121 145.328 120.573 145.739 120.576 146.286C120.578 146.835 120.999 147.241 121.566 147.243Z" fill="#747070" />
        <path d="M210.861 159.156C210.31 159.131 209.855 159.536 209.827 160.075C209.799 160.62 210.211 161.068 210.761 161.088C211.312 161.109 211.78 160.701 211.809 160.172C211.837 159.647 211.403 159.182 210.861 159.156Z" fill="#747070" />
        <path d="M245.323 159.155C244.767 159.167 244.347 159.601 244.364 160.145C244.381 160.699 244.813 161.102 245.373 161.086C245.932 161.07 246.362 160.635 246.348 160.098C246.334 159.571 245.869 159.143 245.323 159.155Z" fill="#747070" />
        <path d="M127.321 145.335C126.757 145.328 126.33 145.738 126.332 146.286C126.334 146.833 126.756 147.24 127.323 147.242C127.887 147.244 128.316 146.833 128.318 146.29C128.319 145.746 127.899 145.343 127.321 145.335Z" fill="#747070" />
        <path d="M150.321 62.9892C150.889 63.0053 151.331 62.6064 151.346 62.0641C151.36 61.5278 150.94 61.0996 150.375 61.0784C149.817 61.0572 149.372 61.4612 149.356 62.0045C149.342 62.5458 149.76 62.973 150.321 62.9892Z" fill="#747070" />
        <path d="M182.051 74.9171C181.479 74.909 181.049 75.3059 181.034 75.8533C181.02 76.3926 181.449 76.8228 182.005 76.8278C182.568 76.8329 183.016 76.4229 183.029 75.8917C183.041 75.3564 182.609 74.9242 182.051 74.9171Z" fill="#747070" />
        <path d="M26.6151 121.7C26.0622 121.701 25.6276 122.128 25.6245 122.675C25.6215 123.219 26.0581 123.655 26.606 123.656C27.1619 123.656 27.5823 123.238 27.5864 122.68C27.5894 122.12 27.172 121.7 26.6151 121.7Z" fill="#747070" />
        <path d="M98.5369 147.238C99.111 147.23 99.5325 146.827 99.5325 146.286C99.5325 145.739 99.106 145.328 98.544 145.334C97.9679 145.34 97.5454 145.743 97.5464 146.285C97.5464 146.832 97.977 147.246 98.5369 147.238Z" fill="#747070" />
        <path d="M114.823 146.232C114.792 146.778 115.189 147.203 115.759 147.237C116.336 147.272 116.766 146.901 116.797 146.341C116.827 145.796 116.429 145.369 115.86 145.336C115.289 145.301 114.854 145.678 114.823 146.232Z" fill="#747070" />
        <path d="M276.997 182.77C276.421 182.774 276.012 183.173 276.009 183.731C276.006 184.29 276.437 184.716 276.988 184.699C277.445 184.685 277.893 184.165 277.908 183.63C277.922 183.128 277.539 182.766 276.997 182.77Z" fill="#747070" />
        <path d="M214.514 43.017C214.464 42.4565 214.045 42.3232 213.587 42.3283C213.05 42.3353 212.642 42.8524 212.688 43.3967C212.73 43.8876 213.177 44.3147 213.632 44.2986C214.041 44.2845 214.561 43.5482 214.514 43.017Z" fill="#747070" />
        <path d="M109.063 146.313C109.077 146.862 109.505 147.255 110.075 147.243C110.641 147.232 111.053 146.817 111.043 146.27C111.033 145.728 110.597 145.325 110.031 145.337C109.462 145.348 109.049 145.765 109.063 146.313Z" fill="#747070" />
        <path d="M153.219 114.271C153.786 114.285 154.227 113.886 154.245 113.346C154.262 112.807 153.843 112.385 153.271 112.364C152.709 112.343 152.264 112.747 152.248 113.29C152.234 113.829 152.657 114.257 153.219 114.271Z" fill="#747070" />
        <path d="M253.874 63.0001C254.447 63.0435 254.902 62.6527 254.936 62.0882C254.967 61.5701 254.622 61.1459 254.117 61.0843C253.503 61.0096 252.975 61.458 252.977 62.0548C252.979 62.5618 253.357 62.9607 253.874 63.0001Z" fill="#747070" />
        <path d="M103.304 146.29C103.305 146.837 103.734 147.246 104.297 147.238C104.874 147.229 105.292 146.827 105.29 146.284C105.288 145.736 104.86 145.327 104.299 145.336C103.722 145.344 103.302 145.747 103.304 146.29Z" fill="#747070" />
        <path d="M279.947 121.704C279.376 121.669 278.945 122.047 278.911 122.613C278.881 123.117 279.229 123.573 279.695 123.637C280.127 123.697 280.811 123.09 280.845 122.615C280.878 122.158 280.464 121.737 279.947 121.704Z" fill="#747070" />
        <path d="M144.684 192.128C144.134 192.08 143.637 192.485 143.611 193.025C143.58 193.659 144.067 193.659 144.506 193.724C144.946 193.651 145.526 193.776 145.58 193.076C145.615 192.616 145.165 192.169 144.684 192.128Z" fill="#747070" />
    </svg>

}