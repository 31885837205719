import WhiteDollarIcon from '@/components/icons/WhiteDollarIcon';
import WhiteChartIcon from '@/components/icons/WhiteChartIcon';
import WhiteHashtagIcon from '@/components/icons/WhiteHashtagIcon';
import WhitePercentageIcon from '@/components/icons/WhitePercentageIcon';
import { formatCurrency, formatDateString } from '@/lib/utils';
import { BannerDetailsViewModel } from './view-models/BannerDetailsViewModel';
// import { formatCurrency } from "@/lib/utils";

interface DealsBannerProps {
  details?: BannerDetailsViewModel;
  showDate?: boolean;
}

export default function DealsBanner({ details }: DealsBannerProps) {
  if (!details) return null;

  return (
    <div>
      <div className="flex w-full place-content-between rounded-xl bg-white bg-gradient-to-t from-sky-300 via-sky-200 to-sky-100 px-5 py-1.5 2xl:px-16 ">
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteChartIcon />
          </div>
          <div className="flex flex-col">
            <div className="text-2xl font-bold">{details.totalOpportunities ? details.totalOpportunities : '--'}</div>
            <div className="text-sm font-light uppercase">total opportunities</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteDollarIcon />
          </div>
          <div className="flex flex-col">
            <div className="font-bold xl:text-xl 2xl:text-2xl">{details.totalProperties}</div>
            <div className="font-light uppercase lg:text-xs 2xl:text-sm">Total Properties</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteChartIcon />
          </div>
          <div className="flex flex-col">
            <div className="text-2xl font-bold">{details.totalAIV ? formatCurrency(details.totalAIV) : '--'}</div>
            <div className="font- text-sm uppercase">Total AIV</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteHashtagIcon />
          </div>
          <div className="flex flex-col">
            <div className="text-2xl font-bold">{details.totalUPB ? formatCurrency(details.totalUPB) : '--'}</div>
            <div className="text-sm font-light uppercase">total upb</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteChartIcon />
          </div>
          <div className="flex flex-col">
            <div className="text-2xl font-bold">{details.averageAIV ? formatCurrency(details.averageAIV) : '--'}</div>
            <div className="text-sm font-light uppercase">average aiv</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhitePercentageIcon />
          </div>
          <div className="flex flex-col">
            <div className="font-bold xl:text-xl 2xl:text-2xl">--</div>
            <div className="font-light uppercase lg:text-xs 2xl:text-sm">profit percentage</div>
            {/* {details.profitPercentage && details.averagePropertyAsIsValue+"%"} */}
          </div>
        </div>
      </div>
      {details.createdAt && <div className="relative mb-3 h-8 border-b border-blue-200"></div>}
      <div className="absolute left-[1100px] top-48 flex items-center justify-center border">{/* <div className="w-[100px] h-[13px] border-blue-200 bg-blue-200"></div> */}</div>
      <div className="text-center ">
        {details.createdAt && <p>{formatDateString(details.createdAt)}</p>}
        {/* <p className="pagination-wrapper !text-[18px]">
                            <span className=" text-[#08a0e2]">6</span>
                            <span>/25</span>
                        </p> */}
      </div>
    </div>
  );
}
