import { AWS_CONFIG } from "@/config/config";
import ComposableCard from "../../composable/ComposableCard";
import FileTree from "@/components/filetree/FileTree";

const DemoS3FileTree = () => {
  return (
    <>
      <ComposableCard title="Account" className="w-full">
        <FileTree bucketName={AWS_CONFIG.rootBucketName} />
      </ComposableCard>
    </>
  );
};

export default DemoS3FileTree;