import React from "react"

const DisableScrollY = ({ children }: { children: React.ReactNode }) => {
    React.useEffect(() => {
        const style = document.createElement('style');
        style.id = 'disable-overscroll';
        style.innerHTML = `
            body {
                overscroll-behavior-y: contain;
                overflow-y: hidden;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        }
    })

    return children;
}

export default DisableScrollY;