import { Dialog, DialogContent } from '../../ui/dialog';
import { useEffect, useState } from 'react';
import PricingRuleForm from './PricingRuleForm';
import { useFilterDetails } from '../pages/deals/hooks';
import { RuleGroupType } from 'react-querybuilder';
import { toast } from 'react-toastify';

export interface PricingModalComponentProps {
  isOpen: boolean;
  toggleModal: () => void;
  showProgressBar?: () => void;
  hideProgressBar?: () => void;
}

const PricingModalComponent = ({ isOpen, toggleModal, showProgressBar, hideProgressBar }: PricingModalComponentProps) => {
  const scopeDefaultRules: RuleGroupType = {
    combinator: 'and',
    rules: [
      { field: 'assetClassGroup.keyword', operator: '=', valueSource: 'value', value: 'Residential' },
      { field: 'is_residential', operator: '=', valueSource: 'value', value: 'true' },
      { field: 'state.keyword', operator: '=', valueSource: 'value', value: 'FL' },
      { field: 'zip5.keyword', operator: 'contains', valueSource: 'value', value: '12345' },
    ],
  };
  const conditionDefaultRules: RuleGroupType = { combinator: 'and', rules: [{ field: 'firstLienPositionMortgageLTV', operator: '=', valueSource: 'value', value: '0' }] };
  const [totalOpportunities] = useState(-1);

  // Filter fields
  const { data: filterDetails, error: filterDetailsError, isLoading: isLoadingFields } = useFilterDetails(isOpen);

  useEffect(() => {
    if (isLoadingFields) {
      showProgressBar && showProgressBar();
    } else {
      hideProgressBar && hideProgressBar();
    }
  }, [isLoadingFields]);

  useEffect(() => {
    if (filterDetailsError) {
      console.error('Error fetching filter details:', filterDetailsError);
      toast.error('Failed to fetch filter details. Please try again later.');
    }
  }, [filterDetailsError]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="max-w-screen flex h-full w-2/3 flex-col overflow-x-hidden border-0 p-0 outline-none" showCloseButton={false}>
        <div className="max-h-[90%] flex-grow overflow-auto">
          {filterDetails && <PricingRuleForm filterFields={filterDetails} scopeRules={scopeDefaultRules} conditionRules={conditionDefaultRules} />}
        </div>
        <div className="items-left flex h-full max-h-[10%] flex-col justify-end px-3 pb-5 ">
          <div className="h-auto w-full flex-grow overflow-auto pb-10"></div>
          <div className="h-auto"></div>
          <div className="flex-shrink-0">
            {/* <button className="apply-new-query-button">Preview</button> */}
            <button title={totalOpportunities < 0 ? 'Please preview the query first' : ''} className={`apply-new-query-button ml-5`}>
              Save
            </button>
            {/* <button className="ml-5 apply-new-query-button" onClick={() => setShowReview(!showReview)}>Toggle</button> */}
            <button className="apply-new-query-button ml-5">Clear</button>
            <button className="apply-new-query-button ml-5" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PricingModalComponent;
