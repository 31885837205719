import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
// @ts-ignore
import { featureCollection, point, clustersDbscan } from '@turf/turf';
import { Feature, Point } from "geojson";
import { Tooltip } from "react-tooltip";
import USAMap from "./USAMap";
import { DealLocationProps } from "../pages/deals/view-models/CarouselCardViewModel";

export interface LocationDotsMapProps {
    locations: DealLocationProps[];
};

const LocationDotsMap: React.FC<LocationDotsMapProps> = ({ locations }) => {
    const geoJson = featureCollection(locations.map(({ coordinates, status, ...rest }) => {
        return point(coordinates, { status, ...rest });
    }));

    const clustered = clustersDbscan(geoJson, 60, { units: 'miles' });

    const clustersInfo = new Map();
    const outliers: any[] = [];

    clustered.features.forEach((feature: Feature<Point>) => {
        const clusterId = feature.properties?.cluster;
        const dbscan = feature.properties?.dbscan;
        const status = feature.properties?.status;

        if (dbscan === 'core') {
            if (!clustersInfo.has(clusterId)) {
                clustersInfo.set(clusterId, { point: feature.geometry.coordinates, size: 2, status });
            } else {
                clustersInfo.get(clusterId).size += 1;
            }
        } else {
            // Handle individual, unclustered points
            outliers.push(feature);
        }
    });

    const baseRadius = 4;
    const outlierRadius = 5;

    return (
        <div className="flex justify-between flex-col lg:flex-row gap-4 lg:gap-0">
            <div className="relative">
                <ComposableMap
                    className="absolute w-full h-full scale-x-[1.29] scale-y-[1.22]"
                    projection="geoAlbersUsa"
                >
                    <Geographies geography={geoJson}>
                        {({ geographies }) =>
                            geographies.map(geo => (
                                <Geography key={geo.rsmKey} geography={geo} />
                            ))
                        }
                    </Geographies>
                    {[...clustersInfo.entries()].map(([clusterId, info], index) => (
                        <Marker key={`cluster-${clusterId}-${index}`} coordinates={info.point} className="group">
                            <g data-tooltip-id="map-tooltip" data-tooltip-content={info.address}>
                                <circle r={baseRadius + info.size + 20} fill="transparent" />
                                {info.status === "hot" && (
                                    <circle r={baseRadius + info.size} className="fill-[#08a0e2] drop-shadow-[0_0_10px_hsl(var(--hot-deals))] group-hover:brightness-125 group-hover:scale-125 transition-all" />
                                )}
                                {info.status === "active" && (
                                    <circle r={baseRadius + info.size} className="fill-[#08a0e2] drop-shadow-[0_0_10px_hsl(var(--active-deals))] group-hover:brightness-125 group-hover:scale-125 transition-all" />
                                )}
                                {info.status === "completed" && (
                                    <circle r={baseRadius + info.size} className="fill-[#08a0e2] drop-shadow-[0_0_10px_hsl(var(--completed-deals))] group-hover:brightness-125 group-hover:scale-125 transition-all" />
                                )}
                            </g>
                        </Marker>
                    ))}
                    {outliers.map((outlier, index) => (
                        <Marker key={`outlier-${index}`} coordinates={outlier.geometry.coordinates} className="group">
                            <g data-tooltip-id="map-tooltip" data-tooltip-content={outlier.properties.address}>
                                <circle r={20} fill="transparent" />
                                <circle r={outlierRadius} className="group-hover:brightness-125 group-hover:scale-150 transition-all fill-[#08a0e2]" />
                            </g>
                        </Marker>
                    ))}
                </ComposableMap>
                <USAMap className="w-full h-full" />
                <Tooltip id="map-tooltip" />
            </div>
        </div>
    );
};

export default LocationDotsMap;