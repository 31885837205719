import ComposableCard from "@/components/composable/ComposableCard";
import clsx from "clsx";
import USAMap from "./USAMap";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
// @ts-ignore
import { featureCollection, point, clustersDbscan } from '@turf/turf';
import { Feature, Point } from "geojson";
import { DEALS } from "@/constants/__mock__/Deals";
import { Tooltip } from "react-tooltip";

const DealsCard = ({ className }: { className?: string }) => {
    const geoJson = featureCollection(DEALS.map(({ coordinates, status, ...rest }) => {
        return point(coordinates, { status, ...rest });
    }));

    const clustered = clustersDbscan(geoJson, 60, { units: 'miles' });

    const clustersInfo = new Map();
    const outliers: any[] = [];

    clustered.features.forEach((feature: Feature<Point>) => {
        const clusterId = feature.properties?.cluster;
        const dbscan = feature.properties?.dbscan;
        const status = feature.properties?.status;

        if (dbscan === 'core') {
            if (!clustersInfo.has(clusterId)) {
                clustersInfo.set(clusterId, { point: feature.geometry.coordinates, size: 2, status });
            } else {
                clustersInfo.get(clusterId).size += 1;
            }
        } else {
            // Handle individual, unclustered points
            outliers.push(feature);
        }
    });

    const baseRadius = 4;
    const outlierRadius = 5;

    console.log(clustersInfo, outliers);

    return (
        <ComposableCard
            title="Deal Network"
            Content={{ className: "grow" }}
            className={clsx("flex flex-col  w-full 2xl:max-w-[30vw]", className)}
        >
            <div className="flex justify-between flex-col lg:flex-row gap-4 lg:gap-0">
                <div className="relative mx-auto w-full md:w-[375px]">
                    <ComposableMap
                        className="absolute w-full h-full scale-x-[1.29] scale-y-[1.22]"
                        projection="geoAlbersUsa"
                    >
                        <Geographies geography={geoJson}>
                            {({ geographies }) =>
                                geographies.map(geo => (
                                    <Geography key={geo.rsmKey} geography={geo} />
                                ))
                            }
                        </Geographies>
                        {[...clustersInfo.entries()].map(([clusterId, info], index) => (
                            <Marker key={`cluster-${clusterId}-${index}`} coordinates={info.point} className="group">
                                <g data-tooltip-id="map-tooltip" data-tooltip-content={info.address}>
                                    <circle r={baseRadius + info.size + 20} fill="transparent" />
                                    {info.status === "hot" && (
                                        <circle r={baseRadius + info.size} className="fill-hot-deals drop-shadow-[0_0_10px_hsl(var(--hot-deals))] group-hover:brightness-125 group-hover:scale-125 transition-all" />
                                    )}
                                    {info.status === "active" && (
                                        <circle r={baseRadius + info.size} className="fill-active-deals drop-shadow-[0_0_10px_hsl(var(--active-deals))] group-hover:brightness-125 group-hover:scale-125 transition-all" />
                                    )}
                                    {info.status === "completed" && (
                                        <circle r={baseRadius + info.size} className="fill-completed-deals drop-shadow-[0_0_10px_hsl(var(--completed-deals))] group-hover:brightness-125 group-hover:scale-125 transition-all" />
                                    )}
                                </g>
                            </Marker>
                        ))}
                        {outliers.map((outlier, index) => (
                            <Marker key={`outlier-${index}`} coordinates={outlier.geometry.coordinates} className="group">
                                <g data-tooltip-id="map-tooltip" data-tooltip-content={outlier.properties.address}>
                                    <circle r={20} fill="transparent" />
                                    <circle r={outlierRadius} className="group-hover:brightness-125 group-hover:scale-150 transition-all fill-hot-deals" />
                                </g>
                            </Marker>
                        ))}
                    </ComposableMap>
                    <USAMap className="w-full h-full" />
                    <Tooltip id="map-tooltip" />
                </div>
                {/* <ComposableCard className="!border-accent border" Content={{ className: "!p-5 font-headings flex flex-col justify-around h-full" }}>
                    <div>
                        <span className="inline-block bg-hot-deals rounded-full w-2 h-2 mr-2.5" />
                        <span className="mr-4 text-muted-foreground whitespace-nowrap">Hot Opportunity</span>
                        <span className="float-right lg:float-none">1080</span>
                    </div>
                    <div>
                        <span className="inline-block bg-active-deals rounded-full w-2 h-2 mr-2.5" />
                        <span className="mr-4 text-muted-foreground whitespace-nowrap">Active Deals</span>
                        <span className="float-right lg:float-none">1080</span>
                    </div>
                    <div>
                        <span className="inline-block bg-completed-deals rounded-full w-2 h-2 mr-2.5" />
                        <span className="mr-4 text-muted-foreground whitespace-nowrap">Completed</span>
                        <span className="float-right lg:float-none">1080</span>
                    </div>
                </ComposableCard> */}
            </div>
        </ComposableCard>
    );
};

export default DealsCard;