const BlueCircleCheckIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_315_7635)">
          <path d="M13.9997 25.6667C20.443 25.6667 25.6663 20.4434 25.6663 14C25.6663 7.55672 20.443 2.33337 13.9997 2.33337C7.55635 2.33337 2.33301 7.55672 2.33301 14C2.33301 20.4434 7.55635 25.6667 13.9997 25.6667Z" fill="#ff0033" stroke="#ff0033" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.5 14L12.8333 16.3333L17.5 11.6666" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
          <clipPath id="clip0_315_7635">
              <rect width="28" height="28" fill="white" />
          </clipPath>
      </defs>
  </svg>

);

export default BlueCircleCheckIcon;
// 08A1E2