import React, { useState } from 'react';
// import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { Button } from '../ui/button';
import { BellIcon, MenuIcon } from 'lucide-react';
import { Drawer, DrawerContent } from '@/components/ui/drawer';
import SideNav from './SideNav';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import FundButton from './FundButton';
import WelcomeHeading from './WelcomeHeading';
import useDisplayName from '@/hooks/useDisplayName';
import { ProfileDropdownMenu } from "@/components/dashboard/ProfileDropdownMenu.tsx";
import useStore from '@/store';
import SearchIcon from '../icons/SearchIcon';

export interface TopBarProps {
  className?: string;
  title?: string | null;
  addWelcome?: boolean;
  showFundButton?: boolean;
}

const TopBar = ({ className, addWelcome, showFundButton }: TopBarProps) => {
  const [open, setOpen] = useState(false);
  const displayName = useDisplayName();
  const renderMiddleHeaderContent = useStore((state) => state.renderMiddleHeaderContent);

  const isLg = useBreakpoint('lg');

  React.useEffect(() => {
    if (isLg) setOpen(false);
  }, [isLg]);

  return (
    <div className={className}>
      <div className="top-bar">
        <Drawer direction="left" open={open} onClose={() => setOpen(false)}>
          <DrawerContent>
            <SideNav
              className="bg-card"
              onNavigate={() => {
                setOpen(false);
              }}
            />
          </DrawerContent>
        </Drawer>
        <Button variant="ghost" size="icon" className="-ml-2.5 shrink-0 lg:hidden" onClick={() => setOpen(true)} aria-label="Enter Mobile Navigation">
          <MenuIcon />
        </Button>
        {addWelcome && (
          <>
            <WelcomeHeading className="hidden xl:block" />
            {/* <ToggleGroup type="single" defaultValue="personal" radioGroup="true" className="hidden lg:block">
              <ToggleGroupItem value="personal">Personal</ToggleGroupItem>
              <ToggleGroupItem value="overall">Overall</ToggleGroupItem>
            </ToggleGroup> */}
          </>
        )}
        {renderMiddleHeaderContent && renderMiddleHeaderContent()}
        <div className="notif-tray">
          {showFundButton && <FundButton />}
          <button className="hidden md:block"><SearchIcon /></button>
          <div className="notif-icon">
            <BellIcon />
          </div>
          <ProfileDropdownMenu displayName={displayName} role={'Team Leader'}></ProfileDropdownMenu>
        </div>
      </div>
      <div className="w-full border-b-[1px] pt-2 border-solid border-[#dddddd] hidden md:block"></div>
    </div>
  );
};

export default TopBar;
