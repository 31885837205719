export interface EnvelopeIconProps {
  className?: string;
}

const EnvelopeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2749_10486)">
  <path d="M21.3335 4H2.66683C2.31321 4 1.97407 4.14048 1.72402 4.39052C1.47397 4.64057 1.3335 4.97971 1.3335 5.33333V18.6667C1.3335 19.0203 1.47397 19.3594 1.72402 19.6095C1.97407 19.8595 2.31321 20 2.66683 20H21.3335C21.6871 20 22.0263 19.8595 22.2763 19.6095C22.5264 19.3594 22.6668 19.0203 22.6668 18.6667V5.33333C22.6668 4.97971 22.5264 4.64057 22.2763 4.39052C22.0263 4.14048 21.6871 4 21.3335 4ZM20.3068 18.6667H3.7735L8.44016 13.84L7.48016 12.9133L2.66683 17.8933V6.34667L10.9535 14.5933C11.2033 14.8417 11.5412 14.9811 11.8935 14.9811C12.2457 14.9811 12.5837 14.8417 12.8335 14.5933L21.3335 6.14V17.8067L16.4268 12.9L15.4868 13.84L20.3068 18.6667ZM3.54016 5.33333H20.2535L11.8935 13.6467L3.54016 5.33333Z" fill="#858F9D"/>
  </g>
  <defs>
  <clipPath id="clip0_2749_10486">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
)

export default EnvelopeIcon;