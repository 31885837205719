import {
    DEALS_API_URL_NEW,
    PROPERTY_DETAILS_API_URL_NEW,
    UPDATE_DEAL_STATUS_API_URL,
    DEALS_BANNER_API_URL,
    DEALS_FILTER_API_URL,
    SAVE_DEALS_FILTER_API_URL,
    FILTER_PREVIEW_API_URL,
    FETCH_SAVED_QUERIES_API_URL,
    UPDATE_OPPORTUNITY_PRICING_API_URL,
    DEALS_BLUE_BUTTOM_OFFER_MADE_API_URL,
} from '../../../../../config/config';
import { aggregateValues, countOccurrencesInArray, extractMinMaxValues, mapIdsToValues } from "../../../../../lib/utils";
import { DEAL_STATUS } from '../enum';
import { PropertyProps } from '../Property.interface';
import { PropertyDetailsProps } from '../PropertyDetails.interface';
import {
    GetFilterPreviewInput,
    GetFilterPreviewResponse,
    SavedQueryResponse,
    UpdateFilterInput,
    UpdateFilterResponse,
    UpdateOpportunityStatusInput,
    UpdateOpportunityStatusResponse,
    UpdateOpportunityPricingInput,
    UpdateOpportunityPricingResponse,
    UpdatePropertyDealAttributesInput,
    UpdatePropertyDealAttributesResponse
} from '../types';
import { BannerDetailsViewModel } from '../view-models/BannerDetailsViewModel';
import { DealLocationProps, OpportunityData, RangeValuesProps } from '../view-models/CarouselCardViewModel';
import { OpportunityListViewModel, OpportunityPageToken } from '../view-models/OpportunityListViewModel';
import { OpportunityViewModel } from '../view-models/OpportunityViewModel';
import { defaultOperators, Field } from 'react-querybuilder';

export const fetchDeals = async (token?: OpportunityPageToken, status: string = DEAL_STATUS.Open): Promise<OpportunityListViewModel> => {
    try {
        const LIMIT_DEALS = 10;
        let dealApi = `${DEALS_API_URL_NEW}?page_size=${LIMIT_DEALS}&status=${status}`;
        if (token?.opportunityID) {
            dealApi += `&page_token.opportunityID=${token.opportunityID}`;
        }

        if (token?.status) {
            dealApi += `&page_token.status=${token.status}`;
        }

        if (token?.totalProperties) {
            dealApi += `&page_token.totalProperties=${token.totalProperties}`;
        }

        const response: any = await fetch(dealApi);
        if (!response.ok) throw new Error('Failed to fetch deals.');
        const rawData = await response.json();
        if (rawData.statusCode !== 200) {
            throw new Error('Failed to fetch deals. Error code: ' + rawData.statusCode);
        }
        const dealList = JSON.parse(rawData.body);
        console.log('dealList', dealList);
        // console.log("dealApi "+dealApi);
        // console.log("opportunityID "+ token?.opportunityID)

        const opportunities = dealList.filter((d: any) => !d.next_page_token).map((deal: any) => {
            const propertyOfferPriceMap = mapIdsToValues(deal.propertyids, deal.totalOfferPrice);
            const lender = (deal.lenderdata || []).length > 0 ? deal.lenderdata[0] : {};
            const offerPriceRange = extractMinMaxValues(deal.properties, "offerPrice"); // TODO remove
            const assetValueRange = extractMinMaxValues(deal.properties, "assessed_value");
            // const estimatedValue = parseFloat(deal.estimated_value);
            const borrowerList = (deal.properties || []).map((property: any) => ({
                name: property.company,
                count: Number(((parseFloat(property.estimated_mortgage_balance || 0) / parseFloat(deal.totalUPB)) * 100).toFixed(0)),
            }));

            // console.log('opportunityLeveragesMAOS ', JSON.stringify(deal.opportunityData.opportunityLeveragesMAOS, null, 2));
            // console.log('opportunityLeveragesMAOB ', JSON.stringify(deal.opportunityData.opportunityLeveragesMAOB, null, 2));
            // console.log('opportunityMAOS ', JSON.stringify(deal.opportunityData.opportunityMAOS, null, 2));
            // console.log('opportunityMAOB ', JSON.stringify(deal.opportunityData.opportunityMAOB, null, 2));
            // console.log('opportunityCOCR ', JSON.stringify(deal.opportunityData.opportunityCOCR, null, 2));

            const opportunityViewModel: OpportunityViewModel = {
                assignmentReleases: deal.assignment_releases,
                opportunityId: deal.opportunityID,
                status: deal.status,
                createdDate: deal.dateCreated,
                updatedDate: deal.lastUpdated,
                carouselCard: {
                    assetClass: deal.properties?.map((property: any) => property.assetClassGroup),
                    totalmarketvalue: deal.totalmarketvalue,
                    totalUPB: deal.totalUPB,
                    estimatedAssetValue: parseFloat(deal.totalmarketvalue),
                    estimatedPrincipalBalance: parseFloat(lender.totalAmountInDefault),
                    estimatedPurchasePrice: parseFloat(deal.totalOfferPrice || 0),
                    locations: retrieveLocations(deal.properties),
                    lenderName: deal.lenderName,
                    offerRange: new RangeValuesProps(offerPriceRange.min, offerPriceRange.max),
                    assetValue: new RangeValuesProps(assetValueRange.min, assetValueRange.max),
                    lenderDefaultRatePercentage: Number(parseFloat(lender.LDR24 || 0).toFixed(2)),
                    lenderDefaultRateVolume: new RangeValuesProps(parseInt(lender.defaultvolume24 || 0), parseInt(lender.loanvolume24 || 0)),
                    lenderDefaultRateAmount: new RangeValuesProps(Number(parseInt(lender.defaultamount24 || 0).toFixed(2)), parseFloat(lender.loanamount24 || 0)),
                    taxLien: deal.is_tax_delinquent,
                    firstLienPositionOriginationAmount: deal.firstLienPositionOriginationAmount,
                    totalTaxLiens: (deal.properties || []).filter((property: any) => property.is_tax_delinquent === '1').length,
                    totalHoaLiens: 0, // TODO: retrieve from API 
                    totalVolume: new RangeValuesProps(parseInt(lender.defaultvolume24 || 0), parseInt(lender.loanvolume24 || 0)),
                    totalAmount: new RangeValuesProps(Number(parseFloat(lender.defaultamount24 || 0).toFixed(2)), Number(parseFloat(lender.loanamount24 || 0).toFixed(2))),
                    countOfAssetClassMix: countOccurrencesInArray((deal.properties || []).map((property: any) => property.assetClassGroup)),
                    countOfBorrower: aggregateValues(borrowerList),
                    estimated_mortgage_balance: (deal.properties || []).reduce((acc: number, property: any) => acc + parseFloat(property.estimated_mortgage_balance || 0), 0),
                    totalPropertiesMarketvalue: (deal.properties || []).reduce((acc: number, property: any) => acc + parseFloat(property.estimatedValue || 0), 0),
                    profitPercentage: Number(parseFloat(deal.dealProfitPercent || 0).toFixed(2)),
                    totalOfferPrice: parseFloat(deal.totalOfferPrice || 0),
                    TotalDealFirstLienPositionOriginationAmount: parseFloat(deal.TotalDealFirstLienPositionOriginationAmount || 0),
                    totalProperties: deal.properties.length,
                    // (deal.properties || []).filter((property: any) => property.is_tax_delinquent === '1').length
                    opportunityData: parseOpportunityData(deal.opportunityData),

                },
                properties: deal.properties?.map((p: any) => {
                    const property = mapApiResponseToPropertyProps(p);
                    property.estimatedPurchasePrice = parseFloat(propertyOfferPriceMap[p.id] || "0");
                    return property;
                }) || [],
            }
            return opportunityViewModel;
        });

        // Sort opportunities by totalProperties in descending order
        opportunities.sort((a: OpportunityViewModel, b: OpportunityViewModel) => b.properties.length - a.properties.length);

        const pageTokens = dealList.filter((d: any) => d.next_page_token).map((deal: any) => ({
            opportunityID: deal.next_page_token.opportunityID,
            status: deal.next_page_token.status || 'Open',
            totalProperties: deal.next_page_token.totalProperties,
        }));


        return {
            opportunities,
            pageToken: pageTokens.length > 0 ? pageTokens[0] : null,
            totalOpportunities: opportunities.length,
            totalNewOpportunities: 0,// TODO: retrieve from API
            totalUpdatedOpportunities: 0,// TODO: retrieve from API
        };
    } catch (error) {
        throw error;
    }
}

export const fetchOffers = async (token?: OpportunityPageToken, status: string = DEAL_STATUS.OfferMade): Promise<OpportunityListViewModel> => {
    try {
        const LIMIT_DEALS = 10;
        let dealApi = `${DEALS_API_URL_NEW}?page_size=${LIMIT_DEALS}&status=${status}`;
        if (token?.opportunityID) {
            dealApi += `&page_token.opportunityID=${token.opportunityID}`;
        }

        if (token?.status) {
            dealApi += `&page_token.status=${token.status}`;
        }

        if (token?.totalProperties) {
            dealApi += `&page_token.totalProperties=${token.totalProperties}`;
        }

        const response: any = await fetch(dealApi);
        if (!response.ok) throw new Error('Failed to fetch deals.');
        const rawData = await response.json();
        if (rawData.statusCode !== 200) {
            throw new Error('Failed to fetch deals. Error code: ' + rawData.statusCode);
        }
        const offerList = JSON.parse(rawData.body);
        console.log('offerList', offerList);

        const opportunities = offerList.filter((d: any) => !d.next_page_token).map((deal: any) => {
            const propertyOfferPriceMap = mapIdsToValues(deal.propertyids, deal.totalOfferPrice);
            const lender = (deal.lenderdata || []).length > 0 ? deal.lenderdata[0] : {};
            const offerPriceRange = extractMinMaxValues(deal.properties, "offerPrice"); // TODO remove
            const assetValueRange = extractMinMaxValues(deal.properties, "assessed_value");
            // const estimatedValue = parseFloat(deal.estimated_value);
            const borrowerList = (deal.properties || []).map((property: any) => ({
                name: property.company,
                count: Number(((parseFloat(property.estimated_mortgage_balance || 0) / parseFloat(deal.totalUPB)) * 100).toFixed(0)),
            }));

            // console.log('opportunityLeveragesMAOS ', JSON.stringify(deal.opportunityData.opportunityLeveragesMAOS, null, 2));
            // console.log('opportunityLeveragesMAOB ', JSON.stringify(deal.opportunityData.opportunityLeveragesMAOB, null, 2));
            // console.log('opportunityMAOS ', JSON.stringify(deal.opportunityData.opportunityMAOS, null, 2));
            // console.log('opportunityMAOB ', JSON.stringify(deal.opportunityData.opportunityMAOB, null, 2));
            // console.log('opportunityCOCR ', JSON.stringify(deal.opportunityData.opportunityCOCR, null, 2));

            const opportunityViewModel: OpportunityViewModel = {
                assignmentReleases: deal.assignment_releases,
                opportunityId: deal.opportunityID,
                status: deal.status,
                createdDate: deal.dateCreated,
                updatedDate: deal.lastUpdated,
                carouselCard: {
                    assetClass: deal.assetClassGroup,
                    totalmarketvalue: deal.totalmarketvalue,
                    totalUPB: deal.totalUPB,
                    estimatedAssetValue: parseFloat(deal.totalmarketvalue),
                    estimatedPrincipalBalance: parseFloat(lender.totalAmountInDefault),
                    estimatedPurchasePrice: parseFloat(deal.totalOfferPrice || 0),
                    locations: retrieveLocations(deal.properties),
                    lenderName: deal.lenderName,
                    offerRange: new RangeValuesProps(offerPriceRange.min, offerPriceRange.max),
                    assetValue: new RangeValuesProps(assetValueRange.min, assetValueRange.max),
                    lenderDefaultRatePercentage: Number(parseFloat(lender.LDR24 || 0).toFixed(2)),
                    lenderDefaultRateVolume: new RangeValuesProps(parseInt(lender.defaultvolume24 || 0), parseInt(lender.loanvolume24 || 0)),
                    lenderDefaultRateAmount: new RangeValuesProps(Number(parseInt(lender.defaultamount24 || 0).toFixed(2)), parseFloat(lender.loanamount24 || 0)),
                    taxLien: deal.is_tax_delinquent,
                    firstLienPositionOriginationAmount: deal.firstLienPositionOriginationAmount,
                    totalTaxLiens: (deal.properties || []).filter((property: any) => property.is_tax_delinquent === '1').length,
                    totalHoaLiens: 0, // TODO: retrieve from API 
                    totalVolume: new RangeValuesProps(parseInt(lender.defaultvolume24 || 0), parseInt(lender.loanvolume24 || 0)),
                    totalAmount: new RangeValuesProps(Number(parseFloat(lender.defaultamount24 || 0).toFixed(2)), Number(parseFloat(lender.loanamount24 || 0).toFixed(2))),
                    countOfAssetClassMix: countOccurrencesInArray((deal.properties || []).map((property: any) => property.assetClassGroup)),
                    countOfBorrower: aggregateValues(borrowerList),
                    estimated_mortgage_balance: (deal.properties || []).reduce((acc: number, property: any) => acc + parseFloat(property.estimated_mortgage_balance || 0), 0),
                    totalPropertiesMarketvalue: (deal.properties || []).reduce((acc: number, property: any) => acc + parseFloat(property.estimatedValue || 0), 0),
                    profitPercentage: Number(parseFloat(deal.dealProfitPercent || 0).toFixed(2)),
                    totalOfferPrice: parseFloat(deal.totalOfferPrice || 0),
                    TotalDealFirstLienPositionOriginationAmount: parseFloat(deal.TotalDealFirstLienPositionOriginationAmount || 0),
                    totalProperties: deal.properties.length,
                    // (deal.properties || []).filter((property: any) => property.is_tax_delinquent === '1').length
                    opportunityData: parseOpportunityData(deal.opportunityData),

                },
                properties: deal.properties?.map((p: any) => {
                    const property = mapApiResponseToPropertyProps(p);
                    property.estimatedPurchasePrice = parseFloat(propertyOfferPriceMap[p.id] || "0");
                    return property;
                }) || [],
            }

            return opportunityViewModel;
        });

        // Sort opportunities by totalProperties in descending order
        opportunities.sort((a: OpportunityViewModel, b: OpportunityViewModel) => b.properties.length - a.properties.length);

        const pageTokens = offerList.filter((d: any) => d.next_page_token).map((deal: any) => ({
            opportunityID: deal.next_page_token.opportunityID,
            status: deal.next_page_token.status || 'Open',
            totalProperties: deal.next_page_token.totalProperties,
        }));


        return {
            opportunities,
            pageToken: pageTokens.length > 0 ? pageTokens[0] : null,
            totalOpportunities: opportunities.length,
            totalNewOpportunities: 0,// TODO: retrieve from API
            totalUpdatedOpportunities: 0,// TODO: retrieve from API
        };
    } catch (error) {
        throw error;
    }
}

const parseOpportunityData = (opportunityData: any): OpportunityData => {
    return {
        opportunityMAOS: parseFloat(opportunityData.opportunityMAOS || 0),
        adjustedOpportunityMAOS: parseFloat(opportunityData.adjustedOpportunityMAOS || 0),
        opportunityMAOB: parseFloat(opportunityData.opportunityMAOB || 0),
        adjustedOpportunityMAOB: parseFloat(opportunityData.adjustedOpportunityMAOB || 0),
        opportunityAAO: opportunityData.opportunityAAO,
        opportunityAIV: opportunityData.opportunityAIV,
        opportunityCOCR: opportunityData.opportunityCOCR,
        opportunityLeveragesMAOS: opportunityData.opportunityLeveragesMAOS,
        opportunityLeveragesMAOB: opportunityData.opportunityLeveragesMAOB,
        lastUpdated: opportunityData.lastUpdated,
        dateCreated: opportunityData.dateCreated,
        opportunityCriteriaID: opportunityData.opportunityCriteriaID,
        lenderID: opportunityData.lenderID,
        opportunityProperties: opportunityData.properties,
        status: opportunityData.status,
        lastUpdatedCognitoID: opportunityData.lastUpdatedCognitoID,
        totalProperties: opportunityData.totalProperties,
        source: opportunityData.source,
        premiumPercentMAOS: opportunityData.premiumPercentMAOS,
        discountPercentMAOB: opportunityData.dicountPercentMAOB,
        dateExpired: opportunityData.dateExpired,
        opportunityID: opportunityData.opportunityID,
        opportunityUPB: opportunityData.opportunityUPB,
        createdCognitoID: opportunityData.createdCognitoID,
        lenderName: opportunityData.lenderName,
    };
};

export const fetchBannerDetails = async (): Promise<BannerDetailsViewModel> => {
    try {
        const response: any = await fetch(`${DEALS_BANNER_API_URL}`);
        if (!response.ok) throw new Error('Failed to fetch property deal details.');
        const rawData = await response.json();

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to fetch banner details. Error code: ' + rawData.statusCode);
        }

        const bannerDetails = JSON.parse(rawData.body);

        // console.log('bannerDetails..', bannerDetails);
        if (!bannerDetails || !bannerDetails.length) {
            return {} as BannerDetailsViewModel;
        }

        const banner = bannerDetails[0];
        const bannerDetailsResponse: BannerDetailsViewModel =
        {
            // estimatedPurchasePrice: 0, // TODO: retrieve from API
            // avaragePropertyValue: banner.averagePropertyAsIsValue, // TODO: retrieve from API
            // propertyCount: banner.totalProperties,
            // totalMarketValue: 0, // TODO: retrieve from API
            totalOpportunities: banner.totalOpportunities,
            profitPercentage: 0, // TODO: retrieve from API
            createdAt: banner.createdAt,

            // lastUpdated: banner.lastUpdated,
            // opportunityCriteriaID: banner.opportunityCriteriaID,
            averagePropertyAsIsValue: banner.averagePropertyAsIsValue,
            // totalFirstLienPositionOriginationAmount: banner.totalFirstLienPositionOriginationAmount,
            // totalLoanVolume: banner.totalLoanVolume,
            // status: banner.status,
            totalProperties: banner.totalProperties,
            totalAIV: banner.totalPropertiesAsIsValue,
            totalUPB: banner.totalUPB,
            averageAIV: banner.averagePropertyAsIsValue,
            totalPropertiesAsIsValue: banner.totalPropertiesAsIsValue,
            metrics_uuid: banner.metrics_uuid,
        };

        return bannerDetailsResponse;

    } catch (error) {
        throw error;
    }
};

const getOperators = (input: string) => {
    if (!input) {
        return defaultOperators;
    }

    const numOps = ["=", "!=", "<", ">", "<=", ">="];
    const wordOps = ["contains", "beginsWith", "endsWith", "doesNotContain", "doesNotBeginWith", "doesNotEndWith", "in", "notIn", "between", "notBetween"];

    if (input === "numOps") {
        return defaultOperators.filter(op => numOps.includes(op.name));
    }

    if (input === "wordOps") {
        return defaultOperators.filter(op => wordOps.includes(op.name));
    }

    return defaultOperators.filter(op => op.name === input);
};

export const fetchFilterDetails = async (): Promise<Field[]> => {
    try {
        const response: any = await fetch(`${DEALS_FILTER_API_URL}`);
        if (!response.ok) throw new Error('Failed to fetch filter details.');
        const rawData = await response.json();

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to fetch filter details. Error code: ' + rawData.statusCode);
        }

        const filterDetails = JSON.parse(rawData.body);

        // console.log('filterDetails..', filterDetails);
        if (!filterDetails || !filterDetails.length) {
            return [];
        }

        return filterDetails.map((filter: any) => {
            return {
                ...filter,
                operators: getOperators(filter.operators),
            };
        });
    } catch (error) {
        throw error;
    }
};

export const updateFilter = async (input: UpdateFilterInput): Promise<UpdateFilterResponse> => {
    try {
        const { userId, openSearchQuery, jsonQuery, getCount, opportunityCriteriaName, opportunityCriteriaID } = input;

        if (!getCount && !opportunityCriteriaName) {
            throw new Error('Opportunity Criteria Name is required.');
        }

        // Log the payload before sending
        const payload = getCount
            ? JSON.stringify(openSearchQuery)
            : JSON.stringify({
                openSearchQuery,
                jsonQuery,
                opportunityCriteriaName,
                ...(opportunityCriteriaID && { opportunityCriteriaID }),
            });

        const response: any = await fetch(`${SAVE_DEALS_FILTER_API_URL}?get_count=${getCount}&cognitoID=${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: payload,
        });

        // Log the response status
        console.log('Response Status:', response.status);
        // console.log('Response Payload:', response.payload);

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response Error Text:', errorText);
            throw new Error('Failed to update filter.');
        }

        const rawData = await response.json();

        // Log the raw data received
        // console.log('Raw Data:', rawData);
        // console.log('rawData count:', rawData.body);

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to update filter. Error code: ' + rawData.statusCode);
        }

        const responseBody = rawData.body;
        return {
            numberOfProperties: parseInt(responseBody.count || 0),
            numberOfOpportunities: parseInt(responseBody.numberOfOpportunities || 0),
            totalMarketValue: parseFloat(responseBody.totalMarketValue || 0),
            totalUPB: parseFloat(responseBody.totalUPB || 0),
            averageMarketValue: Number(parseFloat(responseBody.averageMarketValue || 0).toFixed(2)),
        };
    } catch (error) {
        console.error('Error in updateFilter:', error);
        throw error;
    }
};

export const fetchFilterPreview = async (input: GetFilterPreviewInput): Promise<GetFilterPreviewResponse[]> => {
    try {
        const { size, fromIndex, filter } = input;
        const payload = JSON.stringify(filter);

        const response: any = await fetch(`${FILTER_PREVIEW_API_URL}?size=${size}&from_index=${fromIndex}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: payload,
        });

        // Log the response status
        console.log('Response Status:', response.status);

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response Error Text:', errorText);
            throw new Error('Failed to get preview filter.');
        }

        const rawData = await response.json();

        // Log the raw data received
        // console.log('Raw Data:', rawData);
        // console.log('rawData count:', rawData.body);

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to get preview filter. Error code: ' + rawData.statusCode);
        }

        return parseFilterPreviewApiResponse(rawData);
    } catch (error) {
        console.error('Error in fetchFilterPreview:', error);
        throw error;
    }
};

export const updateOpportunityStatus = async (
    input: UpdateOpportunityStatusInput
): Promise<UpdateOpportunityStatusResponse> => {
    try {
        const { opportunityId, userId, totalProperties, newStatus } = input;
        const url = `${UPDATE_DEAL_STATUS_API_URL}?new_state=${String(newStatus)}&opportunity_id=${opportunityId}&cognito_user_id=${userId}&totalProperties=${totalProperties}`;
        const response: any = await fetch(url);

        if (!response.ok) throw new Error('Failed to update opportunity status.');

        const rawData = await response.json();

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to update opportunity status. Error code: ' + rawData.statusCode);
        }

        const parsedData: UpdateOpportunityStatusResponse = {
            status: newStatus,
            opportunityId: opportunityId
        };

        if (newStatus === DEAL_STATUS.OfferMade) {
            const url = `${DEALS_BLUE_BUTTOM_OFFER_MADE_API_URL}?opportunityID=${opportunityId}&cognitoID=${userId}&totalProperties=${totalProperties}`
            // const response: any = await fetch(url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(input),
            });
            
            if (!response.ok) throw new Error('Failed to update offer made status.');
            const rawData = await response.json();

            if (rawData.statusCode !== 200) {
                throw new Error('Failed to update offer made status. Error code: ' + rawData.statusCode);
            }            
        }

        return parsedData;
    } catch (error) {
        throw error;
    }
}

export const fetchPropertyDealDetails = async (propertyId?: string): Promise<PropertyDetailsProps> => {
    try {
        const response: any = await fetch(`${PROPERTY_DETAILS_API_URL_NEW}?input_id=${propertyId}`);
        if (!response.ok) throw new Error('Failed to fetch property deal details.');
        const rawData = await response.json();

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to fetch property deal details. Error code: ' + rawData.statusCode);
        }

        const propertyDetails = parseApiResponseToPropertyDetails(rawData);
        // console.log('propertyDetails..', propertyDetails);
        return propertyDetails;
    } catch (error) {
        throw error;
    }
};

export const updateOpportunityPricing = async (input: UpdateOpportunityPricingInput, opportunityID: string, totalProperties: number, cognitoID: string)
    : Promise<UpdateOpportunityPricingResponse> => {
    const url = `${UPDATE_OPPORTUNITY_PRICING_API_URL}?input_id=${opportunityID}&total_properties=${totalProperties}&cognitoID=${cognitoID}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(input),
    });

    if (!response.ok) {
        throw new Error('Failed to update opportunity pricing.');
    }

    return response.json();
};

export const updatePropertyDealAttributes = async (input: UpdatePropertyDealAttributesInput, opportunityID: string, totalProperties: number, cognitoID: string)
    : Promise<UpdatePropertyDealAttributesResponse> => {
    try {
        const url = `${UPDATE_OPPORTUNITY_PRICING_API_URL}?input_id=${opportunityID}&total_properties=${totalProperties}&cognitoID=${cognitoID}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
        });

        if (!response.ok) {
            throw new Error('Failed to update property deal attributes.');
        }

        const rawData = await response.json();

        // console.log("input " + JSON.stringify(input))

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to update property deal attributes. Error code: ' + rawData.statusCode);
        }

        const parsedData: OpportunityData = parseOpportunityData(rawData.body.body.body);

        // console.log('parsedData: ', parsedData);

        return {
            body: parsedData,
        };

        return response.json();
    } catch (error) {
        throw error;
    }
};

const parseApiResponseToPropertyDetails = (apiResponse: any): PropertyDetailsProps => {
    const body = JSON.parse(apiResponse.body);
    console.log('parseApiResponseToPropertyDetails body', body);
    if (!body || !body.length) {
        return {} as PropertyDetailsProps;
    }

    const propertyArr = body[0].properties;
    const assignmentArr = body[4].assignment_releases;
    // console.log('assignmentArr ', assignmentArr);

    if (!propertyArr.length) {
        return {} as PropertyDetailsProps;
    }

    const properties = propertyArr[0];
    const foreclosureArr = body[2].foreclosures;
    const foreclosure = foreclosureArr.length ? foreclosureArr[0] : null;

    // console.log('mortgages ', body[3].mortgages.map((mortgage: any) => mortgage));

    return {
        assignment_releases: assignmentArr.map((assignment: any) => ({
            new_lender: assignment.new_lender,
            version: assignment.version,
            assignment_uuid: assignment.assignment_uuid,
            county: assignment.county,
            document_type: assignment.document_type,
            created_at: assignment.created_at,
            mortgage_loan_position: assignment.mortgage_loan_position,
            recording_page: assignment.recording_page,
            recording_book: assignment.recording_book,
            original_loan_amount: assignment.original_loan_amount,
            borrower: assignment.borrower,
            original_document_number: assignment.original_document_number,
            recording_date: assignment.recording_date,
            state: assignment.state,
            original_page: assignment.original_page,
            updated_at: assignment.updated_at,
            original_book: assignment.original_book,
            contract_date: assignment.contract_date,
            original_mortgage_date: assignment.original_mortgage_date,
            original_lender: assignment.original_lender,
            property_identifier: assignment.property_identifier,
        })),
        address: properties.address,
        assetClassGroup: properties.assetClassGroup,
        estimatedValue: properties.estimated_value,
        lastSaleDate: properties.last_sale_date,
        estimated_mortgage_balance: properties.estimated_mortgage_balance,
        foreclosures: body[2]?.foreclosures || [],
        mortgages: body[3].mortgages.map((mortgage: any) => ({
            amount: parseInt(mortgage.amount),
            assumable: mortgage.is_assumable == true,
            county: mortgage.county,
            createdAt: mortgage.created_at,
            deedType: mortgage.deed_type,
            documentDate: mortgage.document_date,
            granteeName: mortgage.grantee,
            interestRate: parseFloat(mortgage.interest_rate),
            interestRateType: mortgage.interest_rate,
            isOpen: mortgage.is_open,
            lastUpdateDate: mortgage.updated_at,
            lenderCode: mortgage.lender_code,
            lenderName: mortgage.lender,
            lenderType: mortgage.lender_type,
            loanType: mortgage.loan_type,
            loanTypeCode: mortgage.loan_type_code,
            maturityDate: mortgage.maturity_date,
            mortgageId: mortgage.id,
            open: mortgage.is_open,
            position: mortgage.position,
            propertyId: mortgage.property_identifier,
            recordingDate: mortgage.recording_date,
            seq: mortgage.seq,
            state: mortgage.state,
            term: parseInt(mortgage.term),
            termType: mortgage.term_type,
            transactionType: mortgage.transaction_type,
            updatedAt: mortgage.updated_at,
        })),
        noticeOfDefaultDate: properties.is_foreclosure === "1" ? foreclosure.defaultRecordingDate : null,
        // originalPurchasePrice: parseInt(properties.original_purchase_price), // TODO Not used in the UI
        // propertyOwner: properties.property_owner.map((owner: any) => ({
        //     address: owner.address,
        //     addressFormat: owner.addressformat,
        //     carrierRoute: owner.carrierroute,
        //     city: owner.city,
        //     companyName: owner.companyname,
        //     county: owner.county,
        //     createdAt: owner.created_at,
        //     equity: parseInt(owner.equity),
        //     fips: owner.fips,
        //     house: owner.house,
        //     label: owner.label,
        //     lastUpdateDate: owner.lastupdatedate,
        //     owner1FirstName: owner.owner1firstname,
        //     owner1FullName: owner.owner1fullname,
        //     owner1LastName: owner.owner1lastname,
        //     owner1Type: owner.owner1type,
        //     owner2FirstName: owner.owner2firstname,
        //     owner2FullName: owner.owner2fullname,
        //     owner2LastName: owner.owner2lastname,
        //     owner2Type: owner.owner2type,
        //     ownerInfoCounty: owner.ownerinfo_county,
        //     ownerInfoState: owner.ownerinfo_state,
        //     ownershipLength: parseInt(owner.ownershiplength),
        //     predirection: owner.predirection,
        //     propertyId: owner.propertyid,
        //     state: owner.state,
        //     street: owner.street,
        //     streetType: owner.streettype,
        //     unit: owner.unit,
        //     unitType: owner.unittype,
        //     updatedAt: owner.updated_at,
        //     zip: owner.zip,
        //     zip4: owner.zip4,
        // })),
        propertyId: properties.property_identifier,
        propertyUse: properties.property_use,
        salesHistory: body[1].sale_history.map((sale: any) => ({
            armsLength: sale.is_arms_length == true,
            buyerNames: sale.buyer,
            county: sale.county,
            createdAt: sale.created_at,
            documentType: sale.document_type,
            documentTypeCode: sale.document_type_code,
            downPayment: parseFloat(sale.down_payment || 0),
            lastUpdateDate: sale.updated_at,
            ltv: parseInt(sale.ltv),
            ownerIndividual: sale.is_owner_individual == true,
            propertyId: sale.property_identifier,
            purchaseMethod: sale.purchase_method,
            recordingDate: sale.recording_date,
            saleAmount: parseInt(sale.sale_amount),
            saleDate: sale.sale_date,
            sellerNames: sale.seller,
            seq: sale.seq,
            seqNo: sale.seq_no,
            state: sale.state,
            transactionType: sale.transaction_type,
            updatedAt: sale.updated_at,
        })),
        // taxAssessment: properties.tax_assesment.map((assessment: any) => ({
        //     assessedImprovementValue: parseInt(assessment.assessedimprovementvalue),
        //     assessedLandValue: parseInt(assessment.assessedlandvalue),
        //     assessedValue: parseInt(assessment.assessedvalue),
        //     assessmentYear: assessment.assessmentyear,
        //     county: assessment.county,
        //     createdAt: assessment.created_at,
        //     estimatedValue: parseFloat(assessment.estimatedvalue),
        //     lastUpdateDate: assessment.lastupdatedate,
        //     marketImprovementValue: parseInt(assessment.marketimprovementvalue),
        //     marketLandValue: parseInt(assessment.marketlandvalue),
        //     marketValue: parseInt(assessment.marketvalue),
        //     propertyId: assessment.propertyid,
        //     state: assessment.state,
        //     taxAmount: parseFloat(assessment.taxamount),
        //     taxDelinquentYear: assessment.taxdelinquentyear,
        //     updatedAt: assessment.updated_at,
        //     year: assessment.year,
        // })),
        unitsCount: parseInt(properties.num_units || 0),
        latitude: parseFloat(properties.latitude),
        longitude: parseFloat(properties.longitude),
        county: properties.county,
        lotacres: properties.lot_acres,
        sqft: properties.square_feet, // TODO: retrieve from API - UPDATED
        isJudicialState: properties.is_judicial_state,
        foreclosureDocument: foreclosure?.document_type || '',
        foreclosureDate: foreclosure?.recording_date || '',
        foreclosureDoc: properties.foreclosure_document_type === "1" ? foreclosure?.foreclosure_document_type : null,
        numberOfLiens: properties.numberOfLiens,
        defaultRecordingDate: properties.is_foreclosure === "1" ? foreclosure?.defaultRecordingDate : null,
        foreclosure_document_type: properties.foreclosure_document_type,
        // firstLienPositionOriginationAmount: properties.firstLienPositionOriginationAmount,
        // totalfirstLienPositionPayoffBalance: properties.totalfirstLienPositionPayoffBalance,
        // estimatedMortgageBalance: properties.estimated_mortgage_balance,
        firstLienPositionLoanType: properties.firstLienPositionLoanType,
    };
};

export const fetchSavedQueries = async (userId: string): Promise<SavedQueryResponse[]> => {
    try {
        const response: any = await fetch(`${FETCH_SAVED_QUERIES_API_URL}?cognito_user_id=${userId}`);
        if (!response.ok) throw new Error('Failed to fetch saved queries.');
        const rawData = await response.json();

        if (rawData.statusCode !== 200) {
            throw new Error('Failed to fetch saved queries. Error code: ' + rawData.statusCode);
        }
        // console.log("fetchSavedQueries " + rawData.body);
        return rawData.body;

    } catch (error) {
        throw error;
    }
};

const mapApiResponseToPropertyProps = (response: any): PropertyProps => {

    return {
        ...response,
        assessedValue: parseFloat(response.assessed_value || 0),
        assessedYear: response.assessment_year,
        assetClassGroup: response.assetClassGroup,
        borrower_calc: response.borrowerCalc,
        documentType: response.document_type,
        id: response.property_identifier,
        lenderName: response.lender,
        // loanVolume: parseFloat(response.loanvolume || 0),
        marketValue: parseFloat(response.market_value || 0),
        estimatedValue: parseFloat(response.estimated_value || 0).toString(),
        principalBalance: parseFloat(response.principalbalance || 0), // TODO: retrieve from API (Is it open_mortgage_balance?)
        recordingDate: response.recording_date,
        state: response.mail_state,
        taxLien: response.is_tax_delinquent, // TODO: retrieve from API - THIS ONE IS OK, TAXLIENS is also working now
        // totalAmountinDefault: parseFloat(response.totalamountindefault || 0),
        totalMarketValue: parseFloat(response.market_value || 0),
        // totalOriginationAmount: parseFloat(response.totaloriginationamount || 0),
        zip: response.mail_zip5 || response.mail_zip4,
        address: response.address,
        apn: response.apn,
        loanDocumentsCount: (response.loan_documents || []).length,
        defaultRecordingDate: response.defaultRecordingDate,
        firstLienPositionOriginationAmount: response.firstLienPositionOriginationAmount,
        firstLienPositionLoanOriginationLTV: response.firstLienPositionLoanOriginationLTV,
        firstLienPositionLoanOriginationAIV: response.firstLienPositionLoanOriginationAIV,
        totalfirstLienPositionPayoffBalance: response.totalfirstLienPositionPayoffBalance,
        lastSalePrice: parseFloat(response.last_sale_price || 0),
        estimated_mortgage_balance: response.estimated_mortgage_balance,
        offerPrice: parseFloat(response.offerPrice || 0),
        // foreclosureDoc: response.foreclosure_document_type,
        foreclosure_document_type: response.foreclosure_document_type,
        firstLienPositionLoanType: response.firstLienPositionLoanType,
        firstLienPositionLoanTerm: response.firstLienPositionLoanTerm,
        firstLienPositionLoanTermType: response.firstLienPositionLoanTermType,
        firstLienPostionMortgageOrginationAmount: response.firstLienPostionMortgageOrginationAmount,
        firstLienPostionMortgageLoanType: response.firstLienPostionMortgageLoanType,
        firstLienPositionMortgageLoanTerm: response.firstLienPositionMortgageLoanTerm,
    };
};

function retrieveLocations(properties: any): DealLocationProps[] {
    if (!properties || !properties.length) return [];
    return properties.map((property: any) => {
        return {
            coordinates: [parseFloat(property.longitude), parseFloat(property.latitude)],
            address: property.address,
            estimatedReturn: parseInt(property.estimatedProfitAmount || 0),
            status: 'active',
            type: (property.assetClassGroup || '').includes('Residential') ? 'Residential' : 'Commercial',
        };
    });
}

function parseFilterPreviewApiResponse(response: any): GetFilterPreviewResponse[] {
    const body = JSON.parse(response.body);
    return body.map((item: any) => ({
        assessedValue: parseFloat(item._source.assessed_value) || 0,
        assessedYear: item._source.assessment_year,
        assetClassGroup: item._source.assetClassGroup,
        documentType: item._source.lastAssignment.document_type || '',
        id: item._source.property_identifier,
        lastSalePrice: parseFloat(item._source.last_sale_price) || 0,
        lenderName: item._source.lender || '',
        loanVolume: item._source.loanVolume ? parseFloat(item._source.loanVolume) : undefined,
        marketValue: parseFloat(item._source.market_value) || 0,
        principalBalance: parseFloat(item._source.estimated_mortgage_balance) || 0,
        borrower_calc: item._source.borrowerCalc,
        recordingDate: item._source.recording_date,
        state: item._source.state,
        totalAmountinDefault: item._source.totalAmountinDefault ? parseFloat(item._source.totalAmountinDefault) : undefined,
        totalMarketValue: parseFloat(item._source.total_portfolio_value) || 0,
        totalOriginationAmount: item._source.totalOriginationAmount ? parseFloat(item._source.totalOriginationAmount) : undefined,
        zip: item._source.zip5,
        address: item._source.address,
        estimatedPurchasePrice: item._source.estimatedPurchasePrice ? parseFloat(item._source.estimatedPurchasePrice) : undefined,
        apn: item._source.apn,
        taxLien: item._source.taxLien || '',
        loanDocumentsCount: item._source.loanDocumentsCount ? parseInt(item._source.loanDocumentsCount) : undefined,
        defaultRecordingDate: item._source.defaultRecordingDate || '',
        numberOfLiens: item._source.numberOfLiens || '',
        firstLienPositionOriginationAmount: item._source.firstLienPositionOriginationAmount || '',
        firstLienPositionLoanOriginationLTV: item._source.firstLienPositionLoanOriginationLTV || '',
        firstLienPositionLoanOriginationAIV: item._source.firstLienPositionLoanOriginationAIV || '',
        totalfirstLienPositionPayoffBalance: item._source.totalfirstLienPositionPayoffBalance || '',
        estimated_mortgage_balance: parseFloat(item._source.estimated_mortgage_balance) || 0,
        offerPrice: parseFloat(item._source.asking_price) || 0,
        foreclosure_document_type: item._source.foreclosure_document_type || '',
        firstLienPositionLoanType: item._source.firstLienPositionLoanType || '',
        firstLienPositionLoanTerm: item._source.firstLienPositionLoanTerm || '',
        firstLienPositionLoanTermType: item._source.firstLienPositionLoanTermType || '',
        firstLienPostionMortgageOrginationAmount: item._source.firstLienPostionMortgageOrginationAmount || '',
        firstLienPostionMortgageLoanType: item._source.firstLienPostionMortgageLoanType || '',
        firstLienPositionMortgageLoanTerm: item._source.firstLienPositionMortgageLoanTerm || '',
        lender: item._source.lender ?? '',
        is_corporate_owned: item._source.is_corporate_owned ?? false,
        is_preforeclosure: item._source.is_preforeclosure ?? false,
        property_use: item._source.property_use ?? '',
        zip5: item._source.zip5 ?? '',
        assessed_value: item._source.assessed_value ?? '',
        estimated_value: item._source.estimated_value ?? '',
        borrowerCalc: item._source.borrowerCalc ?? '',
        has_mortgage: item._source.has_mortgage ?? false,
        is_tax_delinquent: item._source.is_tax_delinquent ?? false,
        property_identifier: item._source.property_identifier ?? ''
    }));
}