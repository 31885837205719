
export interface DoubleArrowIconProps {
    className?: string;
}

const DoubleArrowIcon = () => (
    <>
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" className="block dark:hidden">
            <rect width="32" height="32" rx="8" transform="matrix(1 0 0 -1 0 32.5)" fill="#EAEAEA" />
            <path d="M11.9069 21.123L7.82227 17.2119L11.8879 13.3017L12.5592 13.9291L9.64366 16.7386H23.0685L20.1529 13.93L20.8242 13.3008L24.8889 17.2128L20.8233 21.123L20.134 20.4947L23.0675 17.6678H9.62564L12.5592 20.4947L11.9069 21.123Z" fill="#16ACEE" />
        </svg>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden dark:block">
            <rect width="32" height="32" rx="8" transform="matrix(1 0 0 -1 0 32)" fill="#1D2634" />
            <path d="M11.9069 20.622L7.82227 16.7109L11.8879 12.8007L12.5592 13.4281L9.64366 16.2376H23.0685L20.1529 13.429L20.8242 12.7998L24.8889 16.7118L20.8233 20.622L20.134 19.9937L23.0675 17.1669H9.62564L12.5592 19.9937L11.9069 20.622Z" fill="#16ACEE" />
        </svg>
    </>
)

export default DoubleArrowIcon;