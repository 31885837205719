// awsConfig.ts
import { S3Client, S3ClientConfig } from "@aws-sdk/client-s3";
import { AWS_CONFIG } from './config';

const s3Config: S3ClientConfig = {
  credentials: {
    accessKeyId: AWS_CONFIG.accessKeyId,
    secretAccessKey: AWS_CONFIG.secretAccessKey,
  },
  region: AWS_CONFIG.region,
};

const s3 = new S3Client(s3Config);

export default s3;  