import * as React from "react"
import { CaretSortIcon } from "@radix-ui/react-icons"

import { Button } from "@/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"

export interface ComboboxOption {
    value: string
    label: string
}

export interface ComboboxProps {
    options: ComboboxOption[];
    searchPlaceholder?: string;
    onSelect?: (value: string) => void;
}

export default function Combobox({ options = [], searchPlaceholder, onSelect }: ComboboxProps) {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState(options[0].value)

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-fit min-w-0 !px-3 justify-between rounded-lg font-normal text-base !h-[42px] !bg-transparent border-stone-300 text-stone-300"
                >
                    {value
                        ? options.find((option) => option.value === value)?.label
                        : options[0].value}
                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
                <Command>
                    <CommandInput
                        placeholder={searchPlaceholder ?? "Search..."}
                        autoFocus={true}
                        className="h-9"
                    />
                    <CommandList>
                        <CommandEmpty>No label found.</CommandEmpty>
                        <CommandGroup>
                            {options.map(({ label, value }) => (
                                <CommandItem
                                    key={label}
                                    value={value}
                                    onSelect={(currentValue) => {
                                        setValue(currentValue);
                                        setOpen(false);
                                        onSelect?.(currentValue);
                                    }}
                                >
                                    {label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
