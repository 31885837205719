// import { PlusCircleIcon, Wallet } from 'lucide-react';
import { Button } from '../ui/button';

const FundButton = () => {
    return <Button className="bg-card text-sm xs:text-base font-headings font-normal flex h-10 lg:h-13.5 items-center px-2 lg:px-5 rounded-lg group" variant="secondary">
        {/* <Wallet className="mr-2.5 lg:mr-4" />
        <span className='text-slate-500 mr-2.5'>Balance:{" "}</span>
        <span className='font-semibold mr-2.5'>$640k</span>
        <PlusCircleIcon className="fill-green-500 stroke-card transition-all" /> */}
    </Button>
}

export default FundButton;