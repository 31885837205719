import "./PropertyInfoLoanTape.css";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { accordionTableData, tabTableData } from "@/constants/__mock__/LenderProfileDetails";

const PropertyInfoLoanTape = () => {
    return (
        <div className="pl-5 pt-5 mt-3 w-full card-container pb-5">
            <h3 className="text-xl font-semibold mb-5">Property Info & Loan Tape</h3>
            <Table className="no-row-border accordion-table">
                <TableBody>
                    <Accordion type="single" collapsible>
                        {
                            accordionTableData.map((data, index) => (
                                <AccordionItem key={index} value={`item-${index}`} className="rounded-lg overflow-hidden my-3">
                                    <TableRow className="header-row">
                                        <TableCell style={{ width: "12%" }}><span className="text-[#A1A3A7] text-base whitespace-nowrap">{data.col1}</span></TableCell>
                                        <TableCell style={{ width: "10%" }}><span className="text-[#FFFFFF] text-base">{data.col2}</span></TableCell>
                                        <TableCell style={{ width: "14%" }}><span className="text-[#A1A3A7] text-base">PB -</span> <span className=" whitespace-nowrap">{data.col3}</span></TableCell>
                                        <TableCell style={{ width: "15%" }}><span className="text-[#A1A3A7] text-base">UPB -</span> <span className=" whitespace-nowrap">{data.col4}</span></TableCell>
                                        <TableCell style={{ width: "22%" }}><span className="text-[#A1A3A7] text-base">Offer Range -</span><span className=" whitespace-nowrap">{data.col5}</span></TableCell>
                                        <TableCell style={{ width: "11%" }}><span className="text-[#1A9C61] text-base whitespace-nowrap">{data.col6}</span></TableCell>
                                        <TableCell style={{ width: "11%" }}><span className="text-[#FF0000] text-base whitespace-nowrap">{data.col7}</span></TableCell>
                                        <TableCell style={{ width: "3%" }}>
                                            <AccordionTrigger />
                                        </TableCell>
                                    </TableRow>
                                    <AccordionContent>
                                        <Table className="no-row-border">
                                            <TableBody>
                                                {
                                                    tabTableData.map((data, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell><span className="text-[#A1A3A7] text-base">{data.col1}</span></TableCell>
                                                            <TableCell><span className="text-[#FFFFFF] text-base">{data.col2}</span></TableCell>
                                                            <TableCell><span className="text-[#A1A3A7] text-base">{data.col3}</span></TableCell>
                                                            <TableCell><span className="text-[#FFFFFF] text-base">{data.col4}</span></TableCell>
                                                            <TableCell><span className="text-[#A1A3A7] text-base">{data.col5}</span></TableCell>
                                                            <TableCell><span className="text-[#FFFFFF] text-base">{data.col6}</span></TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                            ))
                        }
                    </Accordion >
                </TableBody>
            </Table>
        </div>
    );
}

export default PropertyInfoLoanTape;