// config.ts
export const AWS_CONFIG = {
  accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  region: import.meta.env.VITE_AWS_REGION,
  rootBucketName: import.meta.env.VITE_S3_ROOT_BUCKET_NAME,
};

export const CHECK_USER_PERMISSION_API_URL = import.meta.env.VITE_AUTH_PERMISSION_API_URL;

export const LENDER_PROFILE_QUERY_API_URL = import.meta.env.VITE_LENDER_PROFILE_QUERY_API_URL;

export const PROPERTIES_QUERY_API_URL = import.meta.env.VITE_PROPERTIES_QUERY_API_URL;

export const PROPERTY_DETAILS_API_URL = import.meta.env.VITE_PROPERTY_DETAILS_API_URL;

export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export const DEALS_API_URL = import.meta.env.VITE_DEALS_API_URL;

export const DEALS_API_URL_NEW = import.meta.env.VITE_DEALS_API_URL_NEW;

export const PROPERTY_DETAILS_API_URL_NEW = import.meta.env.VITE_PROPERTY_DETAILS_API_URL_NEW;

export const DEALS_BANNER_API_URL = import.meta.env.VITE_DEALS_BANNER_API_URL

export const UPDATE_DEAL_STATUS_API_URL = import.meta.env.VITE_UPDATE_DEAL_STATUS_API_URL;

export const DEALS_FILTER_API_URL = import.meta.env.VITE_DEALS_FILTER_API_URL;

export const SAVE_DEALS_FILTER_API_URL = import.meta.env.VITE_SAVE_DEALS_FILTER_API_URL;

export const FILTER_PREVIEW_API_URL = import.meta.env.VITE_FILTER_PREVIEW_API_URL;

export const FETCH_SAVED_QUERIES_API_URL = import.meta.env.VITE_FETCH_SAVED_QUERIES_API_URL;

export const UPDATE_OPPORTUNITY_PRICING_API_URL = import.meta.env.VITE_UPDATE_OPPORTUNITY_PRICING_API_URL;

export const DEALS_BLUE_BUTTOM_OFFER_MADE_API_URL = import.meta.env.VITE_DEALS_BLUE_BUTTOM_OFFER_MADE_API_URL;
