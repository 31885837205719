import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

export interface MultipleChoiceDropdownProps {
    selectedOptions: string[];
    options: MultipleChoiceDropdownOption[];
    toggleOption: (option: string) => void;
}

export interface MultipleChoiceDropdownOption {
    label: string;
    value: string;
}


const MultipleChoiceDropdown: React.FC<MultipleChoiceDropdownProps> = ({ options, selectedOptions, toggleOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                className="w-full border border-gray-300 bg-white p-2 rounded-lg text-left flex items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOptions.length > 0 ? selectedOptions.join(', ') : 'Select Options'}
                {isOpen ? <ChevronUp className="ml-auto h-4 w-4 opacity-50" /> : <ChevronDown className="ml-auto h-4 w-4 opacity-50" />}
            </button>
            {isOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => toggleOption(option.value)}
                        >
                            <input
                                type="checkbox"
                                checked={selectedOptions.includes(option.value)}
                                onChange={() => toggleOption(option.value)}
                                className="mr-2"
                            />
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultipleChoiceDropdown;