import clsx from 'clsx';
import { FC } from 'react';
import ApexChart from 'react-apexcharts';

export interface RadialChartProps {
  series?: number[];
  className?: string;
  options?: object;
  title?: string;
}

export const defaultRadialChartOptions = {
  fill: {
    colors: ['#2E3A4B', '#E39600', '#E91649'],
  },
  plotOptions: {
    pie: {
      donut: {
        size: '80%',
      },
      expandOnClick: false,
    },
  },
  chart: {
    fontFamily: 'Inter, sans-serif',
    dropShadow: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  grid: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
    },
  },
  stroke: {
    width: 0,
  },
  dataLabels: {
    enabled: false,
  },
};

const RadialChart: FC<RadialChartProps> = ({ className, series = [80, 10, 20], options = defaultRadialChartOptions, title = "30%" }) => {
  return (
    <div className={clsx('relative', className)}>
      <ApexChart options={options} series={series} type="donut" width="100%" height="100%" />
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-sans font-bold">{title}</span>
    </div>
  );
};

export default RadialChart;