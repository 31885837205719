// src/components/dashboard/pages/deals/PropertyDealsTable.tsx

import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { formatCurrency, getTimeInDefault } from '@/lib/utils';
import { ChevronRight, Pencil } from 'lucide-react';
import { PropertyProps } from '../Property.interface';
// import DocumentIcon from "@/components/icons/DocumentIcon";
import DocumentIcon from '@/components/icons/DocumentIcon';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EditableField, { UpdatedOpportunityField } from '../../../common/EditableField';
import { useUpdatePropertyDealAttributes } from '../hooks';
import { UpdatePropertyDealAttributeInput } from '../types';
import { OpportunityData } from '../view-models/CarouselCardViewModel';
// import { useAuth } from "@/components/AuthProvider";

interface PropertyDealsTableProps {
  properties: PropertyProps[];
  opportunity?: OpportunityData;
  onDetailsClick?: (propertyId: string) => void;
  onUpdateValue?: (opportunityId: string, value: OpportunityData) => void;
  hideProgressBar?: () => void;
  showProgressBar?: () => void;
}

const PropertyDealsSellerTable: React.FC<PropertyDealsTableProps> = ({ properties, onDetailsClick, onUpdateValue, opportunity, hideProgressBar, showProgressBar }) => {
  // const auth = useAuth();
  const [editedPropertyFields, setPropertyFields] = useState<UpdatedOpportunityField[]>([]);
  const [savedPropertyDealAttributes, setSavedPropertyDealAttributes] = useState<UpdatePropertyDealAttributeInput[]>([]);
  const {
    mutate: updatePropertyDealAttributes,
    data: updatedPropertyDealAttributesResponse,
    error: updatePropertyDealAttributesError,
    isLoading: isUpdatingPropertyDealAttributes,
  } = useUpdatePropertyDealAttributes();

  console.log(updatePropertyDealAttributes);

  useEffect(() => {
    if (isUpdatingPropertyDealAttributes) {
      showProgressBar && showProgressBar();
    } else if (properties.length) {
      hideProgressBar && hideProgressBar();
    }
  }, [isUpdatingPropertyDealAttributes]);

  useEffect(() => {
    const error = updatePropertyDealAttributesError;

    if (error) {
      hideProgressBar && hideProgressBar();
    }
  }, [updatePropertyDealAttributesError]);

  useEffect(() => {
    if (updatedPropertyDealAttributesResponse) {
      onUpdateValue && onUpdateValue(opportunity!.opportunityID, updatedPropertyDealAttributesResponse.body);
      toast.success('Property Deal attributes updated successfully.');
    }
  }, [updatedPropertyDealAttributesResponse]);

  const handleEditClick = (currentValue: string, propertyId?: string, field?: string) => {
    const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
    updatedFields.push(new UpdatedOpportunityField(propertyId || '', field || '', Number(currentValue.replace(/[$,]/g, '')), currentValue));
    setPropertyFields(updatedFields);
  };

  const handleSaveFieldClick = (updatedField: UpdatedOpportunityField, propertyId?: string, field?: string) => {
    if (!propertyId || !field) return;

    // Save the updated field value to savedPropertyDealAttributes
    const updatedAttribute: UpdatePropertyDealAttributeInput = {
      propertyID: propertyId,
      operation: 'update',
      attribute: field,
      value: updatedField.value,
    };

    const existingAttributeIndex = savedPropertyDealAttributes.findIndex((attribute) => attribute.propertyID === propertyId && attribute.attribute === field);

    if (existingAttributeIndex !== -1) {
      const updatedAttributes = [...savedPropertyDealAttributes];
      updatedAttributes[existingAttributeIndex] = updatedAttribute;
      setSavedPropertyDealAttributes(updatedAttributes);
    } else {
      setSavedPropertyDealAttributes([...savedPropertyDealAttributes, updatedAttribute]);
    }
    // onUpdateValue && onUpdateValue(updatedField.id, { [updatedField.field]: updatedField.value });
    const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
    setPropertyFields(updatedFields);
  };

  const handleDiscardFieldClick = (propertyId?: string, field?: string) => {
    const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
    setPropertyFields(updatedFields);
  };

  const renderTableCell = (prefix: string, value: string, propertyId?: string, field?: string, isEditable: boolean = false) => {
    const editedField = editedPropertyFields.find((editedField) => editedField.id === propertyId && editedField.field === field);
    const savedAttribute = savedPropertyDealAttributes.find((attribute) => attribute.propertyID === propertyId && attribute.attribute === field);
    if (savedAttribute) {
      value = formatCurrency(savedAttribute.value);
    }

    return (
      <TableCell className="whitespace-nowrap text-base">
        <span className="text-customGray929DAD">{prefix}</span>
        {isEditable ? (
          <>
            {editedField ? (
              <EditableField
                initialValue={editedField}
                onSave={(value) => handleSaveFieldClick(value, propertyId, field)}
                onDiscard={() => handleDiscardFieldClick(propertyId, field)}
              />
            ) : (
              <div className="flex items-center">
                <span className="text-black dark:text-white">{value}</span>
                <Pencil onClick={() => handleEditClick(value, propertyId, field)} className="ml-2 h-4 w-4 cursor-pointer" />
              </div>
            )}
          </>
        ) : (
          <span className="text-black dark:text-white">{value}</span>
        )}
      </TableCell>
    );
  };

  return (
    <>
      <div>
        <Table className="[&_tr:nth-child(even)]:bg-muted/50">
          <TableBody>
            <TableRow className="border-t-[1px]">
              <TableCell>#</TableCell>
              <TableCell>Docs</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Est Offer</TableCell>
              <TableCell>As Is Value (AIV)</TableCell>
              <TableCell>Unpaid Principal Balance (UPB)</TableCell>
              <TableCell>Origination Amount (OA)</TableCell>
              <TableCell>Origination LTV</TableCell>
              <TableCell>Origination AIV</TableCell>
              <TableCell>Tax Assessment Value</TableCell>
              <TableCell>Total Payoff Balance</TableCell>
              <TableCell>Loan Type</TableCell>
              <TableCell>Loan Term</TableCell>
              <TableCell>Borrower</TableCell>
              <TableCell>Origination Date</TableCell>
              <TableCell>Lien Position of Lender</TableCell>
              <TableCell>Tax Lien</TableCell>
              <TableCell>Tax Lien Amount</TableCell>
              <TableCell>HOA Lien</TableCell>
              <TableCell>HOA Lien Amount</TableCell>
              <TableCell>Default Type</TableCell>
              <TableCell>Time in Default</TableCell>
              <TableCell>Tax Assessment Year</TableCell>
              <TableCell>APN</TableCell>
              <TableCell>Asset Class</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {properties.map((property, index) => (
              <TableRow key={property.id} className="border-t-[1px]">
                <TableCell>{index + 1}</TableCell>
                <TableCell className="mb-2 flex">
                  <div className="absolute text-lg text-blue-500">{property.loanDocumentsCount}</div>
                  <div className="relative">
                    <DocumentIcon />
                  </div>
                </TableCell>
                {renderTableCell('', property.address)}
                {renderTableCell('', formatCurrency(opportunity?.opportunityProperties.map((property) => property.adjustedMAOB)[index] || 0), property.id, 'adjustedMAOB')}
                {renderTableCell('', formatCurrency(Number(property.estimatedValue)), property.id, 'AIV')} {/* As Is Value */}
                {renderTableCell('', formatCurrency(property.estimated_mortgage_balance))}
                {renderTableCell('', formatCurrency(Number(property.firstLienPostionMortgageOrginationAmount)))}
                {renderTableCell('', property.firstLienPositionLoanOriginationLTV !== null ? `${property.firstLienPositionLoanOriginationLTV}%` : '--')}
                {renderTableCell('', property.firstLienPositionLoanOriginationLTV !== null ? formatCurrency(Number(property.firstLienPositionLoanOriginationAIV)) : '--')}
                {renderTableCell('', formatCurrency(property.assessedValue))}
                {renderTableCell('', formatCurrency(Number(property.totalfirstLienPositionPayoffBalance)))}
                {renderTableCell('', property.firstLienPostionMortgageLoanType || '--')}
                {renderTableCell(
                  '',
                  property.firstLienPositionMortgageLoanTerm !== null ? `${property.firstLienPositionMortgageLoanTerm} ${property.firstLienPositionLoanTermType}` : '--',
                )}
                {renderTableCell('', property.borrower_calc)}
                {renderTableCell('', property.recordingDate.split(' ')[0])}
                {renderTableCell('', 'FIRST')}
                {renderTableCell('', property.taxLien === '1' ? 'YES' : 'NO')}
                {renderTableCell('', '--')}
                {renderTableCell('', '--')}
                {renderTableCell('', '--')}
                {renderTableCell('', property.foreclosure_document_type)}
                {renderTableCell('', property.defaultRecordingDate !== 'null' ? getTimeInDefault(property.defaultRecordingDate || '') : '--')}
                {renderTableCell('', property.assessedYear)}
                {renderTableCell('', property.apn)}
                <TableCell className="text-base text-customGray929DAD">{property.assetClass}</TableCell>
                <TableCell>
                  <button className="text-black dark:text-white" onClick={() => onDetailsClick && onDetailsClick(property.id)}>
                    <ChevronRight />
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default PropertyDealsSellerTable;
