import { Button } from "@/components/ui/button";
import { SlidersHorizontal } from "lucide-react";

interface FilterButtonProps {
  onFilterButtonClick: () => void;
}
const FilterButton: React.FC<FilterButtonProps> = ({ onFilterButtonClick }) => {
  return (
    <Button className="rounded bg-customGrayEAEAEA dark:bg-customDark131923" onClick={() => onFilterButtonClick()}>
      <SlidersHorizontal className="bg-customGrayEAEAEA dark:bg-customDark131923"/>
    </Button>
  );
};

export default FilterButton;