import "./Deals.css";
import { useEffect, useState } from "react";
import PropertyDealsSellerTable from "./PropertyDealsSellerTable";
import PropertyDetailsModal from "./PropertyDetailsModal";
import useStore from "@/store";
import DealsMobile from "../deals/DealsMobile";
import DealsSellerDesktop from "../deals/DealsSellerDesktop";
// import SearchIcon from "@/components/icons/SearchIcon";
import { OpportunityQueryKeys, PropertyDealDetailsQueryKeys, useDealDetails, useOffers } from "./hooks/"; //useUpdatePropertyDealAttributes
import useProgressBar from "@/hooks/useProgressBar";
import { useQueryClient } from "react-query";
// import DealsBanner from "./DealsBanner";
import { OpportunityListViewModel, OpportunityPageToken } from "./view-models/OpportunityListViewModel";
// import PaginationComponent from "@/components/pagination-component/PaginationComponent";
import { toast } from "react-toastify";
import { DealStatusType } from "./types";
import QueryBuilderModalComponent, { } from "../../common/QueryBuilderModalComponent";
import PricingModalComponent from "../../common/PricingModalComponent";
// import { FilterIcon } from "lucide-react";
// import PricingIcon from "@/components/icons/PricingIcon";
import { OpportunityViewModel } from "./view-models/OpportunityViewModel";
// import Logo from '../../../branding/Logo';
import { OpportunityData } from "./view-models/CarouselCardViewModel";
// import { set } from "date-fns";
// import { useAuth } from "@/components/AuthProvider";

interface DealsSellerProps {
    status: DealStatusType;
    useDynamicPaging?: boolean;
}

export const LIMIT_FILTER_PREVIEW = 5;

const DealsSeller = ({ status }: DealsSellerProps) => {
    const [activeDealIndex, setActiveDealIndex] = useState(0);
    const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
    const [page, setPage] = useState(1);
    const [token, setToken] = useState<OpportunityPageToken>();
    const setRenderMiddleHeaderContent = useStore((state) => state.setRenderMiddleHeaderContent);
    const queryClient = useQueryClient();
    const cachedOpportunities = queryClient.getQueryData([OpportunityQueryKeys, page]);
    const { data: opportunityData, error: opportunityError, isLoading: isLoadingOpportunities } = useOffers(page, token, !cachedOpportunities, status);
    const { data: propertyDetails, error: propertyDetailsError } = useDealDetails(selectedPropertyId);
    // const { data: bannerDetails, error: bannerDetailsError, isLoading: isLoadingBannerDetails } = useBannerDetails();

    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
    const [isOpenQueryModal, setIsOpenQueryModal] = useState(false);
    const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
    const [renderProgressBarUI, showProgressBar, hideProgressBar] = useProgressBar();

    useEffect(() => {
        if (!isLoadingOpportunities) {
            hideProgressBar();
        }
    }, [isLoadingOpportunities]);

    useEffect(() => {
        // Define the middle content
        const middleContent = (): JSX.Element => (
            <>
                {/* <div className="items-center w-full hidden md:flex">
                    <div className="flex items-center w-1/2">
                        <h1 className="title text-4xl mr-5">Opportunities</h1>
                    </div>
                    <div className="w-1/2">
                        <div className="flex w-full items-center justify-end">
                            <div className=""><button className="btn" onClick={() => setIsOpenPricingModal(true)}><PricingIcon /></button></div>
                            <div className="pl-5"><button className="btn" onClick={() => setIsOpenQueryModal(true)}><FilterIcon /></button></div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="flex items-center w-full md:hidden">
                    <div><button className="btn"><SearchIcon /></button></div>
                    <div className="flex flex-col items-center justify-center w-full">
                        <div className="items-center justify-center">
                            <Logo className="mr-auto w-[82px] lg:w-[109px] h-auto lg:-translate-x-3 ml-0 lg:ml-auto mt-2 mb-3" />
                        </div>
                        <div className="font-medium text-sm flex flex-col items-center justify-center w-[90%] whitespace-nowrap">07/17/24-07/31/24</div>
                    </div>
                </div> */}
            </>
        );

        // Set the middle content in the store
        setRenderMiddleHeaderContent(middleContent);
    }, [setRenderMiddleHeaderContent]);

    useEffect(() => {
        if (propertyDetails) {
            hideProgressBar();
            setIsOpenDetailsModal(true);
        }
    }, [propertyDetails]);

    useEffect(() => {
        if (opportunityData) {
            hideProgressBar();

            const opportunityList = opportunityData.opportunities || [];

            const isValidPageToken = !!opportunityData.pageToken && !!opportunityData.pageToken.opportunityID;
            setHasMoreItems(isValidPageToken);

            if ((page === 1 && opportunityList.length === 0)
                || opportunityList.some(o => opportunities.some(ol => ol.opportunityId === o.opportunityId))) {
                setOpportunities([...opportunityList]);
                setActiveDealIndex(0);
            } else if (opportunityList.length > 0) {
                setOpportunities([...opportunities, ...opportunityList]);
            }
        }
    }, [opportunityData]);

    useEffect(() => {
        const error = opportunityError || propertyDetailsError;

        if (error) {
            hideProgressBar();

            console.error(error);
            toast.error(error?.message || "An error occurred");
        }
    }, [opportunityError, propertyDetailsError]);

    const onViewPropertyDetailsHandler = (propertyId: string) => {
        console.log('onViewPropertyDetailsHandler v2: ', propertyId);
        setSelectedPropertyId(propertyId);
        const cachedPropertyDetails = queryClient.getQueryData([PropertyDealDetailsQueryKeys, propertyId]);
        if (cachedPropertyDetails === undefined) {
            showProgressBar();
        } else {
            setIsOpenDetailsModal(true);
        }
    };

    const onPageChangeHandler = (page: number) => {
        const cachedOpportunities = queryClient.getQueryData<OpportunityListViewModel>([OpportunityQueryKeys, status, page]);

        // If data is already cached, do not fetch again
        if (cachedOpportunities !== undefined) {
            setToken(cachedOpportunities.pageToken);
            setPage(page);
            return;
        }

        if (opportunityData && opportunityData.pageToken && hasMoreItems) {
            setToken(opportunityData.pageToken);
        }

        hideProgressBar(); // Hide progress bar when next set of data is loaded
        setPage(page);
    };

    const handleSlideChange = (index: number) => {
        setActiveDealIndex(index);
        console.log("activeDealIndex ...", activeDealIndex);

        // Load more data if the last item is reached
        if (hasMoreItems && index === opportunities.length - 4) {
            onPageChangeHandler(page + 1);
        }
    };

    const onUpdateDealsDesktopValue = (opportunityId: string, newValues: Record<string, number>) => {
        console.log("onUpdateDealsDesktopValue: ", newValues);

        const clonedOpportunities = [...opportunities];
        const updatedOpportunity = clonedOpportunities.find(o => o.opportunityId === opportunityId);

        if (!updatedOpportunity) {
            console.error("Opportunity not found");
            return;
        }

        if (newValues.adjustedOpportunityMAOB) {
            updatedOpportunity.carouselCard.opportunityData.adjustedOpportunityMAOB = newValues.adjustedOpportunityMAOB;
        }

        if (newValues.discountPercentMAOB) {
            updatedOpportunity.carouselCard.opportunityData.discountPercentMAOB = newValues.discountPercentMAOB;
        }

        setOpportunities(clonedOpportunities);
    };

    const handleUpdateTableValue = (opportunityId: string, newValue: OpportunityData) => {
        const clonedOpportunities = [...opportunities];
        const updatedOpportunity = clonedOpportunities.find(o => o.opportunityId === opportunityId);

        if (!updatedOpportunity) {
            console.error("Opportunity not found");
            return;
        }

        updatedOpportunity.carouselCard.opportunityData = newValue;
        setOpportunities(clonedOpportunities);
    };

    return (
        <>
            {renderProgressBarUI()}
            <div className="w-full flex-col gap-5 deals-container hidden md:flex">

                {/* {bannerDetails && <DealsBanner details={bannerDetails} />} */}

                {
                    opportunities.length === 0 && !isLoadingOpportunities && (
                        <div className="flex justify-center items-center h-[300px]">
                            <p className="text-xl">No opportunities found</p>
                        </div>
                    )
                }

                {
                    isOpenPricingModal && <PricingModalComponent
                        isOpen={isOpenPricingModal}
                        toggleModal={() => setIsOpenPricingModal(!isOpenPricingModal)}
                        showProgressBar={showProgressBar}
                        hideProgressBar={hideProgressBar}
                    />
                }

                {
                    isOpenQueryModal && <QueryBuilderModalComponent
                        isOpen={isOpenQueryModal}
                        toggleModal={() => setIsOpenQueryModal(!isOpenQueryModal)}
                        showProgressBar={showProgressBar}
                        hideProgressBar={hideProgressBar}
                    />
                }
                <div className="w-full flex justify-center items-center ">
                  <div className="w-full flex items-center justify-between">
                    <DealsSellerDesktop 
                        properties={opportunities[activeDealIndex]?.carouselCard.assetClass || []}
                        opportunities={undefined}   
                        lenderName={opportunities[activeDealIndex]?.carouselCard?.lenderName || 'No company name listed'}                 
                        initialOpportunities={opportunities}
                        onSlideChange={handleSlideChange}
                        hideProgressBar={hideProgressBar}
                        showProgressBar={showProgressBar}
                        onUpdateValue={onUpdateDealsDesktopValue}
                        />
                  </div>
                </div>
                <div>
                    <div className="flex justify-between items-center">
                        <h3 className="font-semibold text-black dark:text-white text-xl my-5">Property Details</h3>
                        {/* {opportunities[activeDealIndex] && opportunities[activeDealIndex].carouselCard?.lenderName} -  */}
                    </div>
                    {
                        opportunities[activeDealIndex] && <PropertyDealsSellerTable
                            onDetailsClick={onViewPropertyDetailsHandler}
                            properties={opportunities[activeDealIndex].properties}
                            opportunity={opportunities[activeDealIndex].carouselCard.opportunityData}
                            onUpdateValue={handleUpdateTableValue}
                            showProgressBar={showProgressBar}
                            hideProgressBar={hideProgressBar} />
                    }
                </div>
                {
                    opportunities[activeDealIndex]?.properties?.length > 0 && (
                        <PropertyDetailsModal
                            isOpen={isOpenDetailsModal}
                            toggleModal={() => setIsOpenDetailsModal(!isOpenDetailsModal)}
                            property={opportunities[activeDealIndex].properties.find(p => p.id === selectedPropertyId)}
                            details={propertyDetails}
                        />
                    )
                }
            </div>
            <div className="w-full flex-col gap-5 deals-container flex md:hidden">
                <DealsMobile />
            </div>
        </>
    );
}

export default DealsSeller;