import React, { useState } from 'react';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';

const UnAuthorized = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogout = () => {
    setIsSubmitting(true);
    navigate('/logout', { replace: true });
  };

  return (
    <React.Fragment>
      <div className="m-auto text-center">
        <img className="m-auto" src="/images/error_403.svg" alt="Access Forbidden" />
        <h2 className="mt-5 text-2xl xl:block 2xl:text-3xl">Access Forbidden</h2>

        <Button type="submit" variant="default" className="mt-4 bg-[#08163e] text-white hover:bg-[#08163e]" disabled={isSubmitting} onClick={() => handleLogout()}>
          <span className="login-bt-text">Logout</span>
        </Button>
      </div>
    </React.Fragment>
  );
};

export default UnAuthorized;
