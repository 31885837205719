import "./DealsDesktop.css";
import CarouselComponent from '../../common/CarouselComponent';
import LocationDotsMap from "../../deals/LocationDotsMap";
import ProgressComponent from "../../common/ProgressComponent";
import BlueCircleCheckIcon from "@/components/icons/BlueCircleCheckIcon";
import BlueCircleXIcon from "@/components/icons/BlueCircleXIcon";
import SemiCircleChart from "../../charts/SemiCircleChart";
import CircleChart from "../../charts/CircleChart";
import NumberDisplay from "../../common/NumberDisplay";
import { formatCurrencyShort } from "@/lib/utils";
import { OpportunityViewModel } from "./view-models/OpportunityViewModel";
import { UpdateOpportunityPricingInput, UpdateOpportunityStatusInput, UpdateOpportunityStatusResponse } from "./types"; //DealStatusType,
import { ReactNode, useEffect, useState } from "react";
import { DEAL_STATUS } from "./enum";
import { useAuth } from "@/components/AuthProvider";
import BlueFillThumbDownIcon from "@/components/icons/BlueFillThumbDownIcon";
import BlueFillThumbUpIcon from "@/components/icons/BlueFillThumbUpIcon";
import BlueThumbDownIcon from "@/components/icons/BlueThumbDownIcon";
import BlueThumbUpIcon from "@/components/icons/BlueThumbUpIcon";
import { useOpportunityStatusUpdate, useUpdateOpportunityPricing } from "./hooks";
import SliderComponent from "../../common/SliderComponent";
import CarouselCardActionMenu from "./CarouselCardActionMenu";
import { toast } from "react-toastify";
import EditableField, { UpdatedOpportunityField } from "../../common/EditableField";
import { Pencil } from "lucide-react";

interface DealsDesktopProps {
  initialOpportunities: OpportunityViewModel[];
  onSlideChange?: (index: number) => void;
  hideProgressBar?: () => void;
  showProgressBar?: () => void;
  onUpdateValue?: (opportunityId: string, values: Record<string, number>) => void;
}

export interface OpportunityCalculations {
  discountPercentMAOB: number;
  adjustOpportunityMAOB: number;
  opportunityProperties: Object;
  profit: number;
  opportunityNetProfitMargin: number;
  percentage: number;
}

const DealsDesktop: React.FC<DealsDesktopProps> = ({
  initialOpportunities,
  onSlideChange,
  showProgressBar,
  hideProgressBar,
  onUpdateValue }) => {
  const colorList = [
    "#ceecf9",
    "#1d2634",
    "#08a0e2",
    "#d2d4d6",
    "#84d0f1",
    "#dddddd",
    "#0000FF", // Blue
    "#FFFF00", // Yellow
    "#FFA500", // Orange
    "#FF0000", // Red
    "#00FF00", // Green
    "#800080", // Purple
    "#00FFFF", // Cyan
    "#FF00FF", // Magenta
    "#00FF00", // Lime
    "#FFC0CB", // Pink
    "#A52A2A", // Brown
    "#808080", // Gray
    "#808000", // Olive
  ];

  const MAX_DISCOUNT = 0.3; // 30%

  const activeIcons: { [key: string]: React.ReactNode } = {
    [DEAL_STATUS.Rejected]: <BlueFillThumbDownIcon />,
    [DEAL_STATUS.InReview]: <BlueFillThumbUpIcon />
  };

  const inactiveIcons: { [key: string]: React.ReactNode } = {
    [DEAL_STATUS.Rejected]: <BlueThumbDownIcon />,
    [DEAL_STATUS.InReview]: <BlueThumbUpIcon />
  };

  const [opportunityStatuses, setOpportunityStatuses] = useState<UpdateOpportunityStatusResponse[]>([]);
  const [oppDiscountPercentMAOBMap, setOppDiscountPercentMAOBMap] = useState<Record<string, number>>({});
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const [editedMAOBList, setEditedMAOBList] = useState<UpdatedOpportunityField[]>([]);
  const { mutate: updateOpportunityStatus, error: updatedStatusError, isLoading: isUpdatingOpportunityStatus } = useOpportunityStatusUpdate();
  const { mutate: updateOpportunityPricing, error: updateOpportunityPricingError, isLoading: isUpdatingOpportunityPricing } = useUpdateOpportunityPricing();

  const auth = useAuth();

  useEffect(() => {
    if (initialOpportunities) {
      setOpportunities([...initialOpportunities]);
      const initialOpportunityStatuses = initialOpportunities.map(opportunity => ({
        status: opportunity.status,
        opportunityId: opportunity.opportunityId,
      }));

      setOpportunityStatuses(initialOpportunityStatuses);

      const initialDicountPercentMAOBMap: Record<string, number> = {};
      initialOpportunities.forEach(opportunity => {
        if (oppDiscountPercentMAOBMap[opportunity.opportunityId] === undefined) {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = calculateDiscountPercentForSlider(opportunity.carouselCard.opportunityData.discountPercentMAOB);
        } else {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = oppDiscountPercentMAOBMap[opportunity.opportunityId];
        }
      });

      setOppDiscountPercentMAOBMap(initialDicountPercentMAOBMap);
    }
  }, [initialOpportunities]);

  useEffect(() => {
    const error = updatedStatusError || updateOpportunityPricingError;
    if (!error) return;

    if (updatedStatusError) {
      // remove the last added opportunity status
      const updatedOpportunityStatuses = opportunityStatuses.slice(0, opportunityStatuses.length - 1);
      setOpportunityStatuses(updatedOpportunityStatuses);
    }

    toast.error("An error occurred while updating the opportunity status. Please try again later.");
    hideProgressBar && hideProgressBar();
  }, [updatedStatusError, updateOpportunityPricingError]);

  useEffect(() => {
    if (opportunities.length > 0 && !isUpdatingOpportunityStatus && !isUpdatingOpportunityPricing) {
      hideProgressBar && hideProgressBar();
    } else {
      showProgressBar && showProgressBar();
    }
  }, [isUpdatingOpportunityStatus, isUpdatingOpportunityPricing]);

  const opportunityStatusChangeHandler = (opportunity: OpportunityViewModel, newStatus: DEAL_STATUS): void => {
    if (!auth
      || !opportunity) return;

    const latestStatus = opportunity.status === newStatus ? DEAL_STATUS.Open : newStatus;
    const isConfirmed = window.confirm(`Are you sure you want to add this to ${latestStatus} items?`);

    if (!isConfirmed) return;

    const cachedOpportunityStatus = { status: latestStatus, opportunityId: opportunity.opportunityId };
    const updatedOpportunityStatuses = [...opportunityStatuses];

    // Find the index of the existing opportunity status
    const index = updatedOpportunityStatuses.findIndex(
      (status) => status.opportunityId === cachedOpportunityStatus.opportunityId
    );

    if (index !== -1) {
      // Update the existing status
      updatedOpportunityStatuses[index].status = cachedOpportunityStatus.status;
    } else {
      // Add the new status
      updatedOpportunityStatuses.push(cachedOpportunityStatus);
    }

    setOpportunityStatuses(updatedOpportunityStatuses);
    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth.sub,
      totalProperties: opportunity.properties.length,
      newStatus: latestStatus
    };

    updateOpportunityStatus(updatedOpportunity);
  };

  const renderThumbUpDownIcon = (opportunity: OpportunityViewModel, status: string): ReactNode => {
    const opportunityStatus = opportunityStatuses.find(s => s.opportunityId === opportunity.opportunityId)?.status || opportunity.status;
    if (opportunityStatus === status) {
      return activeIcons[status];
    }

    return inactiveIcons[status];
  };

  const handleSliderValueChange = (opportunity: OpportunityViewModel, value: number) => {
    const { opportunityID, opportunityMAOB, opportunityMAOS } = opportunity.carouselCard.opportunityData;
    // console.log("opportunityId " + opportunityID)
    // console.log("value " + value)
    const newOppDiscountPercentMAOBMap = { ...oppDiscountPercentMAOBMap };
    newOppDiscountPercentMAOBMap[opportunityID] = value;
    setOppDiscountPercentMAOBMap(newOppDiscountPercentMAOBMap);

    const metric = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, value);

    const updatedValues = { "adjustedOpportunityMAOB": metric.adjustOpportunityMAOB };
    onUpdateValue && onUpdateValue(opportunityID, updatedValues);
  };

  const handleMenuItemClick = (action: string, opportunity: OpportunityViewModel) => {
    if (!auth) {
      toast.error('User not authenticated');
      return;
    }

    console.log(`Menu item clicked: ${action}`);
    // Handle the action accordingly
    switch (action) {
      case 'edit':
        // Handle edit action
        break;
      case 'save':
        const {
          opportunityMAOS,
          opportunityMAOB,
          opportunityLeveragesMAOS,
          opportunityProperties,
          premiumPercentMAOS,
          opportunityAIV,
          opportunityUPB,
          opportunityAAO
        } = opportunity.carouselCard.opportunityData;
        const metric = calculateOpportunityMetrics(
          opportunityMAOB,
          opportunityMAOS,
          oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);
        // const savedSliderValue = (discountPercentMAOB * 0.333) * 100; 
        console.log("opportunityProperties ... " + JSON.stringify(opportunityProperties));
        console.log("discountPercentMAOB ... " + metric.discountPercentMAOB)

        // console.log("new value AIV2 "+ (calcMOAB) / (opportunity.carouselCard.opportunityData.opportunityLeveragesMAOB.AIV))

        const newOpportunityMAOB = {
          AIV: Number(((metric.adjustOpportunityMAOB) / (opportunityAIV)).toFixed(2)),
          UPB: Number(((metric.adjustOpportunityMAOB) / (opportunityUPB)).toFixed(2)),
          AAO: Number(((metric.adjustOpportunityMAOB) / (opportunityAAO)).toFixed(2)),
        };

        // console.log("new value newopp " + JSON.stringify(newOpportunityMAOB));
        // console.log("new value slider "+ JSON.stringify(sliderValue))
        // console.log("new value premiumPercentMAOS " + premiumPercentMAOS)
        // console.log("new value opportunityMAOB " + Number(calcMOAB.toFixed(2)))
        // console.log("new value opportunityMAOS " + opportunityMAOS)
        // console.log("new value opportunityCOCR " + newPercentage)
        // console.log("new value oppDiscountPercentMAOBMap[opportunity.opportunityId] " + JSON.stringify(oppDiscountPercentMAOBMap[opportunity.opportunityId]))
        // console.log("new value opportunityLeveragesMAOB " + JSON.stringify(newOpportunityMAOB))
        // console.log("new value opportunityNetProfitMargin " + opportunityNetProfitMargin)


        // Handle save action
        const input: UpdateOpportunityPricingInput = {
          dicountPercentMAOB: metric.discountPercentMAOB,
          premiumPercentMAOS,
          adjustedOpportunityMAOB: metric.adjustOpportunityMAOB,
          adjustedOpportunityMAOS: opportunityMAOS,
          opportunityCOCR: metric.percentage,
          opportunityLeveragesMAOS,
          opportunityLeveragesMAOB: newOpportunityMAOB,
          opportunityNetProfitMargin: metric.opportunityNetProfitMargin,
        };

        updateOpportunityPricing({
          input,
          opportunityID: opportunity.opportunityId,
          totalProperties: opportunity.properties.length,
          cognitoID: auth.sub,
        });
        break;
      case 'pricing':
        // Handle pricing action
        break;
      default:
        break;
    }
  };

  const handleEditMAOBClick = (e: React.MouseEvent, opportunity: OpportunityViewModel, field: string, value: number) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation
    const editedMAOBItem = new UpdatedOpportunityField(opportunity.opportunityId, field, value);
    const newEditedMAOBList = [...editedMAOBList];
    newEditedMAOBList.push(editedMAOBItem);
    setEditedMAOBList(newEditedMAOBList);
  };

  const handleSaveMAOBClick = (newItem: UpdatedOpportunityField, opportunity: OpportunityViewModel) => {
    const editedMAOB = editedMAOBList.find(item => item.id === newItem.id);
    if (!editedMAOB) return;

    const newAdjustOpportunityMAOB = newItem.value;

    const { adjustedOpportunityMAOB, opportunityMAOB } = opportunity.carouselCard.opportunityData;

    let updatedValues: Record<string, number> = {};

    if (newAdjustOpportunityMAOB >= opportunityMAOB) {
      updatedValues["discountPercentMAOB"] = ((newAdjustOpportunityMAOB / opportunityMAOB) - 1) * (-1)
      // console.log("here " + updatedValues["discountPercentMAOB"])
    } else {
      updatedValues["discountPercentMAOB"] = 1 - newAdjustOpportunityMAOB / opportunityMAOB
      // console.log("here " + updatedValues["discountPercentMAOB"])
    }

    // Update opportunities
    if (adjustedOpportunityMAOB !== newAdjustOpportunityMAOB) {
      updatedValues["adjustedOpportunityMAOB"] = newAdjustOpportunityMAOB;
      // console.log("here adj " + updatedValues["adjustedOpportunityMAOB"])
    }

    const sliderDiscountPercentMAOBMap = { ...oppDiscountPercentMAOBMap };
    sliderDiscountPercentMAOBMap[newItem.id] = calculateDiscountPercentForSlider(updatedValues["discountPercentMAOB"]);
    setOppDiscountPercentMAOBMap(sliderDiscountPercentMAOBMap);

    onUpdateValue && onUpdateValue(newItem.id, updatedValues);

    // Save the edited values
    setEditedMAOBList(editedMAOBList.filter(item => item.id !== newItem.id));
  };

  const handleDiscardMAOBClick = (opportunity: OpportunityViewModel) => {
    setEditedMAOBList(editedMAOBList.filter(item => item.id !== opportunity.opportunityId));
  };

  const calculateDiscountPercentForSlider = (discountPercentMAOB: number): number => {
    // console.log("discountPercent here ... " + (1 - (discountPercentMAOB / MAX_DISCOUNT)) * 100)
    return (1 - (discountPercentMAOB / MAX_DISCOUNT)) * 100;
  }

  const calculateOpportunityMetrics = (
    opportunityMAOB: number,
    opportunityMAOS: number,
    sliderDiscountPercent: number,
  ): OpportunityCalculations => {
    const sliderValue = Number((sliderDiscountPercent / 100));
    let discountPercentMAOB;
    if (sliderValue > 1) {
      discountPercentMAOB = (1 - sliderValue) * MAX_DISCOUNT;
    } else {
      discountPercentMAOB = Math.abs((1 - sliderValue) * MAX_DISCOUNT);
    }
    // console.log("opportunityMAOB here ... " + opportunityMAOB)
    const adjustedMAOB = opportunityMAOB * (1 - discountPercentMAOB); // Bluebutton $
    if (discountPercentMAOB < 0) {

    }
    const profit = opportunityMAOS - adjustedMAOB;
    const opportunityNetProfitMargin = Number((profit / adjustedMAOB).toFixed(2)); // saved value
    const percentage = Number(((profit / (adjustedMAOB * 0.2)) * 100).toFixed(2)); // 3260000 profit / 1670000*0.2 = 33400 
    const calcMOAB = opportunityMAOB * ((1 - MAX_DISCOUNT) + sliderValue * MAX_DISCOUNT); //

    // console.log("profit " + profit)
    // console.log("percentage " + percentage)
    // console.log("calcMOAB " + calcMOAB)
    // console.log("calculateOpportunityMetrics " + organizationId + " " + " " + " sliderDiscountPercent =" + sliderDiscountPercent + " discountPercentMAOB " + discountPercentMAOB + " adjustOpportunityMAOB =" + calcMOAB)
    // console.log("discountPercentMAOB " + discountPercentMAOB)
    // console.log("adjustedMAOB "+ adjustedMAOB)
    // console.log("opportunityMAOS "+ opportunityMAOS)
    // console.log("opportunityMAOB " + opportunityMAOB)
    // console.log("profit "+ profit)
    // console.log("adjustOpportunityMAOB "+ calcMOAB)
    // console.log("percentage "+ percentage)

    return {
      discountPercentMAOB: Number(discountPercentMAOB),
      opportunityProperties: {},
      adjustOpportunityMAOB: calcMOAB,
      profit,
      opportunityNetProfitMargin,
      percentage
    };
  }

  const handleSubmitOfferClick = (e: React.MouseEvent, opportunity: OpportunityViewModel) => {
    e.preventDefault();
    if (!confirm("Are you sure you want to submit the offer?")) {
      return;
    }

    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth!.sub,
      totalProperties: opportunity.properties.length,
      newStatus: DEAL_STATUS.OfferMade
    };

    updateOpportunityStatus(updatedOpportunity);
  }

  return (
    <div className="2xl:h-[660px] xl:h-[690px] ">
      <CarouselComponent onSlideChange={onSlideChange}>
        {
          opportunities.map((opportunity, index) => {
            const {
              totalUPB,
              locations,
              lenderName,
              totalTaxLiens,
              totalVolume,
              totalAmount,
              countOfAssetClassMix,
              countOfBorrower,
              TotalDealFirstLienPositionOriginationAmount,
              totalProperties,
              opportunityData,
            } = opportunity.carouselCard;
            const assetClassMixKeys = Object.keys(countOfAssetClassMix);
            const colorMapAssetClassMix = assetClassMixKeys.map((key, index) => ({
              color: colorList[index % colorList.length],
              description: key
            }));

            // console.log("locations "+ JSON.stringify(locations))

            // Transform countOfBorrower to the expected array format
            const borrowerArray = Object.entries(countOfBorrower).map(([name, count]) => ({
              name,
              count
            }));

            const borrowerNames = borrowerArray.map(borrower => borrower.name);
            const colorMapBorrower = borrowerNames.map((key, index) => ({
              color: colorList[index % colorList.length],
              description: key
            }));

            const reformattedCountOfBorrower = borrowerArray.map(borrower => borrower.count);

            // console.log(" " + JSON.stringify(opportunities))
            const countOfAssetClassMixValues = Object.values(countOfAssetClassMix);

            const {
              opportunityMAOS,
              opportunityMAOB,
              opportunityAIV,
              opportunityUPB,
              opportunityAAO,
              adjustedOpportunityMAOB,
            } = opportunityData;

            const { percentage, adjustOpportunityMAOB } = calculateOpportunityMetrics(
              opportunityMAOB,
              opportunityMAOS,
              oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);

            // const sliderValue = (oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0) / 100;
            // const profit = opportunityMAOS - adjustedMAOB;
            // const percentage = ((profit / (adjustedMAOB * 0.2)) * 100).toFixed(0);
            // console.log("opportunity.opportunityId " + opportunity.opportunityId)
            const blueButtonValue = adjustOpportunityMAOB;
            const editedMAOB = editedMAOBList.find(item => item.id === opportunity.opportunityId);

            return (
              <div className="carousel-slide px-5 2xl:px-8 pt-3 pb-5" key={`opportunity-${opportunity.opportunityId}`}>
                <div className="w-full flex justify-start">
                  <div className="min-w-8 min-h-8 border text-stone-600 flex items-center justify-center rounded-full">{index + 1}</div>
                </div>
                <div className="flex">
                  <div className="w-[32%] 2xl:w-[30%] mr-5 mt-5">
                    <div className="w-full flex">
                      <div className="w-1/2 mr-3">
                        <p className="uppercase thin-title text-xs mb-5"><span className="">TOTAL ORIGINATION AMOUNT</span></p>
                      </div>
                      <div className="w-1/2">
                        <p className="uppercase thin-title text-xs mb-5">UNPAID PRINCIPAL<br /><span className="whitespace-nowrap">BALANCE (UPB)</span></p>
                      </div>
                    </div>
                    <div className="w-full flex">
                      <div className="w-1/2 mr-3">
                        <p className="estimated-value-row-wrapper text-sm 2xl:text-base mb-5">
                          <span className="text-[#1d2634]">{formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0} - {formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0}</span>{/*estimatedAssetValue is totalassetvalue*/}
                          <span className="text-[#929dad] leading-[10px]"></span>
                        </p>
                      </div>
                      <div className="w-1/2">
                        <p className="estimated-value-row-wrapper text-sm 2xl:text-base mb-5">
                          <span className="text-[#1d2634]">{formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0} - {formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0}</span>
                        </p>
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="uppercase thin-title text-xs mt-8 mb-10">LOCATIONS</p>
                      <div className="max-h-[238px]"><LocationDotsMap locations={locations} /></div>
                    </div>
                  </div>
                  <div className="w-[40%]">
                    <div className="center-card">
                      <div className="2xl:p-5 xl:p-3 center-card-wrapper">
                        <div className="flex items-center justify-around">
                          <div className="w-[90%]"><p className="text-[#1d2634] desktop-opportunity-title">{lenderName}</p></div>
                          <div className="w-[20px]">
                            <CarouselCardActionMenu onItemClick={(action) => handleMenuItemClick(action, opportunity)} />
                          </div>
                        </div>
                        <div className="flex justify-between mt-2">
                          <button
                            className="btn"
                            onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.Rejected)}>
                            {
                              renderThumbUpDownIcon(opportunity, DEAL_STATUS.Rejected)
                            }
                          </button>
                          {/* <div className="text-white pill-wrapper xl:w-[70%] h-8">
                            <span className="font-bold">{formatCurrencyShort({ amount: adjustedMAOB, decimals: 2 })}</span>
                            <span className="font-light mx-1">|</span>
                            <span className="font-bold">{percentage}%</span>
                          </div> */}
                          <div
                            className="text-white pill-wrapper xl:w-[70%] h-8 flex items-center cursor-pointer"
                            onClick={(e) => !editedMAOB && handleSubmitOfferClick(e, opportunity)}>
                            {editedMAOB ? (
                              <>
                                <EditableField
                                  initialValue={editedMAOB}
                                  onSave={(value) => handleSaveMAOBClick(value, opportunity)}
                                  onDiscard={() => handleDiscardMAOBClick(opportunity)}
                                />
                                <span className="font-light mx-1">|</span>
                                <span className="font-bold">{percentage}%</span>
                              </>
                            ) : (
                              <>
                                <div className="flex items-center">
                                  <span
                                    title="Click to edit"
                                    className="font-bold">
                                    {formatCurrencyShort({ amount: adjustedOpportunityMAOB, decimals: 2 })}
                                  </span>
                                  <span className="cursor-pointer">
                                    <Pencil onClick={(e) => handleEditMAOBClick(e, opportunity, "adjustedOpportunityMAOB", adjustedOpportunityMAOB)} className="ml-2 cursor-pointer w-4 h-4" />
                                  </span>
                                </div>
                                <span className="font-light mx-1">|</span>
                                <span className="font-bold">{percentage}%</span>
                              </>
                            )}
                          </div>
                          <button
                            className="btn"
                            onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.InReview)}>
                            {
                              renderThumbUpDownIcon(opportunity, DEAL_STATUS.InReview)
                            }
                          </button>
                        </div>
                        <div className="mt-5 flex">
                          <div className="w-[100%]">
                            <div className="w-[100%] 2xl:w-[100%] border border-gray-300 rounded-lg p-2 my-0">
                              <div className="flex items-center mt-3">
                                <div className="w-[10%] uppercase thin-title text-sm mb-3 flex">PP</div>
                                <div className="w-[90%]">
                                  {/* <SliderComponent 
                                    initialProgress={Number((opportunity.carouselCard.opportunityData.opportunityMAOS || 0).toFixed(2))} 
                                    fromLabel={formatCurrencyShort({ amount: (opportunity.carouselCard.opportunityData.opportunityMAOB * .7), decimals: 2 })} 
                                    toLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOB, decimals: 2 })} 
                                    onValueChange={handleSliderValueChange}
                                  />     */}
                                  {
                                    oppDiscountPercentMAOBMap[opportunity.opportunityId] !== undefined && (
                                      <SliderComponent
                                        initialProgress={oppDiscountPercentMAOBMap[opportunity.opportunityId]}
                                        fromLabel={formatCurrencyShort({ amount: (opportunityMAOB * .7), decimals: 2 })}
                                        toLabel={formatCurrencyShort({ amount: opportunityMAOB, decimals: 2 })}
                                        onValueChange={(value) => handleSliderValueChange(opportunity, value)}
                                      />
                                    )
                                  }
                                </div>
                              </div>
                              {/* <div className="mt-2 text-center text-sm">
                                  Slider Value: {sliderValue / 100}

                               ((((totalOfferPrice*0.7)-totalOfferPrice)*(sliderValue/100))+(totalOfferPrice*0.7))
                               {formatCurrencyShort({ amount: ((((totalOfferPrice*0.7)-totalOfferPrice)*(sliderValue/100))+(totalOfferPrice)), decimals: 2 })}

                              </div>                           */}
                              <div className="flex items-center">
                                <div className="w-[10%] uppercase thin-title text-sm mb-3">LEV</div>
                                <div className="w-[90%]">
                                  <div className="flex justify-evenly p-2 my-0">
                                    <div className="uppercase thin-title text-xs">
                                      <div className="flex text-sm">AIV</div>
                                      <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base mb-1">{((blueButtonValue) / (opportunityAIV) * 100).toFixed(0)}%</div>
                                      {/* {formatCurrencyShort({ amount: totalOfferPrice * (0.7 + (sliderValue / 100) * 0.3), decimals: 2 })} */}
                                    </div>
                                    <div className="uppercase thin-title text-xs">
                                      <div className="flex">UPB</div>
                                      <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base mb-1">{((blueButtonValue) / (opportunityUPB) * 100).toFixed(0)}%</div>
                                    </div>
                                    <div className="uppercase thin-title text-xs">
                                      <div className="flex">AAO</div>
                                      <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base mb-1">{((blueButtonValue) / (opportunityAAO) * 100).toFixed(0)}%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <ProgressComponent progress={Number((offerRange.percentage || 0).toFixed(2))} fromLabel={formatCurrencyShort({ amount: offerRange.min, decimals: 2 })} toLabel={formatCurrencyShort({ amount: offerRange.max, decimals: 2 })} /> */}
                            </div>
                          </div>
                          {/* <div className="2xl:w-[40%] xl:w-[50%]">
                            <div className="w-full flex flex-col">
                              <div className="uppercase thin-title text-xs mb-3">AS IS VALUE (AIV)</div>
                              <div className="text-base font-medium">{formatCurrencyShort({ amount: Number(totalmarketvalue), decimals: 2 }) || 0}-{formatCurrencyShort({ amount: totalmarketvalue, decimals: 2 }) || 0}</div>
                            </div>
                          </div> */}{/*estimatedAssetValue is totalassetvalue*/}
                        </div>
                        <div className="flex">
                          <div className="w-[100%]">
                            <div className="w-[100%] 2xl:w-[100%] flex flex-col border border-gray-300 rounded-lg p-2 mt-5">
                              <div className="flex items-center mt-3">
                                <div className="w-[10%] uppercase thin-title text-sm mb-3 flex">SP</div>
                                <div className="w-[90%]">
                                  <ProgressComponent
                                    progress={Number((0).toFixed(2))}
                                    fromLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOS, decimals: 2 })}
                                    toLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOS + opportunity.carouselCard.opportunityData.opportunityMAOS * .3, decimals: 2 })} />
                                </div>
                              </div>
                              <div className="flex items-center">
                                <div className="w-[10%] uppercase thin-title text-sm mb-3">LEV</div>
                                <div className="w-[90%]">
                                  <div className="flex justify-evenly p-2 my-0">
                                    <div className="uppercase thin-title text-xs">
                                      <div className="flex">AIV</div>
                                      <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base mb-1">{(opportunity.carouselCard.opportunityData.opportunityLeveragesMAOS.AIV * 100).toFixed(0)}%</div>
                                    </div>
                                    <div className="uppercase thin-title text-xs">
                                      <div className="flex">UPB</div>
                                      <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base mb-1">{(opportunity.carouselCard.opportunityData.opportunityLeveragesMAOS.UPB * 100).toFixed(0)}%</div>
                                    </div>
                                    <div className="uppercase thin-title text-xs">
                                      <div className="flex">AAO</div>
                                      <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base mb-1">{(opportunity.carouselCard.opportunityData.opportunityLeveragesMAOS.AAO * 100).toFixed(0)}%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-col md:flex-col mt-2">
                          {/* <div className="2xl:w-[55%] xl:w-[90%] lg:w-[70%] flex">
                            <div className="2xl:mr-3 xl:mr-5 xl:ml-3">
                              <div className="uppercase thin-title text-xs mb-3 mt-8">DEFAULT RATE</div>
                              <div className="flex items-start justify-around">
                                <div className="w-[90px] h-[80px] ">
                                  <CircleChart
                                    series={[100 - lenderDefaultRatePercentage, lenderDefaultRatePercentage]}
                                    title={`${lenderDefaultRatePercentage}%`}
                                    donutSize="70%"
                                    className="2xl:font-base xl:font-xs"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="2xl:w-[60%] lg:w-[30%] lg:ml-3">
                              <div className="uppercase thin-title text-xs mb-3 mt-8 2xl:mr-4 xl:mr-2">DEFAULT AMOUNT</div>
                              <div className="flex items-center 2xl:justify-center xl:justify-left">
                                <div className="2xl:w-[40%] xl:w-[30%] h-[60px]">
                                  <NumberDisplay topNumber={formatCurrencyShort({ amount: lenderDefaultRateAmount.min, decimals: 2 })} bottomNumber={formatCurrencyShort({ amount: lenderDefaultRateAmount.max, decimals: 2 })} className="font-light xl:text-sm lg:text-xs" />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="2xl:w-[100%] xl:ml-0 lg:px-5 ml-0">
                            <div className="flex justify-between">
                              <div className="flex flex-col items-center md:px-3">
                                <div className="uppercase thin-title text-xs 2xl:mb-3 xl:mb-2">TOTAL PROP</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="md:mb-2">{totalProperties}</div>
                              </div>
                              <div className="flex flex-col items-center md:px-3">
                                <div className="uppercase thin-title text-xs 2xl:mb-3 xl:mb-2">TAX LIEN <span className="2xl:visibility:visible xl:visibility:hidden;"></span></div>
                                {/* ({totalTaxLiens}) */}
                                <div className="md:mb-2">{totalTaxLiens !== 0 ? <BlueCircleCheckIcon /> : <BlueCircleXIcon />}</div>
                              </div>
                              <div className="flex flex-col items-center lg:px-3 md:px-3">
                                <div className="uppercase thin-title text-xs 2xl:mb-3 xl:mb-2 lg:mb-0">HOA LIEN</div>
                                <div className="md:mb-2"><BlueCircleXIcon /></div>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center items-center h-full">
                            <div className="2xl:w-1/3 xl:ml-0 justify-around pt-2">
                              <div className="flex items-start md:px-3 space-x-3 w-1/2">
                                <div className="flex uppercase thin-title text-xs justify-end pr-1">AIV</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base">{formatCurrencyShort({ amount: opportunityAIV, decimals: 2 }) || 0}</div>
                                </div>
                              </div>
                            </div>
                            <div className="2xl:w-1/3 xl:ml-0 lg:px-0 justify-around pt-2">
                              <div className="flex items-start md:px-3 space-x-3 w-1/2">
                                <div className="flex uppercase thin-title text-xs justify-end pr-1">AO</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base">{formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0}</div>
                                </div>
                              </div>
                            </div>
                            <div className="2xl:w-1/3 xl:ml-0 lg:px-0 justify-around pt-2">
                              <div className="flex items-start md:px-3 space-x-3 w-1/2">
                                <div className="flex uppercase thin-title text-xs justify-end pr-1">UPB</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="text-center text-sm font-bold estimated-value-row-wrapper 2xl:text-base">{formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[35%] 2xl:w-[30%] ml-8">
                    <div>
                      <div className="flex mt-5 flex-wrap">
                        <div className="w-1/2 uppercase thin-title text-xs">
                          LOANS: DEFAULT / TOTAL<br />
                          VOLUME LAST 24 MONTHS
                        </div>
                        <div className="w-1/2 uppercase thin-title text-xs">
                          LOANS: DEFAULT AMOUNT / TOTAL AMOUNT
                          LAST 24 MONTHS
                        </div>
                      </div>
                      <div className="flex mt-5 flex-wrap">
                        <div className="w-1/2">
                          <div className="w-full">
                            <SemiCircleChart
                              label={<NumberDisplay topNumber={totalVolume.min} bottomNumber={totalVolume.max} />}
                              series={[Number((totalVolume.percentage).toFixed(2))]} />
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="w-full">
                            <SemiCircleChart
                              label={<NumberDisplay topNumber={formatCurrencyShort({ amount: totalAmount.min, decimals: 2 })} bottomNumber={formatCurrencyShort({ amount: totalAmount.max, decimals: 2 })} />}
                              series={[Number((totalAmount.percentage).toFixed(2))]} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="items-center justify-center ">
                      <div className="flex mt-5 ">
                        <div className="w-1/2 uppercase items-center justify-center thin-title text-xs mb-1 ">
                          COUNT OF ASSET CLASS MIX
                        </div>
                        <div className="w-1/2 uppercase items-center justify-center thin-title text-xs lg:mb-2 ">
                          COUNT OF BORROWER
                        </div>
                      </div>
                      <div className="flex mt-5 flex-wrap">
                        <div className="w-1/2">
                          <div className="w-full">
                            <CircleChart className="2xl:w-[90%] xl:w-[80%] h-[127px] font-bold"
                              series={countOfAssetClassMixValues}
                              fillColors={colorMapAssetClassMix.map(item => item.color)}
                              title={countOfAssetClassMixValues.length.toString()}
                              donutSize="60%"
                              labels={assetClassMixKeys}
                            />
                          </div>
                          <div className="ms-10 2xl:h-[60px] 2xl:mt-3 xl:mt-1">
                            {colorMapAssetClassMix.map((item, index) => (
                              <div key={index} className="color-item text-xs flex items-center 2xl:ml-10 lg:ml-0">
                                <div className="me-3" style={{ backgroundColor: item.color, width: '10px', height: '10px', borderRadius: "5px" }}></div>
                                <span className=" text-[#929dad] font-normal ">{item.description}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="w-full">
                            <CircleChart className="2xl:w-[90%] xl:w-[80%] lg:w-[100%] h-[127px] font-bold 2xl:ml-4 lg:ml-4"
                              series={Object.values(reformattedCountOfBorrower)}
                              fillColors={colorMapBorrower.map(item => item.color)}
                              title={reformattedCountOfBorrower.length.toString()}
                              donutSize="60%"
                              labels={borrowerNames}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )
          })
        }
      </CarouselComponent>
    </div>
  );
}

export default DealsDesktop;