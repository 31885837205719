const WhiteDollarIcon = () => {
    return (
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="-0.5" width="31" height="31" rx="7.5" transform="matrix(1 0 0 -1 0.605469 31.5)" stroke="white"/>
        <path d="M16.6055 9V24" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.3555 11.25H14.7305C14.0343 11.25 13.3666 11.5266 12.8743 12.0188C12.382 12.5111 12.1055 13.1788 12.1055 13.875C12.1055 14.5712 12.382 15.2389 12.8743 15.7312C13.3666 16.2234 14.0343 16.5 14.7305 16.5H18.4805C19.1767 16.5 19.8443 16.7766 20.3366 17.2688C20.8289 17.7611 21.1055 18.4288 21.1055 19.125C21.1055 19.8212 20.8289 20.4889 20.3366 20.9812C19.8443 21.4734 19.1767 21.75 18.4805 21.75H12.1055" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      
    )
}

export default WhiteDollarIcon;