import './LenderProfileDetails.css';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import DiamondIcon from '@/components/icons/DiamondIcon';

import useStore from '@/store';
import BackIcon from '@/components/icons/BackIcon';
import InfoCard from '../../common/InfoCard';
import CustomTabs from './CustomTabs';
import { infoCardList } from '@/constants/__mock__/LenderProfileDetails';
import PropertyInfoLoanTape from './PropertyInfoLoanTape';
import FinancialInformation from './FinancialInformation';

const LenderProfileDetails = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useStore();
  const setRenderMiddleHeaderContent = useStore((state) => state.setRenderMiddleHeaderContent);
  const { name } = useParams();

  useEffect(() => {
    if (!profile || profile.lenderName !== name) {
      setProfile(null);
      navigate('/lender-profiles');
      return;
    }

    // Define the middle content
    const middleContent = (): JSX.Element => (
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <button className="mr-3" onClick={() => navigate('/lender-profiles')}>
            <BackIcon />
          </button>
          {renderLenderProfileIcon(profile.lenderName)}
          <h3 className="text-lg font-semibold ml-3">{profile.lenderName}</h3>
        </div>
        <div className="flex items-center">
          <button className="create-offer-button">Create Offer</button>
        </div>
      </div>
    );

    // Set the middle content in the store
    setRenderMiddleHeaderContent(middleContent);
  }, [setRenderMiddleHeaderContent, profile]);

  const renderLenderProfileIcon = (lenderName?: string) => {
    switch (lenderName) {
      default:
        return <DiamondIcon />;
    }
  };

  const tabContents = [
    {
      title: "Finance",
      value: "finance"
    },
    {
      title: "Marketing",
      value: "marketing"
    },
    {
      title: "HR",
      value: "hr"
    },
    {
      title: "Equity Shares",
      value: "equity-shares"
    },
    {
      title: "Human Resource",
      value: "human-resource"
    },
    {
      title: "Jira",
      value: "jira"
    },
    {
      title: "Confluence",
      value: "confluence"
    },
    {
      title: "Equity Sharing",
      value: "equity-sharing"
    }
  ];


  const renderTabContent = (value: string): JSX.Element => {
    switch (value) {
      case "finance":
        return (
          <FinancialInformation />
        );

      default:
        return <div className="m-5">TBD</div>;
    }
  }

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="flex w-full gap-x-12">
        {
          infoCardList.map((infoCard, index) => (
            <InfoCard key={index} title={infoCard.title} value={infoCard.value} icon={infoCard.icon} />
          ))
        }
      </div>

      <div className="mt-5 w-full card-container">
        <CustomTabs contents={tabContents} renderTabContent={renderTabContent} />
      </div>
      <PropertyInfoLoanTape />
    </div>
  );
};

export default LenderProfileDetails;