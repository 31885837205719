// src/components/dashboard/pages/deals/PropertyDealsTable.tsx

import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { ChevronRight, Pencil } from "lucide-react";
import { formatCurrency, getTimeInDefault } from "@/lib/utils";
import { Checkbox } from "@radix-ui/react-checkbox";
import { PropertyProps } from "./Property.interface";
import DocumentIcon from "@/components/icons/DocumentIcon";
import EditableField, { UpdatedOpportunityField } from "../../common/EditableField";
import { useEffect, useState } from "react";
import { OpportunityData } from "./view-models/CarouselCardViewModel";
import { UpdatePropertyDealAttributeInput, UpdatePropertyDealAttributesInput } from "./types";
import { useUpdatePropertyDealAttributes } from "./hooks";
import { toast } from "react-toastify";
import { useAuth } from "@/components/AuthProvider";

interface PropertyDealsTableProps {
    properties: PropertyProps[];
    opportunity?: OpportunityData;
    onDetailsClick?: (propertyId: string) => void;
    onUpdateValue?: (opportunityId: string, value: OpportunityData) => void;
    hideProgressBar?: () => void;
    showProgressBar?: () => void;
}

const PropertyDealsTable: React.FC<PropertyDealsTableProps> = ({
    properties,
    onDetailsClick,
    onUpdateValue,
    opportunity,
    hideProgressBar,
    showProgressBar }) => {
    const auth = useAuth();
    const [editedPropertyFields, setPropertyFields] = useState<UpdatedOpportunityField[]>([]);
    const [savedPropertyDealAttributes, setSavedPropertyDealAttributes] = useState<UpdatePropertyDealAttributeInput[]>([]);
    const {
        mutate: updatePropertyDealAttributes,
        data: updatedPropertyDealAttributesResponse,
        error: updatePropertyDealAttributesError,
        isLoading: isUpdatingPropertyDealAttributes } = useUpdatePropertyDealAttributes();

    useEffect(() => {
        if (isUpdatingPropertyDealAttributes) {
            showProgressBar && showProgressBar();
        } else if (properties.length) {
            hideProgressBar && hideProgressBar();
        }
    }, [isUpdatingPropertyDealAttributes]);

    useEffect(() => {
        const error = updatePropertyDealAttributesError;

        if (error) {
            hideProgressBar && hideProgressBar();
        }
    }, [updatePropertyDealAttributesError]);

    useEffect(() => {
        if (updatedPropertyDealAttributesResponse) {
            onUpdateValue && onUpdateValue(opportunity!.opportunityID, updatedPropertyDealAttributesResponse.body);
            toast.success("Property Deal attributes updated successfully.");
        }
    }, [updatedPropertyDealAttributesResponse]);

    const handleEditClick = (currentValue: string, propertyId?: string, field?: string) => {
        const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
        updatedFields.push(new UpdatedOpportunityField(propertyId || '', field || '', Number(currentValue.replace(/[$,]/g, '')), currentValue));
        setPropertyFields(updatedFields);
    };

    const handleSaveFieldClick = (updatedField: UpdatedOpportunityField, propertyId?: string, field?: string) => {
        if (!propertyId || !field) return;

        // Save the updated field value to savedPropertyDealAttributes
        const updatedAttribute: UpdatePropertyDealAttributeInput = {
            propertyID: propertyId,
            operation: 'update',
            attribute: field,
            value: updatedField.value,
        };

        const existingAttributeIndex = savedPropertyDealAttributes.findIndex(attribute => attribute.propertyID === propertyId && attribute.attribute === field);

        if (existingAttributeIndex !== -1) {
            const updatedAttributes = [...savedPropertyDealAttributes];
            updatedAttributes[existingAttributeIndex] = updatedAttribute;
            setSavedPropertyDealAttributes(updatedAttributes);
        } else {
            setSavedPropertyDealAttributes([...savedPropertyDealAttributes, updatedAttribute]);
        }
        // onUpdateValue && onUpdateValue(updatedField.id, { [updatedField.field]: updatedField.value });
        const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
        setPropertyFields(updatedFields);
    };

    const handleDiscardFieldClick = (propertyId?: string, field?: string) => {
        const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
        setPropertyFields(updatedFields);
    };

    const renderTableCell = (prefix: string, value: string, propertyId?: string, field?: string, isEditable: boolean = false) => {
        const editedField = editedPropertyFields.find((editedField) => editedField.id === propertyId && editedField.field === field);
        const savedAttribute = savedPropertyDealAttributes.find(attribute => attribute.propertyID === propertyId && attribute.attribute === field);
        if (savedAttribute) {
            value = formatCurrency(savedAttribute.value);
        }

        return (
            <TableCell className="text-base whitespace-nowrap">
                <span className="text-customGray929DAD">{prefix}</span>
                {isEditable ? (
                    <>
                        {editedField ? (
                            <EditableField
                                initialValue={editedField}
                                onSave={(value) => handleSaveFieldClick(value, propertyId, field)}
                                onDiscard={() => handleDiscardFieldClick(propertyId, field)}
                            />
                        ) : (
                            <div className="flex items-center">
                                <span className="text-black dark:text-white">{value}</span>
                                <Pencil onClick={() => handleEditClick(value, propertyId, field)} className="ml-2 cursor-pointer w-4 h-4" />
                            </div>
                        )}
                    </>
                ) : (
                    <span className="text-black dark:text-white">{value}</span>
                )}
            </TableCell>
        );
    };

    const onDiscardAllChanges = () => {
        setPropertyFields([]);
        setSavedPropertyDealAttributes([]);
    };

    const onSaveAttributesClickHandler = () => {
        if (!opportunity) return;

        const input: UpdatePropertyDealAttributesInput = {
            propertyUpdates: savedPropertyDealAttributes
        };

        // Should get response
        updatePropertyDealAttributes({
            input,
            opportunityID: opportunity.opportunityID,
            totalProperties: properties.length,
            cognitoID: auth!.sub
        });
    };

    return (
        <>
            <div className="flex justify-end mb-4">
                <button
                    className="apply-new-query-button"
                    onClick={onSaveAttributesClickHandler}>Save</button>
                <button
                    className="ml-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    onClick={onDiscardAllChanges}>Discard</button>
            </div>
            <div>

                <Table className="[&_tr:nth-child(even)]:bg-muted/50">
                    <TableBody>
                        <TableRow className="border-t-[1px]">
                            <TableCell></TableCell>
                            <TableCell>#</TableCell>
                            <TableCell>Docs</TableCell>
                            <TableCell>Address</TableCell>
                            {/* <TableCell>Zip</TableCell> */}

                            <TableCell>Est Offer</TableCell>
                            <TableCell>As Is Value (AIV)</TableCell>
                            <TableCell>Unpaid Principal Balance (UPB)</TableCell>

                            <TableCell>Origination Amount (OA)</TableCell>
                            <TableCell>Origination LTV</TableCell>
                            <TableCell>Origination AIV</TableCell>
                            <TableCell>Tax Assessment Value</TableCell>
                            <TableCell>Total Payoff Balance</TableCell>


                            <TableCell>Loan Type</TableCell>
                            <TableCell>Loan Term</TableCell>
                            <TableCell>Borrower</TableCell>
                            <TableCell>Origination Date</TableCell>

                            <TableCell>Lien Position of Lender</TableCell>
                            <TableCell>Tax Lien</TableCell>
                            <TableCell>Tax Lien Amount</TableCell>
                            <TableCell>HOA Lien</TableCell>
                            <TableCell>HOA Lien Amount</TableCell>

                            <TableCell>Default Type</TableCell>
                            <TableCell>Time in Default</TableCell>
                            <TableCell>Tax Assessment Year</TableCell>
                            {/* <TableCell>Assessed Value</TableCell>
                                <TableCell>Est Profit Amount</TableCell>
                                <TableCell>Estimate Sale Price</TableCell>
                                <TableCell>Document Type</TableCell> */}
                            {/* <TableCell>Estimated Purchase Price</TableCell> */}
                            {/* <TableCell>PB</TableCell> */}
                            <TableCell>APN</TableCell>
                            <TableCell>Asset Class</TableCell>
                        </TableRow>
                        {
                            properties.map((property, index) => (

                                <TableRow key={property.id} className="border-t-[1px]">
                                    <TableCell><Checkbox className="bg-transparent border-black w-[16px] h-[18px] border-2 mr-3" /></TableCell>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell className="flex mb-2">
                                        <div className="absolute text-blue-500 text-lg">{property.loanDocumentsCount}</div>
                                        <div className="relative"><DocumentIcon /></div>
                                    </TableCell>
                                    {renderTableCell("", property.address)}
                                    {/* {renderTableCell("", property.zip)} */}
                                    {renderTableCell("", formatCurrency((opportunity?.opportunityProperties.map((property) => property.adjustedMAOB)[index] || 0)), property.id, "adjustedMAOB", true)} {/* Est Offer */}
                                    {renderTableCell("", formatCurrency(Number(property.estimatedValue)), property.id, "AIV", true)} {/* As Is Value */}
                                    {renderTableCell("", formatCurrency(property.estimated_mortgage_balance))}
                                    {renderTableCell("", formatCurrency(Number(property.firstLienPostionMortgageOrginationAmount)))}
                                    {renderTableCell("", property.firstLienPositionLoanOriginationLTV !== null ? `${property.firstLienPositionLoanOriginationLTV}%` : '--')}
                                    {renderTableCell("", property.firstLienPositionLoanOriginationLTV !== null ? formatCurrency(Number(property.firstLienPositionLoanOriginationAIV)) : '--')}
                                    {renderTableCell("", formatCurrency(property.assessedValue))}
                                    {renderTableCell("", formatCurrency(Number(property.totalfirstLienPositionPayoffBalance)))}


                                    {renderTableCell("", property.firstLienPostionMortgageLoanType || '--')}
                                    {renderTableCell("", property.firstLienPositionMortgageLoanTerm !== null ? `${property.firstLienPositionMortgageLoanTerm} ${property.firstLienPositionLoanTermType}` : '--')}
                                    {renderTableCell("", property.borrower_calc)}
                                    {renderTableCell("", property.recordingDate.split(' ')[0])}




                                    {renderTableCell("", 'FIRST')}
                                    {renderTableCell("", property.taxLien === '1' ? "YES" : "NO")}
                                    {renderTableCell("", '--')}
                                    {renderTableCell("", '--')}
                                    {renderTableCell("", '--')}

                                    {renderTableCell("", property.foreclosure_document_type)}
                                    {renderTableCell("", property.defaultRecordingDate !== 'null' ? getTimeInDefault(property.defaultRecordingDate || '') : '--')}
                                    {renderTableCell("", property.assessedYear)}
                                    {/* {renderTableCell("", formatCurrency(property.assessedValue))} */}
                                    {/* {renderTableCell("LTV - ", property.totalOriginationAmount + '%')} */}
                                    {/* {renderTableCell("", formatCurrency(deal.summary.averagePropertyValue))}
                                {renderTableCell("", formatCurrency(property.marketValue))}
                                {renderTableCell("", property.documentType)} */}
                                    {/* {renderTableCell("", '??')} */}
                                    {/* {renderTableCell("", '??')} */}
                                    {renderTableCell("", property.apn)}
                                    <TableCell className="text-customGray929DAD text-base">
                                        {property.assetClass}
                                    </TableCell>
                                    <TableCell>
                                        <button className="text-black dark:text-white" onClick={() => onDetailsClick && onDetailsClick(property.id)}>
                                            <ChevronRight />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default PropertyDealsTable;